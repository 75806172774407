import {Component, Inject, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProfessionalModel} from '../../models/professional.model';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {SelectorOption} from '../../types/selector.interface';
import {TimeUnitsAdministrationRoute, TimeUnitsTherapy} from 'src/app/therapeutic/types/therapeutic.interface';
import {LoadingService} from "../../services/loading.service";
import {MedicinesInterfaceBD} from "../../../settings/medicines/types/medicines.interface";
import {MedicinesService} from "../../../settings/medicines/services/medicines.service";

import {ValidatorsService} from "../../services/validators.service";
import {getError} from "../../types/error";

@Component({
  selector: 'app-therapy-dialog',
  templateUrl: './therapy-dialog.component.html',
  styleUrls: ['./therapy-dialog.component.scss'],
})
export class TherapyDialogComponent implements OnInit {
  public therapyForm: FormGroup;
  public dataMedicine: MedicinesInterfaceBD[] = [];
  medicines = [{id: 1, name: 'Dipirona'}];
  public timeUnitsList: SelectorOption<TimeUnitsTherapy>[] = [
    {value: TimeUnitsTherapy.DAYS, label: 'Día(s)'},
    {value: TimeUnitsTherapy.HOURS, label: 'Hora(s)'},
    {value: TimeUnitsTherapy.MINUTES, label: 'Minuto(s)'},
  ];
  public frequencyUnitsList: SelectorOption<TimeUnitsTherapy>[] = [
    {value: TimeUnitsTherapy.HOURS, label: 'Hora(s)'},
    {value: TimeUnitsTherapy.MINUTES, label: 'Minuto(s)'},
  ];
  public administrationRouteList: SelectorOption<TimeUnitsAdministrationRoute>[] = [
    {value: TimeUnitsAdministrationRoute.INTRAMUSCULAR, label: 'Intramuscular'},
    {value: TimeUnitsAdministrationRoute.INTRAVENOUS, label: 'Intravenosa'},
    {value: TimeUnitsAdministrationRoute.NASAL, label: 'Nasal'},
    {value: TimeUnitsAdministrationRoute.OCULAR, label: 'Ocular'},
    {value: TimeUnitsAdministrationRoute.ORAL, label: 'Oral'},
    {value: TimeUnitsAdministrationRoute.OTIC, label: 'Ótica'},
    {value: TimeUnitsAdministrationRoute.SUBCUTANEOUS, label: 'Subcutánea'},
    {value: TimeUnitsAdministrationRoute.SUBLINGUAL, label: 'Sublingual'},
    {value: TimeUnitsAdministrationRoute.RECTAL, label: 'Rectal'},
    {value: TimeUnitsAdministrationRoute.TOPIC, label: 'Tópico'},
    {value: TimeUnitsAdministrationRoute.TRANSDERMAL, label: 'Transdérmico'},
  ];


  constructor(
    public dialogRef: MatDialogRef<TherapyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    public validatorsService: ValidatorsService,
    private medicinesService: MedicinesService,
  ) {
  }

  async ngOnInit() {
    this.buildForm();
    await this.getData();
    if (this.data) {
      this.setUpdateData();
    }
    this.getChangesInputsFrequencyAndTime();
  }

  close(data?: ProfessionalModel) {
    this.dialogRef.close(data);
  }

  /**
   * initialize the fields that the form has
   */
  buildForm() {
    this.therapyForm = this.fb.group({
      denomination: new FormControl('', Validators.required),
      dose: new FormControl('', [Validators.required, Validators.pattern(this.validatorsService.positiveTwoDecimalNumber)]),
      frequency: new FormControl('', [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]),
      frequencyUnit: new FormControl('', Validators.required),
      timeUnit: new FormControl('', Validators.required),
      time: new FormControl('', [Validators.required, Validators.pattern(this.validatorsService.numberValidator)]),
      administrationRoute: new FormControl('', Validators.required),
      quantity: new FormControl({value: '', disabled: true}, Validators.required),
      indication: new FormControl('', Validators.required),
    });

  }

  /**
   * Get the changes by frequency and time
   */
  public getChangesInputsFrequencyAndTime() {
    let frequencyUnits = '';
    let timeUnits = '';
    let frequency = 0;
    let time = 0;
    this.therapyForm.get('frequency')?.valueChanges.subscribe((change) => {
      frequency = change;
      this.calculateQuantityTotal(frequencyUnits, timeUnits, frequency, time);
    });
    this.therapyForm.get('frequencyUnit')?.valueChanges.subscribe((change) => {
      frequencyUnits = change;
      this.calculateQuantityTotal(frequencyUnits, timeUnits, frequency, time);
    });
    this.therapyForm.get('time')?.valueChanges.subscribe((change) => {
      time = change;
      this.calculateQuantityTotal(frequencyUnits, timeUnits, frequency, time);
    });
    this.therapyForm.get('timeUnit')?.valueChanges.subscribe((change) => {
      timeUnits = change;
      this.calculateQuantityTotal(frequencyUnits, timeUnits, frequency, time);
    })
  }

  /**
   * Calculate the quantity total
   * @param frequencyUnits  Units by frequency
   * @param timeUnits Units by time
   * @param frequency frequency value
   * @param time time value
   */
  public calculateQuantityTotal(frequencyUnits: string, timeUnits: string, frequency: number, time: number) {
    let total = 0;
    const frequencyData = frequencyUnits === TimeUnitsTherapy.HOURS ? 60 * frequency : frequency !== 0 && frequencyUnits ? frequency : 0;
    const timeData = timeUnits === TimeUnitsTherapy.DAYS ? 24 * 60 * time : timeUnits === TimeUnitsTherapy.HOURS ? 60 * time : time !== 0 && timeUnits ? time : 0;
    total = frequencyData !== 0 ? (timeData / frequencyData) : 0;
    this.therapyForm.get('quantity')?.setValue(total);
  }

  cancel() {
    this.close();
  }

  changeQuantity() {
    this.therapyForm.get('quantity')?.setValue(
      Number(this.therapyForm.get('time')?.value) +
      Number(this.therapyForm.get('frequency')?.value) +
      Number(this.therapyForm.get('dose')?.value)
    );
  }

  onSubmit() {
    this.therapyForm.enable();
    if (this.therapyForm.valid) {
      const medicineT = this.dataMedicine.find(
        (medicine) => medicine.denomination === this.therapyForm.get('denomination')?.value
      );
      const medicine = this.dataMedicine.find(medicine =>
        medicine.denomination == this.therapyForm.get('denomination')?.value);
      this.close({
        ...this.therapyForm.value,
        medicine: medicineT ? medicineT.denomination : '',
        drug: medicineT,
        drugId: medicine?.id,
      });
    } else {
      const errorDataUser: AllValidationErrors = getFormValidationErrors(
        this.therapyForm.controls
      ).shift() as any;
      this.therapyForm.get("quantity")?.disable();
      if (errorDataUser.control_name === 'time') {
        this.dialogService.errorModal(`El campo Tiempo de Tratamiento es requerido`);
        return;
      }
      this.dialogService.errorModal(searchFormError(errorDataUser));
    }
  }

  private setUpdateData() {
    this.therapyForm.patchValue(this.data);
  }

  /**
   * get medicine data
   * @param params
   */
  public async getData() {
    this.loadingService.setLoading(true);
    try {
      this.dataMedicine = this.orderOptions(await this.medicinesService.getMedicines());
      this.dataMedicine = this.dataMedicine.map((data) => {
        return {
          ...data,
          typeMedicine: data.type.name,
        }
      });
      this.dataMedicine = this.dataMedicine.filter(data => data.state);
      if (this.dataMedicine.length === 0) {
        this.dialogService.infoModal('No hay información registrada');
        this.loadingService.setLoading(false);
      }
    } catch (e) {
      this.dialogService.errorModal('Ha ocurrido un error al traer los datos');
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  orderOptions(optionList: MedicinesInterfaceBD[]){
    return optionList.sort( (a,b)=> {
      const itemA = a.denomination? a.denomination.toString().toLowerCase(): '';
      const itemB = b.denomination? b.denomination.toString().toLowerCase(): '';
      return itemA.localeCompare(itemB)
    })
  }

}
