import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServerResponse } from '../types/http.interfaces';
import { FormGroup } from '@angular/forms';
import { AdministrationInterface } from '../types/administration.interface';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class GeneraService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogService: DialogService
  ) {}

  public async getGenera(includeInactive: boolean = false) {
    let res;
    if (includeInactive) {
      res = await this.http
        .get<ServerResponse<AdministrationInterface[]>>(
          `${this._baseUrl}/genera`
        )
        .toPromise();
    } else {
      let params = new HttpParams();
      params = params.set('state', true);
      res = await this.http
        .get<ServerResponse<AdministrationInterface[]>>(
          `${this._baseUrl}/genera`,
          { params }
        )
        .toPromise();
    }
    if (res.data.length > 0) {
      return res.data;
    } else {
      this.dialogService.infoModal(
        'Debe registrar un género antes de continuar'
      );
      await this.router.navigateByUrl('/home/genera/form');
      return [];
    }
  }

  //service to get data agree the filter
  public async getGeneraFilter(
    params?: HttpParams
  ): Promise<AdministrationInterface[]> {
    const res = await this.http
      .get<ServerResponse<AdministrationInterface[]>>(
        `${this._baseUrl}/genera`,
        { params }
      )
      .toPromise();
    return res.data;
  }

  async getGeneraById(id: number): Promise<AdministrationInterface> {
    const res = await this.http
      .get<ServerResponse<AdministrationInterface>>(
        `${this._baseUrl}/genera/${id}`
      )
      .toPromise();
    return res.data;
  }

  //update data genera
  public async updateGeneraRecords(form: AdministrationInterface, id: number) {
    return await this.http
      .patch<AdministrationInterface>(`${this._baseUrl}/genera/${id}`, form)
      .toPromise();
  }

  /**
   * Method to update only status
   * @param id
   * @param stateSearch
   */
  public async updateOnlyStatus(id: number, stateSearch: boolean) {
    return await this.http
      .patch<AdministrationInterface>(`${this._baseUrl}/genera/${id}`, {
        state: stateSearch,
      })
      .toPromise();
  }

  public async postNewGenera(data: FormGroup) {
    return await this.http.post(`${this._baseUrl}/genera`, data).toPromise();
  }

  public async deleteRegisterGenera(id: number) {
    return this.http.delete(`${this._baseUrl}/genera/${id}`).toPromise();
  }

  public async downloadGenera(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http
        .get<any>(`${this._baseUrl}/genera/download`, {
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise();
    }
    return result;
  }
}
