import {MedicinesInterfaceBD} from "../../settings/medicines/types/medicines.interface";

export interface Medicine {
  id: number;
  available: number;
  name: string;
  administrationRoute: RouteOfAdministration;
}

export interface MedicineAdministrated {
  medicine: MedicinesInterfaceBD;
  initialDose: string;
  finalDose: string;
  administrationRoute: RouteOfAdministration;
}

export interface MedicineAdministratedCreatedInput {
  drug: string;
  initialDose: string;
  finalDose: string;
  administrationRoute: string
}

export enum RouteOfAdministration {
  ORAL = 'Oral',
  INTRAVENOUS = 'Intravenosa',
  INTRAMUSCULAR = 'Intramuscular',
  SUBCUTANEOUS = 'Subcutánea',
  SUBLINGUAL = 'Sublingual',
  OCULAR = 'Ocular',
  OTIC = 'Ótica',
  NASAL = 'Nasal',
  RECTAL = 'Rectal',
  TOPIC = 'Tópico',
  TRANSDERMAL = 'Transdérmico'
}

export const RouteOfAdministrationHash: { [RouteOfAdministration: string]: string } = {
  Oral: 'ORAL',
  Intravenosa: 'INTRAVENOUS',
  Intramuscular: 'INTRAMUSCULAR',
  Subcutánea: 'SUBCUTANEOUS',
  Sublingual: 'SUBLINGUAL',
  Ocular: 'OCULAR',
  ótica: 'OTIC',
  Nasal: 'NASAL',
  Rectal: 'RECTAL',
  Tópico: 'TOPIC',
  Transdérmico: 'TRANSDERMAL'
};



