import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MedicalHistoryService} from 'src/app/medical-history/services/medical-history.service';
import {ClinicHistoryDB} from 'src/app/medical-history/types/medical-history.interface';
import {Safeguard} from 'src/app/safeguard/types/safeguard.interface';
import {SeizureRecord} from 'src/app/seizure-record/types/seizure-record.interface';
import {DialogService} from '../../services/dialog.service';
import {GeneraService} from '../../services/genera.service';
import {SpeciesService} from '../../services/species.service';
import {AdministrationInterface} from '../../types/administration.interface';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {LoadingService} from "../../services/loading.service";
import {IndividualTable} from "../../types/individual.interface";
import {MatOptionSelectionChange} from '@angular/material/core';

@Component({
  selector: 'app-specimen-dialog',
  templateUrl: './specimen-dialog.component.html',
  styleUrls: ['./specimen-dialog.component.scss'],
})
export class SpecimenDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger) autoMedical: MatAutocompleteTrigger;

  public deliverSpecimenSaveForm: FormGroup;
  public medicalHistoriesList: ClinicHistoryDB[];
  public generaList: AdministrationInterface[];
  public speciesList: AdministrationInterface[];
  public title: string = '';
  public filteredOptionsGenera: Observable<AdministrationInterface[]>;
  public filteredOptionsSpecie: Observable<AdministrationInterface[]>;
  public filteredOptionsMedical: Observable<ClinicHistoryDB[]>;
  public isClinicHistorySelected = false;
  public dataEdit: IndividualTable;
  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<SpecimenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, dataEdit: IndividualTable },
    private fb: FormBuilder,
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private loadingService: LoadingService,
    private medicalHistoryService: MedicalHistoryService,
    private dialogService: DialogService
  ) {
    this.title = data.title;
    this.dataEdit = data.dataEdit;
  }

  async ngOnInit() {
    this.buildDeliverSpecimenSaveForm();
    await this.getSelectorOptions();
    this.getFilteredOptions('genus');
    this.getFilteredOptions('specie');
    this.getFilteredOptions('preliminaryClinicHistory');
    if (this.data.dataEdit) {
      await this.editDeliverSpecimens();
    }
    this.deliverSpecimenSaveForm.get('seizureRecordNumber')?.disable();
    this.deliverSpecimenSaveForm.get('safePassageRecord')?.disable();
    this.deliverSpecimenSaveForm.get('quantity')?.disable();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.addSubscription(this.autoMedical, 'preliminaryClinicHistory', this.deliverSpecimenSaveForm);
  }

  private addSubscription(
    trigger: MatAutocompleteTrigger,
    control: string,
    form: FormGroup
  ) {
    const sub1 = trigger.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        form.get(control)?.setValue(null);
        trigger.closePanel();
      }
    });
    this.subscriptions.add(sub1);
  }

  /**
   * Method update register
   */
  public async editDeliverSpecimens() {
    this.isClinicHistorySelected = true;
    await this.deliverSpecimenSaveForm.patchValue({
      preliminaryClinicHistory: this.data.dataEdit.preliminaryClinicHistory,
      commonName: this.data.dataEdit.commonName,
      genus: this.data.dataEdit.genus,
      specie: this.data.dataEdit.specie,
      seizureRecordNumber: this.data.dataEdit.seizureRecord,
      safePassageRecord: this.data.dataEdit.safePassageRecord,
      quantity: this.data.dataEdit.quantity,
      observationsIndividual: this.data.dataEdit.observations
    });
    this.autoMedical.closePanel();
  }

  private getFilteredOptions(control: string) {
    let filteredOption: Observable<any[]>;
    filteredOption = this.deliverSpecimenSaveForm.controls[
      control
      ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', control))
    );
    switch (control) {
      case 'genus':
        this.filteredOptionsGenera = filteredOption;
        break;
      case 'specie':
        this.filteredOptionsSpecie = filteredOption;
        break;
      case 'preliminaryClinicHistory':
        this.filteredOptionsMedical = filteredOption;
        break;
    }
  }

  private _filter(
    value: string,
    control: string
  ): (AdministrationInterface | ClinicHistoryDB)[] {
    const filterValue = value.toString().toLowerCase();
    let optionFilter: (
      | AdministrationInterface
      | ClinicHistoryDB
      )[] = [];
    switch (control) {
      case 'genus':
        optionFilter = this.generaList.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        break;
      case 'specie':
        optionFilter = this.speciesList.filter((option) =>
          option.name.toLowerCase().includes(filterValue)
        );
        break;
      case 'preliminaryClinicHistory':
        optionFilter = this.medicalHistoriesList.filter((option) =>
          option.id.toString().toLowerCase().includes(filterValue)
        );
        break;
    }
    return optionFilter
      .sort((a, b) => {
        let aValue
        let bValue
        if (['specie', 'genus'].includes(control)) {
          aValue = (a as AdministrationInterface).name
          bValue = (b as AdministrationInterface).name
        } else {
          aValue = (a as ClinicHistoryDB).id
          bValue = (b as ClinicHistoryDB).id
        }
        if (typeof aValue === 'number' && typeof bValue === 'number') return aValue - bValue
        return aValue.toString().toLowerCase().localeCompare(bValue.toString().toLowerCase(), undefined, {
          sensitivity: 'base',
        })
      });
  }

  close(data?: any) {
    this.dialogRef.close(data);
  }

  /**
   * Get selector options
   * @private
   */
  private async getSelectorOptions() {
    this.loadingService.setLoading(true);
    try {
      this.generaList = await this.generaService.getGenera();
      this.speciesList = await this.specieService.getSpecies();
      this.medicalHistoriesList =
        await this.medicalHistoryService.getMedicalHistory();
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  validateClinicHistory() {
    let isValueInArray = this.medicalHistoriesList.some(history => history.id === Number(this.deliverSpecimenSaveForm.get('preliminaryClinicHistory')?.value));
    if (!isValueInArray) {
      this.deliverSpecimenSaveForm.get('preliminaryClinicHistory')?.setValue('');
      this.deliverSpecimenSaveForm.get('preliminaryClinicHistory')?.setErrors({required: true});
    } else {
      this.deliverSpecimenSaveForm.get('preliminaryClinicHistory')?.setErrors(null);
    }
  }


  public async listenFieldClinicHistory(event: MatOptionSelectionChange) {
    this.loadingService.setLoading(true);
    let clinicHistory = await this.medicalHistoryService.getMedicalHistoryById(event.source.value);
    this.isClinicHistorySelected = true;
    let quantity = 0;
    this.deliverSpecimenSaveForm.patchValue({
      commonName: clinicHistory.specimen?.commonName ? clinicHistory.specimen.commonName : '',
      genus: clinicHistory.specimen.genus?.name ? clinicHistory.specimen.genus.name : '',
      specie: clinicHistory.specimen.specie?.name ? clinicHistory.specimen.specie.name : '',
    });
    if (!clinicHistory.specimen.genus) {
      this.getFilteredOptions('genus');
    }
    if (clinicHistory.safePassageRecordId) {
      this.deliverSpecimenSaveForm.controls['safePassageRecord'].setValue(clinicHistory.safePassageRecordId);
      quantity = 1;
    } else {
      this.deliverSpecimenSaveForm.controls['safePassageRecord'].reset();
    }
    if (clinicHistory.seizureRecordId) {
      this.deliverSpecimenSaveForm.controls['seizureRecordNumber'].setValue(clinicHistory.seizureRecordId);
      quantity = 1;
    } else {
      this.deliverSpecimenSaveForm.controls['seizureRecordNumber'].reset();
    }
    this.deliverSpecimenSaveForm.controls['quantity'].setValue(quantity);
    this.loadingService.setLoading(false);
  }

  /**
   * initialize the fields that the form has
   */
  buildDeliverSpecimenSaveForm() {
    this.deliverSpecimenSaveForm = this.fb.group({
      preliminaryClinicHistory: new FormControl('', Validators.required),
      commonName: new FormControl('', Validators.required),
      genus: new FormControl('', Validators.required),
      specie: new FormControl('', Validators.required),
      seizureRecordNumber: new FormControl(''),
      safePassageRecord: new FormControl(''),
      quantity: new FormControl(''),
      observationsIndividual: new FormControl('', Validators.required),
    });
  }

  onSubmit() {
    this.validateClinicHistory();
    if (this.deliverSpecimenSaveForm.valid) {
      const genusId = this.getGeneraId(
        this.deliverSpecimenSaveForm.get('genus')?.value
      );
      const specieId = this.getSpecieId(
        this.deliverSpecimenSaveForm.get('specie')?.value
      );
      let individual: IndividualTable = {
        preliminaryClinicHistory: this.deliverSpecimenSaveForm.get(
          'preliminaryClinicHistory'
        )?.value,
        seizureRecord: this.deliverSpecimenSaveForm.get('seizureRecordNumber')
          ?.value,
        safePassageRecord:
        this.deliverSpecimenSaveForm.get('safePassageRecord')?.value,
        quantity: this.deliverSpecimenSaveForm.get('quantity')?.value as number,
        observations: this.deliverSpecimenSaveForm.get('observationsIndividual')
          ?.value,
        commonName: this.deliverSpecimenSaveForm.get('commonName')?.value,
        genus: this.deliverSpecimenSaveForm.get('genus')?.value,
        specie: this.deliverSpecimenSaveForm.get('specie')?.value,
        scientificName:
          this.deliverSpecimenSaveForm.get('genus')?.value +
          ' ' +
          this.deliverSpecimenSaveForm.get('specie')?.value,
        genusId: genusId,
        specieId: specieId,
      };
      if (this.data.dataEdit) {
        individual.firstQuantity = this.data.dataEdit.quantity;
      }
      this.close(individual);
    } else {
      const errorDelivery: AllValidationErrors = getFormValidationErrors(
        this.deliverSpecimenSaveForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorDelivery));
    }
  }

  cancel() {
    this.close();
  }

  public getGeneraId(generaName: string) {
    let generaId = 0;
    for (const genera of this.generaList) {
      if (genera.name === generaName) {
        generaId = genera.id;
      }
    }
    return generaId;
  }

  public getSpecieId(specieName: string) {
    let specieId = 0;
    for (const specie of this.speciesList) {
      if (specie.name === specieName) {
        specieId = specie.id;
      }
    }
    return specieId;
  }
}
