import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuOptions } from '../../entities/menu-options/menu-options.model';
import { HeaderAndSidebarService } from '../../services/header-and-sidebar.service';
import { MENU_OPTIONS } from '../../entities/menu-options/menu-options-data';
import { RegisterSVGIconsService } from '../../services/register-svg-icons.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [RegisterSVGIconsService],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public mobileQuery: MediaQueryList;
  public menuOptions: MenuOptions[];
  public selectedOption: MenuOptions & { subMenuIndex: number };

  public period_observation = 'assets/svg/title-section/date_range.svg';
  list = 'clock';
  ngOnInit(): void {
    this.menuOptions = MENU_OPTIONS;
  }

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public headerService: HeaderAndSidebarService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public toggleAction() {
    this.headerService.isSidebarBig = !this.headerService.isSidebarBig;
  }
}
