import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {
  SeizureIncomeHashSpanish,
  SeizureRecord,
} from '../types/seizure-record.interface';
import {ServerResponse} from '../../shared/types/http.interfaces';
import {FormGroup} from '@angular/forms';
import {CalendarManagerService} from '../../shared/services/calendar-manager.service';
import {SafeguardSeizureStatusHashSpanish} from '../../safeguard/types/safeguard.interface';
import {DatePipe} from '@angular/common';
import {SeizureForm} from "../components/seizure-form/seizure-form.component";

@Injectable({
  providedIn: 'root',
})
export class SeizureService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(
    private http: HttpClient,
    private calendarManager: CalendarManagerService,
    private datePipe: DatePipe
  ) {
  }

  public async getSeizureRecords(
    params?: HttpParams
  ): Promise<SeizureRecord[]> {
    const res = await this.http
      .get<ServerResponse<SeizureRecord[]>>(`${this._baseUrl}/seizurerecords`, {
        params,
      })
      .toPromise();
    return res.data.map((data) => {
      const incomeClassificationT =
        SeizureIncomeHashSpanish[data.incomeClassification];
      const statusT = SafeguardSeizureStatusHashSpanish[data.status.toString()];
      const regionT = data.regional?.name || '';
      return {
        incomeClassificationT,
        regionT,
        dataT: this.datePipe.transform(data.date, 'dd/MM/yyyy'),
        statusT,
        ...data,
      };
    });
  }

  public async saveSeizureRecords(form: SeizureForm, file: File, specimens: any) {
    const formData = this.getSaveUpdateBody(form, file, specimens);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const res = await this.http
      .post<SeizureRecord>(`${this._baseUrl}/seizurerecords`, formData, {
        headers,
      })
      .toPromise();
  }

  public async updateSeizureRecords(
    form: SeizureForm,
    file: File,
    specimens: any,
    id: number
  ) {
    const formData = this.getSaveUpdateBody(form, file, specimens, true);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return await this.http
      .put<SeizureRecord>(`${this._baseUrl}/seizurerecords/${id}`, formData, {
        headers,
      })
      .toPromise();
  }

  public async downloadSeizure(params: HttpParams) {
    return await this.http
      .get<any>(`${this._baseUrl}/seizurerecords/download`, {
        params,
        responseType: 'blob' as 'json',
      })
      .toPromise();
  }

  public async getSeizureById(id: number): Promise<SeizureRecord> {
    const res = await this.http
      .get<ServerResponse<SeizureRecord>>(
        `${this._baseUrl}/seizurerecords/${id}`
      )
      .toPromise();
    return res.data;
  }

  private getSaveUpdateBody(
    form: SeizureForm,
    file: File,
    specimens: string,
    update = false
  ) {
    const formData = new FormData();
    if (!update) {
      formData.append('recordNumber', form.recordNumber);
      formData.append(
        'date',
        this.calendarManager.changeFormatDate(form.date)
      );
      formData.append('regionalId', form.regional.toString());
      formData.append(
        'incomeClassification',
        form.incomeClassification
      );
      formData.append('originDepartmentId', form.originDepartmentId);
      formData.append('originMunicipalityId', form.originMunicipalityId);
    }
    formData.append('quantity', form.quantity.toString());
    if (file) {
      formData.append('files', file);
    }
    formData.append('specimens', specimens);
    return formData;
  }

  public async deleteRegisterSeizure(id: number) {
    return this.http
      .delete(`${this._baseUrl}/seizurerecords/${id}`)
      .toPromise();
  }

  async downloadFile(response: string, filename: string): Promise<void> {
    fetch(
      response
    ).then((data: Response) => {
      data.blob().then((data: Blob) => {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = `Acta de incautación ${filename}.pdf`;
        link.click();
      });
    });
  }

  /**
   * delete document of seizure record by id
   * @param id
   * @param filesToDelete
   */
  public async deleteDocumentSeizure(id: number, filesToDelete: number[]) {
    let formData = new FormData();
    formData.append('filesToDelete', JSON.stringify(filesToDelete));
    return this.http
      .put(`${this._baseUrl}/seizurerecords/${id}`, formData)
      .toPromise();
  }
}
