import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "../../services/dialog.service";
import {DiagnosticTestService} from "../../../settings/diagnostic-test/services/diagnostic-test.service";
import {DiagnosticTestInterface} from "../../../settings/diagnostic-test/types/diagnostic-test.interface";
import {LoadingService} from "../../services/loading.service";
import {AllValidationErrors, getFormValidationErrors, searchFormError} from "../../types/get-errors-form";
import {ValidatorsService} from "../../services/validators.service";


@Component({
  selector: 'app-edit-configuration',
  templateUrl: './edit-configuration.component.html',
  styleUrls: ['./edit-configuration.component.scss']
})
export class EditConfigurationComponent implements OnInit {
  public dataSource: DiagnosticTestInterface;
  public nameInput: string;

  constructor(public dialogRef: MatDialogRef<EditConfigurationComponent>,
              private diagnosticTestService: DiagnosticTestService,
              private loadingService: LoadingService,
              private dialogService: DialogService,
              public validatorsService: ValidatorsService,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                title: string;
                id?: number;
                section: string
              }) {
  }

  public generalForm: FormGroup;

  async ngOnInit() {
    this.buildGeneralForm();
    await this.getDataUpdate(this.data.id);
    if (this.dataSource) {
      this.showDataUpdate();
    }
  }

  /**
   * Gets the data corresponding to the section
   * @param id
   */
  public async getDataUpdate(id: number | undefined) {
    this.loadingService.setLoading(true);
    try {
      if (this.data.section == 'diagnostic') {
        this.nameInput = 'Prueba Diagnóstica';
        this.dataSource =
          await this.diagnosticTestService.getDiagnosticTestById(
            Number(id)
          );
      } else {
        this.nameInput = 'Nombre';
      }
    } catch (e) {
      console.error(e);
      this.dialogService.errorModal(
        'Hay un error al traer los datos asociados al id: ' +
        this.data.id +
        '. Por favor inténtelo nuevamente'
      );
    }
    this.loadingService.setLoading(false);
  }

  /**
   * initialize the form
   */
  public buildGeneralForm() {
    this.generalForm = this.fb.group({
      id: [{value: '', disabled: true}],
      name: ['', [Validators.required, Validators.maxLength(80)]],
      value: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(this.validatorsService._positiveDecimalNumberPattern)]],
      active: [false],
    });
  }

  /**
   * Show the data found in the modal form
   */
  public showDataUpdate() {
    this.generalForm.patchValue({
      id: this.dataSource.id,
      name: this.dataSource.name,
      value: this.dataSource.value,
      active: this.dataSource.active,
    });
  }

  /**
   * Close modal
   */
  public async cancel() {
    if (this.dataSource.name !== this.generalForm.get('name')?.value
      || this.dataSource.value !== this.generalForm.get('value')?.value
      || this.dataSource.active !== this.generalForm.get('active')?.value) {
      const dialog = this.dialogService.openDialog(
        {
          icon: 'report',
          isButtons: true,
          orientationIconTop: true,
          subMessage: '¿Está seguro que desea Cancelar?',
          isActions: true,
          typeConfirm: 'confirm',
        },
        'my-dialog-confirm-class'
      );
      dialog.afterClosed().subscribe(async (action) => {
        if (action) {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  /**
   * Assigns the changed values of the form to the object that is sent to update
   */
  public onSubmit() {
    if (this.generalForm.invalid) {
      let errorDiagnosticTestForm: AllValidationErrors = getFormValidationErrors(
        this.generalForm.controls
      ).shift() as any;
      errorDiagnosticTestForm.control_name = errorDiagnosticTestForm.control_name == 'name' ? 'name_diagnostic' : 'name_value';
      this.dialogService.errorModal(searchFormError(errorDiagnosticTestForm));
    } else {
      let body: DiagnosticTestInterface = {
        id: this.generalForm.get('id')?.value,
        name: this.generalForm.get('name')?.value,
        value: this.generalForm.get('value')?.value,
        active: this.generalForm.get('active')?.value
      }
      this.close(body)
    }
  }

  /**
   * Close modal
   */
  public close(data?: DiagnosticTestInterface) {
    this.dialogRef.close(data);
  }
}
