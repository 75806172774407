import {DialogComponent} from '../components/dialog/dialog.component';
import {ComponentType} from '@angular/cdk/portal';
import {Injectable, TemplateRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogConfig} from '@angular/material/dialog/dialog-config';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {StatusDialogComponent} from "../components/status-dialog/status-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog, private matDialogRef: MatDialog) {
  }

  public openDialog(data: any, panelClass?: string): MatDialogRef<any> {
    return this.dialog.open(DialogComponent, {
      panelClass: panelClass ? panelClass : 'my-dialog-register-class',
      data: data,
    });
  }

  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    config.width = config.width ? config.width : '25%';
    config.height = config.height ? config.height : 'calc(var(--vh, 1vh) * 90)';
    config.minHeight = config.minHeight ? config.minHeight : '600px';
    config.maxHeight = config.maxHeight ? config.maxHeight : '518px';
    config.minWidth = config.minWidth ? config.minWidth : '570px';
    config.maxWidth = config.maxWidth ? config.maxWidth : '30% !important';

    return this.matDialogRef.open(componentOrTemplateRef, config);
  }

  public async confirmDialog(message?: string) {
    const dialog = this.openDialog(
      {
        message: message,
        icon: 'report',
        isButtons: true,
        orientationIconTop: true,
        subMessage: '¿Está seguro que desea continuar?',
        isActions: true,
        typeConfirm: 'confirm',
      },
      'my-dialog-confirm-class'
    );
    const response = await dialog
      .afterClosed()
      .toPromise()
      .then((action) => {
        return action;
      });
    return response;
  }

  public isDialogOpen(): boolean {
    return this.dialog.openDialogs.length === 0;
  }

  public errorModal(message: string, showErrorDate: boolean = false, date?: Date | string) {
    const dialog = this.openDialog(
      {
        message: message,
        icon: 'highlight_off',
        isButtons: false,
        orientationIconTop: true,
        isActions: false,
        showErrorDate: showErrorDate,
        date: date,
        typeConfirm: 'alert',
      },
      'my-dialog-alert-class'
    );
  }

  public infoModal(message: string) {
    const dialog = this.openDialog(
      {
        message: message,
        icon: 'info',
        isButtons: false,
        orientationIconTop: true,
        isActions: false,
        typeConfirm: 'info',
      },
      'my-dialog-alert-class'
    );
  }

  public infoModalSubMessage(only: boolean = false, message: string, subMessage: string, icon: string) {
    const dialog = this.openDialog(
      {
        only: only,
        message: message,
        icon: icon,
        isButtons: false,
        orientationIconTop: true,
        isActions: true,
        subMessage: subMessage,
        typeConfirm: '',
      },
      'my-dialog-success-class'
    );
    setTimeout(() => dialog.close(), 2500)
  }

  public successModal(message: string, roundedCorners?: boolean) {
    if (roundedCorners) {
      const dialog = this.openDialog(
        {
          message: message,
          icon: 'lock',
          isButtons: false,
          orientationIconTop: false,
          isActions: false,
          typeConfirm: 'success',
        },
        'my-dialog-success-class-rounded-corners'
      );
    } else {
      const dialog = this.openDialog(
        {
          message: message,
          icon: 'task',
          isButtons: false,
          orientationIconTop: false,
          isActions: true,
          date: new Date(),
          typeConfirm: 'success',
        },
        'my-dialog-success-class'
      );
    }
  }

  public async confirmOutputForm(closeAllDialogs: boolean = true, showMsg = true, yesNo = false, message = '¿Está seguro que desea continuar?'): Promise<boolean> {
    const msg = {
      icon: 'report',
      isButtons: true,
      orientationIconTop: true,
      subMessage: message,
      isActions: true,
      typeConfirm: yesNo ? 'yesNo' : 'confirm',
    };
    const dialog = this.openDialog(
      showMsg ? {
        message: `Al salir perderá los datos registrados.`
        , ...msg
      } : msg,
      'my-dialog-confirm-class'
    );
    const response = await dialog
      .afterClosed()
      .toPromise()
      .then((action) => {
        return action;
      });
    if (closeAllDialogs) {
      this.matDialogRef.closeAll();
    }
    return response;
  }

  public async statusDialog(message: string, icon: string) {
    this.dialog.open(StatusDialogComponent, {
      panelClass: 'status-dialog-class',
      data: {message, icon}
    });
  }

  public async closeAll() {
    this.dialog.closeAll();
  }
}
