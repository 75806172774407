<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-0-5">
    <span class="material-icons-bold blue">add</span>
    <div class="sub-title-blue-left">{{title}}</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <form novalidate [formGroup]="individualForm" class="input-form flex-column-start mt-2">
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Nombre Común</mat-label>
      <input matInput formControlName="commonName" #autoCommonNameInput="matAutocompleteTrigger"
             [matAutocomplete]="autoCommonName" data-cy="common-name-input"
             autocomplete="off"/>
      <mat-autocomplete autoActiveFirstOption #autoCommonName="matAutocomplete"
                        (optionSelected)="displayScientificName()">
        <mat-option *ngFor="let commonName of filteredOptionsCommonName | async" [value]="commonName">{{
          commonName
          }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Género</mat-label>
      <input matInput data-cy="genus-input" formControlName="genus" #autoGenusInput="matAutocompleteTrigger"
             [matAutocomplete]="autoGenus"
             autocomplete="off">
      <mat-autocomplete autoActiveFirstOption #autoGenus="matAutocomplete" (optionSelected)="displayScientificName()">
        <mat-option *ngFor="let genera of filteredOptionsGenera | async" [value]="genera.name">{{
          genera.name
          }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Especie</mat-label>
      <input matInput data-cy="specie-input" formControlName="specie" #autoSpecieInput="matAutocompleteTrigger"
             [matAutocomplete]="autoSpecie"
             autocomplete="off">
      <mat-autocomplete autoActiveFirstOption #autoSpecie="matAutocomplete" (optionSelected)="displayScientificName()">
        <mat-option *ngFor="let specie of filteredOptionsSpecie | async" [value]="specie.name">{{ specie.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Nombre Científico</mat-label>
      <input matInput formControlName="scientificName" class="form-control" type="text" autocomplete="off"/>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Cantidad</mat-label>
      <input matInput formControlName="quantity" class="form-control" type="number" autocomplete="off"/>
    </mat-form-field>
    <div class="container-row coordinates">
      <mat-form-field class="input-control input-row-50 size-field-x" appearance="outline">
        <mat-label>Coordenadas eje X</mat-label>
        <input data-cy="latitude-input" matInput formControlName="latitude" class="form-control" type="text" autocomplete="off"
               (keydown)="validatorsService.validateCoordinate($event)"
               (keyup)="validatorsService.validateSymbol('latitude', individualForm)"/>
      </mat-form-field>
      <mat-form-field class="input-control input-row-50 size-field-y" appearance="outline">
        <mat-label>Coordenadas eje Y</mat-label>
        <input matInput data-cy="longitude-input" formControlName="longitude" class="form-control" type="text" autocomplete="off"
               (keydown)="validatorsService.validateCoordinate($event)"
               (keyup)="validatorsService.validateSymbol('longitude', individualForm)"/>
      </mat-form-field>
    </div>
    <mat-radio-group class="flex-row-start radio-group mt-1 mb-1" formControlName="type">
      <mat-radio-button class="container-radios-one text-m-14-gray" value="preliminary-clinic-history"
                        (change)="clearHistories()">
        Historia Clínica Preliminar
      </mat-radio-button>
      <mat-radio-button data-cy="radio-clinic-history-input" class="container-radios-two text-m-14-gray" value="clinic-history" (change)="clearHistories()">
        Historia Clínica
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="input-control" appearance="outline"
                    *ngIf="individualForm.get('type')?.value === 'preliminary-clinic-history'">
      <mat-label>No. Historia Clínica Preliminar</mat-label>
      <input matInput formControlName="preliminaryClinicHistory" #autoMedicalInput="matAutocompleteTrigger"
             [matAutocomplete]="autoMedical" autocomplete="off">
      <mat-autocomplete autoActiveFirstOption #autoMedical="matAutocomplete"
                        (optionSelected)="selectPreliminaryClinicHistory()">
        <mat-option *ngFor="let medicalHistory of  filteredOptionsMedicalHistories | async" [value]="medicalHistory.id">
          {{
          medicalHistory.id }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="container-bubbles" *ngIf="preliminaryClinicHistory.length > 0">
      <div class="reference-selector mb-1 mr-1" *ngFor="let history of preliminaryClinicHistory; let i = index;">
        <span class="ml-1 text-m-12-blue">{{ history }}</span>
        <button class="reference-delete">
          <span class="text-m-12-blue" (click)="deletePreliminaryClinicHistory(i)">X</span></button>
      </div>
    </div>
    <mat-form-field class="input-control" appearance="outline"
                    *ngIf="individualForm.get('type')?.value === 'clinic-history'">
      <mat-label>No. Historia Clínica</mat-label>
      <input data-cy="clinic-history-input" matInput formControlName="clinicHistory" #autoClinicHistoryInput="matAutocompleteTrigger"
             [matAutocomplete]="autoClinicHistory" autocomplete="off">
      <mat-autocomplete autoActiveFirstOption #autoClinicHistory="matAutocomplete"
                        (optionSelected)="selectClinicHistory()">
        <mat-option *ngFor="let medicalHistory of  filteredOptionsMedicalHistory | async" [value]="medicalHistory.id">
          {{
          medicalHistory.id }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="container-bubbles" *ngIf="clinicHistory.length > 0">
      <div class="reference-selector mb-1 mr-1" *ngFor="let history of clinicHistory; let i = index;">
        <span class="ml-1 text-m-12-blue">{{ history }}</span>
        <button class="reference-delete">
          <span class="text-m-12-blue" (click)="deleteClinicHistory(i)">X</span></button>
      </div>
    </div>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Número de Salvoconducto</mat-label>
      <input matInput formControlName="safePassageRecord" alphanumeric-coma trim (keydown)="alphanumericOnlyStrict($event)" type="text" class="form-control" autocomplete="off">
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Número de Acta de Incautación</mat-label>
      <input matInput formControlName="seizureRecordNumber" #autoSeizureInput="matAutocompleteTrigger"
             [matAutocomplete]="autoSeizure" autocomplete="off">
      <mat-autocomplete autoActiveFirstOption #autoSeizure="matAutocomplete">
        <mat-option *ngFor="let seizureRecord of filteredOptionsSeizure | async" [value]="seizureRecord.recordNumber">
          {{seizureRecord.recordNumber}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <div class="button-container flex-row-end">
    <button mat-button class="btn-cancel-stepper" (click)="cancel()">CANCELAR</button>
    <button type="submit" mat-button class="btn-search" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>
</mat-dialog-content>
