<div class="drop-container">
  <ngx-dropzone [disableClick]="files.length > 0"  (change)="onSelect($event)" [accept]="accept">
    <ngx-dropzone-label>
      <div class="icon-container-center drop-text-container">
        <span class="material-icons blue"> attach_file </span>
        <span class="text-m-14-blue"
          >Arrastre aquí su(s) archivo(s) o haga click para añadir</span
        >
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-preview
      *ngFor="let f of files"
      [removable]="!f.url"
      (removed)="onRemove(f)"
      (click)="f.url ? openFile(f.url): ''"
      [style]="f.url ? 'cursor: pointer':''"
    >
      <ngx-dropzone-label class="label">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
</div>
