import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ServerResponse} from "../../../shared/types/http.interfaces";
import {
  MedicineHistoryInterfaceBD,
  MedicinesInterfaceBD,
  MedicinesInterfaceInput,
  TypeDrugsDB,
  TypeDrugsInput
} from "../types/medicines.interface";
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class MedicinesService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  private typeMedicines: Array<{ id: number, value: string }> = [];

  constructor(private http: HttpClient,
              private datePipe: DatePipe,
  ) {
  }

  public async getMedicines(
    includeInactive: boolean = false,
    params?: HttpParams
  ): Promise<MedicinesInterfaceBD[]> {
    let res;
    if (includeInactive) {
      res = await this.http
        .get<ServerResponse<MedicinesInterfaceBD[]>>(
          `${this._baseUrl}/drugConfigurations`, {params}
        )
        .toPromise();
    } else {
      res = await this.http
        .get<ServerResponse<MedicinesInterfaceBD[]>>(
          `${this._baseUrl}/drugConfigurations`,
          {params}
        )
        .toPromise();
    }
    return res.data;
  }

  public async getTypeDrugs(): Promise<TypeDrugsDB[]> {
    let res = await this.http
      .get<ServerResponse<TypeDrugsDB[]>>(
        `${this._baseUrl}/drugTypes`
      )
      .toPromise();
    return res.data;
  }

  public async postTypeDrug(body: TypeDrugsInput) {
    await this.http.post<TypeDrugsInput>(`${this._baseUrl}/drugTypes`, body).toPromise()
  }

  // update register
  public async updateMedicine(form: MedicinesInterfaceInput, id: number) {
    return await this.http
      .patch<MedicinesInterfaceInput>(`${this._baseUrl}/drugConfigurations/${id}`, form)
      .toPromise();
  }

  /**
   * Method to update only status
   * @param id
   * @param body
   */
  public async updateOnlyStatusMedicine(id: number, body: MedicinesInterfaceInput) {
    return await this.http
      .patch<MedicinesInterfaceInput>(`${this._baseUrl}/drugConfigurations/${id}`, body)
      .toPromise();
  }

  //Method delete one row
  public async deleteMedicine(id: number) {
    return this.http.delete(`${this._baseUrl}/drugConfigurations/${id}`).toPromise();
  }

  public async getMedicinesById(
    id: number
  ): Promise<MedicinesInterfaceBD> {
    const res = await this.http
      .get<ServerResponse<MedicinesInterfaceBD>>(
        `${this._baseUrl}/drugConfigurations/${id}`
      )
      .toPromise();
    return res.data;
  }

  public async getDrugHistoryById(
    id: number
  ): Promise<MedicineHistoryInterfaceBD[]> {
    const res = await this.http
      .get<ServerResponse<MedicineHistoryInterfaceBD[]>>(
        `${this._baseUrl}/drugConfigurations/history/${id}`
      )
      .toPromise();
    res.data.pop();
    return res.data.map((drug: MedicineHistoryInterfaceBD) => {
      return {
        ...drug,
        unitName: drug.unit?.name ?? '',
        name: drug.type?.name ?? '',
        date: this.datePipe.transform(drug.createdAt, 'dd/MM/yyyy') ?? ''
      }
    });
  }

  public async downloadMedicine(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http
        .get<any>(`${this._baseUrl}/drugConfigurations/download`, {
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise();
    }
    return result;
  }

  // send new medicines
  public async postNewMedicines(data: MedicinesInterfaceInput) {
    return await this.http.post(`${this._baseUrl}/drugConfigurations`, data).toPromise();
  }
}
