import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  ClinicHistory, ClinicHistoryDB,
  Gender,
  GenderHash,
  Growth,
} from '../../../medical-history/types/medical-history.interface';
import { DatePipe } from '@angular/common';
import { SpecimenService } from '../../services/specimens.service';
import { AdministrationInterface } from '../../types/administration.interface';
import {MarkingTypesHash, SpecimenMarking} from '../../types/specimen.interface';
import {TableAction} from "../../types/table";
import {SeizureIncomeHashSpanish } from "../../../seizure-record/types/seizure-record.interface";

@Component({
  selector: 'app-admission-info-expandable',
  templateUrl: './admission-info-expandable.component.html',
  styleUrls: ['./admission-info-expandable.component.scss'],
})
export class AdmissionInfoExpandableComponent implements OnInit, OnChanges {
  @Input() panelOpenState: boolean = false;
  @Input() patientHistory: ClinicHistory;
  @Input() displayedColumnsPro: any[] = [];
  @Input() displayedColumnsProfessionals: any[] = [];
  @Input() public actions: TableAction[] = [];
  @Input() data: { [key: string]: any }[];
  @Input() dataE: { [key: string]: any }[];
  @Input() dataR: { [key: string]: any }[];
  @Input() public editable: boolean = false;
  @Input() public isNewMarking: boolean = false;
  @Input() public isAdmissionDeparture: boolean = false;
  @Input() public showMarkings: boolean = true;
  @Output() changeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPanelOpen = new EventEmitter<boolean>();
  @Input() public fields: Array<| 'commonName'
    | 'entityName'
    | 'CNIF'
    | 'specimen'
    | 'preliminaryClinicHistory'
    | 'genera'
    | 'incomeClassification'
    | 'markingType'
    | 'location'
    | 'chipNo'
    | 'clinicH'>;
  public dataSource: ClinicHistoryDB;
  public genderList = [
    { id: 0, name: 'Macho' },
    { id: 1, name: 'Hembra' },
    { id: 2, name: 'Indeterminado' },
  ];
  public speciesList: AdministrationInterface[];
  public regionalList: AdministrationInterface[];
  public markings: SpecimenMarking[] = [];
  public dataSourceT: data[]=[];
  public dataSourceE: data[]=[];
  public dataSourceR: data[]=[];
  commonName: FormControl;
  public form: FormGroup;
  public markingTypesHash = MarkingTypesHash;

  displayedColumns: any[] = [
    { key: 'createdAt', value: 'Fecha de marcación' },
    { key: 'type', value: 'Tipo de marcaje' },
    { key: 'markingNo', value: 'Número' },
  ];
  public developmentStageList: { id: number; name: string }[] = [
    { id: Growth.ADULT, name: 'Adulto' },
    { id: Growth.GERIATRIC, name: 'Geronte' },
    { id: Growth.YOUTH, name: 'Juvenil' },
    { id: Growth.NEONATE, name: 'Neonato' },
  ];

  fieldsInfo: FieldInfo[] = [];
  tableLength: number;

  constructor(
    public datePipe: DatePipe,
    public specimenService: SpecimenService,
  ) {
  }

  async ngOnInit() {
    this.setFields();
    await this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFields();
  }
  public async getData() {
    this.data.forEach(data => {
      this.dataSourceT.push(
        {
          associationT: data?.association!,
          phoneT: data?.phone!,
          firstNameT: `${data?.firstName + ' ' + data?.lastName}`,
          entityT: data?.entity!,
          positionT: data?.position!,
          professionalCard: data?.professionalCard!,
          document: `${data?.documentType + ' ' + data?.document}`,
        }
      )
    });
    this.dataE.forEach(data => {
      this.dataSourceE.push(
        {
          associationT: data?.association!,
          phoneT: data?.phone!,
          firstNameT: `${data?.firstName + ' ' + data?.lastName}`,
          entityT: data?.entity!,
          positionT: data?.position!,
          professionalCard: data?.professionalCard!,
          document: `${data?.documentType + ' ' + data?.document}`,
        }
      )
    })
    this.dataR.forEach(data => {
      this.dataSourceR.push(
        {
          associationT: data?.association!,
          phoneT: data?.phone!,
          firstNameT: `${data?.firstName + ' ' + data?.lastName}`,
          entityT: data?.entity!,
          positionT: data?.position!,
          professionalCard: data?.professionalCard!,
          document: `${data?.documentType + ' ' + data?.document}`,
        }
      )
    })
  }
  /**
   * set information of fields to be showed
   * @private
   */
  private setFields() {
    this.fieldsInfo = [];
    this.fields.forEach((field) => {
      const addField = this.allFields.find((f) => f.name === field);
      if (addField) {
        this.fieldsInfo.push(addField);
      }
    });
  }

  checkStatusOpenPanel() {
    this.isPanelOpen.emit();
  }

  /*ALL AVAILABLE FIELDS DEFINITION*/
  get allFields(): FieldInfo[] {
    const fields = [
      {
        name: 'commonName',
        label: 'Nombre Común',
        value: this.patientHistory?.commonName
          ? this.patientHistory?.commonName
          : 'No especificado',
        ctrlName: 'commonName',
        ctrlValue: this.patientHistory?.commonName
          ? this.patientHistory?.commonName
          : '',
      },
      {
        name: 'entityName',
        label: 'Entidad de procedencia',
        value: this.patientHistory?.entryRecord?.specimen?.entityName?
          this.patientHistory?.entryRecord?.specimen?.entityName : '-',
        ctrlName: 'entityName',
        ctrlValue: this.patientHistory?.entryRecord?.specimen?.entityName ?? '',
      },
      {
        name: 'CNIF',
        label: 'Código Nacional de ingreso CNIF',
        value: this.patientHistory?.entryRecord?.CNIF ?? '',
        ctrlName: 'CNIF',
        ctrlValue: this.patientHistory?.entryRecord?.CNIF ?? '',
      },
      {
        name: 'specimen',
        label: 'Especie',
        value: this.patientHistory?.specimen?.specie?.name ?
          this.patientHistory.specimen?.specie?.name : '-',
        ctrlName: 'specimen',
        ctrlValue: this.patientHistory?.entryRecord?.specimen?.specie?.name ?? '-',
      },
      {
        name: 'preliminaryClinicHistory',
        label: 'No. de Historia Clínica',
        value: this.patientHistory.id ?? '-',
        ctrlName: 'growth',
        ctrlValue: this.patientHistory?.id,
      },
      {
        name: 'genera',
        label: 'Sexo',
        value: GenderHash[Gender[this.patientHistory?.specimen?.gender]] ?? '-',
        ctrlName: '',
      },
      {
        name: 'incomeClassification',
        label: 'Clasificación de ingreso',
        value: this.patientHistory?.seizureRecord?.incomeClassification
          ? SeizureIncomeHashSpanish[this.patientHistory.seizureRecord.incomeClassification]
          : '-',
        ctrlName: '',
      },
      {
        name: 'markingType',
        label: 'Tipo de marcaje',
        value: this.patientHistory?.specimen?.markings[0]?.type ? this.markingTypesHash[this.patientHistory.specimen.markings[0].type] : '-',
        ctrlName: '',
      },
      {
        name: 'location',
        label: 'Ubicación',
        value: this.patientHistory?.entryRecord?.location
          ? this.patientHistory.entryRecord?.location
          : '-',
        ctrlName: '',
      },
      {
        name: 'chipNo',
        label: 'No. de chip',
        value: this.patientHistory?.specimen?.markings[0]?.markingNo
          ? this.patientHistory.specimen?.markings[0]?.markingNo
          : '-',
        ctrlName: '',
      },
      {
        name: 'clinicH',
        label: 'No. de Historia Clínica',
        value: this.patientHistory.clinicHistory?.id || '-',
        ctrlName: '',
        ctrlValue: this.patientHistory.clinicHistory?.id,
      },
    ];
    return fields;
  }

}
interface data{
  associationT:string,
    entityT:string,
    positionT:string,
    firstNameT:string,
    document:string,
    professionalCard:string,
    phoneT:string,
}
interface FieldInfo {
  name: string;
  label: string;
  value: any;
  ctrlName: string;
  ctrlValue?: any;
  options?: any[];
}
