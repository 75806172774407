import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ShowImage} from "../../../shared/types/dialog.interface";

/**
 * Modal show image
 */
@Component({
  selector: 'dialog-show-image-component',
  templateUrl: './dialog-show-image.component.html',
  styleUrls: ['./dialog-show-image.component.scss'],
})
export class DialogShowImageComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowImage) { }

  fileType(name: string, type?: string){
    let regExp = /\.(png|jpeg|jpg|svg|webp)$/i;
    if(type == 'film'){
      regExp = /\.(avi|mp4)$/i;
    }
    return regExp.test(name);
  }
}
