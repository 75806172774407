import {EnvironmentDefinition} from "./types";
import {MENU_OPTIONS} from "../app/shared/entities/menu-options/menu-option-dev";
import {ITEMS_MENU_DEV} from "../app/shared/entities/menu-options/header-option-dev";

export const environmentProd: EnvironmentDefinition = {
  production: true,
  keycloakClient: 'operations_front_client',
  authService: "",
  api: "",
  keycloakRealm: "cav_fauna",
  section: MENU_OPTIONS,
  header: ITEMS_MENU_DEV
};
