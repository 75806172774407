import {Actor} from '../../shared/entities/actor/actor.model.entity';
import {LabSampleOrder} from '../../samples/types/samples.interface';
import {DiagnosticTestInterface} from "../../settings/diagnostic-test/types/diagnostic-test.interface";
import {ClinicHistory} from "../../medical-history/types/medical-history.interface";
import {DrugConfiguration} from "../../clinic-history/types/clinic-history.interface";

export enum AmnesicIngressType {
    SEIZURE = 'seizure',
    VOLUNTARY_SUBMISSION = 'voluntarySubmission',
    RESCUE = 'rescue',
    RESTITUTION = 'restitution',
    OTHER = 'other',
}

export const AmnesicIngressTypeHash: { [type: string]: string } = {
    seizure: 'Incautación',
    voluntarySubmission: 'Entrega voluntaria',
    rescue: 'Rescate',
    restitution: 'Restitución',
    other: 'Otra',
};

export enum AmnesicSource {
    CAPTIVITY = 'captivity',
    WILD_LIFE = 'wildLife',
    UNKNOWN = 'unknown',
    NO_DATA = 'noData',
}

export const AmnesicSourceHash: { [type: string]: string } = {
    captivity: 'Cautiverio',
    wildLife: 'Vida silvestre',
    unknown: 'Desconocido',
    noData: 'Sin datos',
};
export const OriginSourceHash: { [type: number]: string } = {
    0: 'captivity',
    1: 'wildLife',
    2: 'unknown',
    3: 'noData',
};

export const OriginSourceHashSpanish: { [type: number]: string } = {
    0: 'Cautiverio',
    1: 'Vida silvestre',
    2: 'Desconocido',
    3: 'Sin datos',
};

export enum AmnesicConsumptionType {
    GOOD = 'good',
    BAD = 'bad',
    REGULAR = 'regular',
    NO_DATA = 'no-data',
}

export const AmnesicConsumptionTypeHash: { [type: string]: string } = {
    good: 'Bueno',
    bad: 'Malo',
    regular: 'Regular',
    noData: 'Sin datos',
};

export const AmnesicConsumptionTypeList: Array<{ value: string, description: string }> = [
    {
        value: AmnesicConsumptionType.GOOD,
        description: 'Bueno',
    },
    {
        value: AmnesicConsumptionType.BAD,
        description: 'Malo',
    },
    {
        value: AmnesicConsumptionType.REGULAR,
        description: 'Regular',
    },
    {
        value: AmnesicConsumptionType.NO_DATA,
        description: 'Sin datos',
    },
];

export enum WeightMeasurementType {
    KG = 'kg',
    LB = 'lb',
    GR = 'gr',
}

export const WEIGHT_TYPE: Array<{
    value: WeightMeasurementType;
    label: string;
}> = [
    {
        value: WeightMeasurementType.KG,
        label: 'Kilos',
    },
    {value: WeightMeasurementType.GR, label: 'Gramos'},
];

export enum SpecialPhysicalExamOptions {
    NORMAL = 'normal',
    ABNORMAL = 'abnormal',
    NOT_EVALUATED = 'notEvaluated',
}

export const SpecialPhysicalExamHash: { [type: string]: string } = {
    normal: 'Normal',
    abnormal: 'Anormal',
    notEvaluated: 'No evaluado',
};

export enum ImmediateDestinations {
    HOSPITALIZATION = 'hospitalization',
    ISOLATION = 'isolation',
    EUTHANASIA = 'euthanasia',
    QUARANTINE = 'quarantine',
    RELEASE = 'release',
    OTHER = 'other',
}

export const ImmediateDestinationHash: { [type: string]: string } = {
    hospitalization: 'Hospitalización',
    isolation: 'Aislamiento',
    euthanasia: 'Eutanasia',
    quarantine: 'Cuarentena',
    release: 'Liberación',
    other: 'Otro',
};

export interface MedicalExamination {
    date: string | Date;
    id: number;
    updatedAt: Date | string;
    createdAt: Date | string | null;
    chipNumber: string;
    CNIF: string;
    clinicHistoryId: number;
    amnesicIngressType: AmnesicIngressType;
    amnesicSource: AmnesicSource;
    amnesicExtractionSite: string;
    amnesicCaptivityTimeYears: number | string;
    amnesicCaptivityTimeMonths: number | string;
    amnesicCaptivityTimeDays: number | string;
    amnesicCaptivityTimeHours: number | string;
    amnesicSpeciesHaveHadContact: string;
    amnesicContactTime: string;
    amnesicIllnessesOfNearbyAnimals: string;
    amnesicCaptivityCharacteristics: string;
    amnesicDiet: string;
    amnesicDietFrequency: string;
    amnesicConsumptionType: AmnesicConsumptionType;
    amnesicAbnormalFindings: string;
    amnesicTreatments: string;
    amnesicVaccinesHistory: string;
    amnesicRegionalManagement: string;
    amnesicTransportType: string;
    amnesicTransportTime: string;
    amnesicTransportDiet: string;
    amnesicTransportTemperature: string;
    amnesicTransportHydration: string;
    amnesicTransportObservations: string;
    physicalTemperature: number;
    physicalCardiacFrequency: number;
    physicalBreathingFrequency: number;
    physicalMucousMembranes: string;
    physicalCapillaryFillTime: number;
    physicalTemperament: string;
    physicalWeight: number;
    physicalWeightMeasurementType: WeightMeasurementType;
    physicalCondition: string;
    physicalAttitude: string;
    physicalO2Saturation: string;
    physicalBloodPressure: number;
    physicalTRIAGEClassification: number;
    physicalExamContention: 'physical' | 'chemical';
    physicalProtocol: string;
    physicalGeneralState: SpecialPhysicalExamOptions;
    physicalHydration: SpecialPhysicalExamOptions;
    physicalIntegument: SpecialPhysicalExamOptions;
    physicalCardiovascularSystem: SpecialPhysicalExamOptions;
    physicalRespiratorySystem: SpecialPhysicalExamOptions;
    physicalDigestiveSystem: SpecialPhysicalExamOptions;
    physicalNervousSystem: SpecialPhysicalExamOptions;
    physicalUrinarySystem: SpecialPhysicalExamOptions;
    physicalReproductiveSystem: SpecialPhysicalExamOptions;
    physicalMusculoskeletalSystem: SpecialPhysicalExamOptions;
    physicalLymphoidSystem: SpecialPhysicalExamOptions;
    physicalSenseOrgans: SpecialPhysicalExamOptions;
    physicalMentalState: SpecialPhysicalExamOptions;
    physicalAbnormalFindings: string;
    physicalProblemsList: string;
    physicalDifferentialDiagnosis: string;
    physicalImmediateDestination: ImmediateDestinations;
    registeringActor: Actor;
    medicines: MedicineRegister[];
    labSampleOrders: LabSampleOrder[];
    otherSpecialPhysicalExam: PhysicalExam[];
    preliminaryClinicHistory: ClinicHistory;
    therapeutic?: TherapeuticCreateInput;
}

export type TherapeuticCreateInput = {
    date: Date;
    request: string;
    registration: string;
    clinicHistoryId: number;
    medicines: Array<TherapeuticMedicineCreateInput>;
    registeringActorId: number;
}

export type TherapeuticSupplyCreateInput = {
    date: Date;
    hour: Date;
    observation: string;
    therapeuticMedicine?: TherapeuticMedicine;
    createdById: number;
}

export enum Unit {
    DAYS = "days",
    HOURS = "hours",
    MINUTES = "minutes"
}

export const UnitHash: { [type: string]: Unit } = {
    "days": Unit.DAYS,
    "hours": Unit.HOURS,
    "minutes": Unit.MINUTES,
}

export interface TherapeuticMedicine {
    id: number;
    denomination: string;
    dose: number;
    frequency: number;
    frequencyUnit: Unit;
    time: number;
    timeUnit: Unit;
    administrationRoute: string;
    quantity: number;
    indication: string;
    drug?: DrugConfiguration;
    supplies?: Array<TherapeuticSupply>
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface TherapeuticSupply {
    id: number;
    date: Date;
    hour: Date;
    observation: string;
    createdById: number;
    createdBy?: Actor;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export type TherapeuticMedicineCreateInput = {
    denomination: string;
    dose: number;
    frequency: number;
    frequencyUnit: string;
    time: number;
    timeUnit: string;
    administrationRoute: string;
    quantity: number;
    indication: string;
    supplies: Array<TherapeuticSupplyCreateInput>;
    createdById: number;
    drugId: number;
}

export interface PhysicalExam {
    name: string;
    value: string;
}

export interface MedicineRegister {
    medicineId: number;
    dose: number;
    doseMeasurementType: 'mg' | 'gr' | 'ml';
    frequency: string;
    frequencyUnit: string;
    treatmentPeriod: string;
    administrationRoute: string;
    totalQuantity: number;
    indications: string;
    medicine: string;
    doseT: string;
    denomination: string;
    timeT: string;
    time: string;
    timeUnit: string;
    quantity: number;
    indication: string;
}

export const TestTypeHash: { [type: string]: string } = {
    internal: 'Interna',
    external: 'Externa',
};

export interface DiagnosticTest {
    diagnosticTest: DiagnosticTestInterface[];
    row: Evidence;
}

export interface Evidence {
    testType: string;
    testTypeDescription: number;
    testTypeSpecifications: string;
    testQuantity: number;
    observations: string;
    createdAt: string;
    type: string;
}
