import {NavItem} from "../../components/header/header.component";

export const ITEMS_MENU_DEV: NavItem[] = [
  {
    displayName: 'Gestión de Usuarios',
    iconName: 'manage_accounts',
    route: '',
    children: [
      {
        displayName: 'Configuración Usuarios',
        iconName: '',
        route: '/home/actor-management/list',
      },
      {
        displayName: 'Configuración Roles',
        iconName: '',
        route: '/home/roles/list',
      },
    ],
  },
  {
    displayName: 'Administración',
    iconName: 'folder',
    children: [
      {
        displayName: 'Configuración Regional',
        iconName: '',
        route: '/home/regional-direction/list',
      },
      {
        displayName: 'Configuración Género',
        iconName: '',
        route: '/home/genera/list',
      },
      {
        displayName: 'Configuración Especie',
        iconName: '',
        route: '/home/species/list',
      },
      {
        displayName: 'Configuración Pruebas Diagnósticas',
        iconName: '',
        route: '/home/diagnostic-test/list',
      },
      {
        displayName: 'Configuración Material de Enriquecimiento',
        iconName: '',
        route: '/home/enrichment-material/list',
      },
      {
        displayName: 'Configuración Medicamentos',
        iconName: '',
        route: '/home/medicines/list',
      },
      {
        displayName: 'Configuración Administración',
        iconName: '',
        route: '/home/administration-management/basic-data-form',
      }
    ],
  },
];
