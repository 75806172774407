<div class="classes-container">
  <div class="text-container">
    <div class="main-title-green align">Tamaños y colores</div>
    <br>
    <div class="big-title-green align">.big-title-green|h1</div>
    <div class="big-title-blue align">.big-title-blue |h1 </div>
    <div class="big-title-white dark align">.big-title-white |h1 </div>
    <div class="big-title-inactive align">.big-title-inactive </div>
    <br>
    <div class="main-title-blue align">.main-title-blue | h2 </div>
    <div class="main-title-green align">.main-title-green | h2 </div>
    <div class="main-title-white dark-medium align">.main-title-white | h2 </div>
    <div class="main-title-inactive align">.main-title-inactive |h2 </div>
    <br>
    <div class="sub-title-blue align">.sub-title-blue | h3 </div>
    <div class="sub-title-green align">.sub-title-green |h3 </div>
    <div class="sub-title-white dark-medium align">.sub-title-white | h3 </div>
    <div class="sub-title-inactive align">.sub-title-inactive </div>
    <br>

    <div class="small-sub-title-blue align">.small-sub-title-blue |h4 </div>
    <div class="small-sub-title-green align">.small-sub-title-green |h4 </div>
    <div class="small-sub-title-white dark-medium align">.small-sub-title-white |h4 </div>
    <div class="small-sub-title-inactive align">.small-sub-title-inactive |h4</div>
    <br>
    <div class="text-m-16-blue align">.text-m-16-blue |p</div>
    <div class="text-m-16-green align">.text-m-16-green |p</div>
    <div class="text-m-16-white dark-regular align">.text-m-16-white |p</div>
    <div class="text-m-16-inactive align">.text-m-16-inactive |p</div>

    <br>
    <div class="text-m-14-blue align">.text-m-14-blue |p</div>
    <div class="text-m-14-green align">.text-m-14-green |p</div>
    <div class="text-m-14-white dark-regular align">.text-m-14-white |p</div>
    <div class="text-m-14-inactive align">.text-m-14-inactive |p</div>
    <br>

    <div class="text-m-12-blue align">.text-m-12-blue |p</div>
    <div class="text-m-12-green align">.text-m-12-green |p</div>
    <div class="text-m-12-white dark-regular align">.text-m-12-white |p</div>
    <div class="text-m-12-inactive align">.text-m-12-inactive |p</div>
    <br>
  </div>

  <br>
  <div class="main-title-green">Posicionamiento general</div>
  <div class="text-m-16-blue"><strong>.flex-center :</strong> Posiciona en el centro tanto vertical como horizontalmente
    (si se van a añadir
    más propiedades no añadir directamente al html, usar @extend
  </div>
  <div class="flex-center auxiliary">
    <div class="auxiliary-sub"></div>
  </div>
  <br>
  <!--FORMS-->
  <div class="main-title-green">Formularios</div>
  <div class="sub-title-green">Botones</div>
  <div class="text-m-14-blue"> No olvidar usar la directiva mat-button
    <br><strong>ejemplo:</strong> &lt;button <strong> mat-button </strong> class="main-button-blue">Enviar
    &lt;/button&gt;
  </div>
  <button mat-button class="main-button-blue" (click)="globalLoading()">Enviar</button>
  <br>
  <div class="text-m-14-blue"><strong>.main-button: </strong>Se puede añadir diferenciador de color -green, -blue. Tener
    en cuenta que en los casos de ejemplo el contenedor tiene padding de 1
    rem<br>
    Tener en cuenta que siempre el botón tendrá un maximo de 15 rem y un mínimo del contenido más el espacio en
    laterales de padding.
  </div>
  <br>
  <div class="text-m-14-blue"><strong>.main-button-blue</strong></div>
  <div class="big-box flex-center">
    <button mat-button class="main-button-blue" (click)="warningToaster()">Warning</button>
  </div>
  <div class="text-m-14-blue"><strong>.main-button-green</strong></div>
  <div class="box flex-center">
    <button mat-button class="main-button-green" (click)="infoToaster()">Info</button>
  </div>
  <div class="text-m-14-blue"><strong>.main-button-white</strong></div>
  <div class="box flex-center">
    <button mat-button class="main-button-white" (click)="errorToaster()">Error</button>
  </div>
  <div class="text-m-14-blue"><strong>.main-button-white</strong></div>
  <div class="box flex-center">
    <button mat-button class="main-button-white" (click)="successToaster()">Success</button>
  </div>
  <br>
  <div class="sub-title-green">Input</div>
  <form class="form-blue flex-center" autocomplete="off" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput type="text" class="input-control" appearance="outline" formControlName="first">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Apellido</mat-label>
      <input matInput type="text" class="input-control" appearance="outline" formControlName="second">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Tipo de documento</mat-label>
      <mat-select class="input-control " formControlName="selector">
        <mat-option *ngFor="let type of ['CC cédula de ciudadanía', 'CC cédula de extrangería']" [value]="type">{{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Text area</mat-label>
      <textarea matInput formControlName="textArea" class="input-control text-area" appearance="outline"></textarea>
    </mat-form-field>
    <button type="submit" mat-button class="main-button-green">Iniciar sesión</button>
  </form>

  <div class="input"></div>

  <div [appLoading]="isLoading" class="container-form">
    <h3 class="sub-title-blue">Directive</h3>
    <div class="form-container">
      <form class="form-login" [formGroup]="loginForm" autocomplete="off">
        <div class="icon-container">
          <mat-icon svgIcon="userLogin"></mat-icon>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Usuario</mat-label>
            <input type="email" matInput formControlName="email" placeholder="Usuario">
          </mat-form-field>
        </div>
        <div class="icon-container">
          <mat-icon svgIcon="padLockLogin"></mat-icon>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input [type]="this.showPass ? 'text':'password'" matInput formControlName="password"
              placeholder="Contraseña">
            <ng-container *ngIf="this.showPass">
              <mat-icon class="show-password-icon" (click)="changePassMode()" svgIcon="eyeLogin"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!this.showPass">
              <mat-icon class="show-password-icon" (click)="changePassMode()" svgIcon="eyeLoginClose"></mat-icon>
            </ng-container>

          </mat-form-field>
        </div>
      </form>
    </div>
    <div>
      <button mat-button class="main-button-blue" (click)="loadingDirective()">Loading Directive</button>
    </div>
  </div>
  <div>
    <h3 class="text-m-14-green">Stepper</h3>
    <div class="width-100">
      <mat-horizontal-stepper linear #stepper ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}"
        labelPosition="bottom">
        <mat-step [stepControl]="releaseActSaveForm" state="start">
          <div class="icon-container-center mb-1 mt-1">
            <span class="material-icons blue mr-1"> pets </span>
            <span class="text-m-14-blue"><strong>Inicio</strong></span>
          </div>
          <mat-divider></mat-divider>
          <ng-template matStepLabel><span class="text-m-14-blue">Inicio</span></ng-template>
          <div class="flex-row-end">
            <button type="submit" mat-button class="btn-save" matStepperNext (click)="nextStepOne()">
              SIGUIENTE
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="specieDataForm" state="persons">
          <div class="icon-container-center mb-1 mt-2">
            <span class="material-icons blue mr-1"> pets </span>
            <span class="text-m-14-blue"><strong>Agregar Individuos</strong></span>
          </div>
          <mat-divider></mat-divider>
          <ng-template matStepLabel><span class="text-m-14-blue">Registro</span></ng-template>
          <div class="flex-row-end mt-2">
            <button mat-button class="btn-cancel mt-2" matStepperPrevious>VOLVER</button>
            <button type="submit" mat-button class="btn-save" mat-button matStepperNext>SIGUIENTE</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="captivityHistoryForm" state="user">
          <div class="icon-container-center mb-1 mt-1">
            <span class="material-icons blue mr-1">person</span>
            <span class="text-m-14-blue"><strong>Funcionario</strong></span>
          </div>
          <mat-divider></mat-divider>
          <ng-template matStepLabel><span class="text-m-14-blue">Funcionario</span></ng-template>
          <div class="flex-row-end mt-2">
            <button mat-button class="btn-cancel" matStepperPrevious>VOLVER</button>
            <button type="submit" mat-button class="btn-save" (click)="stepper.reset()">CANCELAR</button>
          </div>
        </mat-step>
        <ng-template matStepperIcon="start">
          <i class="material-icons-two-tone">pets</i>
        </ng-template>
        <ng-template matStepperIcon="persons">
          <i class="material-icons-two-tone">medical_services</i>
        </ng-template>
        <ng-template matStepperIcon="user">
          <i class="material-icons-two-tone">person_add</i>
        </ng-template>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
