import { AdministrationInterface } from './administration.interface';
import { Individual } from './individual.interface';
import {ClinicHistoryDB} from "../../medical-history/types/medical-history.interface";

export interface SpecimenDB {
  id: number;
  date: string;
  entity: Entity;
  genus: AdministrationInterface;
  specie: AdministrationInterface;
  scientificName?: string;
  growth?: number;
  specieName?:string;
  gender?: number;
  status?: number;
  weight?: string;
  weightType?: number;
  originId?: number;
  markings?: Array<SpecimenMarking>;
  individuals: Individual[];
  preliminaryClinicHistory?: ClinicHistoryDB;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string;
  referencedPreliminaryClinicHistory: any;
}



export enum MarkingTypes {
  CHIP = "microchip",
  RING = "ring",
  NOTCH = "notch",
  TATTOO = "tattoo",
  PLATE = "plate",
  OTHER = "other"
}

export enum MarkingTypesSpanish {
  CHIP = "Microchip",
  RING = "Anillo",
  NOTCH = "Muesca",
  TATTOO = "Tatuaje",
  PLATE = "Placa",
  OTHER = "Otro"
}

export const MarkingTypesHash: { [type: string]: MarkingTypesSpanish } = {
  "microchip": MarkingTypesSpanish.CHIP,
  "ring": MarkingTypesSpanish.RING,
  "notch": MarkingTypesSpanish.NOTCH,
  "tattoo": MarkingTypesSpanish.TATTOO,
  "plate": MarkingTypesSpanish.PLATE,
  "other": MarkingTypesSpanish.OTHER
}

export const markingTypeList: { value: string, name: string }[] = [
  {value: 'ring', name: 'Anillo'},
  {value: 'microchip', name: 'Microchip'},
  {value: 'notch', name: 'Muesca'},
  {value: 'other', name: 'Otro'},
  {value: 'plate', name: 'Placa'},
  {value: 'tattoo', name: 'Tatuaje'},
];

export interface SpecimenMarking{
  id: number;
  specimen?: Specimen;
  type: MarkingTypes;
  markingNo: string;
  markingDate: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

export interface NewMarkings{
  type: string,
  createdAt: string | null,
  markingDate: Date,
  markingNo: string
}

export interface Entity {
  id: number;
  name: string;
  state: boolean;
}

export interface Specimen extends SpecimenDB{
  date: string;
  entityName: string;
  commonName: string;
  scientificName: string;
}

export type SpecimenMarkingCreateInput = {
  specimenId: number;
  type: MarkingTypes;
  markingNo: string;
  markingDate: Date;
};
