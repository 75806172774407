import { AdministrationInterface } from "../../shared/types/administration.interface";
import { Actor, EntityActor } from "../../shared/entities/actor/actor.model.entity";
import { IncomeClassification, SpecimenStatus } from "../../seizure-record/types/seizure-record.interface";
import { EntryRecord } from "../../admission-departure-management/types/admission-management.interface";
import {
  MedicalExamination,
  WeightMeasurementType
} from "../../clinical-examination/types/clinical-examination.interfaces";
import { SpecimenMarking } from "../../shared/types/specimen.interface";
import { QuarantineEntry } from "../../quarantine/types/quarantine.interface";
import { Therapeutic } from "../../therapeutic/types/therapeutic.interface";
import { HospitalizationIncome } from "../../hospitalization/income-record/types/hospitalization-income.interface";
import { DepartureRecord } from "../../admission-departure-management/types/departure-management.interface";
import { BiologySpecieRecord } from "../../biology-species/types/biology-specie.interface";
import { Individual } from "../../shared/types/individual.interface";

export interface ClinicHistoryDB {
  id: number;
  date: Date | string;
  CARFilingNumber: number;
  clinicHistory?: _ClinicHistoryDB;
  preliminaryClinicHistory?: ClinicHistoryDB;
  specimen: Specimen;
  state: SpecimenStatus;
  regional: AdministrationInterface;
  safePassageRecord: ERecord;
  seizureRecordSpecimenId?: number;
  safePassageSpecimenId?: number;
  seizureRecordId?: string,
  safePassageRecordId?: string,
  seizureRecordObservation?: string;
  safePassageRecordObservation?: string;
  biologicalManagements?: BiologySpecieRecord[],
  deliveringActor?: Actor;
  entryRecord?: EntryRecord;
  entryRecords?: EntryRecord[];
  departureRecord?: DepartureRecord;
  seizureRecord: ERecord & { incomeClassification?: IncomeClassification };
  captivityHistory: CaptivityHistory;
  actors: EntityActor[];
  quarantineEntries?: QuarantineEntry[];
  therapeutic?: Therapeutic;
  therapeutics?: Therapeutic[];
  medicalExamination?: MedicalExamination;
  hospitalizationAdmission?: HospitalizationIncome[];
  individuals: DeliveryIndividuals[];
}

export interface _ClinicHistoryDB {
  id: number;
  status: string;
  createdAt: Date;
}

export interface DeliveryIndividuals {
  id: number;
  quantity: number;
  longitude: string;
  safePassageRecord: string[];
  seizureRecord: string[];
  preliminaryClinicHistory: string[];
  clinicHistories: string[];
  individualsDelivery: IndividualsDelivery;
  latitude: string;
  createdAt: Date;
}

export interface IndividualsDelivery {
  id: number;
  date: Date;
  individuals: Individual[];
  sentTo: EntityActor[];
  sentFrom: EntityActor[];
  createdAt: Date;
}

export type ClinicHistory =
  ClinicHistoryDB & {
    regionalName: string,
    seizureRecordId: string,
    safePassageRecordId: string,
    commonName: string,
    destination: string
    dateT?: string | null;
    stateT?: string
    residueDiets?: ResidueDiet[];
  }

export interface CaptivityHistory {
  typeWeight: string;
  years: string;
  months: string;
  days: string;
  hours: string;
  quantityPeopleInContact: string;
  name: string;
  phone: number;
  diet: string;
  dietFrequency: string;
  contactWithOtherSpecies: string;
  whichOtherSpecies: string;
  consumption: string;
  typeConfinement: string;
  story: string;
  initialBehavior: string;
  whichBehavior: string;
  aggressivenessLevel: string;
  meeknessLevel: string;
  mutilationsPresence: string;
  mutilationsDescription: string;
  restriction: string;
  restrictionType: string;
  product: string;
  dosage: string;
  administrationWay: string;
  process: string;
  time: string;
  restrictionProblems: string;
  whichProblemsRestraint: string;
  abnormalFindings: string;
  destination: string;
  witchDestinyIndividual: string;
  biologicalData: string;
  habits: string;
  dangerLevel: string;
  observations: string;
}

export interface ERecord {
  id: number;
  recordNumber: string;
  date: Date | string;
  quantity: number;
  regional: number;
  type: string;
  status: number;
}

export interface Specimen {
  id: number;
  commonName: string;
  genus: AdministrationInterface;
  specie: Specie;
  growth: number;
  scientificName: string;
  gender: number;
  status: number;
  weight: number;
  weightType: WeightMeasurementType
  markings: SpecimenMarking[];
  originId: number;
}

export interface Specie extends AdministrationInterface {
  naturalDistribution?: string;
  specimens?: Specimen[];
}


export interface Association {
  id: number;
  name: string;
  value: string;
}

export interface ContactPoint {
  id: number;
  value: string;
  rank: number;
  use: number;
  type: string;
}

export interface ResidueDiet {
  id: number;
  date: string;
  weight: number;
  observations: string;
  createdAt: string;
  registeringActor: Actor;
}

export enum Growth {
  ADULT,
  SUBADULT,
  YOUTH,
  CHILD,
  NEONATE,
  GERIATRIC
}

export enum Gender {
  MALE,
  FEMALE,
  UNKNOWN
}

export const GenderHash: { [type: string]: string | undefined } = {
  "MALE": "Macho",
  "FEMALE": "Hembra",
  "UNKNOWN": "Indeterminado"
}

export function getGenderHash(genderId: number): string {
  switch (genderId) {
    case 0: return 'Macho';
    case 1: return 'Hembra';
    case 2:
    default: return 'Indeterminado'
  }
}

export enum ActualStatus {
  ALIVE,
  DEAD,
  UNKNOWN
}

export enum Origin {
  CAPTIVITY,
  WILD_LIFE,
  UNKNOWN,
  NO_DATA
}

export const DestinyIndividualHashSpanish: { [type: string]: string } = {
  'CAV-R': 'CAV-R',
  'liberation': 'Liberación',
}

export const DestinyIndividualHashEnglish: { [type: string]: string } = {
  'CAV-R': 'CAV-R',
  'Liberación' : 'liberation',
}
