<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-0-5">
    <span class="material-icons-bold blue mr-1">add</span>
    <div class="sub-title-blue-left">{{title}}</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <form class="input-form flex-column-start mt-2 mb-1" [formGroup]="deliverSpecimenSaveForm">
    <ng-template matStepLabel><span class="text-m-14-blue">Inicio</span></ng-template>

    <mat-form-field class="input-control" appearance="outline">
      <mat-label>No. de Historia Clínica Preliminar</mat-label>
      <input matInput formControlName="preliminaryClinicHistory" data-cy="clinic-history-input" [matAutocomplete]="autoMedical">
      <mat-autocomplete autoActiveFirstOption #autoMedical="matAutocomplete">
        <mat-option (onSelectionChange)="listenFieldClinicHistory($event)"
          *ngFor="let medicalHistory of filteredOptionsMedical | async" [value]="medicalHistory.id">{{
          medicalHistory.id }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-container *ngIf="isClinicHistorySelected">
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Nombre Común</mat-label>
        <input matInput formControlName="commonName" class="form-control" />
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Género</mat-label>
        <input matInput formControlName="genus" [matAutocomplete]="autoGenus">
        <mat-autocomplete autoActiveFirstOption #autoGenus="matAutocomplete">
          <mat-option *ngFor="let genera of filteredOptionsGenera | async" [value]="genera.name">{{
            genera.name
            }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Especie</mat-label>
        <input matInput formControlName="specie" [matAutocomplete]="autoSpecie">
        <mat-autocomplete autoActiveFirstOption #autoSpecie="matAutocomplete">
          <mat-option *ngFor="let specie of filteredOptionsSpecie | async" [value]="specie.name">{{ specie.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>No. Acta de Incautación</mat-label>
        <input matInput formControlName="seizureRecordNumber" type="number" class="form-control">
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>No. de Salvoconducto</mat-label>
        <input matInput formControlName="safePassageRecord" type="number" class="form-control">
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Cantidad</mat-label>
        <input matInput formControlName="quantity" type="number" class="form-control" />
      </mat-form-field>

      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Observaciones</mat-label>
        <textarea matInput cdkTextareaAutosize data-cy="observations-individual-input" formControlName="observationsIndividual" cdkAutosizeMinRows="4"
          cdkAutosizeMaxRows="4" autocomplete="off"></textarea>
      </mat-form-field>
    </ng-container>
  </form>
  <div class="button-container flex-row-end">
    <button mat-button class="btn-cancel-stepper" (click)="cancel()">CANCELAR</button>
    <button type="submit" mat-button class="btn-search" data-cy="save-specimen-dialog-button" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>

</mat-dialog-content>
