<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
    <div class="icon-container-center mb-1">
      <span class="material-icons-bold blue">add</span>
      <div class="sub-title-blue-left">{{title}}</div>
    </div>
    <mat-divider></mat-divider>
  </div>
<mat-dialog-content>
    <form novalidate [formGroup]="saveMedicinesForm" class="input-form register-form-wrapper mt-2">
      <div class="flex-column-center width-100">
        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Fármaco</mat-label>
          <mat-select formControlName="drug" class="form-control">
            <mat-option *ngFor="let medicine of this.fetchedMedicine" [value]="medicine">{{ medicine.denomination }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Dosis inicial</mat-label>
          <input matInput formControlName="initialDose" class="form-control">
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Dosis final</mat-label>
          <input matInput formControlName="finalDose" class="form-control">
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Vía de administración</mat-label>
          <mat-select formControlName="administrationRoute" class="form-control">
            <mat-option *ngFor="let routeOfAdministration of this.knownRouteOfAdministration" [value]="routeOfAdministration">{{ routeOfAdministration.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="button-container flex-row-end">
      <button mat-button class="btn-cancel-stepper" (click)="cancel()">CANCELAR</button>
      <button type="submit" mat-button class="btn-search" (click)="onSubmit()">
        GUARDAR
      </button>
    </div>
</mat-dialog-content>
