import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {DialogService} from "../../services/dialog.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input() clearUpload: boolean = false;
  @Input() fileUrl: Array<{ id?: string, name: string, type: string, url: string }>;
  @Output() filesSend: EventEmitter<File[]> = new EventEmitter();
  public files: (File & { url: string })[] = [];
  @Input() accept: string = '*';
  @Output() fileToDelete: EventEmitter<any> = new EventEmitter();

  constructor(private dialogService: DialogService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fileUrl) {
      for (const file of this.fileUrl) {
        this.files.push({id: Number(file.id) || '', name: file.name, type: file.type, url: file.url} as any);
      }
    }
  }

  openFile(url: string) {
    window.open(url);
  }

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      if (!this.clearUpload) {
        this.filesSend.emit(this.files);
      }
      this.files.splice(this.files.indexOf(event.addedFiles), 1);
      this.files.push(...event.addedFiles);
      this.filesSend.emit(this.files);
    }
    if (this.clearUpload) {
      this.files = [];
    }
  }

  onRemove(event: any) {
    const dialog = this.dialogService.openDialog(
      {
        message: ``,
        icon: 'report',
        isButtons: true,
        orientationIconTop: true,
        subMessage: '¿Está seguro que quiere eliminar el documento?',
        isActions: true,
        typeConfirm: 'confirm',
      },
      'my-dialog-confirm-class'
    );
    dialog.afterClosed().subscribe(async (action) => {
      if (action) {
        this.filesSend.emit([]);
        this.files.splice(this.files.indexOf(event), 1);
        this.fileToDelete.emit(event);
      }
    });
  }
}
