import {EventEmitter, Injectable} from '@angular/core';

export enum KnownHeaderIcons {
  SEIZURE = 'summarize',
  SAFEGUARD = 'source',
  MEDICAL_HISTORY = 'medical_history',
  RELEASE = 'release_form',
  DELIVER_SPECIMENS = 'assignment',
  ADMISSION_DEPARTURE_MANAGEMENT = 'assignment_turned_in',
  CLINICAL_EXAMINATION = 'medical_exam',
  EUTHANASIA = 'vaccines',
  NECROPSY = 'necropsy',
  SAMPLES = 'bloodtype',
  ETHOGRAMS = 'table_chart',
  NEONATAL_MONITORING = 'bird2',
  DIET_CONSUMPTION = 'waste',
  BIOLOGY_SPECIES = 'biological',
  ROUTINE_EVALUATION = 'routine_evaluation',
  DIET_MANAGEMENT = 'set_meal',
  ENRICHMENT_MANAGEMENT = 'sports_baseball',
  ENRICHMENT_ENVIRONMENT = 'grass-white',
  THERAPEUTIC_MANAGEMENT = 'medication',
  TEMPORARY_DELIVERY = 'fact_check',
  CLINIC_HISTORY = 'ballot',
  INCOME_HOSPITALIZATION = 'exit_register',
  TRACKING_HOSPITALIZATION = 'history_white',
  DISCHARGE_CRITICAL_CARE = 'output_white',
  CHECK_OUT_HOSPITALIZATION = 'exit_register',
  ADMISSION_QUARANTINE = 'quarantine',
  DEPARTURE_QUARANTINE = 'exit-quarantine',
  CONFIG = 'settings',
  ADMINISTRATION_MANAGEMENT = 'donut_large',
  CLIPBOARDS = 'content_paste',
  SAMPLES_LAB_REPORT = 'create_new_folder',
  GENERAL_REPORT = 'feed',
  SEARCH_CLINIC_HISTORY = 'entry',
  TEMPORARY_EXIT = 'temporary-exit',
  ANALYTICS = 'analytics',
  HOME = 'home',
  PETS = 'pets',
}

@Injectable({
  providedIn: 'root',
})
export class HeaderAndSidebarService {
  private _currentText = 'Ingreso';
  private _currentSvgIcon = KnownHeaderIcons.CLIPBOARDS;
  private _currentIsSVG: boolean;
  private _isSidebarBig = false;
  public emmitTabTitle: EventEmitter<'Registrar' | 'Actualizar' | 'Ver'> =
    new EventEmitter();
  private emmitHeaderIcon: 'home' | 'analytics';
  private urlHeaderIcon: string;

  constructor() {
    this.isSidebarBig = localStorage.getItem('isSidebarBig') === 'true';
    this.emmitHeaderIcon = 'analytics';
    this.urlHeaderIcon = '/home/dashboard/income';
  }

  public set currentText(title: string) {
    setTimeout(() => {
      this._currentText = title;
    }, 10);
  }

  public get currentText() {
    return this._currentText;
  }

  public set currentIsSVG(isSVG: boolean) {
    setTimeout(() => {
      this._currentIsSVG = isSVG;
    }, 10);
  }

  public get currentIsSVG() {
    return this._currentIsSVG;
  }

  public set currentSvgIcon(iconPath: KnownHeaderIcons) {
    setTimeout(() => {
      this._currentSvgIcon = iconPath;
    }, 10);
  }

  public get currentSvgIcon() {
    return this._currentSvgIcon;
  }

  public set isSidebarBig(value: boolean) {
    localStorage.setItem('isSidebarBig', value + '');
    this._isSidebarBig = value;
  }

  public get isSidebarBig() {
    return this._isSidebarBig;
  }

  secondTabTitle(text: 'Registrar' | 'Actualizar' | 'Ver') {
    this.emmitTabTitle.emit(text);
  }

  getSecondTabTitle() {
    return this.emmitTabTitle;
  }

  public get iconHeader() {
    return this.emmitHeaderIcon;
  }

  public get urlIcon() {
    return this.urlHeaderIcon;
  }

  public set changeIconHeader(text: 'home' | 'analytics') {
    if (text === 'home') {
      this.urlHeaderIcon = '/home';
    } else {
      this.urlHeaderIcon = '/home/dashboard/income';
    }
    this.emmitHeaderIcon = text;
  }
}
