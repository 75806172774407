import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, interval} from 'rxjs';
import {ContactPointType} from '../shared/entities/actor/contact-point/contact-point.model.entity';
import {AuthService} from '../shared/services/auth/auth.service';
import {DialogService} from '../shared/services/dialog.service';
import {LoadingService} from '../shared/services/loading.service';
import {ProfileService} from '../shared/services/profile/profile.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;

  public showPass = false;
  public showNewPass = false;
  public showConfirmPass = false;
  public passwordPattern: any =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
  public emailPattern: any =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  public recoveryForm: FormGroup = new FormGroup({
    newPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(this.passwordPattern),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(this.passwordPattern),
    ]),
  });

  public pinForm: FormGroup = new FormGroup({
    field1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    field2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    field3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    field4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
  });

  public recoveryPassForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]),
  });

  public minutes = 1;
  public seconds = 30;
  clock: Subscription;

  public loginFormActive = 1;

  disableButton = true;

  private pinToSend: string = '';
  iconUserRoute = 'assets/svg/login/user.svg';
  private recoverByToken = false;
  private token: string | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authUserService: AuthService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
  ) {
  }

  async ngOnInit() {
    if (this.router.isActive('recovery', false)) {
      await this.validateRecoveryMethod();


    }
  }

  private async validateRecoveryMethod() {
    this.loadingService.setLoading(true);
    try {
      this.token = this.route.snapshot.queryParamMap.get("recoveryToken");
      if (!this.token) {
        throw new Error("No token found");
      }
      await this.authUserService.validateRecoveryToken(this.token, "TOKEN");
      this.loginFormActive = 4;
      this.recoverByToken = true;
    } catch (e) {
      console.error(e);
      this.dialogService.errorModal("Token inválido o expirado");
    }
    this.loadingService.setLoading(false);
  }


  async login() {
    if (
      this.loginForm.value.email != '' &&
      !this.loginForm.controls.email.valid
    ) {
      this.dialogService.errorModal('Correo no valido');
    } else if (
      this.loginForm.value.email === '' ||
      this.loginForm.value.password === ''
    ) {
      this.dialogService.errorModal('Completar todos los campos');
    } else {
      this.loadingService.setLoading(true);
      try {
        await this.authUserService.loginPasswordFlow(
          this.loginForm.get('email')!.value.trim(),
          encodeURIComponent(this.loginForm.get('password')!.value.trim())
        );
        await this.profileService.recoverUserData();
        this.dialogService.infoModal('Inicio de sesión exitoso');
        this.router.navigateByUrl('/home');
      } catch (e) {
        console.error(e);
        let message = e.message;
        if (e.error?.error?.includes('Not authenticated or authorized')) {
          message = 'El usuario no tiene permisos de ingreso, revise las credenciales';
        }
        this.dialogService.errorModal(message);
      }
      this.loadingService.setLoading(false);
    }
  }

  async recoveryPassword() {
    if (this.recoveryForm.valid) {
      if (
        this.recoveryForm.value.newPassword ===
        this.recoveryForm.value.confirmPassword
      ) {
        this.loadingService.setLoading(true);

        try {
          this.loadingService.setLoading(true);
          const email = this.recoveryPassForm.controls.email.value;
          if (this.recoverByToken) {
            await this.authUserService.userPasswordRecoveryCode(this.token as string, "TOKEN", encodeURIComponent(this.recoveryForm.controls.newPassword.value));
          } else {
            await this.authUserService.userPasswordRecoveryCode(
              this.pinToSend, 'OTP',
              this.recoveryForm.controls.newPassword.value,
              {
                value: email, type: ContactPointType.EMAIL,
              },
            );
          }

          this.dialogService.successModal('Su contraseña ha sido actualizada con éxito', true);
          this.loginFormActive = 1;
        } catch (e) {
          console.error(e);
          this.dialogService.errorModal(e.message);
        }
        this.loadingService.setLoading(false);
      } else {
        this.dialogService.infoModal('Las contraseñas deben ser iguales');
      }
    } else {
      this.dialogService.errorModal(
        'La contraseña debe tener mínimo 8 caracteres una mayúscula, un número y un carácter especial.'
      );
    }
  }

  cancelButton() {
    this.loginFormActive = 1;
  }

  //change visibility of pass field
  changePassMode() {
    this.showPass = !this.showPass;
  }

  //change visibility of pass field
  changeNewPassMode() {
    this.showNewPass = !this.showNewPass;
  }

  //change visibility of pass field
  changeConfirmPassMode() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  changeLoginFormActive() {
    this.loginFormActive = 2;
  }

  async sendRecoveryLinkToEmail(event?: Event) {
    if (this.recoveryPassForm.valid) {
      await this.resendPin();
    } else if (this.recoveryPassForm.value.email === '') {
      this.dialogService.errorModal('Llenar el formulario');
    } else {
      this.dialogService.errorModal('Ingresar un correo valido');
    }
  }

  async validatePin() {
    if (this.pinForm.valid) {
      try {
        this.loadingService.setLoading(true);
        const valueForm = this.pinForm.value;
        const pin = `${valueForm.field1}${valueForm.field2}${valueForm.field3}${valueForm.field4}`;
        this.pinToSend = pin;
        await this.authUserService.validateRecoveryToken(
          this.pinToSend,
          'OTP',
          {
            value: this.recoveryPassForm.controls.email.value,
            type: ContactPointType.EMAIL,
          }
        );
        this.dialogService.successModal(
          'Código válido, ingresa tu nueva contraseña',
          true
        );
        this.loginFormActive = 4;
        this.clock.unsubscribe();
        this.pinForm.reset();
      } catch (e) {
        console.error(e);
        this.dialogService.errorModal(e.message);
      }
      this.loadingService.setLoading(false);
    }
  }

  async resendPin() {
    try {
      this.loadingService.setLoading(true);
      await this.authUserService.requestPasswordRecoveryCode('TOKEN', 'EMAIL', {
        value: this.recoveryPassForm.controls.email.value,
        type: ContactPointType.EMAIL,
      });
      this.dialogService.successModal(
        'Se ha enviado un link de recuperación a tu correo',
        true
      );
      this.minutes = 1;
      this.seconds = 30;
      this.countdown();
      this.disableButton = true;
      this.loadingService.setLoading(false);
      this.loginFormActive = 1;
    } catch (e) {
      this.loadingService.setLoading(false);
      this.dialogService.errorModal(e.message);
      console.error(e);
      throw e;
    }
  }

  /**
   * countdown to reactive resend button
   */
  countdown() {
    this.clock = interval(1000).subscribe((t) => {
      this.seconds -= 1;
      if (this.seconds < 10) {
        this.seconds = ('0' + this.seconds) as unknown as number;
      }
      if (this.seconds <= 0 && this.minutes > 0) {
        this.minutes--;
        this.seconds = 60;
      } else if (this.seconds == 0 && this.minutes == 0) {
        this.disableButton = false;
        this.clock.unsubscribe();
      }
    });
  }

  goBackToMailInput() {
    this.pinForm.reset();
    setTimeout(() => {
      this.pinForm.reset();
      this.clock.unsubscribe();
    }, 300);
    this.loginFormActive = 2;
  }

  moveFocus(event: KeyboardEvent) {
    let inputLength: number;
    const inputMaxLength: number = 1;

    if (event.code === 'Backspace') {
      inputLength = this.input2.nativeElement.value.length;
      if (inputLength < inputMaxLength) {
        this.input1.nativeElement.focus();
        return;
      }
      inputLength = this.input3.nativeElement.value.length;
      if (inputLength < inputMaxLength) {
        this.input2.nativeElement.focus();
        return;
      }
      inputLength = this.input4.nativeElement.value.length;
      if (inputLength < inputMaxLength) {
        this.input3.nativeElement.focus();
      }
    } else {
      inputLength = this.input1.nativeElement.value.length;
      if (inputLength === inputMaxLength) {
        this.input2.nativeElement.focus();
      }
      inputLength = this.input2.nativeElement.value.length;
      if (inputLength === inputMaxLength) {
        this.input3.nativeElement.focus();
      }
      inputLength = this.input3.nativeElement.value.length;
      if (inputLength === inputMaxLength) {
        this.input4.nativeElement.focus();
      }
    }
  }
}
