import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';
import { SplashDirective } from './directives/splash.directive';
import { LoadingDirective } from './directives/loading,directive';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TableComponent } from './components/table/table.component';
import { StylePaginatorDirective } from './directives/style-paginator.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SeizureRegisterFormComponent } from './components/seizure-register-form/seizure-register-form.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './components/dialog/dialog.component';
import { ViewDialogComponent } from './components/view-dialog/view-dialog.component';
import { RegisterAnimalFormComponent } from './components/register-animal-form/register-animal-form.component';
import { UserDataDialogComponent } from './components/user-data-dialog/user-data-dialog.component';
import { AddEditMedicinesComponent } from './components/add-edit-medicines/add-edit-medicines.component';
import { AddEditVitalSignsComponent } from './components/add-edit-vital-signs/add-edit-vital-signs.component';
import { InformationDropdownComponent } from './components/information-dropdown/information-dropdown.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { SpecimenDialogComponent } from './components/specimen-dialog/specimen-dialog.component';
import { BasicInfoExpandableComponent } from './components/basic-info-expandable/basic-info-expandable.component';
import { IndividualDialogComponent } from './components/individual-dialog/individual-dialog.component';
import { AddMsgComponent } from './components/add-msg/add-msg.component';
import { AddEditConfigurationComponent } from './components/add-edit-configuration/add-edit-configuration.component';
import { EditConfigurationComponent } from './components/edit-configuration/edit-configuration.component';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { TherapyDialogComponent } from './components/therapy-dialog/therapy-dialog.component';
import { GeneralClassesComponent } from './components/general-classes/general-classes.component';
import { PanelWithActionsComponent } from './components/panel-with-actions/panel-with-actions.component';
import { IntegerDirective } from "./directives/integer.directive";
import { AlphabeticDirective } from "./directives/alphabetic.directive";
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { AdmissionInfoExpandableComponent } from "./components/admission-info-expandable/admission-info-expandable.component";
import { ExitInfoExpandableComponent } from "./components/exit-info-expandable/exit-info-expandable.component";
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { TriageCategoryInfoComponent } from './components/triage-category-info/triage-category-info.component';
import { DangerLevelInfoComponent } from './components/danger-level-info/danger-level-info.component';
import {FormControlTrimDirective} from "./directives/trim.directive";
import { FormatTimePipeMayus } from './pipes/format-time-mayus.pipe';
import { ToBooleanPipe } from './pipes/to-boolean.pipe';
import {AlphanumericComaDirective} from './directives/alphanumeric-coma.directive';
import {DecimalPipe} from "./pipes/decimal.pipe";

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    SplashDirective,
    LoadingDirective,
    AccordionComponent,
    TableComponent,
    StylePaginatorDirective,
    FileUploadComponent,
    SeizureRegisterFormComponent,
    DialogComponent,
    ViewDialogComponent,
    RegisterAnimalFormComponent,
    UserDataDialogComponent,
    AddEditMedicinesComponent,
    AddEditVitalSignsComponent,
    InformationDropdownComponent,
    ExpansionPanelComponent,
    SpecimenDialogComponent,
    IndividualDialogComponent,
    BasicInfoExpandableComponent,
    AddMsgComponent,
    AddEditConfigurationComponent,
    EditConfigurationComponent,
    FormatTimePipe,
    TherapyDialogComponent,
    GeneralClassesComponent,
    PanelWithActionsComponent,
    IntegerDirective,
    AlphabeticDirective,
    AlphaNumericDirective,
    AdmissionInfoExpandableComponent,
    ExitInfoExpandableComponent,
    StatusDialogComponent,
    AlphanumericComaDirective,
    FormControlTrimDirective,
    TriageCategoryInfoComponent,
    DangerLevelInfoComponent,
    FormatTimePipeMayus,
    ToBooleanPipe,
    DecimalPipe
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    MaterialModule,
    RouterModule,
    NgxDropzoneModule,
    FormsModule,
    ReactiveFormsModule,
  ],
    exports: [
        SidebarComponent,
        HeaderComponent,
        SplashDirective,
        LoadingDirective,
        AccordionComponent,
        TableComponent,
        FileUploadComponent,
        SeizureRegisterFormComponent,
        DialogComponent,
        ViewDialogComponent,
        RegisterAnimalFormComponent,
        StylePaginatorDirective,
        UserDataDialogComponent,
        AddEditMedicinesComponent,
        AddEditVitalSignsComponent,
        InformationDropdownComponent,
        ExpansionPanelComponent,
        IndividualDialogComponent,
        BasicInfoExpandableComponent,
        AddMsgComponent,
        AddEditConfigurationComponent,
        FormatTimePipe,
        TherapyDialogComponent,
        GeneralClassesComponent,
        PanelWithActionsComponent,
        IntegerDirective,
        AlphabeticDirective,
        AlphaNumericDirective,
        AdmissionInfoExpandableComponent,
        ExitInfoExpandableComponent,
        AlphaNumericDirective,
        FormControlTrimDirective,
        FormatTimePipeMayus,
        ToBooleanPipe,
      DecimalPipe
    ],
})
export class SharedModule { }
