import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ShowImage} from "../../../shared/types/dialog.interface";

/**
 * Modal show image
 */
@Component({
  selector: 'dialog-show-document-component',
  templateUrl: './dialog-show-document.component.html',
  styleUrls: ['./dialog-show-document.component.scss'],
})
export class DialogShowDocumentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShowImage) {
  }
}
