<div class="flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-green" (click)="close()" data-cy="dialog-close-btn">
    <strong >X</strong>
  </button>
</div>
<div mat-dialog-content data-cy="status-dialog-container">
  <div class="main-dialog-container message-container">
    <div class="">
        <div class="icon-container flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog-confirm material-icons-two-tone tone-green">{{icon}}</span>
        </div>
        <span class="flex-column-center sub-title-green message-dialog mb-0-5 ">{{ message }}</span>
    </div>
  </div>
</div>
