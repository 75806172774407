<div class="header-container flex-row-space-between">
  <div class="header-logo flex-row-space-between">
    <img class="cav-logo" src="../../assets/png/login/logo_cav.png"/>
    <div class="small-sub-title-green">
      <strong>CENTRO DE ATENCIÓN Y VALORACIÓN DE FAUNA SILVESTRE</strong>
    </div>
  </div>
  <div class="header-options flex-row-space-between">
    <div class="icon-container">
      <mat-icon class="icon-header" svgIcon="calendar"></mat-icon>
      <span class="ml-1 text-m-14-blue">{{ day | date: "dd/MM/yyyy" }}</span>
    </div>
    <div class="icon-container">
      <mat-icon class="icon-header" svgIcon="clock"></mat-icon>
      <span class="ml-1 text-m-14-blue">{{ hour }}:{{ minutes }}</span>
      <span class="text-m-14-blue">{{ amPm }}</span>
    </div>
    <div class="icon-container">
      <a [routerLink]="headerAndSidebarService.urlIcon" routerLinkActive>
        <span class="material-icons-two-tone mt-0-5"> {{ headerAndSidebarService.iconHeader }} </span>
      </a>
    </div>
    <div class="icon-container">
      <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><span
        class="material-icons-two-tone mr-1" data-cy="header-settings-btn">settings</span></button>
      <mat-menu #menu="matMenu">
        <div class="icon-container-center ml-1-5">
          <span class="material-icons-two-tone mr-1">settings</span>
          <span class="text-m-14-blue"><strong>CONFIGURACIÓN</strong></span>
        </div>
        <div class="configuration-menu" *ngFor="let item of itemsMenu">
          <mat-accordion class="app-nav-accordion" (click)="$event.stopPropagation()">
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header id="menu-configuration" class="bottom-title">
                <mat-panel-title data-cy="header-menu-btn">
                  <div class="icon-container-center">
                    <span class="material-icons-two-tone mr-1">{{item.iconName}}</span>
                    <span class="text-m-14-blue">{{item.displayName}}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list *ngFor="let children of item.children" id="sub-menu-configuration" class="bottom-list">
                <a mat-list-item [routerLink]="children.route" routerLinkActive="active-link-sub"
                   (click)="closeMenu()" data-cy="header-menuOpt-btn"><span
                  class="text-m-12-blue">{{children.displayName}}</span></a>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-menu>
      <div class="icon-container btn" (click)="profileService.logOut()">
        <mat-icon class="icon-header" svgIcon="user-header"></mat-icon>
        <span class="text-m-14-blue ml-1">{{
          profileService.getNames({firstName: true, lastName: true})
          }}</span>
      </div>
    </div>
  </div>
