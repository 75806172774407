<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue"
          data-cy="btn-close-dialog" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-1">
    <div class="sub-title-blue-left title-danger">Detalle Nivel de Peligrosidad</div>
  </div>
  <mat-divider></mat-divider>
</div>

<mat-dialog-content>
  <div class="table-title-danger">
    <div class="table-title-text">Nivel de Riesgo</div>
    <div class="table-title-text">Categoría y Código de Peligrosidad</div>
    <div class="table-title-text">Tipo de Peligro</div>
    <div class="table-title-text">Ejemplos Individuos</div>
  </div>
  <div class="table-content">
    <div class="table-item-danger">
      <div class="table-item-text">
        <span class="table-item-bold">Nivel 1</span><br>
        <span>Alto riesgo</span>
      </div>
      <div class="table-item-text table-red-text">
        <span class="table-item-bold">Código Rojo</span><br>
        <span>Alta peligrosidad</span>
      </div>
      <div class="table-item-text">
        <span class="table-item-bold">Causa del riesgo: </span>
        <span>Por agresividad o toxicidad, capacidad de desplazamiento y defensa o dificultad de manipulación.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Daño: </span>
        <span>Pueden causar lesiones graves o muerte del personal encargado.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Manejo al escape o ataque: </span>
        <span>Podría significar la muerte de trabajadores o visitantes.
          El escape puede controlarse con inmovilización física y/o química a distancia. En casos graves se controlan con arma de fuego,
          generalmente se dispara a matar.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Recomendaciones: </span>
        <span>Se recomienda desarrollar las actividades en silencio y evitar los movimientos bruscos. El manejo debe ser con restricción química obligatoria y con protección adicional según sea el caso.</span>
      </div>
      <div class="table-item-text item-with-list">
        <ul>
          <li>Félidos grandes (jaguares, pumas)</li>
          <li>Osos</li>
          <li>Primates grandes (individuo o en tropa)</li>
          <li>Pecaríes, tapires, venados con astas o en época de celo</li>
          <li>Ciconiformes mayores (garzones)</li>
          <li>Grandes rapaces</li>
          <li>Ranas venenosas</li>
          <li>Ofidios venenosos</li>
          <li>Cocodrilidos mayores a 1 m.</li>
        </ul>
      </div>
    </div>
    <div class="table-item-danger">
      <div class="table-item-text">
        <span class="table-item-bold">Nivel 2</span><br>
        <span>Riesgo moderado</span>
      </div>
      <div class="table-item-text table-yellow-text">
        <span class="table-item-bold">Código Amarillo:</span><br>
        <span>Peligroso - moderadamente peligroso</span>
      </div>
      <div class="table-item-text">
        <span class="table-item-bold">Causa del riesgo: </span>
        <span>Por toxicidad, capacidad de desplazamiento y defensa o dificultad de manipulación</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Daño: </span>
        <span>Causa heridas incapacitantes o lesiones de tipo irreversible (amputaciones)</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Manejo al escape o ataque: </span>
        <span>Puede significar lesiones en trabajadores o visitantes o alteraciones al orden público. Se controla
         con inmovilización física y/o química a distancia.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Recomendaciones: </span>
        <span>Se recomienda desarrollar las actividades en silencio y evitar los movimientos bruscos. El manejo
         debe ser con restricción química obligatoria y con adicional según sea el  caso.</span>
      </div>
      <div class="table-item-text item-with-list">
        <ul>
          <li>Psitácidos y paseriformes mayores</li>
          <li>Mustélidos mayores</li>
          <li>Rapaces de tamaño medio</li>
          <li>Ciconiformes menores</li>
          <li>Tucanes</li>
          <li>Ofidios aglifos constrictores mayores a 1,5 m</li>
          <li>Ofidios opistoglifos</li>
          <li>Cocodrilidos entre 50 cm y 1 m</li>
          <li>Venados sin astas</li>
          <li>Carnívoros menores</li>
          <li>Hormigueros adultos</li>
          <li>Artrópodos venenosos y de moderada capacidad venenosa.</li>
        </ul>
      </div>
    </div>
    <div class="table-item-danger">
      <div class="table-item-text">
        <span class="table-item-bold">Nivel 3</span><br>
        <span>Bajo riesgo</span>
      </div>
      <div class="table-item-text table-green-text">
        <span class="table-item-bold">Código Verde</span><br>
      </div>
      <div class="table-item-text">
        <span class="table-item-bold">Causa del riesgo: </span>
        <span>Peligrosidad mínima.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Daño: </span>
        <span>Causan heridas no incapacitantes o reversibles</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Manejo al escape o ataque: </span>
        <span>Su escape reviste peligro mínimo para el humano. Su captura debe ser inmediata, principalmente a través
          de captura física.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Recomendaciones: </span>
        <span>Se recomienda desarrollar actividades en silencio y evitar movimientos bruscos. En cuanto al manejo se
        debería utilizar una técnica de restricción física o química según sea el caso.</span>
      </div>
      <div class="table-item-text item-with-list">
        <ul>
          <li>Psitaciformes menores</li>
          <li>Galliformes, anseriformes, rallidos</li>
          <li>Cocodrilidos inferiores a 50 cm.</li>
          <li>Lagartos menores</li>
          <li>Ofidios aglifos menores de 50 cm de longitud</li>
          <li>Armadillos.</li>
        </ul>
      </div>
    </div>
    <div class="table-item-danger">
      <div class="table-item-text">
        <span class="table-item-bold">Nivel 4</span><br>
        <span>Sin riesgo</span>
      </div>
      <div class="table-item-text table-blue-text">
        <span class="table-item-bold">Código Azul</span><br>
      </div>
      <div class="table-item-text">
        <span class="table-item-bold">Causa del riesgo: </span>
        <span>Peligrosidad nula</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Daño: </span>
        <span> No causan heridas físicas o causan heridas con bajo potencial de daño.</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Manejo al escape o ataque: </span>
        <span>Su escape no reviste peligro para el humano. Su captura debe ser inmediata, principalmente a través
        de captura física</span>
        <div class="break-line"></div>
        <span class="table-item-bold">Recomendaciones: </span>
        <span>Se recomienda desarrollar actividades en silencio y evitar movimientos bruscos. En cuanto al manejo
         se debería utilizar una técnica de restricción física o química según sea el caso.</span>
      </div>
      <div class="table-item-text item-with-list">
        <ul>
          <li>Passeriformes menores</li>
          <li>Columbiformes</li>
        </ul>
      </div>
    </div>
  </div>
</mat-dialog-content>
