import { MenuOptions, SideBarMenu } from './menu-options.model';

export const MENU_OPTIONS: MenuOptions[] = [
  {
    icon: 'edit',
    menuLabel: 'Registro',
    menuOption: SideBarMenu.START,
    collapsed: false,
    link: '/home',
    subMenu: [
      {
        icon: 'summarize',
        subMenuOption: 'Gestionar Actas de Incautación',
        subMenuLink: '/home/seizure-record',
      },
      {
        icon: 'source',
        subMenuOption: 'Gestionar Salvoconductos',
        subMenuLink: '/home/safeguard',
      },
      {
        icon: 'medical_history',
        subMenuOption: 'Historia Clínica Preliminar',
        subMenuLink: '/home/medical-history',
      },
      {
        icon: 'release_form',
        subMenuOption: 'Acta de Liberación',
        subMenuLink: '/home/release',
      },
    ],
  },
  {
    icon: 'entry',
    menuLabel: 'Ingreso',
    menuOption: SideBarMenu.PET,
    collapsed: false,
    link: '',
    subMenu: [
      {
        icon: 'assignment',
        subMenuOption: 'Gestionar Actas de Entrega de Individuos',
        subMenuLink: '/home/deliver-specimens',
      },
      {
        icon: 'assignment_turned_in',
        subMenuOption: 'Registrar Ingreso y Salida',
        subMenuLink: '/home/admission-departure-management',
      },
    ],
  },
  {
    icon: 'medical_services',
    menuLabel: 'Gestión Médica',
    menuOption: SideBarMenu.START,
    collapsed: false,
    link: '/home',
    subMenu: [
      {
        icon: 'medical_exam',
        subMenuOption: 'Gestión de Examen Médico',
        subMenuLink: '/home/clinical-examination',
      },
      {
        icon: 'vaccines',
        subMenuOption: 'Gestión de Procesos de Eutanasia',
        subMenuLink: '/home/euthanasia',
      },
      {
        icon: 'necropsy',
        subMenuOption: 'Gestión de Necropsias',
        subMenuLink: '/home/necropsy',
      },
      {
        icon: 'bloodtype',
        subMenuOption: 'Gestión de Muestras',
        subMenuLink: '/home/samples',
      },
      {
        icon: 'table_chart',
        subMenuOption: 'Gestión de Etogramas',
        subMenuLink: '/home/ethograms',
      },
      {
        icon: 'bird2',
        subMenuOption: 'Gestión de Monitoreo de Neonatos',
        subMenuLink: '/home/neonatal-monitoring',
      },
      {
        icon: 'biological',
        subMenuOption: 'Gestión Biológica de la Especie',
        subMenuLink: '/home/biology-species',
      },
      {
        icon: 'routine_evaluation',
        subMenuOption: 'Gestión de Seguimiento a la Evolución',
        subMenuLink: '/home/routine-evaluation',
      },
      {
        icon: 'set_meal',
        subMenuOption: 'Gestión de Dietas',
        subMenuLink: '/home/diet-management',
      },
      {
        icon: 'sports_baseball',
        subMenuOption: 'Solicitud de Enriquecimiento',
        subMenuLink: '/home/enrichment-management',
      },
      {
        icon: 'grass-white',
        subMenuOption: 'Gestión de Enriquecimiento',
        subMenuLink: '/home/enrichment-environment',
      },
      {
        icon: 'medication',
        subMenuOption: 'Gestión Terapéutica',
        subMenuLink: '/home/therapeutic',
      },
      {
        icon: 'waste',
        subMenuOption: 'Gestión Evaluación Consumo y Aceptación Dieta',
        subMenuLink: '/home/diet-consumption',
      },
      {
        icon: 'temporary-exit',
        subMenuOption: 'Gestión Acta de Entrega Temporal',
        subMenuLink: '/home/temporary-delivery',
      },
      {
        icon: 'fact_check',
        subMenuOption: 'Gestión Concepto Técnico de Egreso',
        subMenuLink: '/home/biological-veterinary-concept',
      },
      {
        icon: 'entry',
        subMenuOption: 'Gestión de Historias Clínicas',
        subMenuLink: '/home/clinic-history',
      }
    ],
  },
  {
    menuLabel: 'Hospitalización',
    icon: 'local_hospital',
    link: '',
    menuOption: SideBarMenu.HOSPITALIZATION,
    collapsed: false,
    subMenu: [
      {
        subMenuOption: 'Registro Ingreso',
        icon: 'exit_register',
        subMenuLink: '/home/hospitalization-income-record',
      },
      {
        subMenuOption: 'Registro Seguimiento',
        icon: 'history_white',
        subMenuLink: '/home/hospitalization-tracking-record',
      },
      {
        subMenuOption: 'Registro Salida',
        icon: 'output_white',
        subMenuLink: '/home/hospitalization-check-out-record',
      },
    ],
  },
  {
    menuLabel: 'Cuarentena',
    icon: 'window',
    link: '',
    menuOption: SideBarMenu.QUARANTINE,
    collapsed: false,
    subMenu: [
      {
        subMenuOption: 'Registro Ingreso',
        icon: 'quarantine',
        subMenuLink: '/home/quarantine/admission-quarantine/list',
      },
      {
        subMenuOption: 'Registro Salida',
        subMenuLink: '/home/quarantine/departure-quarantine/list',
        icon: 'exit-quarantine',
      },
    ],
  },
  {
    menuLabel: 'Cuidado Crítico',
    icon: 'critical-care',
    menuOption: SideBarMenu.CRITICAL,
    collapsed: false,
    link: '',
    subMenu: [
      {
        subMenuOption: 'Registro Ingreso',
        icon: 'exit_register',
        subMenuLink: '/home/critical-care-income/list',
      },
      {
        subMenuOption: 'Registro Monitoreo',
        icon: 'history_white',
        subMenuLink: '/home/critical-monitoring/list',
      },
      {
        subMenuOption: 'Registro Salida',
        icon: 'output_white',
        subMenuLink: '/home/critical-care-discharge/list',
      },
    ],
  },
  {
    icon: 'assessment',
    menuLabel: 'Reportes',
    menuOption: SideBarMenu.REPORT,
    collapsed: false,
    link: '',
    subMenu: [
      {
        subMenuOption: 'Reporte General',
        icon: 'feed',
        subMenuLink: '/home/general-report/list',
      },
      {
        subMenuOption: 'Reporte Muestras de Laboratorio',
        icon: 'create_new_folder',
        subMenuLink: '/home/sample-lab-report/list',
      },
    ],
  },
];
