export class MenuOptions {
  icon: string;
  menuLabel: string;
  menuOption: SideBarMenu;
  collapsed: boolean;
  link: string;
  subMenu?: SubMenu[];
}

export enum SideBarMenu {
  START,
  REGISTER,
  HISTORY,
  CATEGORY,
  PET,
  REPORT,
  HOSPITALIZATION,
  QUARANTINE,
  SETTINGS,
  CRITICAL
}

class SubMenu {
  icon: string;
  subMenuOption: string;
  SubMenuOptionE?: string;
  editableId?: Entities;
  subMenuLink: string;
}

enum Entities {
  START,
  REGISTER,
  HISTORY,
  CATEGORY,
  PET,
  REPORT,
  SETTINGS,
  HOSPITALIZATION,
}
