<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
    <div class="icon-container-center mb-0-5">
      <span class="material-icons-bold blue mr-1">add</span>
      <div class="sub-title-blue-left">{{title}}</div>
    </div>
    <mat-divider></mat-divider>
  </div>
<mat-dialog-content>

  <form novalidate [formGroup]="dataUserControl"  class="input-form mt-2">
    <div class="flex-column-center">
      <mat-form-field class="input-control" appearance="outline" placeholder="Seleccione un usuario">
        <mat-label>Nombre</mat-label>
        <input matInput data-cy="official-name-input" formControlName="idProfessional" #autoProfessionalInput="matAutocompleteTrigger"  [matAutocomplete]="autoProfessional">
        <mat-autocomplete autoActiveFirstOption #autoProfessional="matAutocomplete" (optionSelected)="optionSelectedAutoComplete($event)">
          <mat-option value="Otro" *ngIf="showOptionOther && isEndData">Otro</mat-option>
          <ng-container *ngIf="viewActorPermission">
            <mat-option  [value]="professional" *ngFor="let professional of  filteredOptionProfessional | async">
              {{professional.firstName.value + ' ' + professional.secondName.value + ' ' + professional.lastName.value + ' ' + professional.secondLastName.value}}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </form>

  <ng-container *ngIf="isOptionSelected">
    <form novalidate [formGroup]="dataUserSaveForm" class="input-form" >
      <div class="flex-column-center">
        <ng-container *ngIf="dataUserControl.value">
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input alphabetic matInput formControlName="userName" class="form-control" autocomplete="off"
                   (keydown)="validatorsService.textOnly($event)" (paste)="validatorsService.textOnly($event)"
                   (keyup)="validatorsService.validateText('userName', dataUserSaveForm)"/>
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Cargo</mat-label>
            <input type="text" matInput formControlName="position" class="form-control" autocomplete="off" />
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Entidad</mat-label>
            <input type="text" matInput formControlName="entity" class="form-control" autocomplete="off"/>
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Tipo de Documento</mat-label>
            <mat-select formControlName="documentType" class="form-control">
              <mat-option *ngFor="let typeDocument of typeDocumentList" [value]="typeDocument.value">{{
                typeDocument.label
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Documento</mat-label>
            <input type="number" matInput formControlName="document" class="form-control" autocomplete="off" />
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input matInput integerNumber formControlName="phone" class="form-control" autocomplete="off"
                   (keydown)="validatorsService.numberOnly($event)" (paste)="validatorsService.numberOnly($event)"
                   (keyup)="validatorsService.validateNumber('phone', dataUserSaveForm)"/>
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>No. Tarjeta Profesional</mat-label>
            <input type="text" matInput formControlName="professionalCard" class="form-control" autocomplete="off" />
          </mat-form-field>
          <mat-form-field class="input-control" appearance="outline">
            <mat-label>Vinculación</mat-label>
            <mat-select formControlName="association" class="form-control">
              <mat-option *ngFor="let association of associationList" [value]="association.value">{{
                association.label
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
    </form>
    <div class="button-container flex-row-end" *ngIf="dataUserControl.value">
      <button mat-button class="btn-cancel-stepper" (click)="cancel()">CANCELAR</button>
      <button type="submit" mat-button class="btn-search" data-cy="save-official-button" (click)="onSubmit($event)">
        GUARDAR
      </button>
    </div>
  </ng-container>

</mat-dialog-content>
