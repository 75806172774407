import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { Clock } from '../entities/clock/clock.model';
import {map, shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  public clock: Observable <Date>;
  public dateInfo$ = new Subject<Clock>();
  public dateActive: Clock;
  public date: Date;
  public amPm: string;
  public hours: number;
  public minutes: string;

  constructor() {
    this.clock = timer(0,1000).pipe(map(t => new Date()),shareReplay(1));
  }

  /**
   * Method that get tha actually date
   * @returns date
   */
  getActuallyDate(): Observable<Clock>{
    this.clock.subscribe(time => {
     this.hours = time.getHours() % 12;
     this.hours = this.hours ? this.hours : 12;
      this.dateActive = {
        hour: this.hours,
        minutes: (time.getMinutes() < 10) ? '0' + time.getMinutes() : time.getMinutes().toString(),
        amPm: time.getHours() > 11 ? 'PM' : 'AM',
      date: time
      }
      this.dateInfo$.next(this.dateActive);
    });
    return this.dateInfo$.asObservable();

  }

}
