import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input[alphanumeric-coma]'
})
export class AlphanumericComaDirective {

  constructor(private _el: ElementRef) { }
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initialValue.replace(/[^0-9a-zA-ZñÑáíóúéÁÉÍÓÚ, ]*/g, '');
    if(initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
