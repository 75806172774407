import {
    OnInit,
    OnChanges,
    Directive,
    Input,
    HostBinding,
    Renderer2,
    ElementRef,
    SimpleChanges
  } from '@angular/core';
  
  @Directive({
    selector: '[appLoading]'
  })
  export class LoadingDirective implements OnInit, OnChanges {
    @HostBinding('style.position')
    hostPosition: string = 'relative';
  
    @Input() public appLoading: boolean = false;
  
    constructor(private targetEl: ElementRef, private renderer: Renderer2) {
    }
  
    ngOnInit() {
  
      const loadingContainer = this.renderer.createElement('div');
      this.renderer.setStyle(
        loadingContainer,
        'display',
        this.appLoading ? 'flex' : 'none'
      );
      this.renderer.setStyle(loadingContainer, 'justify-content', 'center');
      this.renderer.setStyle(loadingContainer, 'align-items', 'center');
      this.renderer.addClass(loadingContainer, 'loading-container');
      this.renderer.setStyle(loadingContainer, 'position', 'absolute');
      this.renderer.setStyle(loadingContainer, 'top', '0');
      // this.renderer.setStyle(loadingContainer, 'background', '#e4e4e4');
      this.renderer.setStyle(loadingContainer, 'width', '100%');
      this.renderer.setStyle(loadingContainer, 'height', '100%');
  
      // custom spinner -- start
      const spinnerContainer = this.renderer.createElement('div');
      this.renderer.addClass(spinnerContainer, 'lds-roller');
      const spinnerInnerDiv1 = this.renderer.createElement('div');
      const spinnerInnerDiv2 = this.renderer.createElement('div');
      const spinnerInnerDiv3 = this.renderer.createElement('div');
      const spinnerInnerDiv4 = this.renderer.createElement('div');
      const spinnerInnerDiv5 = this.renderer.createElement('div');
      const spinnerInnerDiv6 = this.renderer.createElement('div');
      const spinnerInnerDiv7 = this.renderer.createElement('div');
      const spinnerInnerDiv8 = this.renderer.createElement('div');
  
  
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv2);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv3);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv4);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv5);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv6);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv7);
      this.renderer.appendChild(spinnerContainer, spinnerInnerDiv8);
  
      this.renderer.appendChild(loadingContainer, spinnerContainer);
      // custom spinner -- end
  
      this.renderer.appendChild(this.targetEl.nativeElement, loadingContainer);
    }
  
    ngOnChanges(simpleChanges: SimpleChanges) {
      if (simpleChanges.appLoading) {
        const container = this.targetEl.nativeElement;
        const div = container.querySelector('.loading-container');
        if (div) {
          this.renderer.setStyle(
            div,
            'display',
            this.appLoading ? 'flex' : 'none'
          );
        }
      }
    }
  }
  