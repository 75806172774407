import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toBoolean'
})
export class ToBooleanPipe implements PipeTransform {

  transform(value: boolean | string | undefined): boolean {
    if (typeof value === 'undefined' || value === '')
      return false;
    if (typeof value === 'boolean')
      return value;
    return value.toLowerCase() === 'true';
  }

}
