import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {from, Observable} from 'rxjs';
import {ProfileService} from "../profile/profile.service";
import {AuthService} from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class CustomHttpInterceptor implements CustomHttpInterceptor {

    private profileService: ProfileService | undefined;
    private authService: AuthService | undefined;

    constructor(public router: Router, private injector: Injector) {
        setTimeout(() => {
            this.profileService = this.injector.get(ProfileService);
            this.authService = this.injector.get(AuthService);
        });
    }

    /**
     * intercept interceptor to refresh token and errors control
     * @param request request to send
     * @param next next request step
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //
        this.authService = this.injector.get(AuthService);
        const skipIntercept = request.headers.has('skip');
        request = request.clone({
            headers: request.headers.delete('skip')
        });
        //
        if (skipIntercept || request.url.includes("openstreetmap") || request.url.includes("hereapi") || request.url.includes("geoserver") || request.url.includes(".json")) {
            return next.handle(request);
        }
        return from(this.validateToken(request, next));
    }

    /**
     * handle validate refresh token and clone request with new access token
     * @param req request to send
     * @param next next request step
     */
    public async validateToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        try {
            let success = false;
            const cloneRequest = () => {
                //
                const headers = req.headers.append('Authorization', `Bearer ${this.authService!.getToken("ACCESS")}`);
                if (!req.url.includes('assets/svg'))
                    req = req.clone({
                        headers
                    });
                success = true;
            };
            if (!this.authService) {
                while (true) {
                    await new Promise(resolve => setTimeout(resolve, 10));
                    if (this.authService) {
                        break;
                    }
                }
            }
            if (this.authService.checkToken("ACCESS")) {
                cloneRequest();
            } else if (this.authService.checkToken("REFRESH")) {
                try {
                    await this.authService.refreshToken();
                    cloneRequest();
                } catch (e) {
                    console.error("[Interceptor Catch] refresh token", e);
                    return this.logout();
                }
            }
            if (success) {
                return await next.handle(req).toPromise();
            } else {
                return this.logout();
            }
        } catch (error) {
            this.handleError(error);
            throw error;
        }
    }

    /**
     * fired when a request ends with error
     * @param errorEvent
     */
    public handleError(errorEvent: HttpErrorResponse) {
    }

    /**
     * error redirect to login and delete existing tokens
     */
    public logout() {
        this.profileService!.clearProfileData();
        this.authService!.showSessionExpired();
        this.goIndex();
        return new Observable<HttpEvent<any>>().toPromise();
    }

    public goIndex() {
        const link = ['/'];
        this.router.navigate(link);
    }
}
