import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {LoadingService} from './shared/services/loading.service';
import {MatIconRegistry} from "@angular/material/icon";
import {RegisterSVGIconsService} from "./shared/services/register-svg-icons.service";
import {AuthService} from "./shared/services/auth/auth.service";
import {AlertService} from "./shared/services/alert.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cav';
  private lastVH = -1;
  private lastVW = -1;

  constructor(public loadingService: LoadingService,
              private authService: AuthService,
              private alert: AlertService,
              private matIconRegistry: MatIconRegistry, // no borrar
              private domSanitizer: DomSanitizer, // no borrar
              private registerSVGIconsService: RegisterSVGIconsService, // no borrar
  ) {
  }

  refreshToken(){
    this.authService.refreshToken().then(value => {
      this.alert.showInformation('Token refrescado');
    })
  }

  ngOnInit(): void {
    /**
     *capture the size of the viewport height
     *calculate the equivalence to 1vh and
     *save the value creating a css variable
     */
    const calculateViewport = () => {
// We execute the same script as before
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      return {vh, vw};
    };
    window.addEventListener('resize', calculateViewport);
    let eventFired = false;
    window.addEventListener('orientationchange', () => {
      if (eventFired) {
        return;
      }
      eventFired = true;
      let running = true;
      for (const delay of [50, 100, 200, 300, 500, 800, 1000]) {
        setTimeout(() => {
          if (running) {
            const {vh, vw} = calculateViewport();
            if (vh !== this.lastVH || vw !== this.lastVW) {
              this.lastVH = vh;
              this.lastVW = vw;
              running = false;
            }
          }
        }, delay);
      }
      eventFired = false;
    });
    const {vh, vw} = calculateViewport();
    this.lastVH = vh;
    this.lastVW = vw;
//
  }

  public readonly window = window;
}
