import { ElementRef, Injectable } from '@angular/core';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { DomSanitizer } from "@angular/platform-browser";
import { LogoInterface } from "../../settings/administration-management/types/basic-data.interface";
import { DialogService } from "./dialog.service";
import {
  AdministrationManagementService
} from "../../settings/administration-management/services/administration-management.service";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {
  public carLogo: string;
  public cavLogo: string;
  public carLogoImageUrl: string;
  public cavLogoImageUrl: string;

  public options = {
    background: 'white',
    scale: 2,
    pagesplit: true,
    pagesbreak: {before: '.newPage', avoid: ['h2', 'h3', 'h4', '.field']},
    jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
  };

  constructor(private readonly http: HttpClient,
    private readonly sanitizer: DomSanitizer,
    private dialogService: DialogService,
    public datePipe: DatePipe,
    private administrationManagementService: AdministrationManagementService
  ) {
    this.getCavCarIcons();
  }

  /**
   * generate the pdf to download
   * @param fileName
   * @param element
   */
  public async downloadPDF(fileName: string, element: ElementRef) {
    const DATA = element.nativeElement;
    DATA.style.pageBreakAfter = "always";
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = this.options;
    if (DATA) {
      html2canvas(DATA, options).then((canvas) => {
        const img = canvas.toDataURL('image/PNG');
        let position = 24;
        const bufferX = 24;
        const marginTop = 24;
        const marginBootom = 48;
        const imgWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // 2 * bufferX represent horizontal margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width; //total img width
        const pageHeight = doc.internal.pageSize.getHeight() - (marginTop + marginBootom);
        let heightLeft = imgHeight;

        while (heightLeft >= 0) {
          doc.addImage(img, "PNG", bufferX, position, imgWidth, imgHeight, 'img', 'FAST');
          position -= pageHeight
          heightLeft -= pageHeight;

          if (heightLeft >= 0) //no agregar pagina en blanco al final
            doc.addPage();
        }
        return doc;
      }).then((docResult) => {
        for (let page = 1; page < docResult.internal.pages.length; page++) {
          doc.setPage(page);

          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();
          //margin (2 rectangles in top and bootom)
          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pageWidth, 24, 'F');
          doc.rect(0, pageHeight - 48, pageWidth, 48, 'F');
          //pageNumber
          doc.setFontSize(8);
          doc.text(String(page), pageWidth / 2, pageHeight - 24,);
        }
        docResult.save(`${fileName}.pdf`);
      });
    } else {
      console.error('error')
    }
  }

  /**
   * sets whitespaces when information is cut off when changing pages
   * @param pdfItems - items to adjust
   * @param pageHeight - Page size, this is where the pdf is cut
   * @param padding - true if you want to set whitesSpace as a padding (it is set to margin by default)
   * @param sumSpace
   */
  adjustPDF(pdfItems: ElementRef[], pageHeight = 1024) {

    let heightCounter = 0;
    let counter = 0
    pdfItems.forEach(item => {

      if(item.nativeElement && item.nativeElement.style){
        item.nativeElement.style.marginTop = `0px`; //To clean the previous styles
        item.nativeElement.style.marginBottom = `0px`;
        item.nativeElement.style.paddingTop = `0px`;
        item.nativeElement.style.paddingBottom = `0px`;
        item.nativeElement.style.borderBottom = `1px solid white`;
        item.nativeElement.style.borderTop = `1px solid white`;
      }


      const styles = window.getComputedStyle(item.nativeElement);
      const marginTop = parseFloat(styles.marginTop);
      const marginBottom = parseFloat(styles.marginBottom);
      const sizeNode = parseFloat(item.nativeElement.getBoundingClientRect().height)
      let totalSizeItem = sizeNode + marginTop + marginBottom


      if (heightCounter + totalSizeItem >= pageHeight) {
        const whiteSpace = pageHeight - heightCounter;
        if(totalSizeItem >= pageHeight){
          if(whiteSpace>= pageHeight){
            heightCounter = totalSizeItem - whiteSpace
            // item.nativeElement.style.backgroundColor = 'lightcoral';
          }else{
            heightCounter = totalSizeItem - pageHeight
            item.nativeElement.style.marginTop = `${whiteSpace }px`; //
            // item.nativeElement.style.backgroundColor = 'red';
          }
        }else{
          heightCounter = totalSizeItem - marginTop ; //Because the margin top belong to previous page
          item.nativeElement.style.marginTop = `${whiteSpace +(3+counter)*counter }px`; //+3**counter
          // item.nativeElement.style.backgroundColor = 'green';
          counter = counter+1
        }
      } else {
        heightCounter += totalSizeItem; //Because is the whole item
        //item.nativeElement.style.backgroundColor = 'yellow';
      }
    });
  }


  adjustPDFDoc(pdfItems: ElementRef[], pageHeight = 1024, padding = false) {
    pdfItems.forEach(item => {
      const element = item.nativeElement
      const pageNumber = Math.ceil(element.offsetTop / pageHeight)

      if (element.offsetTop > 0 && (
        element.offsetTop <= pageHeight * pageNumber &&
        element.offsetTop + element.offsetHeight >= pageHeight * pageNumber
      )) {
        const whiteSpace = (pageHeight * pageNumber) - element.offsetTop;
        if (padding) {
          item.nativeElement.style.paddingTop = `${whiteSpace}px`;
        } else
          item.nativeElement.style.marginTop = `${whiteSpace}px`;
      }
    });
  }






  /**
   * generate the pdf to download with watermark
   * @param fileName
   * @param element
   * @param watermark
   * @param marginBot
   */
  public async downloadPDFWithWaterMark(fileName: string, element: ElementRef, watermark: ElementRef, marginBot: number = 185) {

    // This adds the link to the watermark picture
    const waterMark = await this.createWaterMark(watermark);

    const DATA = element.nativeElement;
    DATA.style.pageBreakAfter = "always";
    const doc = new jsPDF('p', 'pt', 'a4');
    const options = this.options;
    if (DATA) {
      html2canvas(DATA, options).then((canvas) => {
        const img = canvas.toDataURL('image/PNG');
        let position = 24;
        const bufferX = 24;
        const marginTop = 24;

        //if you change this value, you must change the second height of doc.rect
        const marginBootom = marginBot;
        const imgWidth = doc.internal.pageSize.getWidth() - 2 * bufferX; // 2 * bufferX represent horizontal margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width; //total img width
        const pageHeight = doc.internal.pageSize.getHeight() - (marginTop + marginBootom);
        let heightLeft = imgHeight;

        while (heightLeft >= 0) {
          doc.addImage(img, "PNG", bufferX, position, imgWidth, imgHeight, 'img', 'FAST');
          position -= pageHeight
          heightLeft -= pageHeight;

          if (heightLeft >= 0) //no agregar pagina en blanco al final
            doc.addPage();
        }
        return doc;
      }).then((docResult) => {
        for (let page = 1; page < docResult.internal.pages.length; page++) {
          doc.setPage(page);

          const pageWidth = doc.internal.pageSize.getWidth();
          const pageHeight = doc.internal.pageSize.getHeight();

          //margin (2 rectangles in top and bootom)
          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pageWidth, 24, 'F');

          //if you change this height, you must change the marginBootom
          doc.rect(0, pageHeight - 185, pageWidth, pageHeight, 'F');

          //This adds the watermark as footer
          doc.addImage(waterMark, 'png', 0, pageHeight - 180, pageWidth, 132, 'img', 'FAST');

          //pageNumber
          doc.setFontSize(8);
          doc.text(String(page), pageWidth / 2, pageHeight - 24,);
        }
        docResult.save(`${fileName}.pdf`);
      });
    } else {
      console.error('error')
    }
  }

  /**
   * converts elementRef to a url image
   * @param waterMark
   */
  async createWaterMark(waterMark : ElementRef){
    return html2canvas(waterMark.nativeElement).then((canvas) => {
      return canvas.toDataURL("image/png");
    });
  }

  /**
   * convert local images and generate url
   * @param fileUri
   * @param expectedMimeType
   */
  public async fetchFileDataURL(fileUri: string, expectedMimeType?: string): Promise<any> {
    if (typeof fileUri === 'object') {
      return fileUri;
    }
    try {
      let headers = new HttpHeaders();
      headers = headers.append('skip', 'true')
      const response = await this.http.get<Blob>(fileUri, {
        responseType: "blob" as "json",
        headers
      }).toPromise();
      const file = new File([response], `file-${Date.now()}`, expectedMimeType ? { type: expectedMimeType } : undefined);
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    } catch (e) {
      console.error(e);
      throw new Error("could not fetch given uri");
    }
  }

  public async getCavCarIcons() {
    try {
      let dataSource: LogoInterface[] = [];
      dataSource = await this.administrationManagementService.getAllLogo();
      dataSource.sort(this.sortArray);
      const d = dataSource.map(async img => {
        return await this.fetchFileDataURL(img.pictureUrl as string, 'image/png');
      })
      this.carLogo = await d[0];
      this.cavLogo = await d[1];
      this.carLogoImageUrl = dataSource[0]?.pictureUrl as string;
      this.cavLogoImageUrl = dataSource[1]?.pictureUrl as string;
    } catch (e) {
      console.error(e);
      this.dialogService.errorModal('Ha ocurrido un error al traer los logos');
    }
  }
  /**
   * sort array logos by id
   * @private
   * @param logo1
   * @param logo2
   */
  private sortArray(logo1: LogoInterface, logo2: LogoInterface) {
    if (logo1.id < logo2.id) {
      return -1;
    }
    if (logo1.id > logo2.id) {
      return 1;
    }
    return 0;
  }

  public commonFile(response: Blob, filename: string) {
    let dataType = response.type;
    let binaryData: Blob[] = [];
    binaryData.push(response);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType })
    );
    downloadLink.setAttribute(
      'download',
      `${filename}.xlsx`
    );
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

}
