<div [formGroup]="registerSpecimensForm">
  <ng-container formArrayName="specimenForm" class="specimen-form">
    <ng-container *ngFor="let specimen of specimenForm.controls; let i = index">
      <div class="lesson-form-row search-form" [formGroup]="specimen">
        <span class="text-m-16-blue mr-1"
        ><strong>{{ i + 1 }}.</strong></span
        >

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Género</mat-label>
          <mat-select formControlName="genusId" class="form-control">
            <mat-option *ngFor="let genera of generaList" [value]="genera.id">{{
              genera.name
              }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Especie</mat-label>
          <mat-select formControlName="specieId" class="form-control">
            <mat-option
              *ngFor="let specie of speciesList"
              [value]="specie.id"
            >{{ specie.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Nombre Común</mat-label>
          <input matInput formControlName="commonName" class="form-control"/>
        </mat-form-field>

        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Historia Clínica Preliminar</mat-label>
          <input matInput formControlName="preliminaryClinicHistory" class="form-control"/>
        </mat-form-field>

        <input type="text" hidden formControlName="editable">
        <i data-cy="delete-item-mat-icon" class="mr-1 material-icons-two-tone btn" *ngIf="specimen.get('editable').value && !specimen.get('preliminaryClinicHistory')?.value"
            (click)="deleteItem(i)">delete</i>
      </div>
    </ng-container>
  </ng-container>
</div>
