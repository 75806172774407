import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from "@angular/core";

@Directive({
    selector: '[appSplash]',
    inputs: ['animation', 'appSplash']
})
/**
 * splash directive (have only 2 animations)
 */
export class SplashDirective implements OnInit, OnChanges {

    @HostBinding('style.position')
    hostPosition: string = 'relative';

    @Input('appSplash') public appSplash: boolean = false;

    constructor(private targetEl: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {

        this.createSplashElement();
        
    }

    //add splash to DOM
    private createSplashElement() {
        //create splash container
        const splashContainer = this.renderer.createElement('div');

        this.renderer.setStyle(splashContainer, 'display',
            this.appSplash ? 'flex' : 'none'
        );
        this.renderer.addClass(splashContainer, 'splash-container');

        //create logo container
        const logoContainer = this.renderer.createElement('div');
        this.renderer.addClass(logoContainer, 'logo-container');

        //create logo 
        const logo = this.renderer.createElement('img');
        this.renderer.addClass(logo, 'logo');
        this.renderer.setAttribute(logo, 'src', 'assets/png/logo-gy.png');

        //append childs
        this.renderer.appendChild(logoContainer, logo);
        this.renderer.appendChild(splashContainer, logoContainer);
        this.renderer.appendChild(this.targetEl.nativeElement, splashContainer);

        if(this.appSplash) {
            this.showSplash();
        }
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        
        if(simpleChanges.appSplash) {
            if(this.appSplash) {
                this.showSplash();
            }
            else {
                this.hideSplash();
            }
        }

    }

    //show splash, select the animation and add it to elements
    private showSplash() {
        const container = this.targetEl.nativeElement;
        const splash = container.querySelector('.splash-container');
        this.renderer.setStyle(splash, 'display', 'flex');
        if(splash) {
            this.renderer.addClass(splash, 'splash-fade-in');
            const logoContainer = splash.querySelector('.logo-container');
            const logo = logoContainer.querySelector('.logo');
            this.renderer.setStyle(logo, 'width', '0px');
            setTimeout(()=> {
                if(logoContainer) {
                    this.renderer.addClass(logoContainer, 'logo-zoom')
                    this.renderer.removeStyle(logo, 'width');
                    setTimeout(() => {
                        this.renderer.removeClass(logoContainer, 'logo-zoom')
                        this.renderer.addClass(logoContainer, 'logo-zoom-bounce')
                    }, 1800);
                }
            }, 300);
        }
    }

    //reset all css class in elements
    private hideSplash() {
        const splash = this.targetEl.nativeElement.querySelector('.splash-container');
        if(!splash) {
            return;
        }
        this.renderer.removeClass(splash, 'splash-fade-in');
        const logo = splash.querySelector('.logo-container');
        this.renderer.removeClass(logo, 'logo-zoom-bounce')
        this.renderer.setStyle(splash, 'display', 'none');
    }

}