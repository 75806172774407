<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-1">
    <span class="material-icons-bold blue">add</span>
    <div class="sub-title-blue-left">{{title}}</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content class="mat-dialog-content-wrapper">
  <form novalidate [formGroup]="saveVitalSignsForm" class="input-form register-form-wrapper mt-1">
    <div class="flex-column-start width-100 mb-0-5 radio-button-options">
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Tiempo (Minutos)</mat-label>
        <input matInput formControlName="timeMin" class="form-control" type="number" autocomplete="off">
      </mat-form-field>

      <div class="flex-row-space-between">
        <span class="width-30">FR.C: </span>
        <mat-radio-group formControlName="FRC" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" value=true>Presente</mat-radio-button>
          <mat-radio-button class="radio-button-width" value=false>Ausente</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex-row-space-between">
        <span class="width-30">FR.R: </span>
        <mat-radio-group formControlName="FRR" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" value=true>Presente</mat-radio-button>
          <mat-radio-button class="radio-button-width" value=false>Ausente</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex-row-space-between">
        <span class="width-30">Pulso: </span>
        <mat-radio-group formControlName="pulse" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" value=true>Presente</mat-radio-button>
          <mat-radio-button class="radio-button-width" value=false>Ausente</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex-row-space-between">
        <span class="width-30">Mucosa oral: </span>
        <mat-radio-group formControlName="oralMucosa" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" *ngFor="let item of ORAL_MUCOSA_TRANSLATE" [value]="item.value">
            {{item.label}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="flex-row-space-between">
        <span class="width-30">Reflejo corneal: </span>
        <mat-radio-group formControlName="cornealReflex" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" value=true>Presente</mat-radio-button>
          <mat-radio-button class="radio-button-width" value=false>Ausente</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="flex-row-space-between">
        <span class="width-30">Pupilas: </span>
        <mat-radio-group formControlName="pupils" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" *ngFor="let item of PUPILS_TRANSLATE" [value]="item.value">
            {{item.label}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="flex-row-space-between">
        <span class="width-30">Reflejo al dolor: </span>
        <mat-radio-group formControlName="painReflex" class="width-70 radio-button-height">
          <mat-radio-button class="radio-button-width" value=true>Presente</mat-radio-button>
          <mat-radio-button class="radio-button-width" value=false>Ausente</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Rigor mortis</mat-label>
        <input matInput formControlName="rigorMortis" class="form-control" />
      </mat-form-field>

    </div>
  </form>
  <div class="button-container flex-row-end">
    <button mat-button class="btn-cancel-stepper" (click)="cancel()">CANCELAR</button>
    <button type="submit" mat-button class="btn-search" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>
</mat-dialog-content>
