import { EnvironmentDefinition } from './types';
import {MENU_OPTIONS} from "../app/shared/entities/menu-options/menu-option-dev";
import {ITEMS_MENU_DEV} from "../app/shared/entities/menu-options/header-option-dev";

export const environmentDev: EnvironmentDefinition = {
  production: false,
  // api: "http://localhost:5588/api/v1",
  authService: 'https://admin.dev2.cav.smartsoftlabs.com/auth',
  keycloakRealm: 'cav_fauna',
  keycloakClient: 'operations_front_client',
  api: 'https://api.dev2.cav.smartsoftlabs.com/api/v1',
  section: MENU_OPTIONS,
  header: ITEMS_MENU_DEV,
  allowFillForms: true
};
