<div class="main-container">
  <div class="container-img"></div>
  <div class="logos-top">
    <img class="car-logo" src="../../assets/png/login/logo_car.png" />
    <img class="gov-logo" src="../../assets/jpg/login/govco.jpg" />
  </div>
  <div class="form-container" *ngIf="loginFormActive === 1">
    <div class="flex-column-center">
      <div class="cav-img">
        <img class="cav-logo" src="../../assets/png/login/logo_cav.png" />
      </div>
      <h3 class="text-center small-sub-title-green mb-1 text-resize">
        <strong>CENTRO DE ATENCIÓN Y VALORACIÓN DE FAUNA SILVESTRE</strong>
      </h3>
      <form class="input-form width-100" [formGroup]="loginForm" autocomplete="off">
        <div class="icon-container">
          <span class="material-icons-two-tone mr-1"> person_outline </span>
          <mat-form-field class="input-control-auth" appearance="outline">
            <mat-label>Usuario</mat-label>
            <input type="email" matInput formControlName="email" placeholder="Usuario" />
          </mat-form-field>
        </div>
        <div class="icon-container">
          <span class="material-icons-two-tone mr-1"> lock </span>
          <mat-form-field class="input-control-auth" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input [type]="this.showPass ? 'text' : 'password'" matInput formControlName="password"
              placeholder="Contraseña" />
            <span class="material-icons-two-tone show-password-icon" (click)="changePassMode()">
              {{ this.showPass ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="options-login text-m-14-blue flex-row-space-between">
      <mat-label>Recordarme
        <mat-checkbox></mat-checkbox>
      </mat-label>
      <a class="forgot-password" (click)="changeLoginFormActive()">Olvidé Contraseña</a>
    </div>
    <p class="text-conditions text-m-14-blue">
      Al ingresar aceptas nuestros
      <strong>términos y condiciones</strong> y nuestra
      <strong>política de protección de datos personales. </strong>
    </p>
    <div class="flex-row-end">
      <button type="submit" mat-button class="btn-login" (click)="login()">
        INGRESAR
      </button>
    </div>
  </div>

  <div class="form-container" *ngIf="loginFormActive === 2">
    <div class="flex-column-center">
      <div class="cav-img">
        <img class="cav-logo" src="../../assets/png/login/logo_cav.png" />
      </div>
      <h3 class="text-center small-sub-title-green mb-1 text-resize">
        <strong>CENTRO DE ATENCIÓN Y VALORACIÓN DE FAUNA SILVESTRE</strong>
      </h3>
      <form class="input-form width-100" autocomplete="off" [formGroup]="recoveryPassForm">
        <h3 class="text-center small-sub-title-green mb-1 text-resize">
          Recuperar Contraseña
        </h3>
        <p class="text-m-14-blue mb-1">
          Por favor ingresar el correo electrónico con el cual esta registrado en
          la plataforma.
        </p>
        <div class="icon-container">
          <mat-form-field class="input-control-auth" appearance="outline">
            <input type="email" matInput formControlName="email" placeholder="Usuario" />
          </mat-form-field>
        </div>
        <div class="btn-container flex-row-end">
          <button data-cy="recovery-password" type="submit" mat-button class="btn-login" (click)="sendRecoveryLinkToEmail()">
            RECUPERAR
          </button>
          <button type="submit" mat-button class="btn-cancel" (click)="cancelButton()">
            CANCELAR
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="form-container" *ngIf="loginFormActive === 3">
    <div class="flex-column-center">
      <div class="cav-img">
        <img class="cav-logo" src="../../assets/png/login/logo_cav.png" />
      </div>
      <h3 class="text-center small-sub-title-green mb-1 text-resize">
        <strong>CENTRO DE ATENCIÓN Y VALORACIÓN DE FAUNA SILVESTRE</strong>
      </h3>

      <form class="input-form width-100" [formGroup]="pinForm" (change)="validatePin()">
        <h3 class="text-center small-sub-title-green mb-1 text-resize">
          Recuperar Contraseña
        </h3>
        <p class="text-center small-sub-title-green text-resize">Ingresar PIN</p>
        <div class="pins-container flex-row-center mb-1">
          <div class="pin-input-field flex-row-space-evenly">
            <input class="pin-input-control" #input1 formControlName="field1" maxlength="1"
              (keyup)="moveFocus($event)" />
            <input class="pin-input-control" #input2 formControlName="field2" maxlength="1"
              (keyup)="moveFocus($event)" />
            <input class="pin-input-control" #input3 formControlName="field3" maxlength="1"
              (keyup)="moveFocus($event)" />
            <input class="pin-input-control" #input4 formControlName="field4" maxlength="1"
              (keyup)="moveFocus($event)" />
          </div>
        </div>
        <div class="text-m-14-blue mb-1">
          <p class="text-m-14-blue mb-1">
            Por favor ingresar el código que fue enviado al correo electronico.
            Solicitar nuevo código en:
            <strong>{{ "" + this.minutes + ":" + this.seconds }}</strong>
          </p>
        </div>

        <div class="btn-container flex-row-end">
          <button type="submit" mat-button class="{{ disableButton ? 'btn-login-unactive' : 'btn-login' }}"
            (click)="resendPin()" [disabled]="disableButton">
            REENVIAR
          </button>
          <button type="submit" mat-button class="btn-cancel" (click)="goBackToMailInput()">
            VOLVER
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="form-container" *ngIf="loginFormActive === 4">
    <div class="flex-column-center">
      <div class="cav-img">
        <img class="cav-logo" src="../../assets/png/login/logo_cav.png" />
      </div>
      <h3 class="text-right small-sub-title-green mb-1 text-resize">
        <strong>CENTRO DE ATENCIÓN Y VALORACIÓN DE FAUNA SILVESTRE</strong>
      </h3>
      <form class="input-form flex-column-center width-100" [formGroup]="recoveryForm" autocomplete="off">
        <div class="icon-container">
          <span class="material-icons-two-tone mr-1"> lock </span>
          <mat-form-field class="input-control-auth" appearance="outline">
            <mat-label>Nueva contraseña</mat-label>
            <input [type]="this.showNewPass ? 'text' : 'password'" matInput formControlName="newPassword"
              placeholder="Contraseña" />
            <span class="material-icons-two-tone show-password-icon" (click)="changeNewPassMode()">
              {{ this.showPass ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
        <div class="icon-container">
          <span class="material-icons-two-tone mr-1"> lock </span>
          <mat-form-field class="input-control-auth" appearance="outline">
            <mat-label>Confirmar contraseña</mat-label>
            <input [type]="this.showConfirmPass ? 'text' : 'password'" matInput formControlName="confirmPassword"
              placeholder="Contraseña" />
            <span class="material-icons-two-tone show-password-icon" (click)="changeConfirmPassMode()">
              {{ this.showPass ? "visibility" : "visibility_off" }}
            </span>
          </mat-form-field>
        </div>
      </form>
      <p class="text-conditions text-m-14-blue">
        La contraseña debe tener mínimo 8 caracteres
        <strong>una mayúscula, un número y un carácter especial.</strong>
      </p>
      <div class="btn-container flex-row-end">
        <button type="submit" mat-button class="btn-login" (click)="recoveryPassword()">
          RECUPERAR
        </button>
        <button type="submit" mat-button class="btn-cancel" (click)="cancelButton()">
          CANCELAR
        </button>
      </div>
    </div>
  </div>
  <div class="logos-login flex-row-space-between">
    <img class="helios-logo" src="../../assets/png/login/SH.png" />
    <img class="skg-logo" src="../../assets/png/login/logoSKG.png" />
  </div>
  <div class="footer">
    <span class="footer-text text-m-14-white">Corporación Autónoma Regional de Cundinamarca - CAR.</span>
  </div>
</div>
