import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AllValidationErrors, getFormValidationErrors, searchFormError,} from '../../types/get-errors-form';
import {MedicineService} from '../../services/medicine.service';
import {
  Medicine,
  MedicineAdministrated,
  MedicineAdministratedCreatedInput,
  RouteOfAdministration,
} from '../../types/medicine.interface';
import {DialogService} from '../../services/dialog.service';
import {SelectorOption} from "../../types/selector.interface";
import {MedicinesService} from "../../../settings/medicines/services/medicines.service";
import {MedicinesInterfaceBD} from "../../../settings/medicines/types/medicines.interface";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-add-medicines',
  templateUrl: './add-edit-medicines.component.html',
  styleUrls: ['./add-edit-medicines.component.scss'],
})
export class AddEditMedicinesComponent implements OnInit {
  public fetchedMedicine: MedicinesInterfaceBD[] = [];
  public addMedicinesControl: FormControl;
  public saveMedicinesForm: FormGroup;
  public title: string = '';

  public knownRouteOfAdministration: SelectorOption<RouteOfAdministration>[] = [
    {value: RouteOfAdministration.INTRAMUSCULAR, label: 'Intramuscular'},
    {value: RouteOfAdministration.INTRAVENOUS, label: 'Intravenosa'},
    {value: RouteOfAdministration.NASAL, label: 'Nasal'},
    {value: RouteOfAdministration.OCULAR, label: 'Ocular'},
    {value: RouteOfAdministration.ORAL, label: 'Oral'},
    {value: RouteOfAdministration.OTIC, label: 'Ótica'},
    {value: RouteOfAdministration.RECTAL, label: 'Rectal'},
    {value: RouteOfAdministration.SUBCUTANEOUS, label: 'Subcutánea'},
    {value: RouteOfAdministration.SUBLINGUAL, label: 'Sublingual'},
    {value: RouteOfAdministration.TOPIC, label: 'Tópico'},
    {value: RouteOfAdministration.TRANSDERMAL, label: 'Transdérmico'},
  ];

  constructor(
    public dialogRef: MatDialogRef<AddEditMedicinesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; data: MedicineAdministratedCreatedInput, medicines: MedicinesInterfaceBD[] },
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {
    this.title = data.title;
    this.fetchedMedicine = data.medicines;
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);
    this.buildSaveMedicinesForm();
    this.addMedicinesControl = new FormControl('', Validators.required);
    //
    if (this.data.data) {
      this.data.data.administrationRoute = this.data.data.administrationRoute === 'Inyección' ? 'injection' : this.data.data.administrationRoute;
      this.saveMedicinesForm.controls.drug.setValue(
        this.fetchedMedicine.find((value) => value.denomination === this.data.data.drug)
      );
      this.saveMedicinesForm.controls.initialDose.setValue(
        this.data.data.initialDose
      );
      this.saveMedicinesForm.controls.finalDose.setValue(
        this.data.data.finalDose
      );
      this.saveMedicinesForm.controls.administrationRoute.setValue(
        this.knownRouteOfAdministration.find(
          (value) => value.value === this.data.data.administrationRoute
        )
      );
    }
    this.loadingService.setLoading(false);
  }

  close(data?: MedicineAdministrated) {
    this.dialogRef.close(data);
  }

  buildSaveMedicinesForm() {
    this.saveMedicinesForm = this.fb.group({
      drug: new FormControl('', Validators.required),
      initialDose: new FormControl('', [Validators.required, Validators.pattern(/^\d+([.,]?\d{0,2})?$/)]),
      finalDose: new FormControl('', [Validators.required, Validators.pattern(/^\d+([.,]?\d{0,2})?$/)]),
      administrationRoute: new FormControl('', Validators.required),
    });
  }

  resetForm() {
    this.saveMedicinesForm.reset();
  }

  onSubmit() {
    this.setDataUserForm();
  }

  cancel() {
    this.close();
  }

  private getDataForm(): MedicineAdministrated {
    return {
      medicine: this.saveMedicinesForm.get('drug')?.value,
      initialDose: this.saveMedicinesForm.get('initialDose')?.value,
      finalDose: this.saveMedicinesForm.get('finalDose')?.value,
      administrationRoute: this.saveMedicinesForm.get('administrationRoute')
        ?.value.value,
    };
  }

  public setDataUserForm() {
    if (this.saveMedicinesForm.valid) {
      const drug: MedicineAdministrated = this.getDataForm();
      this.close(drug);
    } else {
      const errorMedicinesForm: AllValidationErrors = getFormValidationErrors(
        this.saveMedicinesForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorMedicinesForm));
    }
  }
}
