<mat-accordion class="custom-panel-with-actions">
  <mat-expansion-panel [expanded]="panelOpenState" (opened)="statusOpenPanel()" (closed)="statusOpenPanel()">
    <mat-expansion-panel-header>
      <ng-container>
        <mat-panel-title class="panel-title" [ngClass]="{'open-panel':panelOpenState}">
          <div class="flex-row-space-between mb-0-5" *ngIf="statusPanel">
            <div class="info-panel">
              <div class="info-title">
                <strong>{{information.row1?.key}}</strong>
              </div>
              <div class="info-description">
                {{fixFormat(information.row1?.value)}}
              </div>
            </div>
            <div class="action" [ngClass]="{'centerContent': centerContent}">

            </div>
          </div>
        </mat-panel-title>
      </ng-container>
    </mat-expansion-panel-header>
    <div class="flex-row-space-between mb-0-5">
      <div class="info-panel">
        <div class="info-title">
          <strong>{{information.row1?.key}}</strong>
        </div>
        <div class="info-description" *ngIf="information.row1?.value">
          {{fixFormat(information.row1?.value)}}

        </div>
      </div>
      <div class="action" [ngClass]="{'centerContent': centerContent}">
        {{information.row1?.extraParam}}
      </div>
    </div>
    <div class="flex-row-space-between mb-0-5">
      <div class="info-panel">
        <div class="info-title">
          <strong>{{information.row2?.key}}</strong>
        </div>
        <div class="info-description">
          {{information.row2?.value}}
        </div>
      </div>
      <div *ngIf="information.row2?.extraParam" class="action" [ngClass]="{'centerContent': centerContent}">
        <button mat-mini-fab (click)="onAction(information, action.action)" *ngFor="let action of actions"
          class="action-btn" [matTooltip]="action.tooltip" matTooltipClass="action-tooltip">
          <i class="action-icon material-icons-two-tone" *ngIf="action.icon!='pdf-table' && action.icon!='xls-table'">
            {{action.icon}} </i>
          <mat-icon [svgIcon]="'pdf-table'" *ngIf="action.icon=='pdf-table'"></mat-icon>
          <mat-icon [svgIcon]="'xls-table'" *ngIf="action.icon=='xls-table'"></mat-icon>
        </button>
      </div>
    </div>
    <div class="flex-row-space-between mb-0-5" *ngIf="information.row3">
      <div class="info-panel">
        <div class="info-title">
          <strong>{{information.row3?.key}}</strong>
        </div>
        <div class="info-description  images-container-expansible flex-row-start">
          <div class="container-files mr-0-5" *ngFor="let file of information.row3?.value">
            <div class="reference-image reference-container-image mb-1" data-cy="reference-image-bubble">
              <span class="ml-1 text-m-12-blue reference-span-image"
                (click)="showFileInput(file.urlImage,file.nameFile)">{{file.nameFile}}</span>
              <button class="reference-delete" *ngIf="!isView"
                (click)="removeImageInput(file.label? file.label:undefined)">
                <span class="text-m-12-blue">X</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="action" [ngClass]="{'centerContent': centerContent}">
        {{information.row3?.extraParam}}
      </div>
    </div>
    <div class="flex-row-space-between mb-0-5" *ngIf="information.row4">
      <div class="info-panel">
        <div class="info-title">
          <strong>{{information.row4?.key}}</strong>
        </div>
        <div class="container-files mr-0-5" *ngFor="let document of information.row4?.value">
          <div class="reference-image reference-container-image mb-1">
            <span class="ml-1 text-m-12-blue reference-span-image"
              (click)="showDocumentInput(document)">{{document.nameFile}}</span>
            <button *ngIf="!isView" class="reference-delete"
              (click)="removeDocumentInput(document.label? document.label: undefined)">
              <span class="text-m-12-blue">X</span>
            </button>
          </div>
        </div>
      </div>
      <div class="action" [ngClass]="{'centerContent': centerContent}">
        {{information.row4?.extraParam}}
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
