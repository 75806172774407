import {
  ActorAttribute,
  ActorAttributeCreateInput,
  ActorAttributeSearchInput,
} from './actor-attribute.model.entity';
import {ActorProfile} from './actor-profile.entity';
import {
  ContactPoint,
  ContactPointCreateInput,
  ContactPointSearchInput,
} from './contact-point/contact-point.model.entity';
import {ENTAILMENT} from '../../types/entailment';
import {RolType} from '../../types/professional-data.interface';
import {RoleInterface} from "../../../settings/roles/types/roles.interfaces";

export enum ActorStatus {
  ACTIVE = 0,
  INACTIVE = 1,
}

export const ActorStatusHash: { [status: number]: ActorStatus } = {
  0: ActorStatus.ACTIVE,
  1: ActorStatus.INACTIVE,
};

export enum ActorDocumentType {
  CC = 'CC',
  TI = 'TI',
  CE = 'CE',
  PASSPORT = 'PASSPORT',
}

export const ActorDocumentTypesHash: { [type: string]: ActorDocumentType } = {
  CC: ActorDocumentType.CC,
  TI: ActorDocumentType.TI,
  CE: ActorDocumentType.CE,
  PASSPORT: ActorDocumentType.PASSPORT,
};

export interface ActorAssistantCreateInput {
  id?: number;
  firstName?: string;
  secondName?: string;
  lastName?: string;
  secondLastName?: string;
  documentType?: string;
  document?: string;
  contactPoints?: Array<ContactPointCreateInput>;
  position?: string;
  entity?: string;
  association?: string;
  role?: string;
  birthDate?: any;
  status?: number;
  signature?: string;
  professionalCard?: string;
}

export interface Actor {
  id: number;
  actorId: number;
  documentType: ActorDocumentType;
  document: string;
  charge?: string;
  dependence?: string;
  userNameFull?: string;
  userWhoSent?: boolean;
  lastNameFull?: string;
  email?: string;
  firstName: ActorAttribute;
  lastName: ActorAttribute;
  secondName: ActorAttribute;
  secondLastName: ActorAttribute;
  type: RolType;
  roles?: RoleInterface[];
  position: ActorAttribute;
  association?: ActorAttribute;
  professionalCard?: ActorAttribute;
  actorAttributes?: Array<ActorAttribute>;
  contactPoints: Array<ContactPoint>;
  profile?: ActorProfile;
  entity: Entity;
  status: ActorStatus;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | null;
  signature: ActorAttribute;
}

export interface Entity {
  id: number;
  name: string;
  value: string;
}

export type EntityActor = Actor & { entity: any; association: ActorAttribute, actorId: number; };

export interface ActorCreateInput {
  documentType?: ActorDocumentType;
  document?: string;
  charge?: string;
  dependence?: string;
  userNameFull?: string;
  lastNameFull?: string;
  firstName: string;
  lastName: string;
  secondName?: string;
  secondLastName?: string;
  roleIds?: number[];
  position?: string;
  association?: '0' | '1';
  contactPoints: Array<ContactPoint>;
  entity?: string;
  professionalCard?: string;
  status?: string;
  birthDate?: string;
  dateStartContract?: any;
  dateEndContract?: any;
}

export type ActorUpdateInput = Partial<Omit<Actor,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
  | 'actorAttributes'
  | 'profile'
  | 'type'
  | 'contactPoints'>> & { id: number };
export type ActorSearchInput = Partial<Omit<Actor,
  | 'createdAt'
  | 'updatedAt'
  | 'actorAttributes'
  | 'deletedAt'
  | 'profile'
  | 'contactPoints'> & {
  actorAttribute: ActorAttributeSearchInput;
  contactPoint: ContactPointSearchInput;
}>;

export enum Role {
  SYSTEM_ADMIN = 'system_admin',
  SYSTEM_OPERATOR = 'system_operator',
  ASSISTANT = 'assistant',
  ENTITY_OPERATOR = 'entity_operator',
}

export const RoleHash: { [type: string]: Role } = {
  assistant: Role.ASSISTANT,
  entity_operator: Role.ENTITY_OPERATOR,
  system_admin: Role.SYSTEM_ADMIN,
  system_operator: Role.SYSTEM_OPERATOR,
};

export const RoleHashSpanish: { [type: string]: string } = {
  assistant: 'Asistente',
  entity_operator: 'Operador Entidad',
  system_admin: 'Administrador',
  system_operator: 'Operador',
};

export const RoleOptions = [
  {value: Role.SYSTEM_ADMIN, label: RoleHashSpanish[Role.SYSTEM_ADMIN]},
  {value: Role.ASSISTANT, label: RoleHashSpanish[Role.ASSISTANT]},
  {value: Role.ENTITY_OPERATOR, label: RoleHashSpanish[Role.ENTITY_OPERATOR]},
  {value: Role.SYSTEM_OPERATOR, label: RoleHashSpanish[Role.SYSTEM_OPERATOR]},
];
