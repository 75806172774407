import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ServerError, ServerResponse } from '../../types/http.interfaces';
import { AuthService } from '../auth/auth.service';
import {
  Actor,
  ActorDocumentType,
} from '../../entities/actor/actor.model.entity';
import { ProfessionalDB } from '../../types/professional-data.interface';
import { ContactPointType } from '../../entities/actor/contact-point/contact-point.model.entity';
import { DialogService } from '../dialog.service';

export enum AccountStatus {
  IDLE,
  RECOVERING,
  READY,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _authenticatedActor: any;

  private _authenticatedActorPhotoUrl: string = '';

  private _ready: AccountStatus = AccountStatus.IDLE;

  public get ready() {
    return this._ready;
  }

  public get authenticatedActor() {
    return this._authenticatedActor;
  }

  constructor(
    private http: HttpClient,
    public router: Router,
    private dialogService: DialogService,
    private authService: AuthService
  ) {
    this.init().then();
  }

  public async init() {
    try {
      this._ready = AccountStatus.RECOVERING;
      await this.recoverUserData();
      this._ready = AccountStatus.READY;
    } catch (e) {
      console.error('e', e);
      if (e.error?.error) {
        const error = <ServerError>e;
        if (error.error.httpCode === 403) {
          // invalid token, force login
          this.dialogService.errorModal('Sus credenciales no son válidas');
          this.clearProfileData();
          this.router.navigateByUrl('/login');
        }
      }
      this._ready = AccountStatus.ERROR;
    }
  }

  /**
   * restore user data if authenticated
   */
  public async recoverUserData() {
    //
    let success = false;
    if (this.authService.checkToken('ACCESS')) {
      success = true;
    } else if (this.authService.checkToken('REFRESH')) {
      await this.authService.refreshToken();
      success = true;
    }
    if (success) {
      //TODO NEEDS TO BE CHANGED TO SELF RESOURCE
      // get account info, must be Practitioner Type
      let apiUrl =
        environment.api +
        '/actors-ms/actors/operators/' +
        this.authService.getActorId();
      const userInfo = await this.http.get<{ data: any }>(apiUrl).toPromise();
      this._authenticatedActor = userInfo.data;
    } else {
      this._authenticatedActor = null;
    }
  }
  public async getActorById(id? : string): Promise<Actor> {
    let apiUrl = environment.api + '/actors-ms/actors/operators/';
    if(id){
      apiUrl += id;
    }else{
      apiUrl += this.authService.getActorId();
    }
    let res = await this.http.get<ServerResponse<Actor>>(apiUrl).toPromise();
    return res.data;
  }
  public async getAllOperators(): Promise<ProfessionalDB[]> {
    const res = await this.http
      .get<ServerResponse<ProfessionalDB[]>>(
        `${environment.api}/actors-ms/actors/operators`
      )
      .toPromise();
    return res.data;
  }
  public async getAllAssistants(): Promise<ProfessionalDB[]> {
    const res = await this.http
      .get<ServerResponse<ProfessionalDB[]>>(
        `${environment.api}/actors-ms/actors/assistants`
      )
      .toPromise();
    return res.data;
  }

  /**
   * returns string with url of profile photo
   */
  public getMyPhoto() {
    return (
      this._authenticatedActorPhotoUrl ||
      '../../../assets/svg/catalogue-lawyers/photo4.svg'
    );
  }

  /**
   * returns string array with all user emails (normally 1)
   */
  public getMyEmails() {
    let emails = [];
    for (const contactPoint of this.authenticatedActor.contactPoints) {
      if (contactPoint.type === ContactPointType.EMAIL) {
        emails.push(contactPoint.value);
      }
    }
    return emails;
  }

  /**
   * returns string array with all user phones (normally 1)
   */
  public getMyPhones() {
    let phones = [];
    for (const contactPoint of this.authenticatedActor.contactPoints) {
      if (contactPoint.type === ContactPointType.PHONE) {
        phones.push(contactPoint.value);
      }
    }
    return phones;
  }

  /**
   * logout sign out and delete keycloak session
   */
  public async logOut() {
    await this.authService.logOut();
    this.clearProfileData();
    this.router.navigateByUrl('/login');
  }

  /**
   * clears all saved data
   */
  public clearProfileData() {
    this._authenticatedActor = null;
    this._authenticatedActorPhotoUrl = '';
  }

  public getNames(names: {
    firstName?: boolean;
    secondName?: boolean;
    lastName?: boolean;
    secondLastName?: boolean;
  }) {
    let output = [];
    if (!this._authenticatedActor) {
      return '';
    }
    if (names.firstName) {
      output.push(
        ProfileService.validateName(this._authenticatedActor.firstName.value)
      );
    }
    if (names.secondName) {
      output.push(
        ProfileService.validateName(this._authenticatedActor.secondName.value)
      );
    }
    if (names.lastName) {
      output.push(
        ProfileService.validateName(this._authenticatedActor.lastName.value)
      );
    }
    if (names.secondLastName) {
      output.push(
        ProfileService.validateName(
          this._authenticatedActor.secondLastName.value
        )
      );
    }
    return output.join(' ');
  }

  private static validateName(name: string) {
    if (!name || name === 'null' || name === 'undefined') {
      return '';
    }
    return name;
  }

  /**
   * returns current user id, null if error
   */
  public getId() {
    return this._authenticatedActor.id || null;
  }

  public translateGroup(groupName: string) {
    if (groupName === 'client') {
      return 'Cliente';
    } else if (groupName === 'lawyer') {
      return 'Abogado';
    }
    return groupName;
  }

  public getDocument() {
    return this._authenticatedActor.document || '';
  }

  public getDocumentType() {
    const id = this._authenticatedActor.documentType;
    let type = '';
    if (id === ActorDocumentType.CC) {
      type = 'Cédula de Ciudadanía';
    } else if (id === ActorDocumentType.TI) {
      type = 'Tarjeta de Identidad';
    } else if (id === ActorDocumentType.CE) {
      type = 'Cédula de Extranjería';
    }
    return { type: type, id: id };
  }

  /**
   * Validate the role according the actor given
   * @param actor
   * @keyclockGroup string[] validate if actor contains all values of the array
   * @noKeyclock string[] if actor contains one value of this return false
   */
  validateRole(actor: Actor, keyclockGroup: string[] = [], noKeyclock: string[] = []): boolean{
    if(!actor.roles){
      return false;
    }

    for (let i = 0; i < keyclockGroup.length; i++) {
      let roleFound = false;
      for (let j = 0; j < actor.roles.length; j++) {
        if (actor.roles[j].keycloakGroupName === keyclockGroup[i]) {
          roleFound = true;
          break;
        }
      }
      if (!roleFound) {
        return false;
      }
    }

    for (let i = 0; i < noKeyclock.length; i++) {
      for (let j = 0; j < actor.roles.length; j++) {
        if (actor.roles[j].keycloakGroupName === noKeyclock[i]) {
          return false;
        }
      }
    }
    return true;
  }
}
