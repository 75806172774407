

import { Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input[integerNumber]'
})
export class IntegerDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initialValue.replace(/\D/g, '');
      if ( initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
