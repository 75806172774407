import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SafeguardService} from 'src/app/safeguard/services/safeguard.service';
import {Safeguard} from 'src/app/safeguard/types/safeguard.interface';
import {SeizureService} from 'src/app/seizure-record/services/seizure.service';
import {SeizureRecord} from 'src/app/seizure-record/types/seizure-record.interface';
import {GeneraService} from '../../services/genera.service';
import {SpeciesService} from '../../services/species.service';
import {ClinicHistoryDB} from 'src/app/medical-history/types/medical-history.interface';
import {AdministrationInterface} from '../../types/administration.interface';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {Individual} from '../../types/individual.interface';
import {MedicalHistoryService} from 'src/app/medical-history/services/medical-history.service';
import {DialogService} from '../../services/dialog.service';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {LoadingService} from '../../services/loading.service';
import {IndividualRegisterTable} from '../../../release/types/release.interface';
import {Moment} from "moment";
import {HttpParams} from "@angular/common/http";
import {AutocompleteCheckDataService} from "../../services/autocomplete-check-data.service";
import {ValidatorsService} from "../../services/validators.service";

@Component({
  selector: 'app-individual-dialog',
  templateUrl: './individual-dialog.component.html',
  styleUrls: ['./individual-dialog.component.scss'],
})
export class IndividualDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('autoGenusInput', {read: MatAutocompleteTrigger})
  autoGenus: MatAutocompleteTrigger;
  @ViewChild('autoSpecieInput', {read: MatAutocompleteTrigger})
  autoSpecie: MatAutocompleteTrigger;
  @ViewChild('autoCommonNameInput', {read: MatAutocompleteTrigger})
  autoCommonName: MatAutocompleteTrigger;
  @ViewChild('autoMedicalInput', {read: MatAutocompleteTrigger})
  autoMedical: MatAutocompleteTrigger;
  @ViewChild('autoClinicHistoryInput', {read: MatAutocompleteTrigger})
  autoClinicHistory: MatAutocompleteTrigger;
  @ViewChild('autoSafeguardInput', {read: MatAutocompleteTrigger})
  autoSafeguard: MatAutocompleteTrigger;
  @ViewChild('autoSeizureInput', {read: MatAutocompleteTrigger})
  autoSeizure: MatAutocompleteTrigger;
  private subscriptions = new Subscription();
  public individualForm: FormGroup;
  public medicalHistoriesList: ClinicHistoryDB[];
  public clinicHistoryList: ClinicHistoryDB[];
  public title: string = '';
  public generaList: AdministrationInterface[];
  public speciesList: AdministrationInterface[];
  public commonNameList: string[];
  public safePassageList: Safeguard[];
  public seizureRecordList: SeizureRecord[];
  public filteredOptionsGenera: Observable<AdministrationInterface[]>;
  public filteredOptionsSpecie: Observable<AdministrationInterface[]>;
  public filteredOptionsCommonName: Observable<SeizureRecord[]>;
  public filteredOptionsMedicalHistories: Observable<ClinicHistoryDB[]>;
  public filteredOptionsMedicalHistory: Observable<ClinicHistoryDB[]>;
  public filteredOptionsSafeguard: Observable<Safeguard[]>;
  public filteredOptionsSeizure: Observable<SeizureRecord[]>;
  public preliminaryClinicHistory: number[] = [];
  public clinicHistory: number[] = [];
  public safePassageRecord: string[] = [];
  public seizureRecordNumber: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<IndividualDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { dataEdit: IndividualRegisterTable; title: string, date: Moment, preliminaryCHIds: number[] },
    private fb: FormBuilder,
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private dialogService: DialogService,
    private safePassageService: SafeguardService,
    private seizureRecordService: SeizureService,
    private medicalHistoryService: MedicalHistoryService,
    private loadingService: LoadingService,
    private autocompleteCheckDataService: AutocompleteCheckDataService,
    public validatorsService: ValidatorsService,
  ) {
    this.title = data.title;
  }

  async ngOnInit() {
    this.buildIndividualForm();
    await this.getSelectorOptions();
    this.getFilteredOptions('genus');
    this.getFilteredOptions('specie');
    this.getFilteredOptions('preliminaryClinicHistory');
    this.getFilteredOptions('seizureRecordNumber');
    this.getFilteredOptions('clinicHistory');
    this.getFilteredOptions('commonName');
    if (this.data.dataEdit) {
      this.editRegisterTable(this.data.dataEdit);
    }
  }

  public editRegisterTable(row: IndividualRegisterTable) {
    this.loadingService.setLoading(true);
    this.individualForm.patchValue({
      commonName: row.commonName,
      genus: row.genus,
      specie: row.specie,
      scientificName: `${row.genus} ${row.specie}`,
      quantity: row.quantity,
      latitude: row.latitude,
      longitude: row.longitude,
      type: row?.type || '',
      preliminaryClinicHistory: row.preliminaryClinicHistory[0],
      safePassageRecord: row.safePassageRecordTable,
      seizureRecordNumber: row.seizureRecord[0],
    });
    this.clinicHistory = row?.clinicHistories || [];
    this.preliminaryClinicHistory = row?.preliminaryClinicHistory || [];
    this.loadingService.setLoading(false);
  }

  ngAfterViewInit() {
    this.addSubscription(this.autoGenus, 'genus', this.individualForm);
    this.addSubscription(this.autoSpecie, 'specie', this.individualForm);
    this.addSubscription(this.autoCommonName, 'commonName', this.individualForm);
    this.addSubscription(
      this.autoMedical,
      'preliminaryClinicHistory',
      this.individualForm
    );
    this.addSubscription(
      this.autoSeizure,
      'seizureRecordNumber',
      this.individualForm
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private addSubscription(
    trigger: MatAutocompleteTrigger,
    control: string,
    form: FormGroup
  ) {
    const sub1 = trigger?.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        form.get(control)?.setValue(null);
        trigger.closePanel();
      }
    });
    this.subscriptions.add(sub1);
  }

  private getFilteredOptions(control: string) {
    let filteredOption: Observable<any[]>;
    filteredOption = this.individualForm.controls[control].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', control))
    );
    switch (control) {
      case 'genus':
        this.filteredOptionsGenera = filteredOption;
        break;
      case 'specie':
        this.filteredOptionsSpecie = filteredOption;
        break;
      case 'preliminaryClinicHistory':
        this.filteredOptionsMedicalHistories = filteredOption;
        break;
      case 'clinicHistory':
        this.filteredOptionsMedicalHistory = filteredOption;
        break;
      case 'seizureRecordNumber':
        this.filteredOptionsSeizure = filteredOption;
        break;
      case 'commonName':
        this.filteredOptionsCommonName = filteredOption;
        break;
    }
  }

  private _filter(
    value: string,
    control: string
  ): (AdministrationInterface | ClinicHistoryDB | SeizureRecord | Safeguard | string)[] {
    let filterValue = '';
    if (value != null) {
      filterValue = value.toString().toLowerCase();
    } else {
      filterValue = '';
    }
    let optionFilter: (
      | AdministrationInterface
      | ClinicHistoryDB
      | SeizureRecord
      | Safeguard
      | string
      )[] = [];
    switch (control) {
      case 'genus':
        optionFilter =
          filterValue === undefined
            ? this.generaList
            : this.generaList.filter((option) =>
              option.name.toLowerCase().includes(filterValue)
            );
        break;
      case 'specie':
        optionFilter =
          filterValue === undefined
            ? this.speciesList
            : this.speciesList.filter((option) =>
              option.name.toLowerCase().includes(filterValue)
            );
        break;
      case 'preliminaryClinicHistory':
        optionFilter =
          filterValue === undefined
            ? this.medicalHistoriesList
            : this.medicalHistoriesList.filter((option) =>
              option.id.toString().toLowerCase().includes(filterValue)
            );
        break;
      case 'clinicHistory':
        optionFilter =
          filterValue === undefined
            ? this.clinicHistoryList
            : this.clinicHistoryList.filter((option) =>
              option.id.toString().toLowerCase().includes(filterValue)
            );
        break;
      case 'seizureRecordNumber':
        optionFilter =
          filterValue === undefined
            ? this.seizureRecordList
            : this.seizureRecordList.filter((option) =>
              option.recordNumber
                .toString()
                .toLowerCase()
                .includes(filterValue)
            );
        break;
      case 'commonName':
        optionFilter =
          filterValue === undefined
            ? this.commonNameList
            : this.commonNameList.filter((option) =>
              option
                .toString()
                .toLowerCase()
                .includes(filterValue)
            );
        break;
    }
    return this.orderOptions(optionFilter, control)
  }

  orderOptions(optionsList: (AdministrationInterface | ClinicHistoryDB | SeizureRecord | Safeguard | string)[], control: string) {
    if (control == 'commonName') {
      return optionsList.slice().sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()))   // to return
    } else if (control == 'genus' || control == 'specie') {
      const optionListAdministration = optionsList.slice() as AdministrationInterface[]
      return optionListAdministration.sort((a, b) => {
        const itemA = a.name ? a.name.toString().toLowerCase() : '';
        const itemB = b.name ? b.name.toString().toLowerCase() : '';
        return itemA.localeCompare(itemB)
      })
    } else {
      return optionsList
    }

  }

  close(data?: Individual) {
    this.dialogRef.close(data);
  }

  cancel() {
    this.close();
  }

  /**
   * Set quanty and regionalList options
   * @private
   */
  private async getSelectorOptions() {
    this.loadingService.setLoading(true);
    try {
      this.clinicHistoryList = [];
      this.medicalHistoriesList = [];
      this.generaList = (await this.generaService.getGenera()).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.speciesList = (await this.specieService.getSpecies()).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.commonNameList = []
      this.seizureRecordList = (await this.seizureRecordService.getSeizureRecords())
        .sort((a, b) => {
          return a.recordNumber.toString().toLowerCase().localeCompare(b.recordNumber.toString().toLowerCase(), undefined, {
            sensitivity: 'base',
          })
        });;
      this.safePassageList = await this.safePassageService.getSafeguards();

      const medicalHistories = await this.medicalHistoryService.getMedicalHistory();
      medicalHistories.forEach(history => {
        const entryDate = this.data.date.toDate();
        const releaseDate = this.data.date.toDate();
        releaseDate.setHours(0, 0, 0, 0);
        if (releaseDate.getTime() >= entryDate.getTime() && (this.data.dataEdit || !this.data.preliminaryCHIds.includes(history.id))) {
          this.medicalHistoriesList.push(history);
          if (!this.commonNameList.includes(history.commonName))
            this.commonNameList.push(history.commonName)
        }
      });
      this.commonNameList.sort((name1, name2) => name1.toLowerCase().localeCompare(name2.toLowerCase()));
      const medicalHistoriesParams = new HttpParams().set('fetchPreliminaryClinicHistory', true)
        .set('fetchClinicRecords', true)
        .set('fetchSpecimen', true)
        .set('fetchSpecimenMarkings', true)
        .set('fetchClinicRecordsData', true)
        .set('fetchPreliminaryClinicHistory', true)
        .set('fetchPreliminaryClinicHistorySafePassageRecord', true)
        .set('fetchPreliminaryClinicHistorySeizureRecord', true);
      const clinicalHistories = await this.medicalHistoryService.getMedicalHistories(medicalHistoriesParams);

      clinicalHistories.forEach(history => {
        if ('preliminaryClinicHistory' in history) {
          const releaseDate = this.data.date.toDate();
          releaseDate.setHours(0, 0, 0, 0);
          const entryDate = new Date();
          entryDate.setHours(0, 0, 0, 0);
          entryDate.setFullYear(parseInt(
              (history as any)['preliminaryClinicHistory'].date.split('-')[0]),
            parseInt((history as any)['preliminaryClinicHistory'].date.split('-')[1]),
            parseInt((history as any)['preliminaryClinicHistory'].date.split('-')[1])
          );
          if (releaseDate.getTime() >= entryDate.getTime()) {
            this.clinicHistoryList.push(history);
          }
        }
      });

      this.medicalHistoriesList.sort((a, b) => {
        return a.id - b.id
      });
      
      this.clinicHistoryList.sort((a, b) => {
        return a.id - b.id
      });
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  buildIndividualForm() {
    this.individualForm = this.fb.group({
      commonName: new FormControl('', [Validators.required, this.validatorsService.alphanumericValidator]),
      genus: new FormControl('', Validators.required),
      specie: new FormControl('', Validators.required),
      scientificName: new FormControl({value: '', disabled: true}),
      quantity: new FormControl({value: 0, disabled: true}, Validators.required),
      latitude: new FormControl('', [Validators.required, Validators.pattern(this.validatorsService.coordinatePattern)]),
      longitude: new FormControl('', [Validators.required, Validators.pattern(this.validatorsService.coordinatePattern)]),
      type: new FormControl(''),
      preliminaryClinicHistory: new FormControl('',),
      clinicHistory: new FormControl('',),
      safePassageRecord: new FormControl('', Validators.required),
      seizureRecordNumber: new FormControl({value: '', disabled: true}),
    });
  }

  displayScientificName() {
    if (this.individualForm.get('genus')?.value !== '' &&
      this.individualForm.get('genus')?.value !== null &&
      this.individualForm.get('specie')?.value !== null &&
      this.individualForm.get('specie')?.value !== '') {
      this.individualForm.get('scientificName')?.setValue(
        this.individualForm.get('genus')?.value + ' ' + this.individualForm.get('specie')?.value
      );
    } else {
      this.individualForm.get('scientificName')?.setValue('');
    }
  }

  selectPreliminaryClinicHistory() {
    const res = this.preliminaryClinicHistory.find(ele => ele === Number(this.individualForm.get('preliminaryClinicHistory')?.value));
    if (!res) {
      //add preliminary clinic history to array
      this.preliminaryClinicHistory.push(
        this.individualForm.get('preliminaryClinicHistory')?.value
      );
      const history = this.medicalHistoriesList.find(history =>
        history.id === Number(this.individualForm.get('preliminaryClinicHistory')?.value));


      history?.seizureRecord?.recordNumber
        ? this.seizureRecordNumber.push(history?.seizureRecord?.recordNumber || '')
        : ''

      //update inputs form
      this.individualForm.get('preliminaryClinicHistory')?.setValue('');
      this.individualForm.get('seizureRecordNumber')?.setValue(this.seizureRecordNumber.join(', '));

      if (history && history.specimen)
        this.individualForm.get('quantity')?.setValue(Number(this.individualForm.get('quantity')?.value) + 1);
    } else {
      this.dialogService.infoModal('Ya se agregó la historia clínica preliminar.');
    }
  }

  selectClinicHistory() {
    const res = this.clinicHistory.find(ele => ele === Number(this.individualForm.get('clinicHistory')?.value));
    if (!res) {
      //add clinic history to array
      this.clinicHistory.push(
        this.individualForm.get('clinicHistory')?.value
      );
      const history = this.clinicHistoryList.find(history =>
        history.id === Number(this.individualForm.get('clinicHistory')?.value));

      history?.preliminaryClinicHistory?.seizureRecord?.recordNumber
        ? this.seizureRecordNumber.push(history?.preliminaryClinicHistory?.seizureRecord?.recordNumber || '')
        : ''

      //update inputs form
      this.individualForm.get('clinicHistory')?.setValue('');
      this.individualForm.get('seizureRecordNumber')?.setValue(this.seizureRecordNumber.join(', '));

      if (history && history.specimen)
        this.individualForm.get('quantity')?.setValue(Number(this.individualForm.get('quantity')?.value) + 1);
    } else {
      this.dialogService.infoModal('Ya se agrego la historia clínica.');
    }
  }

  deletePreliminaryClinicHistory(index: number) {
    const res = this.medicalHistoriesList.find(ele => ele.id === Number(this.preliminaryClinicHistory[index]));
    this.preliminaryClinicHistory.splice(index, 1);
    if (this.preliminaryClinicHistory.length === 0) {
      //this.individualForm.get('quantity')?.setValue('');
      this.safePassageRecord = [];
      this.seizureRecordNumber = [];
      this.individualForm.get('seizureRecordNumber')?.setValue('');
    }
    if (res && res.specimen) {
      this.individualForm.get('quantity')?.setValue(Number(this.individualForm.get('quantity')?.value) - 1);
    }
  }

  deleteClinicHistory(index: number) {
    const res = this.clinicHistoryList.find(ele => ele.id === Number(this.clinicHistory[index]));
    this.clinicHistory.splice(index, 1);
    if (this.clinicHistory.length === 0) {
      this.safePassageRecord = [];
      this.seizureRecordNumber = [];
      this.individualForm.get('seizureRecordNumber')?.setValue('');
    }
    if (res && res.specimen) {
      this.individualForm.get('quantity')?.setValue(Number(this.individualForm.get('quantity')?.value) - 1);
    }
  }

  clearHistories() {
    this.preliminaryClinicHistory = [];
    this.clinicHistory = [];
    this.safePassageRecord = [];
    this.seizureRecordNumber = [];
    this.individualForm.get('quantity')?.setValue(0);
    this.individualForm.get('seizureRecordNumber')?.setValue('');
  }
  private setRecords(): string[]{
    if(this.individualForm.get('safePassageRecord')?.value){
      const items = this.individualForm.get('safePassageRecord')?.value.split(',');
      const noBlank: string[] = [];
      items.map( (it: string) => {
        if(it!=='') {
          noBlank.push(it.trim());
        }
      });
      return noBlank;
    }else{
      return [];
    }
  }
  public alphanumericOnlyStrict(event: any) {
    const key: string = event.key;
    const alphanumericRegex: RegExp = /^[a-zA-ZñÑ0-9, ]*$/;
    if(!alphanumericRegex.test(key)) {
      return event.replace(alphanumericRegex,'');
    }
  }

  onSubmit() {
    if (this.individualForm.valid) {
      if (this.preliminaryClinicHistory.length === 0 && this.clinicHistory.length === 0) {
        this.dialogService.errorModal('Debe registrarse por lo menos una Historia Clínica Preliminar o una Historia Clínica.');
        return;
      }
      const genus = this.generaList.find(
        (g) => g.name == this.individualForm.get('genus')?.value
      );
      const specie = this.speciesList.find(
        (s) => s.name == this.individualForm.get('specie')?.value
      );
      const specimen: any = {
        commonName: this.individualForm.get('commonName')?.value,
        genus: genus,
        specie: specie,
        genusId: genus?.id,
        specieId: specie?.id,
        scientificName: this.individualForm.get('scientificName')?.value,
      };

      const individualForm: any = {
        specimen: specimen,
        quantity: this.individualForm.get('quantity')?.value,
        seizureRecord: this.seizureRecordNumber,
        latitude: this.individualForm.get('latitude')?.value,
        longitude: this.individualForm.get('longitude')?.value,
        safePassageRecord: this.setRecords(),
        type: this.individualForm.get('type')?.value,
        preliminaryClinicHistory: this.preliminaryClinicHistory,
        clinicHistories: this.clinicHistory,
      };
      this.close(individualForm);
    } else {
      const errorIndividualForm: AllValidationErrors = getFormValidationErrors(
        this.individualForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorIndividualForm));
    }
  }
}
