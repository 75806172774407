import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimeMayus'
})
export class FormatTimePipeMayus implements PipeTransform {

  transform(time?:string ): any {
    if(!time){
      return '-';
    }
    let hour: string | number = Number((String(time).split(':'))[0]);
    let min: string | number = Number((String(time).split(':'))[1]);
    let part = hour > 12 ? 'PM' : 'AM';
    if(parseInt(String(hour)) == 0)
      hour = 12;
    min = (min+'').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour+'').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

}
