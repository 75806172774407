<router-outlet></router-outlet>
<ngx-loading [show]="loadingService.loading"
             [config]="{animationType: loadingService.ngxLoadingAnimationTypes.circleSwish, primaryColour: this.loadingService.loadingColor, backdropBorderRadious: '3px'}">
</ngx-loading>
<div
  (click)="refreshToken()"
  *ngIf="window.location.href.includes('localhost') || window.location.href.includes('dev2.cav')"
  class="randomRefreshIcon"
>
  <mat-icon>refresh</mat-icon>
</div>
