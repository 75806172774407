import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: number, decimalPlaces: number = 3): string {
    if (value == null || isNaN(value)) {
      return '';
    }
    if (Number.isInteger(value)) {
      return String(value);
    }
    const factor = Math.pow(10, decimalPlaces);
    const truncated = Math.floor(value * factor) / factor;
    return truncated.toFixed(decimalPlaces);
  }
}
