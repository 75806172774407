import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class RegisterSVGIconsService {
  private iconsToRegister: Array<{ name: string; path: string }> = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    // add icons to register
    this.registerIcons();
    // iterate each icon
    for (const icon of this.iconsToRegister) {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }

  private registerIcons() {
    this.iconsToRegister.push({
      name: 'calendar',
      path: 'assets/svg/header/calendar.svg',
    });

    this.iconsToRegister.push({
      name: 'clock',
      path: 'assets/svg/header/clock.svg',
    });
    this.iconsToRegister.push({
      name: 'gear-header',
      path: 'assets/svg/header/gear.svg',
    });
    this.iconsToRegister.push({
      name: 'bar-graphic',
      path: 'assets/svg/header/bar-graphic.svg',
    });
    this.iconsToRegister.push({
      name: 'user-header',
      path: 'assets/svg/header/user-header.svg',
    });
    this.iconsToRegister.push({
      name: 'information',
      path: 'assets/svg/custom/info.svg',
    });
    this.iconsToRegister.push({
      name: 'entry',
      path: 'assets/svg/sidebar/entry.svg',
    });
    this.iconsToRegister.push({
      name: 'medical_history',
      path: 'assets/svg/sidebar/medical_history.svg',
    });
    this.iconsToRegister.push({
      name: 'release_form',
      path: 'assets/svg/sidebar/release_form.svg',
    });
    this.iconsToRegister.push({
      name: 'medical_exam',
      path: 'assets/svg/sidebar/medical_exam.svg',
    });
    this.iconsToRegister.push({
      name: 'necropsy',
      path: 'assets/svg/sidebar/necropsy.svg',
    });
    this.iconsToRegister.push({
      name: 'paw-print-sb',
      path: 'assets/svg/sidebar/paw-print.svg',
    });

    this.iconsToRegister.push({
      name: 'xls-table',
      path: 'assets/svg/table/xls-table.svg',
    });
    this.iconsToRegister.push({
      name: 'pdf-table',
      path: 'assets/svg/table/pdf.svg',
    });
    // ethogram
    this.iconsToRegister.push({
      name: 'frequency-behavior',
      path: 'assets/svg/stepper/pending_actions.svg',
    });
    this.iconsToRegister.push({
      name: 'period-observation',
      path: 'assets/svg/title-section/date_range.svg',
    });
    this.iconsToRegister.push({
      name: 'catalog-definition',
      path: 'assets/svg/title-section/note_alt.svg',
    });
    this.iconsToRegister.push({
      name: 'article',
      path: 'assets/svg/others/article.svg',
    });
    //animals
    this.iconsToRegister.push({
      name: 'bird',
      path: 'assets/svg/custom/bird.svg',
    });
    this.iconsToRegister.push({
      name: 'bird2',
      path: 'assets/svg/custom/bird2.svg',
    });
    this.iconsToRegister.push({
      name: 'hippo',
      path: 'assets/svg/custom/hippo.svg',
    });
    this.iconsToRegister.push({
      name: 'monkey',
      path: 'assets/svg/custom/monkey.svg',
    });
    this.iconsToRegister.push({
      name: 'ocelot',
      path: 'assets/svg/custom/ocelot.svg',
    });
    this.iconsToRegister.push({
      name: 'porcupine',
      path: 'assets/svg/custom/porcupine.svg',
    });
    this.iconsToRegister.push({
      name: 'sloth_bear',
      path: 'assets/svg/custom/sloth_bear.svg',
    });
    this.iconsToRegister.push({
      name: 'turtle',
      path: 'assets/svg/custom/turtle.svg',
    });
    this.iconsToRegister.push({
      name: 'weasel',
      path: 'assets/svg/custom/weasel.svg',
    });
    this.iconsToRegister.push({
      name: 'diagnostic_tests',
      path: 'assets/svg/custom/diagnostic_tests.svg',
    });
    this.iconsToRegister.push({
      name: 'biological',
      path: 'assets/svg/custom/biological_management.svg',
    });
    this.iconsToRegister.push({
      name: 'routine_evaluation',
      path: 'assets/svg/custom/routine_evaluation.svg',
    });
    this.iconsToRegister.push({
      name: 'set_meal',
      path: 'assets/svg/custom/set_meal.svg',
    });
    this.iconsToRegister.push({
      name: 'diet',
      path: 'assets/svg/others/diet.svg',
    });
    this.iconsToRegister.push({
      name: 'handling-conditions',
      path: 'assets/svg/others/handling-conditions.svg',
    });
    // neonatal
    this.iconsToRegister.push({
      name: 'inventory',
      path: 'assets/svg/others/inventory.svg',
    });
    this.iconsToRegister.push({
      name: 'page-view',
      path: 'assets/svg/others/pageview.svg',
    });
    //  hospitalization
    this.iconsToRegister.push({
      name: 'exit_register',
      path: 'assets/svg/sidebar/exit_to_app.svg',
    });
    this.iconsToRegister.push({
      name: 'update_register',
      path: 'assets/svg/sidebar/update.svg',
    });
    this.iconsToRegister.push({
      name: 'history_white',
      path: 'assets/svg/sidebar/history_white.svg',
    });
    this.iconsToRegister.push({
      name: 'output_white',
      path: 'assets/svg/sidebar/output_white.svg',
    });
    //  Quarantine
    this.iconsToRegister.push({
      name: 'exit-quarantine',
      path: 'assets/svg/quarantine/exit-quarantine.svg',
    });
    this.iconsToRegister.push({
      name: 'quarantine',
      path: 'assets/svg/quarantine/quarantine.svg',
    });
    this.iconsToRegister.push({
      name: 'window_black_24dp',
      path: 'assets/svg/quarantine/window_black_24dp.svg',
    });
    // Critical
    this.iconsToRegister.push({
      name: 'critical-care',
      path: 'assets/svg/critical/critical-care.svg',
    });
    // diet - consumption
    this.iconsToRegister.push({
      name: 'waste',
      path: 'assets/svg/custom/waste.svg',
    });
    // Enrichment environment
    this.iconsToRegister.push({
      name: 'grass-white',
      path: 'assets/svg/title-section/grass_white.svg',
    });
    // administration management
    this.iconsToRegister.push({
      name: 'donut_large',
      path: 'assets/svg/others/donut_large.svg',
    });
    this.iconsToRegister.push({
      name: 'temporary-exit',
      path: 'assets/svg/others/temporary-exit.svg',
    });

  }
}
