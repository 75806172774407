<div class="cav-container" [class.example-is-mobile]="mobileQuery.matches">
  <app-header></app-header>
  <mat-toolbar color="primary" class="cav-toolbar">
    <button mat-icon-button (click)="toggleAction()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="title-toolbar" [ngClass]="headerService.isSidebarBig ? 'title-toolbar-expanded': ''">
      <div class="icon-container-center">
        <ng-container *ngIf="headerService.currentIsSVG">
          <mat-icon [svgIcon]="headerService.currentSvgIcon" class="icons-wh-svg mr-0-5">
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="!headerService.currentIsSVG">
          <i class="material-icons-two-tone icons-wh mr-0-5">{{headerService.currentSvgIcon}}</i>
        </ng-container>
        <span class="sub-title-white-toolbar title-toolbar-text">{{ this.headerService.currentText }}</span>
      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="cav-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0" autosize>
    <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
      mode="side" opened="true">
      <mat-nav-list *ngFor="let option of menuOptions">
        <app-accordion [menuOption]="option" [bigMenu]="this.headerService.isSidebarBig"></app-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="margin-left: 0; flex:1" class="scrollbar">
      <div class="sidenav-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
