import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServerResponse } from '../types/http.interfaces';
import { FormGroup } from '@angular/forms';
import { AdministrationInterface } from '../types/administration.interface';
import { SpeciesInterface } from '../../settings/species/types/species.interface';
import { Router } from '@angular/router';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SpeciesService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialogService: DialogService
  ) {}

  public async getSpecies(
    includeInactive: boolean = false
  ): Promise<SpeciesInterface[]> {
    let res;
    if (includeInactive) {
      res = await this.http
        .get<ServerResponse<SpeciesInterface[]>>(`${this._baseUrl}/species`)
        .toPromise();
    } else {
      let params = new HttpParams();
      params = params.set('state', true);
      res = await this.http
        .get<ServerResponse<SpeciesInterface[]>>(`${this._baseUrl}/species`, {
          params,
        })
        .toPromise();
    }
    if (res.data.length > 0) {
      return res.data;
    } else {
      this.dialogService.infoModal(
        'Debe registrar una especie antes de continuar'
      );
      await this.router.navigateByUrl('/home/species/form');
      return [];
    }
  }

  async getSpeciesById(id: number): Promise<SpeciesInterface> {
    const res = await this.http
      .get<ServerResponse<SpeciesInterface>>(`${this._baseUrl}/species/${id}`)
      .toPromise();
    return res.data;
  }

  // send new specie
  public async postNewSpecies(data: FormGroup) {
    return await this.http.post(`${this._baseUrl}/species`, data).toPromise();
  }

  //service to get data agree the filter
  public async getSpeciesFilter(
    params?: HttpParams
  ): Promise<SpeciesInterface[]> {
    const res = await this.http
      .get<ServerResponse<SpeciesInterface[]>>(`${this._baseUrl}/species`, {
        params,
      })
      .toPromise();
    return res.data;
  }

  //Method delete one row
  public async deleteSpeciesDirection(id: number) {
    return this.http.delete(`${this._baseUrl}/species/${id}`).toPromise();
  }

  // download excel all data
  public async downloadSpecies(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http
        .get<any>(`${this._baseUrl}/species/download`, {
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise();
    }
    return result;
  }

  // update register
  public async updateSpeciesRecords(form: SpeciesInterface, id: number) {
    return await this.http
      .patch<SpeciesInterface>(`${this._baseUrl}/species/${id}`, form)
      .toPromise();
  }

  /**
   * Method to update only status
   * @param specie
   * @param stateSearch
   */
  public async updateOnlyStatus(specie: SpeciesInterface, stateSearch: boolean) {
    return await this.http
      .patch<SpeciesInterface>(`${this._baseUrl}/species/${specie.id}`, {
        name: specie.name,
        naturalDistribution: specie.naturalDistribution,
        state: stateSearch,
      })
      .toPromise();
  }
}
