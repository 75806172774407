<div class="flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()"
          data-cy="dialog-close-btn">
    <strong>X</strong>
  </button>
</div>
<div mat-dialog-content data-cy="dialog-container">
  <div class="main-dialog-container flex-column-center">
    <div class="{{ orientationIconTop ? 'flex-column-center' : 'flex-row-space-between' }}">
      <ng-container *ngIf="typeConfirm==='confirm' ||typeConfirm==='yesNo'">
        <div class="icon-container flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog-confirm material-icons-two-tone tone-red">{{icon}}</span>
        </div>
        <span class="flex-column-center text-m-14-light-blue message-dialog mb-0-5 ">{{ message }}</span>
        <span class="flex-column-center sub-title-light-blue message-dialog mb-0-5"
              data-cy="dialog-message"><strong>{{ subMessage }}</strong></span>
      </ng-container>
      <ng-container *ngIf="only === true">
        <div class="icon-container flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog-confirm material-icons-two-tone tone-green">{{icon}}</span>
        </div>
        <span class="flex-column-center sub-title-light-blue message-dialog mb-0-5"
              data-cy="dialog-message"><strong>{{ message }}</strong></span>
        <span class="flex-column-center text-m-14-light-blue message-dialog mb-0-5 ">{{ subMessage }}</span>
      </ng-container>
      <ng-container *ngIf="typeConfirm==='alert'">
        <div class="icon-container flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog material-icons-two-tone tone-red">{{icon}}</span>
        </div>
        <span class="flex-column-center sub-title-red message-dialog mb-0-5 "><strong>{{ message }}</strong></span>
        <div *ngIf="showErrorDate" class="footer-dialog flex-row-center">
          <span class="text-m-12-red">Fecha: {{ date | date: "dd/MM/yyyy" }} Hora: {{ date | date: "h:mm a" }} </span>
        </div>
      </ng-container>
      <ng-container *ngIf="typeConfirm==='info'">
        <div class="icon-container flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog material-icons-two-tone">{{icon}}</span>
        </div>
        <span class="flex-column-center sub-title-blue message-dialog mb-0-5 "><strong>{{ message }}</strong></span>
      </ng-container>
      <ng-container *ngIf="typeConfirm==='success'">
        <div class="icon-container icon-hide-scroll flex-column-center message-dialog mb-0-5">
          <span class="material-icons-dialog material-icons-two-tone tone-green">{{icon}}</span>
        </div>
        <span class="flex-column-center sub-title-blue message-dialog mb-0-5 "><strong>{{ message }}</strong></span>
      </ng-container>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="isActions">
  <ng-container *ngIf="!isButtons; else buttonsTemplate">
    <div class="footer-dialog flex-row-center">
      <span class="text-m-12-blue">{{ date | date: "d/MM/yyyy, h:mm a" }}</span>
    </div>
  </ng-container>
  <ng-template #buttonsTemplate>
    <div class="btn-container button-container">
      <button mat-button id="cancel" class="blue-btn-cancel-stepper" data-cy="cancel-button"
              (click)="cancel()">{{typeConfirm === 'yesNo' ? 'No' : btnText.dismiss}}</button>
      <button mat-button type="submit" class="btn-submit" data-cy="success-button"
              (click)="onSubmit()">{{typeConfirm === 'yesNo' ? 'Si' : btnText.confirm}}</button>
    </div>
  </ng-template>
</div>
