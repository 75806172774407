import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ServerResponse} from '../types/http.interfaces';
import {Specimen, SpecimenMarking} from '../types/specimen.interface';

@Injectable({
  providedIn: 'root',
})
export class SpecimenService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(private http: HttpClient) {
  }

  getSpeciesUrl() {
    return `${environment.api}/processesanddocumentservice-ms/specimens`;
  }

  public async getSpecimens(): Promise<Specimen[]> {
    const res = await this.http
      .get<ServerResponse<Specimen[]>>(`${this._baseUrl}/specimens`)
      .toPromise();
    return res.data;
  }

  public async updateSpecimens(body: any, id: number): Promise<Specimen> {
    const res = await this.http
      .patch<ServerResponse<Specimen>>(`${this._baseUrl}/specimens/${id}`, body)
      .toPromise();
    return res.data;
  }

  public getLastMarking(data: SpecimenMarking[]): String {
    if (!data || data.length === 0) return '';
    const markings = data.sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      return bDate.getTime() - aDate.getTime();
    });
    return markings[0].markingNo;
  }

  public getLastMarkingTracking(data: SpecimenMarking[]): String {
    if (!data || data.length === 0) return '';
    const markings = data.sort((a, b) => {
      const aDate = new Date(a.markingDate);
      const bDate = new Date(b.markingDate);
      if (aDate === bDate) return a.createdAt.getTime() - b.createdAt.getTime();
      return aDate.getTime() - bDate.getTime();
    });
    return markings[markings.length - 1].markingNo;
  }

  public getLastMarkingById(data: SpecimenMarking[]): String {
    if (!data || data.length === 0) return '-';
    if (data?.length > 0) {
      let max: number = 0;
      let marking;
      for (const mark of data) {
        if (mark.id > max) {
          max = mark.id;
          marking = mark;
        }
      }
      if (marking)
        return marking.markingNo
    }
    return '-';
  }
}
