import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-general-classes',
  templateUrl: './general-classes.component.html',
  styleUrls: ['./general-classes.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class GeneralClassesComponent implements OnInit {
  public releaseActSaveForm: FormGroup;
  public specieDataForm: FormGroup;
  public captivityHistoryForm: FormGroup;
  public form!: FormGroup;
  public isLoading = false;
  public showPass = false;
  public showNewPass = false;
  public showConfirmPass = false;
  public loginFormActive = true;
  public loginForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  public isSplash = false;

  constructor(
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public initForm() {
    this.form = this.formBuilder.group({
      first: ['', [Validators.required, Validators.minLength(3)]],
      second: ['', [Validators.required, Validators.minLength(3)]],
      selector: ['', [Validators.required]],
      textArea: ['', [Validators.required]],
    });
  }

  /**
   * check if the given field is valid
   * @param field to be validated
   */
  public invalidField(field: string) {
    const validator = this.form?.get(field);
    return validator?.touched && validator?.invalid;
  }

  public warningToaster() {
    this.dialogService.infoModal(
      'warning message relatively long within toast'
    );
  }

  public infoToaster() {
    this.dialogService.infoModal('info message relatively long within toast');
  }

  public successToaster() {
    this.dialogService.successModal(
      'success message relatively long within toast'
    );
  }

  public errorToaster() {
    this.dialogService.errorModal('error message relatevely long within toast');
  }

  public globalLoading() {
    this.loadingService.setLoading(true);
    setTimeout(() => {
      this.loadingService.setLoading(false);
    }, 2000);
  }

  public loadingDirective() {
    this.isLoading = !this.isLoading;
    setTimeout(() => {
      this.isLoading = !this.isLoading;
    }, 3000);
  }

  /**
   * activate splash directive
   */
  public showSplash() {
    this.isSplash = !this.isSplash;
    setTimeout(() => {
      this.isSplash = !this.isSplash;
    }, 10000);
  }

  //change visibility of pass field
  changePassMode() {
    this.showPass = !this.showPass;
  }

  //change visibility of pass field
  changeNewPassMode() {
    this.showNewPass = !this.showNewPass;
  }

  //change visibility of pass field
  changeConfirmPassMode() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  changeLoginFormActive() {
    this.loginFormActive = !this.loginFormActive;
  }

  public async nextStepOne() {}
}
