import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ClinicHistory, ClinicHistoryDB, DestinyIndividualHashSpanish,} from '../types/medical-history.interface';
import {ServerResponse} from '../../shared/types/http.interfaces';
import {SafeguardSeizureStatusHashSpanish} from '../../safeguard/types/safeguard.interface';
import {DatePipe} from '@angular/common';
import {RawCnifResult, RawsMarkingResult, SearchableData,} from '../../shared/types/searchableData.interface';

@Injectable({
  providedIn: 'root',
})
export class MedicalHistoryService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(private http: HttpClient, private datePipe: DatePipe) {
  }

  public async getMedicalHistory(
    params?: HttpParams
  ): Promise<Array<ClinicHistory>> {
    const res = await this.http
      .get<ServerResponse<ClinicHistoryDB[]>>(
        `${this._baseUrl}/preliminaryClinicHistories`,
        {params}
      )
      .toPromise();
    return res.data.map((data) => {
      const regional = data.regional?.name;
      const state = SafeguardSeizureStatusHashSpanish[data.state.toString()];
      const seizureRecord = data.seizureRecord?.recordNumber;
      const safePassageRecord = data.safePassageRecord?.recordNumber;
      const commonName = data.specimen?.commonName || '';
      const destination =
        data.captivityHistory.destination === 'other' ||
        (data.captivityHistory.witchDestinyIndividual !== undefined &&
          data.captivityHistory.witchDestinyIndividual.trim() !== '')
          ? data.captivityHistory.witchDestinyIndividual
          : DestinyIndividualHashSpanish[data.captivityHistory.destination];
      return {
        ...data,
        regionalName: regional,
        dateT: this.datePipe.transform(data.date, 'dd/MM/yyyy'),
        seizureRecordId: seizureRecord,
        safePassageRecordId: safePassageRecord,
        commonName: commonName,
        destination: destination,
        stateT: state,
      };
    });
  }


  /**
   * It returns an array of ClinicHistoryDB objects no preliminary histories
   * @param {HttpParams} [params] - HttpParams
   * @returns An array of ClinicHistoryDB objects
   */
  public async getMedicalHistories(
    params?: HttpParams
  ): Promise<Array<ClinicHistoryDB>> {
    const res = await this.http
      .get<ServerResponse<ClinicHistoryDB[]>>(
        `${this._baseUrl}/clinicHistories`,
        {params}
      )
      .toPromise();
    return res.data;
  }

  /**
   * Method post: The variable body contain the information of formularies.
   * @param body
   */
  public async saveMedicalHistory(body: any) {
    return await this.http
      .post(`${this._baseUrl}/preliminaryClinicHistories`, body)
      .toPromise();
  }

  public async downloadMedicalHistory(params: HttpParams) {
    return await this.http.get(`${this._baseUrl}/preliminaryClinicHistories/download`, {
      params,
      responseType: 'blob' as 'json',
    }).toPromise();
  }

  public async getMedicalHistoryById(id: number, params?: HttpParams): Promise<ClinicHistory> {
    const res = await this.http
      .get<ServerResponse<ClinicHistoryDB>>(
        `${this._baseUrl}/preliminaryClinicHistories/${id}`,{params}
      )
      .toPromise();
    const data = res.data;

    const regional = data.regional?.name;
    const state = SafeguardSeizureStatusHashSpanish[data.state.toString()];
    const seizureRecord = data.seizureRecord?.recordNumber;
    const safePassageRecord = data.safePassageRecord?.recordNumber;
    const commonName = data.specimen.commonName;
    const destination =
      data.captivityHistory.destination === 'other' ||
      (data.captivityHistory.witchDestinyIndividual !== undefined &&
        data.captivityHistory.witchDestinyIndividual.trim() !== '')
        ? data.captivityHistory.witchDestinyIndividual
        : DestinyIndividualHashSpanish[data.captivityHistory.destination];
    return {
      ...data,
      regionalName: regional,
      dateT: this.datePipe.transform(data.date, 'dd/MM/yyyy'),
      seizureRecordId: seizureRecord,
      safePassageRecordId: safePassageRecord,
      commonName: commonName,
      destination: destination,
      stateT: state,
    }
  }

  public async getSearchableData(caseView?: string): Promise<{
    data: SearchableData[];
    rawCnifResult: RawCnifResult[];
    numberChipListRaw: RawsMarkingResult[];
  }> {
    let params = new HttpParams()
      .set(`${caseView}`, `True`);
    const result = (
      await this.http
        .get<ServerResponse<SearchableData[]>>(
          `${this._baseUrl}/specimens/searchableData`, {params}
        )
        .toPromise()
    ).data;
    const cnifListRaw: Array<RawCnifResult> = [];
    const numberChipListRaw: Array<RawsMarkingResult> = [];
    const foundCnifs: { [key: string]: number } = {};
    const foundMarkingNumbers: { [key: string]: number } = {};
    for (const item of result) {
      for (const cnif of item.CNIF) {
        foundCnifs[cnif] = item.preliminaryHistoryClinicId;
      }
      for (const markingNo of item.markingNo) {
        foundMarkingNumbers[markingNo] = item.preliminaryHistoryClinicId;
      }
    }
    //
    for (const foundCnif of Object.keys(foundCnifs)) {
      cnifListRaw.push({
        cnifCode: foundCnif,
        preliminaryHistoryClinicId: foundCnifs[foundCnif],
      });
    }
    for (const foundMarkingNumber of Object.keys(foundMarkingNumbers)) {
      numberChipListRaw.push({
        markingChipCode: foundMarkingNumber,
        preliminaryHistoryClinicId: foundMarkingNumbers[foundMarkingNumber],
      });
    }
    return {
      data: result,
      rawCnifResult: cnifListRaw,
      numberChipListRaw: numberChipListRaw,
    };
  }


  public async getSearchableDatabyView(caseView: string): Promise<{
    data: SearchableData[];
    rawCnifResult: RawCnifResult[];
    numberChipListRaw: RawsMarkingResult[];
  }> {
    let params = new HttpParams()
      .set(`${caseView}`, `true`);
    const result = (
      await this.http
        .get<ServerResponse<SearchableData[]>>(
          `${this._baseUrl}/specimens/searchableData`, {params}
        )
        .toPromise()
    ).data;
    const cnifListRaw: Array<RawCnifResult> = [];
    const numberChipListRaw: Array<RawsMarkingResult> = [];
    const foundCnifs: { [key: string]: number } = {};
    const foundMarkingNumbers: { [key: string]: number } = {};
    for (const item of result) {
      for (const cnif of item.CNIF) {
        foundCnifs[cnif] = item.preliminaryHistoryClinicId;
      }
      for (const markingNo of item.markingNo) {
        foundMarkingNumbers[markingNo] = item.preliminaryHistoryClinicId;
      }
    }
    //
    for (const foundCnif of Object.keys(foundCnifs)) {
      cnifListRaw.push({
        cnifCode: foundCnif,
        preliminaryHistoryClinicId: foundCnifs[foundCnif],
      });
    }
    for (const foundMarkingNumber of Object.keys(foundMarkingNumbers)) {
      numberChipListRaw.push({
        markingChipCode: foundMarkingNumber,
        preliminaryHistoryClinicId: foundMarkingNumbers[foundMarkingNumber],
      });
    }
    //

    return {
      data: result,
      rawCnifResult: cnifListRaw,
      numberChipListRaw: numberChipListRaw,
    };
  }

  /**
   * Method preliminaryClinicHistory
   * @param body
   * @param id
   */
  async updateMedicalHistory(body: any, id: number) {
    return await this.http
      .patch<ClinicHistoryDB>(
        `${this._baseUrl}/preliminaryClinicHistories/${id}`,
        body
      )
      .toPromise();
  }

  /**
   * action to delete one register through the id
   * @param id
   */
  public async deleteMedicalHistory(id: number) {
    return this.http
      .delete(`${this._baseUrl}/preliminaryClinicHistories/${id}`)
      .toPromise();
  }
}
