import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-information-dropdown',
  templateUrl: './information-dropdown.component.html',
  styleUrls: ['./information-dropdown.component.scss'],
})
export class InformationDropdownComponent implements OnInit {
  @Input() informationListColumnOne: any[] = [];
  @Input() informationListColumnTwo: any[] = [];

  constructor() {}

  ngOnInit(): void {}
}
