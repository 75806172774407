<mat-accordion *ngIf="information.length>0" class="custom-expansion-panel">
  <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title class="panel-title" [ngClass]="{'open-panel':panelOpenState}">
        <p class="panel-info">{{information[0].key}}: <strong>{{information[0].value}}</strong></p>
        <p class="panel-info">{{information[1].key}}: <strong>{{information[1].value}}</strong></p>
      </mat-panel-title>

    </mat-expansion-panel-header>

        <div *ngFor="let info of information| slice:2:information.length" class="panel-info" >
          <p>{{info.key}}: <strong>{{info.value}}</strong></p>
        </div>

  </mat-expansion-panel>
</mat-accordion>
