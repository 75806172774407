import { Specimen } from 'src/app/shared/types/specimen.interface';
import { AdministrationInterface } from '../../shared/types/administration.interface';

export interface Safeguard {
  id: number;
  recordNumber: string;
  date: string;
  createdAt: Date | string;
  regional: AdministrationInterface;
  files?: {id: number, bucket: string, objectName: string}[];
  filesUrl: string[];
  filesURL?: string[];
  quantity: number;
  status: SafeguardStatus;
  specimens: Specimen[];
}

export enum SafeguardStatus {
  ASSOCIATED,
  IN_ASSOCIATION,
  REGISTERED,
}
export const SafeguardSeizureStatusHashSpanish: { [type: string]: string } = {
  '0': 'Registrado',
  '1': 'En asociación',
  '2': 'Asociado',
};
