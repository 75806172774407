import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ProfessionalModel} from "../../models/professional.model";

@Component({
  selector: 'app-triage-category-info',
  templateUrl: './triage-category-info.component.html',
  styleUrls: ['./triage-category-info.component.scss']
})
export class TriageCategoryInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TriageCategoryInfoComponent>
  ) { }

  ngOnInit(): void {
  }

  close(data?: ProfessionalModel) {
    this.dialogRef.close(data);
  }
}
