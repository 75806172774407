<div class="main-dialog-container mt-2">
  <div class="icon-container-center mb-0-5">
    <span class="material-icons-two-tone blue mr-1">edit</span>
    <div class="sub-title-blue-left">{{data.title}}</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <form [formGroup]="generalForm" class="input-form flex-row-space-between mt-2 mb-1">
    <div class="container-setting-content">
    <span class=" text-m-14-black text-dialog">Id. Registro:</span>
      <strong>{{this.dataSource?.id}}</strong>
    </div>
    <div class="container-content">
      <span class=" text-m-14-black text-dialog">Nombre {{nameInput}}: </span>
      <input matInput class="input-dialog validate-admin-input" data-cy="name-input" type="text" formControlName="name" autocomplete="off">
    </div>
    <div class="container-setting-content">
      <span class="text-m-14-black text-dialog">Estado:</span>
    <mat-slide-toggle data-cy="state-toggle" formControlName="state"></mat-slide-toggle>
    </div>
  </form>
  <div class="button-container flex-row-end">
    <button mat-button class="btn-cancel-stepper" data-cy="cancel-button" (click)="cancel()">CANCELAR</button>
    <button type="submit" mat-button class="btn-search" data-cy="submit-button" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>
</mat-dialog-content>
