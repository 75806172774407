import {Actor} from "../actor.model.entity";

export enum ContactPointType {
  PHONE = "phone",
  EMAIL = "email",
  FAX = "fax",
  PAGER = "pager",
  URL = "url",
  SMS = "sms",
  PUSH_NOTIFICATION = "push_notification",
  OTHER = "other"
}

export enum ContactPointUse {
  HOME = 0,
  WORK = 1,
  TEMP = 2,
  OLD = 3,
  MOBILE = 4
}

export const ContactPointUseHash: { [use: number]: ContactPointUse } = {
  0: ContactPointUse.HOME,
  1: ContactPointUse.WORK,
  2: ContactPointUse.TEMP,
  3: ContactPointUse.OLD,
  4: ContactPointUse.MOBILE
};

export interface ContactPoint {
  id?: number;
  rank: number;
  use: ContactPointUse;
  value: string;
  type: ContactPointType;
  actor?: Actor;
}

export type ContactPointCreateInput =
  Required<Omit<ContactPoint, 'actor' | 'id' | 'rank'>>
  & { actor?: Actor; actorId?: number; rank?: number; };

export type ContactPointUpdateInput = Partial<ContactPoint> & { actorId?: number };

export type ContactPointSearchInput = {
  id?: number;
  type?: string;
  value?: string;
};


export const ContactPointTypeHash: { [type: string]: ContactPointType } = {
  "phone": ContactPointType.PHONE,
  "email": ContactPointType.EMAIL,
  "fax": ContactPointType.FAX,
  "pager": ContactPointType.PAGER,
  "url": ContactPointType.URL,
  "sms": ContactPointType.SMS,
  "push_notification": ContactPointType.PUSH_NOTIFICATION,
  "other": ContactPointType.OTHER
}




