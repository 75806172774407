import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Clock } from '../../entities/clock/clock.model';
import { ClockService } from '../../services/clock.service';
import { ProfileService } from '../../services/profile/profile.service';
import {environment} from "../../../../environments/environment";
import {HeaderAndSidebarService} from "../../services/header-and-sidebar.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuTrigger') trigger: any;

  public data$: Observable<Clock>;
  public hour: number;
  public minutes: string;
  public date: string;
  public amPm: string;
  public day: Date;

  constructor(
    private segundo: ClockService,
    public profileService: ProfileService,
    public headerAndSidebarService: HeaderAndSidebarService
  ) {
  }

  ngOnInit(): void {
    this.data$ = this.segundo.getActuallyDate();
    this.data$.subscribe((time) => {
      this.hour = time.hour;
      this.minutes = time.minutes;
      this.day = time.date;
      this.amPm = time.amPm;
    });
  }

  public closeMenu() {
    this.trigger.closeMenu();
  }

  itemsMenu: NavItem[] = environment.header;
}

export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}
