// Libraries Angular
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// Services
import {GeneraService} from '../../services/genera.service';
import {SpeciesService} from '../../services/species.service';
import {ProfileService} from '../../services/profile/profile.service';
// Interfaces
import {ENTAILMENT} from '../../types/entailment';
import {DOCUMENT_TYPE} from '../../types/document-type';
import {DialogService} from '../../services/dialog.service';
import {SelectorOption} from '../../types/selector.interface';
import {ProfessionalDB} from '../../types/professional-data.interface';
import {AdministrationInterface} from '../../types/administration.interface';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {
  ContactPoint,
  ContactPointType,
} from '../../entities/actor/contact-point/contact-point.model.entity';
import {ActorAssistantCreateInput} from '../../entities/actor/actor.model.entity';
import {RegionalDirectionService} from "../../services/regional-direction.service";
import {ActorAttribute} from "../../entities/actor/actor-attribute.model.entity";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {LoadingService} from "../../services/loading.service";
import {ValidatorsService} from "../../services/validators.service";
import {PermissionValidatorService} from "../../services/auth/permission.validator.service";

@Component({
  selector: 'app-user-data-dialog',
  templateUrl: './user-data-dialog.component.html',
  styleUrls: ['./user-data-dialog.component.scss'],
})
export class UserDataDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autoProfessionalInput', {read: MatAutocompleteTrigger}) autoProfessional: MatAutocompleteTrigger;
  public dataUserControl: FormGroup;
  public dataUserSaveForm: FormGroup;
  public typeDocumentList: SelectorOption<string>[] = [];
  public generaList: AdministrationInterface[];
  public entityList: AdministrationInterface[];
  public speciesList: AdministrationInterface[];
  public associationList: SelectorOption<number>[] = [];
  public professionalList: ProfessionalDB[] = [];
  public title: string = '';
  public showOptionOther: boolean = true;
  public defaultProfessional: ProfessionalDB[] = [];
  public filteredOptionProfessional: Observable<any[]>;
  private subscriptions = new Subscription();
  public isOptionSelected: boolean = false;
  public isEndData: boolean = false;
  public viewActorPermission : boolean;

  constructor(
    public validatorsService: ValidatorsService,
    public dialogRef: MatDialogRef<UserDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      showOptionOther: boolean;
      data: Omit<ActorAssistantCreateInput, 'contactPoints'> & {
        phoneT: string;
        contactPoints: string;
        id?: number;
      };
    },
    private fb: FormBuilder,
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private regionalDirectionService: RegionalDirectionService,
    public permissionValidatorService: PermissionValidatorService
  ) {
    this.title = data.title;
    this.showOptionOther = data.showOptionOther ?? true;
  }

  async ngOnInit() {
    this.buildDataUserControlForm();
    this.buildDataUserSaveForm();
    this.getSelectorOptions();
    await this.getData();
    await this.buildData();
    this.getFilteredOptions('idProfessional');
    if(this.permissionValidatorService.hasPermission(['support_professional_query'])){
      this.viewActorPermission = true
    }
  }

  buildDataUserControlForm() {
    this.dataUserControl = this.fb.group({
      idProfessional: new FormControl(''),
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.addSubscription(this.autoProfessional, 'idProfessional', this.dataUserControl);
  }

  async buildData() {
    if (this.data.data) {
      this.autoProfessional.closePanel();
      const association = this.data.data.association as unknown as ActorAttribute;
      if (this.data.data.id) {
        this.dataUserControl.get('idProfessional')?.setValue(this.data.data.id || 'Otro');
        this.dataUserSaveForm.controls.id.setValue(this.data.data.id);
      } else {
        this.dataUserControl.get('idProfessional')?.setValue('Otro');
      }
      this.dataUserSaveForm.get('position')?.setValue(this.data.data.position);
      this.dataUserSaveForm.get('entity')?.setValue(this.data.data.entity);
      this.dataUserSaveForm
        .get('documentType')
        ?.setValue(this.data.data.documentType);
      if (this.data.data.phoneT) {
        this.dataUserSaveForm.get('phone')?.setValue(this.data.data.phoneT);
      }
      this.dataUserSaveForm.get('document')?.setValue(this.data.data.document);
      this.dataUserSaveForm
        .get('association')
        ?.setValue(Number(this.data.data.association || association.value));
      this.dataUserSaveForm.get('professionalCard')?.setValue(this.data.data.professionalCard);
      this.dataUserSaveForm
        .get('userName')
        ?.setValue(`${this.data.data.firstName} ${this.data.data.secondName} ${this.data.data.lastName}`
        );
      this.onSelectedDataUserControl(this.dataUserControl.get('idProfessional')?.value, false);
    }
  }

  private addSubscription(trigger: MatAutocompleteTrigger, control: string, form: FormGroup) {
    const sub1 = trigger.panelClosingActions
      .subscribe(e => {
        if (!(e && e.source)) {
          form.get(control)?.setValue(null);
          trigger.closePanel();
        }
      });
    this.subscriptions.add(sub1);
  }

  private getFilteredOptions(control: string) {
    let filteredOption: Observable<any[]>;
    filteredOption = this.dataUserControl.controls[
      control
      ].valueChanges.pipe(
      startWith(''),
      map((value) =>
        this._filter(value, control)
      )
    );
    switch (control) {
      case 'idProfessional':
        this.filteredOptionProfessional = filteredOption;
        break;
    }
  }

  private _filter(value: string, control: string): any[] {
    let optionFilter: any[] = [];
    let filterValue = value != null ? value.toString().toLowerCase() : '';
    switch (control) {
      case 'idProfessional':
        this.dataUserControl.get('idProfessional')?.value === '' ? this.isOptionSelected = false : this.isOptionSelected = true;
        optionFilter = filterValue === undefined ? this.professionalList : this.professionalList.filter((option) =>
          option.firstName.value.concat(' ').concat(option.secondName.value ? (option.secondName.value).concat(' ') : '').concat(option.lastName.value ? (option.lastName.value).concat(' ') : '').concat(option.secondLastName.value ? (option.secondLastName.value) : '').toString().toLowerCase().includes(filterValue)
        );
        break;
    }
    return this.orderOptions(optionFilter)
  }

  orderOptions(optionsList: any[]){
    return optionsList.sort( (a,b)=> {
      const itemA = a.firstName.value? a.firstName.value.toString().toLowerCase(): '';
      const itemB = b.firstName.value? b.firstName.value.toString().toLowerCase(): '';
      return itemA.localeCompare(itemB)
    })
  }


  close(data?: any) {
    this.dialogRef.close(data);
  }

  /**
   * Set quantity and regionalList options
   * @private
   */
  private getSelectorOptions() {
    this.typeDocumentList = DOCUMENT_TYPE;
    this.associationList = ENTAILMENT;
  }

  /**
   * Get selectors options
   */
  public async getData() {
    this.loadingService.setLoading(true);
    try {
      this.generaList = await this.generaService.getGenera();
      this.speciesList = await this.specieService.getSpecies();
      this.professionalList = await this.profileService.getAllOperators();
      let assistant = await this.profileService.getAllAssistants()
      this.professionalList = [...this.professionalList, ...assistant];
      this.entityList = await this.regionalDirectionService.getRegionalDirection();
      this.isEndData = true;
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  /**
   * initialize the fields that the form has
   */
  buildDataUserSaveForm() {
    this.dataUserSaveForm = this.fb.group({
      id: new FormControl(''),
      userName: new FormControl('', Validators.required),
      position: new FormControl('', Validators.required),
      entity: new FormControl('', Validators.required),
      documentType: new FormControl('', Validators.required),
      document: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      professionalCard: new FormControl(''),
      association: new FormControl('', Validators.required),
    });
  }

  disabledDataUserSaveFormControls() {
    this.dataUserSaveForm.disable();
  }

  enableDataUserSaveFormControls() {
    this.dataUserSaveForm.enable();
  }

  resetForm() {
    this.dataUserSaveForm.reset();
    this.getFilteredOptions('idProfessional');
  }

  onSubmit(event: any) {
    this.setDataUserForm();
  }

  cancel() {
    this.close();
  }

  /**
   * Identify the select option agree the selector
   * @param event
   * @param resetForm
   */
  onSelectedDataUserControl(event: string, resetForm = true) {
    if (event === 'Otro') {
      if (resetForm) this.resetForm();
      this.enableDataUserSaveFormControls();
    } else {
      if (resetForm) this.resetForm();
      this.populateFields(Number(event));
      this.disabledDataUserSaveFormControls();
    }
  }

  optionSelectedAutoComplete(event: MatAutocompleteSelectedEvent) {
    this.autoProfessional.closePanel();
    this.resetForm();
    this.isOptionSelected = true;
    if (event.option.value === 'Otro') {
      this.enableDataUserSaveFormControls();
    } else {
      const professional: ProfessionalDB = event.option.value;
      this.dataUserControl.get('idProfessional')?.setValue(
        professional.firstName.value + ' ' + professional.secondName.value + ' ' + professional.lastName.value + ' ' + professional.secondLastName.value
      );
      this.populateFields(Number(professional.id));
      this.disabledDataUserSaveFormControls();
    }
  }

  /**
   * Take the actor's info agree the id.
   * @param id
   */
  public populateFields(id: number) {
    this.autoProfessional.closePanel();
    this.defaultProfessional = this.professionalList.filter((data) => {
      return data.id === id;
    });
    this.dataUserControl.get('idProfessional')?.setValue(
      this.defaultProfessional[0].firstName.value + ' ' + this.defaultProfessional[0].secondName.value + ' '
      + this.defaultProfessional[0].lastName.value + ' ' + this.defaultProfessional[0].secondLastName.value
    );
    let entity = this.entityList.filter(element => element.id == Number(this.defaultProfessional[0].entity.value));
    this.dataUserSaveForm.get('id')?.setValue(this.defaultProfessional[0].id);
    this.dataUserSaveForm
      .get('userName')
      ?.setValue(this.defaultProfessional[0].firstName.value + ' ' + this.defaultProfessional[0].secondName.value + ' '
        + this.defaultProfessional[0].lastName.value + ' ' + this.defaultProfessional[0].secondLastName.value);
    this.dataUserSaveForm.get('position')?.setValue(this.defaultProfessional[0].position.value);
    this.dataUserSaveForm.get('entity')?.setValue(entity[0]?.name || this.defaultProfessional[0].entity.value);
    this.dataUserSaveForm
      .get('documentType')
      ?.setValue(this.defaultProfessional[0].documentType);
    this.dataUserSaveForm
      .get('document')
      ?.setValue(this.defaultProfessional[0].document);
    if (this.defaultProfessional[0].contactPoints[0].type === 'phone') {
      this.dataUserSaveForm
        .get('phone')
        ?.setValue(Number(this.defaultProfessional[0].contactPoints[0].value));
    } else {
      this.dataUserSaveForm
        .get('phone')
        ?.setValue(Number(this.defaultProfessional[0].contactPoints[1].value));
    }
    this.dataUserSaveForm.get('professionalCard')?.setValue(this.defaultProfessional[0].professionalCard.value);
    this.dataUserSaveForm
      .get('association')
      ?.setValue(Number(this.defaultProfessional[0].association.value));
  }

  setDataUserForm() {
    if (this.dataUserSaveForm.valid || this.dataUserSaveForm.disabled) {
      const nameFull = this.splitNameOrLastName(
        this.dataUserSaveForm.get('userName')?.value
      );
      const phone = this.dataUserSaveForm.get('phone')?.value;
      const contactPoint: ContactPoint[] = [
        {
          rank: 1,
          use: 1,
          value:
            phone ? (phone.toString() == 'NaN' ? ''
              : this.dataUserSaveForm.get('phone')?.value.toString()) : '',
          type: ContactPointType.PHONE,
        },
      ];
      let actor: any = {}
      if (phone) {
        actor = {...actor, contactPoints: contactPoint}
      }

      actor = {
        ...actor,
        id: this.dataUserSaveForm.get('id')?.value || '',
        firstName: nameFull[0],
        secondName: nameFull.length > 2 ? nameFull[1] : '',
        lastName: nameFull.length > 2 ? nameFull[2] : nameFull.length > 1 ? nameFull[1] : '',
        secondLastName: nameFull.length > 3 ? nameFull[3] : '',
        position: this.dataUserSaveForm.get('position')?.value,
        entity: this.dataUserSaveForm.get('entity')?.value,
        documentType: this.dataUserSaveForm.get('documentType')?.value,
        document: this.dataUserSaveForm.get('document')?.value.toString(),
        professionalCard: this.dataUserSaveForm.get('professionalCard')?.value !== null && this.dataUserSaveForm.get('professionalCard')?.value !== '' ? this.dataUserSaveForm.get('professionalCard')?.value?.toString() : '',
        association: this.dataUserSaveForm.get('association')?.value?.toString() || '',
      }
      if (this.defaultProfessional.length > 0) {
        actor.type = this.defaultProfessional[0].type
      }
      this.close(actor);
    } else {
      this.dataUserSaveForm.markAllAsTouched();
      const errorDataUser: AllValidationErrors = getFormValidationErrors(
        this.dataUserSaveForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorDataUser));
    }
  }

  splitNameOrLastName(data: string): string[] {
    return data.split(' ');
  }
}
