<mat-accordion class="side-bar-accordion" [ngClass]="!bigMenu ? 'side-bar-accordion-small':''">
  <mat-expansion-panel (opened)="panelOpenState = true" [hideToggle]="!bigMenu" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title [ngClass]="bigMenu ? 'show-content': 'hide-content'">
        <div class="icon-container" [matTooltip]="!bigMenu ? menuOption.menuLabel: ''">
          <i class="material-icons-two-tone icons-wh" *ngIf="!customIcon(menuOption.icon)">{{menuOption.icon}}</i>
          <mat-icon [svgIcon]="menuOption.icon" *ngIf="customIcon(menuOption.icon)"></mat-icon>
        </div>
        <span *ngIf="bigMenu" class="text-m-14-white ml-1">{{
          menuOption.menuLabel
          }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngIf="menuOption.subMenu && menuOption.subMenu.length > 0">
      <mat-nav-list *ngFor="let subMenu of menuOption.subMenu">
        <div (click)="goRoute(subMenu.subMenuLink)">
          <a class="icon-container" [routerLink]="subMenu.subMenuLink"
            [ngClass]="bigMenu && panelOpenState ? 'mat-nav-content-icon': ''" routerLinkActive="active-link-sub">
            <div class="width-permanent-icon" [matTooltip]="!bigMenu ? subMenu.subMenuOption: ''">
              <button mat-button matSuffix mat-icon-button>
                <i class="material-icons-two-tone icons-wh"
                  *ngIf="!customIconSubmenu(subMenu.icon)">{{subMenu.icon}}</i>
                <mat-icon [svgIcon]="subMenu.icon" *ngIf="customIconSubmenu(subMenu.icon)"></mat-icon>
              </button>
            </div>
            <div *ngIf="bigMenu" class="text-m-12-white ml-1">
              {{ subMenu.subMenuOption }}
            </div>
          </a>
        </div>
      </mat-nav-list>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>