import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {
  DialogShowDocumentComponent
} from 'src/app/diet-management/components/dialog-show-document/dialog-show-document.component';
import {
  DialogShowImageComponent
} from 'src/app/necropsy/register-necropsy/dialog-show-image/dialog-show-image.component';
import {UploadImage, FotoDocument} from 'src/app/necropsy/types/necropsy.interface';
import {DialogService} from '../../services/dialog.service';
import {TableAction} from '../../types/table';
import moment from "moment";
import {PdfDownloadService} from "../../services/pdf-download.service";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'app-panel-with-actions',
  templateUrl: './panel-with-actions.component.html',
  styleUrls: ['./panel-with-actions.component.scss'],
})
export class PanelWithActionsComponent implements OnInit {
  public fileList: UploadImage[] = [];
  @Input() panelOpenState: boolean = false;
  @Input() information: {
    row1: { key: string; value: string; extraParam: string };
    row2?: { key: string; value: string; extraParam: boolean };
    row3?: { key: string; value: UploadImage[] | FotoDocument[]; extraParam: string };
    row4?: { key: string; value: UploadImage[] | FotoDocument[]; extraParam: string };
  };
  @Input() isView: boolean = false;
  @Input() public actions: TableAction[] = [];
  @Input() useExpansible = '';
  @Input() centerContent: boolean = false;
  @Output() public action: EventEmitter<any> = new EventEmitter<any>();
  statusPanel = true;

  constructor(
    public dialog: DialogService,
    private sanitizer: DomSanitizer,
    private pdfService: PdfDownloadService,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit(): void {
  }

  public removeImageInput(nameInput?: string) {
    if (this.isView)
      return this.dialog.infoModal('No se puede eliminar el documento');
    if (nameInput) {
      let index = this.information.row3?.value.findIndex(
        (element) => element.label ? element.label === nameInput : false
      );
      this.information.row3?.value.splice(index!, 1);
    }
  }

  public removeDocumentInput(nameInput?: string) {
    if (this.isView)
      return this.dialog.infoModal('No se puede eliminar el documento');
    if (nameInput) {
      let index = this.information.row4?.value.findIndex(
        (element) => element.label ? element.label === nameInput : false
      );
      this.information.row4?.value.splice(index!, 1);
    }
  }

  /**
   * onAction is the event when an icon action is clicked
   * @param row is the data of the specific row
   * @param action
   * @param position
   */
  public onAction(row: any, action: string) {
    if (this.information) {
      this.action.emit({
        row,
        action,
        useExpansible: this.useExpansible,
      });
    } else {
      this.action.emit({row, action});
    }
  }

  /**
   * action to show image
   * @param url
   * @param name
   */
  public showFileInput(url: SafeUrl, name: string) {
    this.dialog.open(DialogShowImageComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: '100%',
      data: {img: url, name: name},
      panelClass: 'dialog-show-image',
      backdropClass: 'background-backdrop',
    });
  }

  /**
   * action to show image
   * @param doc
   */
  public async showDocumentInput(doc: UploadImage | FotoDocument) {
    if ("id" in doc && doc?.id) {
      let link = document.createElement('a');
      link.download = doc.nameFile;
      link.href = doc.urlImage.toString();
      link.click();
      return;
    }
    this.loadingService.setLoading(true);
    let url;
    if (this.isView) {
      url = await this.fetchFile(doc.urlImage).then(uri => doc.urlImage = uri);
      if (url) {
        url = this.getSanitizeUrl(url);
      }
    }
    this.dialog.open(DialogShowDocumentComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: '100%',
      data: {img: doc.urlImage, name: doc.nameFile},
      panelClass: 'dialog-show-image',
      backdropClass: 'background-backdrop',
    });
    this.loadingService.setLoading(false);
  }

  statusOpenPanel() {
    this.statusPanel = !this.statusPanel;
  }

  /**
   * Sanitize the url
   * @param url
   */
  public getSanitizeUrl(url: SafeUrl): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  fixFormat(value: string | undefined) {
    if (!value?.includes('GMT')) {
      const dateSplit = value?.split('T');
      const other = dateSplit![0].split('-');
      const year = other[0];
      const month = other[1];
      const day = other[2];
      return day + '/' + month + '/' + year;
    } else {
      return moment(value).format('DD/MM/YYYY');
    }

  }

  private async fetchFile(fileUri: SafeUrl): Promise<string> {
    try {
      const stringUrl = fileUri.toString();
      const file = await this.pdfService.fetchFileDataURL(stringUrl, 'application/pdf');
      return file.changingThisBreaksApplicationSecurity || '';
    } catch (e) {
      console.error(e);
      return '';
    }
  }
}
