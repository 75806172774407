import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {MedicalHistoryService} from "../../../medical-history/services/medical-history.service";
import {LoadingService} from "../../services/loading.service";
import {TemporaryDelivery} from "../../../temporary-delivery/types/temporary-delivery.interface";
import {ClinicHistoryDB} from "../../../medical-history/types/medical-history.interface";


@Component({
  selector: 'app-view-dialog',
  templateUrl: './view-dialog.component.html',
  styleUrls: ['./view-dialog.component.scss'],
})
export class ViewDialogComponent implements OnInit {
  public displayedColumns: string[] = ['id', 'genus', 'specie', 'commonName'];
  public subTitle: string = '';
  public viewData: any;
  public recordNumber: number;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private medicalHistoryService: MedicalHistoryService,
    private loadingService: LoadingService,
  ) {
    if (data.specimens) {
      this.viewData = data.specimens;
    } else {
      this.viewData = data.relatedPreliminaryClinic.map((history: ClinicHistoryDB) => {
        return {
          ...history,
          referencedPreliminaryClinicHistory: history.preliminaryClinicHistory,
        }
      });
    }
    this.recordNumber = data.specimens ? data.recordNumber : data.id;
  }

  async ngOnInit(): Promise<void> {
    await this.getPreliminaryClinicHistory();
    this.subTitle = this.data.specimens ? this.data.incomeClassification ? 'acta de incautación' : ' salvoconducto' : 'Acta Temporal de Entrega';
  }

  private async getPreliminaryClinicHistory() {
    this.loadingService.setLoading(true);
    try {
      const histories = await this.medicalHistoryService.getMedicalHistory();
      for (let i = 0; i < this.viewData.length; i++) {
        const preliminaryClinicHistory = histories.find(clinicH => clinicH.id == this.viewData[i]?.referencedPreliminaryClinicHistory?.id)
        if (preliminaryClinicHistory) {
          this.viewData[i].referencedPreliminaryClinicHistory = preliminaryClinicHistory;
        }
      }
    } catch (e) {
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  close() {
    this.dialogRef.close();
  }
}
