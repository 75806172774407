import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss']
})
export class StatusDialogComponent implements OnInit {

  public message: string;
  public icon: string;

  constructor(
    public dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.icon = data.icon;
    this.message = data.message;
  }

  ngOnInit(): void {
      setTimeout(() => {
        this.close();
      }, 3500);
  }


  close(data?: boolean) {
    this.dialogRef.close(data);
  }

}
