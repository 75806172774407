<div class="flex-row-space-between information-container">
  <div class="container-options flex-column-start">
    <span class="text-m-14-black" [ngClass]="n > 0 ? 'mt-0-5':''"
      *ngFor="let informationColumnOne of informationListColumnOne; let n = index">{{informationColumnOne.description}}:
      <strong>{{informationColumnOne.value}}</strong></span>
  </div>
  <div class="container-options flex-column-start">
    <span class="text-m-14-black" [ngClass]="n > 0 ? 'mt-0-5':''"
      *ngFor="let informationColumnTwo of informationListColumnTwo; let n = index">{{informationColumnTwo.description}}:
      <strong>{{informationColumnTwo.value}}</strong></span>
  </div>
</div>
