<div class="main-dialog-container">
  <form novalidate [formGroup]="animalSaveForm" class="input-form register-form flex-column-start mt-2">
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Nombre Común</mat-label>
      <input matInput formControlName="commonName" class="form-control" />
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Género</mat-label>
      <mat-select formControlName="genusId" class="form-control">
        <mat-option *ngFor="let genera of generaList" [value]="genera.id">{{
          genera.name
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Especie</mat-label>
      <mat-select formControlName="specieId" class="form-control">
        <mat-option *ngFor="let specie of speciesList" [value]="specie.id">{{ specie.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Cantidad</mat-label>
      <mat-select formControlName="quantity">
        <mat-option *ngFor="let value of quantityList" [value]="value">{{ value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex-row-space-between coordinates">
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Coordenadas eje X</mat-label>
        <input matInput formControlName="coordinatesX" class="form-control" />
      </mat-form-field>
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Coordenadas eje Y</mat-label>
        <input matInput formControlName="coordinatesY" class="form-control" />
      </mat-form-field>
    </div>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>Historia clinica</mat-label>
      <mat-select formControlName="medicalHistory">
        <mat-option value="option1">125469</mat-option>
        <mat-option value="option2">857489</mat-option>
        <mat-option value="option3">698745</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>No. De salvoconducto</mat-label>
      <mat-select formControlName="safeguardRecordNumber">
        <mat-option value="option1">125469</mat-option>
        <mat-option value="option2">857489</mat-option>
        <mat-option value="option3">698745</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-control" appearance="outline">
      <mat-label>No. de Acta de incautación</mat-label>
      <mat-select formControlName="seizureRecordNumber">
        <mat-option value="option1">425986</mat-option>
        <mat-option value="option2">987456</mat-option>
        <mat-option value="option3">547896</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
  <div class="btn-container flex-row-end">
    <button type="submit" mat-button class="btn-search" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>
</div>
