import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
  selector: 'input[alphabetic]'
})
export class AlphabeticDirective {
  constructor(private _el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initialValue.replace(/[^a-zA-Z\sóúéáíñÑÁÉÍÓÚüÜ]*/g, '');
    if(initialValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
