import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdministrationInterface } from '../../types/administration.interface';
import { RegionalDirectionService } from '../../services/regional-direction.service';
import { DialogService } from '../../services/dialog.service';
import { SpeciesService } from '../../services/species.service';
import { GeneraService } from '../../services/genera.service';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {ValidatorsService} from "../../services/validators.service";

@Component({
  selector: 'app-add-edit-configuration',
  templateUrl: './add-edit-configuration.component.html',
  styleUrls: ['./add-edit-configuration.component.scss'],
})
export class AddEditConfigurationComponent implements OnInit {
  public dataSource: AdministrationInterface;
  public nameInput: string;

  constructor(
    public dialogRef: MatDialogRef<AddEditConfigurationComponent>,
    private regionalDirectionService: RegionalDirectionService,
    private speciesService: SpeciesService,
    private generaService: GeneraService,
    private dialogService: DialogService,
    private validatorsService: ValidatorsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      id?: number;
      section: string;
    }
  ) {}

  public generalForm: FormGroup;

  async ngOnInit() {
    this.buildGeneralForm();
    await this.getDataUpdate(this.data.id);
    if (this.dataSource) {
      this.showDataUpdate();
    }
  }

  public async getDataUpdate(id: number | undefined) {
    try {
      if (this.data.section == 'regional') {
        this.nameInput = 'Dirección Regional';
        this.dataSource =
          await this.regionalDirectionService.getRegionalDirectionById(
            Number(id)
          );
      } else if (this.data.section == 'genera') {
        this.nameInput = 'Género';
        this.dataSource = await this.generaService.getGeneraById(Number(id));
      } else if (this.data.section == 'specie') {
        this.nameInput = 'Especie';
        this.dataSource = await this.speciesService.getSpeciesById(Number(id));
      }
    } catch (e) {
      console.error(e);
      this.dialogService.errorModal(
        'Hay un error al traer los datos asociados al id: ' +
          this.data.id +
          '. Por favor inténtelo nuevamente'
      );
    }
  }

  public buildGeneralForm() {
    this.generalForm = this.fb.group({
      id: [{ value: '', disabled: true }],
      name: ['', [Validators.required, Validators.maxLength(80), Validators.pattern(this.validatorsService.alphabeticWithAccentsAndNumberValidator)]],
      state: [false],
    });
  }

  public showDataUpdate() {
    this.generalForm.patchValue({
      id: this.dataSource.id,
      name: this.dataSource.name,
      state: this.dataSource.state,
    });
  }

  public cancel() {
    this.close();
  }

  public onSubmit() {
    if (this.generalForm.invalid) {
      let errorGeneralForm: AllValidationErrors = getFormValidationErrors(
        this.generalForm.controls
      ).shift() as any;
      if (errorGeneralForm.control_name == 'name') {
        errorGeneralForm.control_name = `name_${this.data.section}`;
      }
      this.dialogService.errorModal(searchFormError(errorGeneralForm));
    } else {
      let body: AdministrationInterface = {
        id: this.generalForm.get('id')?.value,
        name: this.generalForm.get('name')?.value.trim(),
        state: this.generalForm.get('state')?.value,
      };
      this.close(body);
    }
  }

  public close(data?: AdministrationInterface) {
    this.dialogRef.close(data);
  }
}
