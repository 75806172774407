import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'seizure-record',
        loadChildren: () =>
          import('../seizure-record/seizure-record.module').then(
            (m) => m.SeizureRecordModule
          ),
      },
      {
        path: 'safeguard',
        loadChildren: () =>
          import('../safeguard/safeguard.module').then(
            (m) => m.SafeguardModule
          ),
      },
      {
        path: 'clinical-examination',
        loadChildren: () =>
          import('../clinical-examination/clinical-examination.module').then(
            (m) => m.ClinicalExaminationModule
          ),
      },
      {
        path: 'medical-history',
        loadChildren: () =>
          import('../medical-history/medical-history.module').then(
            (m) => m.MedicalHistoryModule
          ),
      },
      {
        path: 'genera',
        loadChildren: () =>
          import('../settings/genera/genera.module').then(
            (m) => m.GeneraModule
          ),
      },
      {
        path: 'species',
        loadChildren: () =>
          import('../settings/species/species.module').then(
            (m) => m.SpeciesModule
          ),
      },
      {
        path: 'regional-direction',
        loadChildren: () =>
          import(
            '../settings/regional-direction/regional-direction.module'
            ).then((m) => m.RegionalDirectionModule),
      },
      {
        path: 'medicines',
        loadChildren: () =>
          import('../settings/medicines/medicines.module').then(
            (m) => m.MedicinesModule
          ),
      },
      {
        path: 'diagnostic-test',
        loadChildren: () =>
          import('../settings/diagnostic-test/diagnostic-test.module').then(
            (m) => m.DiagnosticTestModule
          ),
      },
      {
        path: 'routine-evaluation',
        loadChildren: () =>
          import('../routine-evaluation/routine-evaluation.module').then(
            (m) => m.RoutineEvaluationModule
          ),
      },
      {
        path: 'release',
        loadChildren: () =>
          import('../release/release.module').then((m) => m.ReleaseModule),
      },
      {
        path: 'samples',
        loadChildren: () =>
          import('../samples/samples.module').then((m) => m.SamplesModule),
      },
      {
        path: 'necropsy',
        loadChildren: () =>
          import('../necropsy/necropsy.module').then((m) => m.NecropsyModule),
      },
      {
        path: 'euthanasia',
        loadChildren: () =>
          import('../euthanasia/euthanasia.module').then(
            (m) => m.EuthanasiaModule
          ),
      },
      {
        path: 'deliver-specimens',
        loadChildren: () =>
          import('../deliver-specimens/deliver-specimens.module').then(
            (m) => m.DeliverSpecimensModule
          ),
      },
      {
        path: 'admission-departure-management',
        loadChildren: () =>
          import(
            '../admission-departure-management/admission-departure-management.module'
            ).then((m) => m.AdmissionDepartureManagementModule),
      },
      {
        path: 'ethograms',
        loadChildren: () =>
          import('../ethograms/ethograms.module').then(
            (m) => m.EthogramsModule
          ),
      },
      {
        path: 'neonatal-monitoring',
        loadChildren: () =>
          import('../neonatal-monitoring/neonatal-monitoring.module').then(
            (m) => m.NeonatalMonitoringModule
          ),
      },
      {
        path: 'biology-species',
        loadChildren: () =>
          import('../biology-species/biology-species.module').then(
            (m) => m.BiologySpeciesModule
          ),
      },
      {
        path: 'actor-management',
        loadChildren: () =>
          import('../settings/actor-management/actor-management.module').then(
            (m) => m.ActorManagementModule
          ),
      },
      {
        path: 'roles',
        loadChildren: () =>
          import('../settings/roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'enrichment-material',
        loadChildren: () =>
          import(
            '../settings/enrichment-material/enrichment-material.module'
            ).then((m) => m.EnrichmentMaterialModule),
      },
      {
        path: 'diet-management',
        loadChildren: () =>
          import('../diet-management/diet-management.module').then(
            (m) => m.DietManagementModule
          ),
      },
      {
        path: 'enrichment-management',
        loadChildren: () =>
          import('../enrichment-management/enrichment-management.module').then(
            (m) => m.EnrichmentManagementModule
          ),
      },
      {
        path: 'therapeutic',
        loadChildren: () =>
          import('../therapeutic/therapeutic.module').then(
            (m) => m.TherapeuticModule
          ),
      },
      {
        path: 'hospitalization-check-out-record',
        loadChildren: () =>
          import(
            '../hospitalization/check-out-record/check-out-record.module'
            ).then((m) => m.CheckOutRecordModule),
      },
      {
        path: 'hospitalization-income-record',
        loadChildren: () =>
          import('../hospitalization/income-record/income-record.module').then(
            (m) => m.IncomeRecordModule
          ),
      },
      {
        path: 'hospitalization-tracking-record',
        loadChildren: () =>
          import(
            '../hospitalization/tracking-record/tracking-record.module'
            ).then((m) => m.TrackingRecordModule),
      },
      {
        path: 'quarantine',
        loadChildren: () =>
          import('../quarantine/quarantine.module').then(
            (m) => m.QuarantineModule
          ),
      },
      {
        path: 'critical-care-income',
        loadChildren: () =>
          import('../critical-care/income-critical-care/critical.module').then(
            (m) => m.CriticalModule
          ),
      },
      {
        path: 'critical-monitoring',
        loadChildren: () =>
          import(
            '../critical-care/critical-monitoring/critical-monitoring.module'
            ).then((m) => m.CriticalMonitoringModule),
      },
      {
        path: 'diet-consumption',
        loadChildren: () =>
          import(
            '../diet-consumption-management/diet-consumption-management.module'
            ).then((m) => m.DietConsumptionManagementModule),
      },
      {
        path: 'temporary-delivery',
        loadChildren: () =>
          import('../temporary-delivery/temporary-delivery.module').then(
            (m) => m.TemporaryDeliveryModule
          ),
      },
      {
        path: 'critical-care-discharge',
        loadChildren: () =>
          import(
            '../critical-care/discharge-critical-care/discharge-critical-care.module'
            ).then((m) => m.DischargeCriticalCareModule),
      },
      {
        path: 'biological-veterinary-concept',
        loadChildren: () =>
          import(
            '../biological-veterinary-concept/biological-veterinary-concept.module'
            ).then((m) => m.BiologicalVeterinaryConceptModule),
      },
      {
        path: 'enrichment-environment',
        loadChildren: () =>
          import(
            '../enrichment-environmental/enrichment-environmental.module'
            ).then((m) => m.EnrichmentEnvironmentalModule),
      },
      {
        path: 'general-report',
        loadChildren: () =>
          import('../general-report/general-report.module').then(
            (m) => m.GeneralReportModule
          ),
      },
      {
        path: 'sample-lab-report',
        loadChildren: () =>
          import('../samples-lab-report/samples-lab-report.module').then(
            (m) => m.SamplesLabReportModule
          ),
      },
      {
        path: 'administration-management',
        loadChildren: () =>
          import(
            '../settings/administration-management/administration-management.module'
            ).then((m) => m.AdministrationManagementModule),
      },
      {
        path: 'clinic-history',
        loadChildren: () =>
          import(
            '../clinic-history/clinic-history.module'
            ).then((m) => m.ClinicHistoryModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import(
            '../dashboard/dashboard.module'
            ).then((m) => m.DashboardModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
}
