import { Injectable } from '@angular/core';
import { DatePipe } from "@angular/common";
import momentTimezone from "moment-timezone";


@Injectable({
  providedIn: 'root',
})
export class CalendarManagerService {
  constructor(private date: DatePipe) { }

  changeFormatDate(date: Date | string, format?: string): any {
    return this.date.transform(date, format ? format : 'YYYY/MM/dd');
  }

  getActuallyDate(): Date {
    return new Date();
  }

  isDateEqualsOrUp(dateOne: Date, dateTwo: Date): boolean {
    if (dateOne && dateTwo) {
      dateOne.setHours(0, 0, 0, 0);
      dateTwo.setHours(0, 0, 0, 0);
      return dateOne >= dateTwo;
    }
    return false;
  }

  transform12hFormat(time: string): string {
    let hour: string | number = Number((String(time).split(':'))[0]);
    let min: string | number = Number((String(time).split(':'))[1]);
    let part = hour > 12 ? 'pm' : 'am';
    if (parseInt(String(hour)) == 0)
      hour = 12;
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`
  }

  /**
   * convert a date (YYYY-mm-dd) to a local Timetamp (00:00) hours
   * @param date
   */
  convertDateToLocalTimestamp(date: string): Date{
    const dateTime = `${date} 00:00`
    const timeZone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    return new Date(momentTimezone.tz(dateTime, timeZone).toDate())
  }
}
