import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort, SortDirection} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {TableAction} from '../../types/table';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [DatePipe],
})
export class TableComponent implements OnChanges {
  @Input() displayedColumns: any[] = [];
  @Input() data: { [key: string]: any }[];
  @Input() markSelectedRow: boolean = false;
  @Input() style: { [klass: string]: any } | null;
  @Input() nameExcel: string = 'Exportar';
  @Input() sortBy: string = 'date';
  @Input() orderBySort: SortDirection = 'desc';
  @Input() hiddenActionFirstElement: boolean = false;
  @Input() toolTip: { input: string, value: string, tooltip: string }[] = [];
  @Input() public actions: TableAction[] = [];
  @Input() tableLength: number;
  @Input() centerContent: boolean = false;
  @Input() hidePaginator: boolean = false;
  @Output() paramsValues: EventEmitter<any> = new EventEmitter();
  @Output() public action: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) tableSort: MatSort;

  public dataTable: MatTableDataSource<{ [key: string]: any }>;
  @Input() showDownloadExcel = true;
  @Input() showDownloadPDF = false;
  @Input() useTable = '';
  @ViewChild(MatTable) table: MatTable<{ [key: string]: any }>;

  constructor(public datePipe: DatePipe,
  ) {
  }

  //render information take of component table
  public ngOnChanges(change: SimpleChanges) {
    setTimeout(() => {
      if (this.data && change.data) {
        this.data = [];
        this.data = [...change.data.currentValue];
        this.sortTable();
      }
    }, 1);
  }

  get keys() {
    return this.displayedColumns.map(({key}) => key);
  }

  //output excel
  getValuesByParams() {
    this.paramsValues.emit();
  }

  getValuesByParamsPDF() {
    this.paramsValues.emit();
  }

  /**
   * onAction is the event when an icon action is clicked
   * @param row is the data of the specific row
   * @param action
   * @param position
   */
  public onAction(row: any, action: string, position: number) {
    if (this.useTable.length > 0) {
      this.action.emit({
        row,
        action,
        useTable: this.useTable,
        position: position,
      });
    } else {
      this.action.emit({row, action, position});
    }
  }


  public dateRegularExpresion(value: string | number) {
    return String(value).match(/([0-3][0-9][/][0-3][0-9][/][0-9]{4})/g)
  }

  public formatDate(date: string) {
    return `${date.split('/')[2]}/${date.split('/')[1]}/${date.split('/')[0]}`
  }

  public typed(value: string | number | Date) {
    return typeof value
  }

  public destructuringDate(date: Date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  public accessDateTable(data: any[]) {
    for (let row = 0; row < data.length; row++) {
      for (let property in data[row]) {
        if (this.dateRegularExpresion(data[row][property])) {
          data[row][property] = new Date(this.formatDate(data[row][property]))
        }
      }
    }
    return data
  }

  public sortTable() {
    this.dataTable = new MatTableDataSource<{ [key: string]: any }>(this.accessDateTable(this.data));
    const sortState: Sort = {active: this.sortBy, direction: this.orderBySort};
    this.dataTable.paginator = this.paginator;
    this.dataTable.sort = this.tableSort;
    this.tableSort.active = sortState?.active;
    this.tableSort.direction = sortState?.direction;
    this.tableSort.sortChange.emit(sortState);

  }

  getTooltipText(element: any, key: string) {
    const value = this.toolTip.find(tooltip => element[key] === tooltip.value);
    if (value) {
      return value.tooltip;
    } else {
      return '';
    }
  }


}
