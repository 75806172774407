import {EnvironmentDefinition} from "./types";
import {MENU_OPTIONS} from "../app/shared/entities/menu-options/menu-option-dev";
import {ITEMS_MENU_DEV} from "../app/shared/entities/menu-options/header-option-dev";

export const environmentQa: EnvironmentDefinition = {
  production: false,
  api: "https://api.qa.cavfauna.com/api/v1",
  authService: "https://admin.qa.cavfauna.com/auth",
  keycloakRealm: "cav_fauna",
  keycloakClient: "operations_front_client",
  section: MENU_OPTIONS,
  header: ITEMS_MENU_DEV
}
