import { ClinicHistoryDB } from "../../medical-history/types/medical-history.interface";
import { EntryRecord } from "./admission-management.interface";

export enum DepartureTypes {
  TEMPORAL = "temporal",
  DEFINITIVE = "definitive"
}

export enum DepartureDestinies {
  TRANSFER = "transfer",
  ZOO = "zoo",
  UN = "un",
  INS = "ins",
  TEN = "ten",
  RAMFA = "ramfa",
  DEATH = "death",
  EUTHANASIA = "euthanasia",
  SCAPE = "scape",
  THEFT = "theft",
  LIBERATION = "liberation"
}
export const DEPARTUREHashSpanish: { [DepartureDestinies: string]: string } = {
  'transfer': "Autoridad Ambiental",
  'zoo': "Zoo - Zoológico",
  'un': "UN - Universidades",
  'ins': "INS - Institutos de Investigación",
  'ten': "TEN - Tenedor de Fauna",
  'ramfa': "RAMFA - Red de Amigos de la Fauna",
  'death': "Muerte",
  'euthanasia': "Eutanasia",
  'scape': "Fuga",
  'theft': "Hurto",
  'liberation': "Liberación",
}
export interface DepartureRecord {
  id: number;
  departureType: DepartureTypes;
  departureDate: Date | string;
  destiny: DepartureDestinies;
  finalDestiny: string;
  observations: string;
  entryRecord?: EntryRecord;
  preliminaryClinicHistory?: ClinicHistoryDB;
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | null;
}

export type DepartureRecordCreateInput = {
  departureType: DepartureTypes;
  departureDate: Date | string;
  destiny: DepartureDestinies;
  finalDestiny: string;
  observations: string;
  entryRecordId?: number;
  preliminaryClinicHistoryId?: number;
};

export type DepartureRecordUpdateInput = {
  id: number;
} & Partial<DepartureRecordCreateInput>;

export type DepartureRecordSearchInput = {
  CNIF?: string;
  preliminaryClinicHistoryId?: number;
  regionalId?: number;
  departureDate?: Date | string;
};
