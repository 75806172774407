import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-add-msg',
  templateUrl: './add-msg.component.html',
  styleUrls: ['./add-msg.component.scss']
})
export class AddMsgComponent implements OnInit {
  @Input() information: { icon: string, msg: string };
  customIcons = ['bird', 'hippo', 'monkey', 'ocelot', 'porcupine', 'sloth_bear', 'turtle', 'weasel','diagnostic_tests'];

  constructor() {
  }

  ngOnInit(): void {
  }

}
