import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteCheckDataService {

  constructor() { }

  public existValueInAutocompleteData(arrayAutocompleteData: number[] | string[], value: string | number){

   for(const data of arrayAutocompleteData){
    if(data.toString().toLowerCase() === value.toString().toLowerCase()){
      return true;
    }
   }
   return false;
  }
}
