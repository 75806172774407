import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {
  ClinicHistory,
  Gender,
  GenderHash,
  Growth,
} from '../../../medical-history/types/medical-history.interface';
import {DatePipe} from '@angular/common';
import {SpecimenService} from '../../services/specimens.service';
import {LoadingService} from '../../services/loading.service';
import {AdministrationInterface} from '../../types/administration.interface';
import {GeneraService} from '../../services/genera.service';
import {SpeciesService} from '../../services/species.service';
import {RegionalDirectionService} from '../../services/regional-direction.service';
import {DialogService} from '../../services/dialog.service';
import {markingTypeList, MarkingTypesHash, NewMarkings, SpecimenMarking} from '../../types/specimen.interface';
import {OriginSourceHashSpanish} from 'src/app/clinical-examination/types/clinical-examination.interfaces';
import {GrowthHashSpanish} from '../../types/growth';
import {MedicalHistoryService} from '../../../medical-history/services/medical-history.service';
import {taxonomicGroup} from "../../../hospitalization/income-record/types/hospitalization-income.interface";
import {IncomeClassification, SeizureIncomeHashSpanish} from "../../../seizure-record/types/seizure-record.interface";
import {MatExpansionPanel} from "@angular/material/expansion";
import {DEPARTUREHashSpanish} from "../../../admission-departure-management/types/departure-management.interface";
import {ValidatorsService} from "../../services/validators.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_DATE_FORMATS} from "../../types/format-date.interface";

@Component({
  selector: 'app-basic-info-expandable',
  templateUrl: './basic-info-expandable.component.html',
  styleUrls: ['./basic-info-expandable.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  ],
})
export class BasicInfoExpandableComponent implements OnInit, OnChanges {
  @ViewChild('expansion')
  public expansionPanel: MatExpansionPanel;
  @Input() panelOpenState: boolean = false;
  @Input() activeCommonName: boolean = true;
  @Input() patientHistory: ClinicHistory;
  @Input() public editable: boolean = false;
  @Input() public isMedicalExam: boolean = false;
  @Input() public showClinicHistoryTitle: boolean = false;
  @Input() public isNewMarking: boolean = false;
  @Input() public isAdmissionDeparture: boolean = false;
  @Input() public fields: Array<| 'genus'
    | 'scientificName'
    | 'specie'
    | 'regional'
    | 'genera'
    | 'developmentS'
    | 'clinicH'
    | 'CNIF'
    | 'seizureR'
    | 'safeguard'
    | 'safeguard2'
    | 'noSafeguard'
    | 'DRO'
    | 'delivery'
    | 'entryDate'
    | 'taxonomicG'
    | 'origin'
    | 'destination'
    | 'preliminaryClinicH'
    | 'emptySpace'
    | 'incomeClassification'>;
  @Input() public requiredFields: Array<string> = [];
  @Input() public showMarkings: boolean = true;
  @Input() public sortById: boolean = false;
  @Input() public saveLocally: boolean = true;
  @Input() public editableFields: Array<| 'genus'
    | 'scientificName'
    | 'specie'
    | 'regional'
    | 'genera'
    | 'developmentS'
    | 'markingDate'
    | 'markingNo'
    | 'type'
    | 'commonName'
    | 'incomeClassification'>;
  @Input() public otherLabel: Array<| 'entryDate'
    | 'origin'
    | 'destination'>;
  @Input() editableMarking: boolean = false;
  @Output() changeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPanelOpen = new EventEmitter<boolean>();
  @Output() clearEditable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() infoExpandibleData: EventEmitter<any> = new EventEmitter<any>();

  public genusList: AdministrationInterface[];
  public genderList = [
    {id: 0, name: 'Macho'},
    {id: 1, name: 'Hembra'},
    {id: 2, name: 'Indeterminado'},
  ];
  public speciesList: AdministrationInterface[];
  public regionalList: AdministrationInterface[];
  public markings: SpecimenMarking[] = [];
  public index: number | undefined;
  commonName: FormControl;
  public form: FormGroup;
  public markingType = markingTypeList;
  public markingTypesHash = MarkingTypesHash;
  public amountNewMarkings: number = 0;

  // add new chip
  public addnNewChip: boolean = false;

  dataSourceMarkings: any;
  newMarkings: NewMarkings[] = [];
  displayedColumns: any[] = [
    {key: 'createdAt', value: 'Fecha de Marcación'},
    {key: 'type', value: 'Tipo Marcaje'},
    {key: 'markingNo', value: 'Número Chip'},
  ];
  public developmentStageList: { id: number; name: string }[] = [
    {id: Growth.ADULT, name: 'Adulto'},
    {id: Growth.GERIATRIC, name: 'Geronte'},
    {id: Growth.YOUTH, name: 'Juvenil'},
    {id: Growth.NEONATE, name: 'Neonato'},
  ];
  incomeClassificationList: any[] = [
    {
      id: IncomeClassification.VOLUNTEER,
      name: 'Entrega voluntaria',
    },
    {id: IncomeClassification.SEIZURE, name: 'Incautación'},
    {id: IncomeClassification.RESTITUTION, name: 'Restitución'},
    {
      id: IncomeClassification.PREVENTIVE_APPREHENSION,
      name: 'Aprensión preventiva',
    },
    {
      id: IncomeClassification.PREVENTIVE_SEIZURE,
      name: 'Decomiso preventivo',
    },
    {id: IncomeClassification.OTHER, name: 'Otro'},
  ];
  fieldsInfo: FieldInfo[] = [];
  otherInfo: FieldInfo[] = [];
  tableLength: number;

  constructor(
    private fb: FormBuilder,
    public datePipe: DatePipe,
    public specimenService: SpecimenService,
    public validatorService: ValidatorsService,
    private loadingService: LoadingService,
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private dialogService: DialogService,
    private regionalDirectionService: RegionalDirectionService,
    private medicalHistoryService: MedicalHistoryService
  ) {
  }

  async ngOnInit() {
    await this.recent()
    await this.getData();
    this.setFields();
    if (this.showMarkings) {
      this.setMarkings();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.setFields();
    if (this.editable) {
      this.buildForm();
      this.panelOpenState = true;
      this.amountNewMarkings = 0;
    }
  }

  /**
   * set information of fields to be showed
   * @private
   */
  private setFields() {
    this.fieldsInfo = [];
    this.otherInfo = [];
    this.otherLabel?.forEach((field) => {
      const addField = this.allFields.find((f) => f.name === field);
      if (addField) {
        this.otherInfo.push(addField);
      }
    });
    this.fields.forEach((field) => {
      const addField = this.allFields.find((f) => f.name === field);
      if (addField) {
        this.fieldsInfo.push(addField);
      }
    });
  }

  /**
   * Create the basic info form
   * @private
   */
  private buildForm() {
    //medical exam case
    if (this.isMedicalExam) {
      this.commonName = new FormControl(
        {value: this.patientHistory.commonName, disabled: !this.activeCommonName},
        Validators.required
      );
    }
    this.form = new FormGroup({});
    this.fieldsInfo.forEach((field) => {
      if (this.isEditable(field.name) && field.ctrlName) {
        this.form.addControl(
          field.ctrlName,
          this.requiredFields?.includes(field.ctrlName) ?
            new FormControl(field.ctrlValue, Validators.required) : new FormControl(field.ctrlValue)
        );
      }
    });

    //with markings
    if (this.showMarkings) {
      this.form.addControl(
        'type',
        new FormControl(this.markings[0].type, Validators.required)
      );
      this.form.addControl(
        'markingDate',
        new FormControl(new Date(), Validators.required)
      );
      this.form.addControl(
        'markingNo',
        new FormControl(this.markings[0].markingNo, Validators.required)
      );
    }
  }

  /**
   * get selectors options
   * @private
   */
  private async getData() {
    try {
      this.genusList = await this.generaService.getGenera();
      this.speciesList = await this.specieService.getSpecies();
      this.regionalList =
        await this.regionalDirectionService.getRegionalDirection();
      await this.addInactiveOptions();
    } catch (e) {
      this.dialogService.errorModal(
        'Ha ocurrido un error al intentar traer los datos de selectores'
      );
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  /**
   * if the previous register is associated to an inactive record add this record to the list.
   * Apply for genus,  species and regional
   * @private
   */
  private async addInactiveOptions() {
    const genus = this.allFields.find((f) => f.name == 'genus');
    const specie = this.allFields.find((f) => f.name == 'specie');
    const regional = this.allFields.find((f) => f.name == 'regional');

    if (!this.genusList.find((g) => g.id == genus?.ctrlValue)) {
      this.genusList.push(
        await this.generaService.getGeneraById(genus?.ctrlValue)
      );
    }
    if (!this.speciesList.find((s) => s.id == specie?.ctrlValue)) {
      this.speciesList.push(
        await this.specieService.getSpeciesById(specie?.ctrlValue)
      );
    }
    if (!this.regionalList.find((s) => s.id == regional?.ctrlValue)) {
      if (regional?.ctrlValue != undefined) {
        this.regionalList.push(
          await this.regionalDirectionService.getRegionalDirectionById(
            regional?.ctrlValue
          )
        );

      }
    }
  }

  /**
   * clear edited fields
   */
  clear(cancel?: true) {
    this.form.reset();
    this.buildForm();
    this.dataSourceMarkings = this.dataSourceMarkings?.filter((mark: NewMarkings) => {
      const markingsNo = this.newMarkings.map(m => m.markingNo)
      return !markingsNo.includes(mark.markingNo)
    })
    this.newMarkings = [];
    if (cancel) {
      this.editable = false;
    }
    this.changeMode.emit();
  }

  /**
   * Validate form information and send to update
   */
  public async sendInformation() {
    this.form.markAllAsTouched();
    if (this.isMedicalExam) {
      this.commonName.markAllAsTouched();
    }
    if (this.form.valid && (!this.isMedicalExam || !this.commonName.invalid)) {
      await this.updateSpecimen();
    } else {
      if (this.form.invalid) {
        const error: AllValidationErrors = getFormValidationErrors(
          this.form.controls
        ).shift() as any;
        this.dialogService.errorModal(searchFormError(error));
      }
      if (this.commonName.invalid) {
        this.dialogService.errorModal('El campo Nombre común es requerido');
      }
    }
  }

  sendInformationNoLocally() {
    this.form.markAllAsTouched();
    if (this.isMedicalExam) {
      this.commonName.markAllAsTouched();
    }
    if (this.form.valid && (!this.isMedicalExam || !this.commonName.invalid)) {
      const body = this.setRequestBody();
      this.infoExpandibleData.emit({
          body,
          id: this.patientHistory.specimen.id
        }
      );
      this.changeHistoryInfo();
      this.changeMode.emit();
    } else {
      if (this.form.invalid) {
        const error: AllValidationErrors = getFormValidationErrors(
          this.form.controls
        ).shift() as any;
        this.dialogService.errorModal(searchFormError(error));
      }
      if (this.commonName.invalid) {
        this.dialogService.errorModal('El campo Nombre común es requerido');
      }
    }
  }

  /**
   * Update the related specimen information
   * @private
   */
  private async updateSpecimen() {
    this.loadingService.setLoading(true);
    try {
      const body = this.setRequestBody();
      if (body) {
        const updatedData = await this.specimenService.updateSpecimens(
          body,
          this.patientHistory.specimen.id
        );
        updatedData.markings?.sort((a, b) => new Date(b.markingDate).getTime() - new Date(a.markingDate).getTime());
        if (this.editableFields.includes('regional') || this.editableFields.includes('incomeClassification')) {
          const bodyPH = {
            regionalId: this.form.get('regionalId')?.value,
            seizureRecord: {
              id: this.patientHistory?.seizureRecord?.id || '',
              incomeClassification: this.form.get('incomeClassification')?.value,
            }
          };
          await this.medicalHistoryService.updateMedicalHistory(
            bodyPH,
            this.patientHistory.id
          );
        }
        this.dialogService.successModal(
          'Se han actualizado correctamente los datos del espécimen'
        );
        this.newMarkings = [];
        this.changeHistoryInfo();
        if (this.showMarkings) {
          this.markings = updatedData.markings as any;
          if (this.sortById) {
            this.markings.sort((a, b) => b.id - a.id);
          }
        }
        this.changeMode.emit();
      }
    } catch (e) {
      this.dialogService.errorModal(
        'Ha ocurrido un error al intentar actualizar los datos del espécimen'
      );
      console.error(e);
    }
    this.loadingService.setLoading(false);
  }

  /**
   * return the specimen request body
   * @private
   */
  private setRequestBody() {
    let body: any = {};
    body = this.form.value;
    if (this.isMedicalExam) {
      body = {...body, commonName: this.commonName.value?.trim()};
    }
    if (this.showMarkings) {
      if (this.addnNewChip) {
        body = {
          ...body,
          markings: this.newMarkings
        };
      } else {
        body = {
          ...body,
        };
      }
    }
    return body;
  }

  /**
   * Section filters
   */
  public dateFilter = (d: any) => {
    const date = new Date();
    return d <= date;
  };

  /**
   * update showed information
   * @private
   */
  private changeHistoryInfo() {
    if (this.isMedicalExam) {
      this.patientHistory.commonName = this.commonName.value;
    }
    if (this.isEditable('scientificName')) {
      this.patientHistory.specimen.scientificName = this.scientificName;
    }
    if (this.isEditable('specie')) {
      const specieData = this.speciesList.find((s) => s.id == this.form.get('specieId')?.value);
      if (specieData) {
        this.patientHistory.specimen.specie = specieData;
      }
    }
    if (this.isEditable('genus')) {
      const genusData = this.genusList.find((g) => g.id == this.form.get('genusId')?.value);
      if (genusData) {
        this.patientHistory.specimen.genus = genusData;
      }
    }
    if (this.isEditable('regional')) {
      const regionalData = this.regionalList.find((r) => r.id == this.form.get('regionalId')?.value);
      if (regionalData) {
        this.patientHistory.regional = regionalData;
        this.patientHistory.regionalName = regionalData.name;
      }
    }
    if (this.isEditable('incomeClassification')) {
      this.patientHistory.seizureRecord.incomeClassification = this.form.get('incomeClassification')?.value;
    }
    if (this.isEditable('developmentS')) {
      this.patientHistory.specimen.growth = this.form.get('growth')?.value;
    }
    if (this.isEditable('genera')) {
      this.patientHistory.specimen.gender = this.form.get('gender')?.value;
    }
  }

  /**
   * get markings information
   */
  public setMarkings() {
    this.markings = this.patientHistory?.specimen?.markings || [];
    this.markings = this.markings.sort((a, b) => {
      const aDate = new Date(a.markingDate);
      const bDate = new Date(b.markingDate);
      if (bDate.getTime() === aDate.getTime()) {
        const aUpdateDate = new Date(a.updatedAt);
        const bUpdateDate = new Date(b.updatedAt);
        return bUpdateDate.getTime() - aUpdateDate.getTime();
      }
      return bDate.getTime() - aDate.getTime();
    });
    if (this.sortById) {
      this.markings = this.markings.sort((a, b) => b.id - a.id);
    }
    this.dataSourceMarkings = this.markings.map((marking) => {
      return {
        createdAt: this.datePipe.transform(marking.markingDate, 'dd/MM/yyyy'),
        type: this.markingTypesHash[marking.type],
        markingNo: marking.markingNo,
      };
    });
    this.tableLength = this.markings.length;
  }

  checkStatusOpenPanel() {
    this.isPanelOpen.emit();
  }

  get scientificName() {
    let name = this.genusList.find((g) => g.id == this.form.get('genusId')?.value)?.name ?? '';
    const speciesName = this.speciesList.find((s) => s.id == this.form.get('specieId')?.value)?.name;
    name += ' ' + (speciesName ?? '');
    return name.trim();
  }

  isEditable(field: any) {
    return this.editableFields.includes(field);
  }

  public async recent() {
    let maxDate = 0;
    if (this.patientHistory?.individuals) {
      this.patientHistory.individuals?.map(data => {
        if (new Date(data.createdAt).getTime() > maxDate) {
          maxDate = new Date(data.createdAt).getTime();
        }
      })
      this.index = this.patientHistory.individuals?.findIndex(data => new Date(data.createdAt).getTime() == maxDate);
    } else {
      this.index = 0
    }
  }

  /*ALL AVAILABLE FIELDS DEFINITION*/
  get allFields(): FieldInfo[] {
    const fields = [
      {
        name: 'genus',
        label: 'Género',
        value: this.patientHistory?.specimen?.genus?.name
          ? this.patientHistory?.specimen?.genus?.name
          : 'No especificado',
        ctrlName: 'genusId',
        ctrlValue: this.patientHistory?.specimen?.genus?.id
          ? this.patientHistory?.specimen?.genus?.id
          : '',
        options: this.genusList,
      },
      {
        name: 'scientificName',
        label: 'Nombre Científico',
        value: `${this.patientHistory?.specimen?.genus?.name ?? ''} ${this.patientHistory?.specimen?.specie?.name ?? ''}`,
        ctrlName: 'scientificName',
        ctrlValue: this.patientHistory?.specimen?.genus?.name &&
        this.patientHistory?.specimen?.specie?.name ?
          `${this.patientHistory?.specimen?.genus?.name} ${this.patientHistory?.specimen?.specie?.name}` : '-',
      },
      {
        name: 'specie',
        label: 'Especie',
        value: this.patientHistory?.specimen?.specie?.name ?
          this.patientHistory.specimen?.specie?.name : '-',
        ctrlName: 'specieId',
        ctrlValue: this.patientHistory?.specimen?.specie?.id ?? '',
        options: this.speciesList,
      },
      {
        name: 'regional',
        label: 'Dirección Regional de Origen',
        value: this.patientHistory?.regionalName ?? '',
        ctrlName: 'regionalId',
        ctrlValue: this.patientHistory?.regional?.id ?? '',
        options: this.regionalList,
      },
      {
        name: 'genera',
        label: 'Sexo',
        value: GenderHash[Gender[this.patientHistory?.specimen?.gender]] ?? '',
        ctrlName: 'gender',
        ctrlValue: this.patientHistory?.specimen?.gender ?? '',
        options: this.genderList,
      },
      {
        name: 'developmentS',
        label: 'Etapa de Desarrollo',
        value: GrowthHashSpanish[this.patientHistory?.specimen?.growth],
        ctrlName: 'growth',
        ctrlValue: this.patientHistory?.specimen?.growth,
        options: this.developmentStageList,
      },
      {
        name: 'clinicH',
        label: 'Historia Clínica',
        value: this.patientHistory?.clinicHistory?.id ?
          this.patientHistory.clinicHistory?.id : '-',
        ctrlName: '',
      },
      {
        name: 'preliminaryClinicH',
        label: 'Historia Clínica Preliminar',
        value: this.patientHistory?.id ? this.patientHistory.id : '-',
        ctrlName: '',
      },
      {
        name: 'CNIF',
        label: 'Código Nacional de Ingreso CNIF',
        value: this.patientHistory?.entryRecord?.CNIF ?
          this.patientHistory.entryRecord?.CNIF : '',
        ctrlName: '',
      },
      {
        name: 'seizureR',
        label: 'Acta de Incautación',
        value: this.patientHistory?.seizureRecordId ?
          this.patientHistory.seizureRecordId : '-',
        ctrlName: '',
      },
      {
        name: 'safeguard',
        label: 'Salvoconducto',
        value: this.patientHistory?.safePassageRecordId
          ? this.patientHistory.safePassageRecordId
          : '-',
        ctrlName: '',
      },
      {
        name: 'safeguard2',
        label: 'Salvoconducto',
        value: this.patientHistory?.safePassageRecordId
          ? this.patientHistory.safePassageRecordId
          : '-',
        ctrlName: '',
      },
      {
        name: 'noSafeguard',
        label: 'Número de Salvoconducto',
        value: this.patientHistory?.safePassageRecordId
          ? this.patientHistory.safePassageRecordId
          : '-',
        ctrlName: '',
      },
      {
        name: 'DRO',
        label: 'Dirección Regional de Origen',
        value: this.patientHistory?.safePassageRecordId
          ? this.patientHistory.safePassageRecordId
          : '-',
        ctrlName: '',
      },
      {
        name: 'emptySpace',
        label: '',
        value: '',
        ctrlName: '',
      },
      {
        name: 'delivery',
        label: 'Acta de Entrega',
        value: this.patientHistory?.individuals && this.patientHistory?.individuals?.length > 0 ?
          (this.patientHistory?.individuals?.filter(individual => individual.individualsDelivery
            && individual?.individualsDelivery?.id).reverse()[0]?.individualsDelivery?.id || '-') : '-',
        ctrlName: ''
      },
      {
        name: 'entryDate',
        label: 'Fecha de Ingreso',
        value: this.patientHistory?.entryRecord?.admissionDate ? this.datePipe.transform(
          this.patientHistory.entryRecord?.admissionDate,
          'dd/MM/yyyy'
        ) : '-',
        ctrlName: '',
      },
      {
        name: 'taxonomicG',
        label: 'Grupo Taxonómico',
        value: this.patientHistory?.entryRecord?.taxonomicGroup ?
          taxonomicGroup[this.patientHistory.entryRecord?.taxonomicGroup!] : '-',
        ctrlName: '',
      },
      {
        name: 'origin',
        label: 'Procedencia',
        value: this.patientHistory?.specimen?.originId !== null &&
        this.patientHistory?.specimen?.originId !== undefined ?
          OriginSourceHashSpanish[this.patientHistory?.specimen?.originId] : '-',
        ctrlName: '',
      },
      {
        name: 'destination',
        label: 'Destino Inmediato',
        value: this.destination,
        ctrlName: '',
      },
      {
        name: 'incomeClassification',
        label: 'Clasificación de Ingreso',
        value: this.patientHistory?.seizureRecord?.incomeClassification
          ? SeizureIncomeHashSpanish[this.patientHistory.seizureRecord.incomeClassification]
          : '-',
        ctrlName: 'incomeClassification',
        ctrlValue: this.patientHistory?.seizureRecord?.incomeClassification ?
          this.patientHistory.seizureRecord?.incomeClassification : '-',
        options: this.incomeClassificationList,
      },
      {
        name: 'type',
        label: 'Tipo de Marcaje',
        value: this.patientHistory?.specimen?.markings[0]?.type
          ? this.markingTypesHash[this.patientHistory.specimen.markings[0].type]
          : '-',
        ctrlName: '',
      },
      {
        name: 'markingDate',
        label: 'Fecha',
        value: this.patientHistory?.specimen?.markings[0]?.markingDate
          ? this.datePipe.transform(this.patientHistory?.specimen?.markings[0]?.markingDate, 'dd/MM/yyyy')
          : '-',
        ctrlName: '',
      },
      {
        name: 'markingNo',
        label: 'No. de Chip',
        value: this.patientHistory?.specimen?.markings[0]?.markingNo
          ? this.patientHistory?.specimen.markings[0].markingNo
          : '-',
        ctrlName: '',
      }
    ];
    return fields;
  }

  get destination(): string {
    const destination = this.patientHistory?.captivityHistory?.destination;
    return DEPARTUREHashSpanish[destination || ''] || destination || '-';
  }

  public addChip() {
    const markingIds = this.dataSourceMarkings.map((m: SpecimenMarking) => m.markingNo)
    const markinValue = this.form.get('markingNo')?.value.trim();
    if (markingIds.includes(markinValue)) {
      this.dialogService.errorModal('El No. de Chip ya se encuentra registrado.');
      return;
    }
    this.addnNewChip = true;
    this.amountNewMarkings++
    const newChip = {
      type: this.form.get('type')?.value,
      createdAt: this.datePipe.transform(this.form.get('markingDate')?.value, 'dd/MM/yyyy'),
      markingDate: this.form.get('markingDate')?.value,
      markingNo: markinValue,
    }
    this.newMarkings.push(newChip);
    this.dataSourceMarkings.push({
      ...newChip,
      type: this.markingTypesHash[newChip.type],
    });
    this.dataSourceMarkings = this.dataSourceMarkings.map((element: any) => {
      return element
    });
  }

  validateKey(event: any) {
    if (event.keyCode === 32) {
      this.commonName.setValue(this.commonName.value + ' ');
      this.expansionPanel.open();
    }
  }
}

interface FieldInfo {
  name: string;
  label: string;
  value: any;
  ctrlName: string;
  ctrlValue?: any;
  options?: any[];
}
