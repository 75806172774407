import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import jwt_decode from 'jwt-decode';
import { ServerError } from '../../types/http.interfaces';
import {
  RecoveryMethod,
  TokenContent,
} from '../../entities/keycloak/keycloak.interfaces';
import { ContactPointSearchInput } from '../../entities/actor/contact-point/contact-point.model.entity';
import { DialogService } from '../dialog.service';

export enum AccountStatus {
  IDLE,
  RECOVERING,
  READY,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _ready: AccountStatus = AccountStatus.IDLE;

  private static sessionExpiredLimiter = 0;

  private static readonly practitionerSuffix = '|pract';

  private _requestingAccessToken: Promise<string | boolean> | null | undefined;

  public get ready() {
    return this._ready;
  }

  private defaultKcClientId: string = environment.keycloakClient;

  constructor(
    private http: HttpClient,
    public router: Router,
    private dialogService: DialogService
  ) {
    if (window['Cypress' as any]) {
      (window['AuthService'  as any] as any as AuthService)=this;
    }
  }

  /**
   * validates if recovery token is valid
   * @param code
   * @param recoveryMethod
   * @param contactPoint
   */
  public async validateRecoveryToken(
    code: string,
    recoveryMethod: RecoveryMethod,
    contactPoint?: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      let decoded = <any>jwt_decode(code);
      await this.http
        .post<any>(
          environment.api + '/actors-ms/auth/passwordRecovery/validate',
          {
            recoveryMethod: recoveryMethod,
            code: code,
            contactPoint: contactPoint,
            keycloakUserId: decoded?.body,
          },
          httpOptions
        )
        .toPromise();
    } catch (e) {
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'Invalid Code/User') {
          message = 'Código no válido';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * changePass change user password
   * @param code
   * @param recoveryMethod
   * @param contactPoint
   * @param newPassword
   */
  public async userPasswordRecoveryCode(
    code: string,
    recoveryMethod: RecoveryMethod,
    newPassword: string,
    contactPoint?: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      let decoded = <any>jwt_decode(code);
      await this.http
        .post<any>(
          environment.api + '/actors-ms/auth/passwordRecovery/use',
          {
            recoveryMethod: recoveryMethod,
            code: code,
            newPassword: newPassword,
            contactPoint: contactPoint,
            keycloakUserId: decoded?.body,
          },
          httpOptions
        )
        .toPromise();
    } catch (e) {
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (data === 'Invalid Code/User') {
          message = 'Código no válido';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * passRecovery send request to password recovery
   * @param recoveryMethod
   * @param sendChannel
   * @param contactPoint
   */
  public async requestPasswordRecoveryCode(
    recoveryMethod: RecoveryMethod,
    sendChannel: 'EMAIL',
    contactPoint: ContactPointSearchInput
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        skip: 'true',
      }),
    };
    try {
      await this.http
        .post<any>(
          environment.api + '/actors-ms/auth/passwordRecovery/request',
          {
            recoveryMethod: recoveryMethod,
            sendChannel: sendChannel,
            contactPoint: contactPoint,
            searchBy: 'CONTACT_POINT',
          },
          httpOptions
        )
        .toPromise();
    } catch (e) {
      console.error(e);
      let message = 'Error desconocido, vuelva a intentar';
      if (e?.error?.data) {
        const data = e.error.data;
        if (
          data === 'No actor found' ||
          data === 'No valid email to send link'
        ) {
          message = 'Correo no registrado en el sistema';
        } else if (data === 'Social account, cannot recover password') {
          message =
            'Tu cuenta fue creada con una red social, intenta con ellas';
        }
      }
      throw new Error(message);
    }
  }

  /**
   * passRecovery send request to password recovery
   * @param recoveryMethod
   * @param sendChannel
   * @param actorId
   */
  public async requestPasswordRecoveryLink(
    recoveryMethod: 'TOKEN',
    sendChannel: 'EMAIL',
    actorId: number
  ) {
    await this.http
      .post<any>(
        environment.api + '/actors-ms/auth/passwordRecovery/requestById',
        {
          recoveryMethod: recoveryMethod,
          sendChannel: sendChannel,
          actorId: actorId,
        }
      )
      .toPromise();
  }

  /**
   * login using password flow
   * @param username identication, email or cellphone
   * @param password user password
   */
  public async loginPasswordFlow(username: string, password: string) {
    try {
      let body = new HttpParams()
        .set('grant_type', 'password')
        .set('client_id', environment.keycloakClient)
        .set('client_secret', '')
        .set('username', username)
        .set('password', password)
        .set('scope', 'offline_access');
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      const result = await this.http
        .post<any>(
          environment.authService +
            '/realms/' +
            environment.keycloakRealm +
            '/protocol/openid-connect/token',
          body.toString(),
          httpOptions
        )
        .toPromise();
      this.saveTokens(result.access_token, result.refresh_token);
    } catch (e) {
      console.error(e);
      throw new Error(AuthService.validateLoginErrors(e, false));
    }
  }

  private static validateLoginErrors(e: any, isSocial: boolean) {
    let message = 'Error desconocido, vuelva a intentar';
    if (e?.error?.error_description) {
      const error_description = e.error.error_description;
      if (error_description === 'Invalid user credentials') {
        message = 'Credenciales no válidas';
      } else if (
        error_description === 'Account is not fully set up' ||
        error_description === 'Invalid Token'
      ) {
        message = 'Debes abrir el link de activación antes de iniciar sesión';
      }else if(error_description === 'Account disabled'){
        message = 'Su usuario se encuentra Inactivo, Por favor comuníquese con el Administrador del Sistema';
      }
    }
    return message;
  }

  /**
   * returns user actor id from token
   */
  public getActorId(): string {
    const result = this.getDecodedToken('ACCESS');
    if (result) {
      return result.actorId;
    } else {
      throw new Error('Invalid access token');
    }
  }

  /**
   * updates user password, important: keep in mind this will revoke all existing refresh tokens and you need to re login, this method will perform a silent login
   * @param oldPassword
   * @param newPassword
   */
  public async updatePassword(oldPassword: string, newPassword: string) {
    // await this.http.post<ServerResponse<any>>(environment.URLS.AuthMS + '/auth/updatePassword', {
    //   oldPassword: oldPassword,
    //   newPassword: newPassword
    // }).toPromise();
    // const tokenContent = <TokenContent>this.getDecodedAccessToken();
    // const result = await this.http.post<ServerResponse<any>>(environment.URLS.AuthMS + '/auth/login/direct', {
    //   username: tokenContent.preferred_username,
    //   password: newPassword
    // }).toPromise();
    // this.saveTokens(result.data.access_token, result.data.refresh_token);
  }

  /**
   * logout sign out and delete keycloak session
   * NOTE: MUST NOT DIRECTLY USE, intended to be used by profile service
   */
  public async logOut() {
    // await this.http.delete(environment.URLS.LogicPYP + "/self/fcmToken", {headers: this.headers}).toPromise();
    try {
      // @ts-ignore
      let body: HttpParams;
      const token = this.getToken('REFRESH');
      let test: string | number | boolean = token ? token : '';
      body = new HttpParams()
        .set('client_id', environment.keycloakClient)
        .set('refresh_token', test);
      body = body.set('scope', 'offline_access');
      const httpOptions = {
        headers: new HttpHeaders({
          skip: 'true',
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      };
      await this.http
        .post<any>(
          environment.authService +
            '/realms/' +
            environment.keycloakRealm +
            '/protocol/openid-connect/logout',
          body.toString(),
          httpOptions
        )
        .toPromise();
    } catch (e) {
      const error = <ServerError>e;
      if (error.error.error !== 'Sesssion not found') {
        throw e;
      }
    }
    this.deleteTokens();
  }

  public goToIndex(): void {
    this.router.navigateByUrl('/');
  }

  /////////////////////////////////////////////////////////////////////////////
  ////               all related to access and refresh token               ////
  /////////////////////////////////////////////////////////////////////////////

  /**
   * returns user groups
   */
  public getUserGroups(): string[] {
    const decodedToken = this.getDecodedToken('ACCESS');
    if (decodedToken) {
      return decodedToken.groups;
    }
    return ['-'];
  }

  /**
   * validates if has at least one of the passed roles
   * @param roles
   */
  public async validateRoles(roles: string[]) {
    let allowed = false;
    // first validate valid access
    if (!this.checkToken('ACCESS')) {
      // if not valid, validate refresh and update access
      if (this.checkToken('REFRESH')) {
        try {
          await this.refreshToken();
        } catch (e) {
          console.error(e);
          this.deleteTokens();
          this.showSessionExpired();
          return false;
        }
      }
    }
    // validate role
    let token = this.getDecodedToken('ACCESS');
    if (!token || !token.resource_access) {
      await this.refreshToken();
      token = this.getDecodedToken('REFRESH');
    }
    if (!token) {
      return false;
    }
    const tokenRoles = token.resource_access[this.defaultKcClientId].roles;
    if (tokenRoles) {
      for (const guardRol of roles) {
        for (const tokenRol of tokenRoles) {
          if (tokenRol === guardRol) {
            allowed = true;
            break;
          }
        }
      }
    }

    return allowed;
  }

  /**
   * behaviour when refresh token expired
   */
  public showSessionExpired() {
    if (AuthService.sessionExpiredLimiter === 0) {
      this.dialogService.errorModal(
        'Error, Su sesión ha expirado, vuelva a iniciarla.'
      );
      AuthService.sessionExpiredLimiter = Date.now();
      setTimeout(() => {
        AuthService.sessionExpiredLimiter = 0;
      }, 1000);
    }
  }

  /**
   * refreshToken generate new access token from refresh token
   */
  public async refreshToken() {
    if (this._requestingAccessToken) {
      return this._requestingAccessToken;
    }
    this._requestingAccessToken = this.doRefreshToken();
    return this._requestingAccessToken;
  }

  // @ts-ignore
  private async doRefreshToken(attempt = 0): Promise<string | boolean> {
    try {
      await new Promise((resolve) => setTimeout(resolve, attempt * 100 + 1));
      const refreshToken = this.getToken('REFRESH');
      if (refreshToken) {
        let body = new HttpParams()
          .set('grant_type', 'refresh_token')
          .set('client_id', environment.keycloakClient)
          .set('client_secret', '')
          .set('refresh_token', refreshToken);
        const httpOptions = {
          headers: new HttpHeaders({
            skip: 'true',
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
        };
        const result = await this.http
          .post<any>(
            environment.authService +
              '/realms/' +
              environment.keycloakRealm +
              '/protocol/openid-connect/token',
            body.toString(),
            httpOptions
          )
          .toPromise();
        this.saveTokens(result.access_token, result.refresh_token);
        this._requestingAccessToken = null;
      } else {
        throw new Error('Invalid refresh token');
      }
    } catch (e) {
      if (attempt < 5) {
        return this.doRefreshToken(attempt + 1);
      } else {
        this._requestingAccessToken = null;
        throw e;
      }
    }
  }

  /**
   * return decoded access or refresh token or false if not valid/present
   */
  public getDecodedToken(type: 'ACCESS' | 'REFRESH'): TokenContent | false {
    try {
      const token = this.getToken(type);
      return jwt_decode(token !== null ? token : '');
    } catch (error) {
      return false;
    }
  }

  /**
   * validate if access or refresh token is valid
   */
  public checkToken(type: 'ACCESS' | 'REFRESH'): boolean {
    const token: any = this.getDecodedToken(type);
    if (type === 'ACCESS') {
      if (token) {
        return Date.now() < token.exp * 1000 - 30000;
      }
    } else if (type === 'REFRESH') {
      if (token) {
        if (!token.exp) {
          return true;
        }
        return Date.now() < token.exp * 1000;
      }
    }
    return false;
  }

  /**
   * saveTokens save tokens into local storage
   * @param access access token
   * @param refresh refresh token
   */
  public saveTokens(access: string, refresh: string) {
    localStorage.setItem('atoken', access);
    localStorage.setItem('rtoken', refresh);
  }

  /**
   * returns access or refresh token
   */
  public getToken(type: 'ACCESS' | 'REFRESH') {
    if (type === 'ACCESS') {
      return localStorage.getItem('atoken')
        ? localStorage.getItem('atoken')
        : '';
    } else if (type === 'REFRESH') {
      return localStorage.getItem('rtoken')
        ? localStorage.getItem('rtoken')
        : '';
    }
    return '';
  }

  /**
   * deletes access and refresh token from local storage
   */
  public deleteTokens() {
    localStorage.removeItem('atoken');
    localStorage.removeItem('rtoken');
  }
}
