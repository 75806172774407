import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ServerResponse} from '../types/http.interfaces';
import {FormGroup} from '@angular/forms';
import {AdministrationInterface} from '../types/administration.interface';
import {catchError} from "rxjs/operators";
import {of} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RegionalDirectionService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(private http: HttpClient) {
  }

  public async getRegionalDirection(
    includeInactive: boolean = false
  ): Promise<AdministrationInterface[]> {
    let res;
    if (includeInactive) {
      res = await this.http
        .get<ServerResponse<AdministrationInterface[]>>(
          `${this._baseUrl}/regionals`
        )
        .toPromise();
    } else {
      let params = new HttpParams();
      params = params.set('state', true);
      res = await this.http
        .get<ServerResponse<AdministrationInterface[]>>(
          `${this._baseUrl}/regionals`,
          {params}
        )
        .toPromise();
    }
    return res.data;
  }

  public async getRegionalDirectionById(
    id: number
  ): Promise<AdministrationInterface> {
    const res = await this.http
      .get<ServerResponse<AdministrationInterface>>(
        `${this._baseUrl}/regionals/${id}`
      )
      .toPromise();
    return res.data;
  }

  // send new regional direction
  public async postNewRegionalDirection(data: FormGroup) {
    return await this.http.post(`${this._baseUrl}/regionals`, data).toPromise();
  }

  //service to get data agree the filter
  public async getRegionDirectionFilter(
    params?: HttpParams
  ): Promise<AdministrationInterface[]> {
    const res = await this.http
      .get<ServerResponse<AdministrationInterface[]>>(
        `${this._baseUrl}/regionals`,
        {params}
      )
      .toPromise();
    return res.data;
  }

  //Method delete one row
  public async deleteRegisterRegionalDirection(id: number) {
    return this.http.delete(`${this._baseUrl}/regionals/${id}`).pipe(
      catchError((err) => of(undefined))
    ).toPromise();
  }

  public async downloadRegionalDirection(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http
        .get<any>(`${this._baseUrl}/regionals/download`, {
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise();
    }
    return result;
  }

  // update data by id
  public async updateRegionalDirectionRecords(
    form: AdministrationInterface,
    id: number
  ) {
    return await this.http
      .patch<AdministrationInterface>(`${this._baseUrl}/regionals/${id}`, form)
      .toPromise();
  }

  /**
   * Method to update only status
   * @param id
   * @param stateSearch
   */
  public async updateOnlyStatus(id: number, stateSearch: boolean) {
    return await this.http
      .patch<AdministrationInterface>(`${this._baseUrl}/regionals/${id}`, {
        state: stateSearch,
      })
      .toPromise();
  }
}
