import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../services/dialog.service';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';
import {
  ORAL_MUCOSA_TRANSLATE,
  PUPILS_TRANSLATE,
  VitalSigns,
} from '../../types/vital-signs.interface';

@Component({
  selector: 'app-vital-signs',
  templateUrl: './add-edit-vital-signs.component.html',
  styleUrls: ['./add-edit-vital-signs.component.scss'],
})
export class AddEditVitalSignsComponent implements OnInit {
  public saveVitalSignsForm: FormGroup;
  public title: string = '';
  public ORAL_MUCOSA_TRANSLATE = ORAL_MUCOSA_TRANSLATE;
  public PUPILS_TRANSLATE = PUPILS_TRANSLATE;

  constructor(
    public dialogRef: MatDialogRef<AddEditVitalSignsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; data: Record<keyof VitalSigns, string> },
    private fb: FormBuilder,
    private dialogService: DialogService
  ) {
    this.title = data.title;
  }

  async ngOnInit() {
    this.buildSaveVitalSignsForm();
    if (this.data.data) {
      this.saveVitalSignsForm.controls.timeMin.setValue(
        Number(this.data.data.time)
      );
      this.saveVitalSignsForm.controls.FRC.setValue(
        (this.data.data.FRC === 'Presente') + ''
      );
      this.saveVitalSignsForm.controls.FRR.setValue(
        (this.data.data.FRR === 'Presente') + ''
      );
      this.saveVitalSignsForm.controls.pulse.setValue(
        (this.data.data.pulse === 'Presente') + ''
      );
      this.saveVitalSignsForm.controls.oralMucosa.setValue(
        ORAL_MUCOSA_TRANSLATE.find(
          (value) => value.label === this.data.data.oralMucosa
        )!.value
      );
      this.saveVitalSignsForm.controls.cornealReflex.setValue(
        (this.data.data.cornealReflex === 'Presente') + ''
      );
      this.saveVitalSignsForm.controls.pupils.setValue(
        PUPILS_TRANSLATE.find((value) => value.label === this.data.data.pupils)!
          .value
      );
      this.saveVitalSignsForm.controls.painReflex.setValue(
        (this.data.data.painReflex === 'Presente') + ''
      );
      this.saveVitalSignsForm.controls.rigorMortis.setValue(
        this.data.data.rigorMortis
      );
    }
  }

  close(data?: VitalSigns) {
    this.dialogRef.close(data);
  }

  buildSaveVitalSignsForm() {
    this.saveVitalSignsForm = this.fb.group({
      timeMin: new FormControl('', Validators.required),
      FRC: new FormControl('', Validators.required),
      FRR: new FormControl('', Validators.required),
      pulse: new FormControl('', Validators.required),
      oralMucosa: new FormControl('', Validators.required),
      cornealReflex: new FormControl('', Validators.required),
      pupils: new FormControl('', Validators.required),
      painReflex: new FormControl('', Validators.required),
      rigorMortis: new FormControl('', Validators.required),
    });
  }

  resetForm() {
    this.saveVitalSignsForm.reset();
  }

  onSubmit() {
    this.setDataUserForm();
  }

  cancel() {
    this.close();
  }

  public setDataUserForm() {
    if (this.saveVitalSignsForm.valid) {
      const FRC: boolean = this.saveVitalSignsForm.get('FRC')?.value === 'true';
      const FRR: boolean = this.saveVitalSignsForm.get('FRR')?.value === 'true';
      const pulse: boolean =
        this.saveVitalSignsForm.get('pulse')?.value === 'true';
      const cornealReflex: boolean =
        this.saveVitalSignsForm.get('cornealReflex')?.value === 'true';
      const painReflex: boolean =
        this.saveVitalSignsForm.get('painReflex')?.value === 'true';
      const vitalSigns: VitalSigns = {
        time: Number(this.saveVitalSignsForm.get('timeMin')?.value),
        FRC: FRC,
        FRR: FRR,
        pulse: pulse,
        oralMucosa: this.saveVitalSignsForm.get('oralMucosa')?.value,
        cornealReflex: cornealReflex,
        pupils: this.saveVitalSignsForm.get('pupils')?.value,
        painReflex: painReflex,
        rigorMortis: this.saveVitalSignsForm.get('rigorMortis')?.value,
      };
      this.close(vitalSigns);
    } else {
      const errorVitalSignsForm: AllValidationErrors = getFormValidationErrors(
        this.saveVitalSignsForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorVitalSignsForm));
    }
  }
}
