import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent implements OnInit {
  @Input() panelOpenState: boolean = false;
  @Input() information: { key: string; value: string | undefined }[];

  constructor() {}

  ngOnInit(): void {}
}
