import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {getError} from './error';
import {Menu} from "../types/menu.interface";

export interface AllValidationErrors {
  control_name: string;
  error_name: string;
  error_value: any;
}

export interface FormGroupControls {
  [key: string]: AbstractControl;
}

export function getFormValidationErrors(
  controls: FormGroupControls
): AllValidationErrors[] {
  let errors: AllValidationErrors[] = [];
  Object.keys(controls).forEach((key) => {
    const control = controls[key];
    if (control instanceof FormGroup) {
      errors = errors.concat(getFormValidationErrors(control.controls));
    }
    const controlErrors: ValidationErrors = controls[key].errors as any;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((keyError) => {
        errors.push({
          control_name: key,
          error_name: keyError,
          error_value: controlErrors[keyError],
        });
      });
    }
  });
  return errors;
}

export function searchFormError(error: AllValidationErrors, errors?: Menu[], formatNames?: { [key: string]: string }) {
  let text;
  switch (error.error_name) {
    case 'nonzero':
      text = `El campo ${getError(error.control_name, errors)} debe ser mayor a cero`;
      break;
    case 'required':
      text = `El campo ${getError(error.control_name, errors)} es requerido`;
      break;
    case 'pattern':
      text = `El campo ${getError(error.control_name, errors)} no cumple con el formato ${getFormatInputError(error.error_value.requiredPattern)}`;
      break;
    case 'alphanumeric':
      text = `El campo ${getError(error.control_name, errors)} debe ser alfanumérico`;
      break;
    case 'email':
      text = `${getError(
        error.control_name, errors
      )} no tiene el formato de correo correcto`;
      break;
    case 'minlength':
      text = `${getError(error.control_name, errors)} demasiado corto! Tamaño requerido: ${error.error_value.requiredLength}`;
      break;
    case 'maxlength':
      text = `${getError(
        error.control_name, errors
      )} demasiado largo! Tamaño máximo de caracteres: ${
        error.error_value.requiredLength
      }`;
      break;
    case 'areEqual':
      text = `${getError(error.control_name, errors)} debe ser igual`;
      break;
    case 'maxLengthFile':
      text = `El campo ${getError(
        error.control_name, errors
      )} no puede contener más de ${error.error_value} archivos`;
      break;
    case 'bigger':
      text = `El campo ${getError(
        error.control_name, errors
      )} no puede ser mayor a la cantidad disponible`;
      break;
    case 'invalidDate':
      text = `El campo Fecha no puede estar vacío`;
      break;
    case 'invalidNameLength':
      text = `El campo Nombre debe tener minimo nombre y apellido`;
      break;
    default:
      text = `${getError(error.control_name, errors)}: ${getError(
        error.control_name, errors
      )}: ${error.error_value}`;
  }
  return text;
}

function getFormatInputError(format: string) {
  const pattern = [{value: '^[A-Za-z0-9À-ÿ ]+$', name: 'alfanumérico'},
    {value: '^[A-Za-zÀ-ÿ ]+$', name: 'alfabético'},
    {value: '^[a-zA-Z]+$', name: 'alfabético'},
    {value: '^[a-zA-Z0-9]+\s$', name: 'alfanumérico'},
    {value: '^[a-zA-Z0-9]+\s$', name: 'alfanumérico'},
    {value: '/^-?\\d*\\.?\\d+$/', name: 'numérico'},
    {value: '/^\\d+\\.\\d{2}$/', name: 'numérico'},
    {value: '/^-?\\d*\\.?\\d+$/', name: 'numérico'},
    {value: '/^\\d+(\\.\\d{0,2})?$/', name: 'numérico'},
    {value: '/^\\d*\\.?\\d+$/', name: 'numérico'}];
  return pattern.find(pattern => pattern.value === format)?.name ?? 'texto';
}
