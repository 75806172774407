<div class="container-dialog">
<div class="header-dialog">
    <span class="text-m-16-white name-dialog">{{data.name}}</span>
    <button class="text-m-16-white close-button" mat-dialog-close>X</button>
  </div>
<div class="container-image">
  <img *ngIf="fileType(data.name)" class="size-image" [src]="data.img" alt="">
  <video class="size-image" *ngIf="fileType(data.name, 'film')" [src]="data.img" controls>
    Este navegador no soporta la etiqueta <code>video</code>
  </video>
</div>
</div>
