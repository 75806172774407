import { ClinicHistoryDB } from 'src/app/medical-history/types/medical-history.interface';
import { Actor, EntityActor } from 'src/app/shared/entities/actor/actor.model.entity';
import { SelectorOption } from "../../shared/types/selector.interface";

export interface Therapeutic {
  date: string;
  request: string;
  registration: string;
  clinicHistoryId: number;
  registeringActorId: number;
  medicines: Medicines[];
  registeringActor?: EntityActor;
}

export interface Critical {
  date: string;
  nivel: number;
  clasification: string;
  dx: string;
}
export interface TherapeuticDB {
  id: string;
  date: string;
  request: string;
  registration: string;
  medicines: Medicines[];
  clinicHistory: ClinicHistoryDB;
  registeringActor: Actor;
  total?: number;
}

export interface TherapeuticUpdate {
  date: string;
  request: string;
  registration: string;
  medicines: Medicines[];
  deleteOlderTherapeuticMedicines: boolean;
}

export interface Medicines {
  id?: number;
  dose: number;
  denomination: string;
  time: number;
  timeUnit: string;
  indication: string;
  frequency: number;
  frequencyUnit: string;
  administrationRoute: string;
  quantity: number;
  supplies: Supplies[];
  drug?: Drug;
  drugId?: number;
}

export interface Drug {
  id:           number;
  denomination: string;
  state:        boolean;
  type:         Type;
  quantity:     number;
  unit:         Unit;
  value:        number;
  createdAt:    Date;
}

export interface Type {
  id:          number;
  name:        string;
  description: null;
  createdAt:   Date;
  updatedAt:   Date;
  deletedAt:   null;
}

export interface Unit {
  id:        number;
  name:      string;
  state:     boolean;
  createdAt: Date;
}


export interface Supplies {
  id?: number;
  date: string;
  hour: string;
  observation: string;
  createdById?: number;
  createdBy?: Actor;
  [k: string]: string | undefined | number | Actor;
}

export enum TypeSupplies {
  HOSPITALIZATION = 'hospitalization',
  QUARANTINE = 'quarantine',
  REHABILITATION = 'rehabilitation',
}

export enum TimeUnitsTherapy {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export enum TimeUnitsAdministrationRoute {
  ORAL = 'oral',
  INTRAVENOUS = 'intravenous',
  INTRAMUSCULAR = 'intramuscular',
  SUBCUTANEOUS = 'subcutaneous',
  SUBLINGUAL = 'sublingual',
  OCULAR = 'ocular',
  OTIC = 'otic',
  NASAL = 'nasal',
  RECTAL = 'rectal',
  TOPIC = 'topic',
  TRANSDERMAL = 'transdermal'
}

export const TimeUnitsAdministrationRouteHashSpanish: {
  [UnitsAdministrationRoute: string]: string;
} = {
  oral: 'Oral',
  intravenous: 'Intravenosa',
  intramuscular: 'Intramuscular',
  subcutaneous: 'Subcutánea',
  sublingual: 'Sublingual',
  ocular: 'Ocular',
  otic: 'Ótica',
  nasal: 'Nasal',
  rectal: 'Rectal',
  topic: 'Tópico',
  transdermal: 'Transdérmico',
};

export const TimeUnitsTherapyHashSpanish: {
  [TimeUnitsTherapy: string]: string;
} = {
  minutes: 'Minuto(s)',
  hours: 'Hora(s)',
  days: 'Día(s)',
};

export const TypeSuppliesHashSpanish: {
  [TypeSupplies: string]: string;
} = {
  hospitalization: 'Hospitalización',
  quarantine: 'Cuarentena',
  rehabilitation: 'Rehabilitación',
};

export const requestList: SelectorOption<TypeSupplies>[] = [
  { value: TypeSupplies.QUARANTINE, label: 'Cuarentena' },
  { value: TypeSupplies.HOSPITALIZATION, label: 'Hospitalización' },
  { value: TypeSupplies.REHABILITATION, label: 'Rehabilitación' },
];
