import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogInterface} from '../../types/dialog.interface';
import {FocusMonitor} from '@angular/cdk/a11y';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit {
  public only : boolean;
  public message: string;
  public date: string;
  public icon: string;
  public isButtons: boolean;
  public orientationIconTop: boolean;
  public subMessage: string;
  public isActions: boolean;
  public showErrorDate: boolean;
  public typeConfirm: string;
  public btnText: { confirm?: string, dismiss?: string } = {confirm: 'ACEPTAR', dismiss: 'CANCELAR'}

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInterface,
    private _focusMonitor: FocusMonitor
  ) {
    this.only = data.only;
    this.icon = data.icon;
    this.message = data.message;
    this.subMessage = data.subMessage;
    this.showErrorDate = data.showErrorDate;
    this.date = data.date;
    this.isButtons = data.isButtons;
    this.orientationIconTop = data.orientationIconTop;
    this.isActions = data.isActions;
    this.typeConfirm = data.typeConfirm;
    if (this.data.btnText?.confirm)
      this.btnText.confirm = this.data.btnText.confirm;
    if (this.data.btnText?.dismiss)
      this.btnText.dismiss = this.data.btnText.dismiss;
  }

  ngOnInit(): void {
    if (this.typeConfirm === 'success' || this.typeConfirm === 'info') {
      setTimeout(() => {
        this.close();
      }, 3500);
    }
  }

  ngAfterViewInit() {
    const element: any = document.getElementById('cancel');
    this._focusMonitor.stopMonitoring(element);
  }

  close(data?: boolean) {
    this.dialogRef.close(data);
  }

  cancel() {
    this.close(false);
  }

  onSubmit() {
    this.close(true);
  }
}
