<div class="scrollbar width-100">

  <table class="table width-100 mb-3" [ngClass]="{'activeRow': markSelectedRow}" mat-table [dataSource]="dataTable"
         matSort>
    <ng-container matColumnDef="{{ column.key }}" *ngFor="let column of displayedColumns; let i = index">
      <ng-container *ngIf="column.key !== 'actions'">
        <th mat-header-cell *matHeaderCellDef class="text-m-14-dark-blue" [ngStyle]="style"
            [ngClass]="{'centerContent': centerContent}" mat-sort-header data-cy="table-title">
          {{ typed(column.value) == 'number' ? (column.value | number) : column.value }}
        </th>
        <td mat-cell *matCellDef="let element" [matTooltip]="getTooltipText(element, column.key)"
            class="text-m-14-dark-gray" [ngClass]="{'centerContent': centerContent}">
          {{ typed(element[column.key]) == 'object' ? destructuringDate(element[column.key]) :
          typed(element[column.key]) == 'number' ? (element[column.key] | number) : element[column.key]}}
        </td>
      </ng-container>
      <ng-container *ngIf="column.key === 'actions'">
        <th mat-header-cell *matHeaderCellDef class="text-m-14-blue" [ngStyle]="style"
            [ngClass]="{'centerContent': centerContent}" data-cy="table-title">
          {{ column.value}}
        </th>
        <td mat-cell *matCellDef="let element; let j = index">
          <div class="action" *ngIf="hiddenActionFirstElement ? j > 0 : true"
               [ngClass]="{'centerContent': centerContent}">
            <button mat-mini-fab (click)="onAction(element, action.action, j)"
                    *ngFor="let action of ( element['overrideActions'] ? element['overrideActions'] : actions)"
                    class="action-btn" [matTooltip]="action.tooltip" matTooltipClass="action-tooltip">
              <i class="action-icon material-icons-two-tone"
                 *ngIf="action.icon!='pdf-table' && action.icon!='xls-table'"
                 data-cy="table-action-btn"> {{action.icon}} </i>
              <mat-icon [svgIcon]="'pdf-table'" *ngIf="action.icon=='pdf-table'" data-cy="table-pdf-btn"></mat-icon>
              <mat-icon [svgIcon]="'xls-table'" *ngIf="action.icon=='xls-table'"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="keys"></tr>
    <tr mat-row *matRowDef="let row; columns: keys"></tr>
  </table>
</div>
<div class="{{showDownloadExcel ?  'flex-row-space-between width-100 ':  'flex-row-end width-100'}}">
  <div class="icon-container-center cursor-excel" *ngIf="showDownloadExcel && data && data.length > 0 ">
    <a (click)="getValuesByParams()" class="mr-1 cursor-excel">
      {{nameExcel}} Excel
      <mat-icon svgIcon="xls-table"></mat-icon>
    </a>
  </div>
  <mat-paginator *ngIf="data && data.length > 0  && !hidePaginator"
                 showFirstLastButtons
                 [pageSize]="10"
                 [length]="data?.length"
                 [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
<div class="{{showDownloadPDF ?  'flex-row-space-between width-100 ':  'flex-row-end width-100'}}">
  <div class="icon-container-center cursor-excel" data-cy="pdf-download" *ngIf="showDownloadPDF && data && data.length > 0 ">
    <a (click)="getValuesByParamsPDF()" class="mr-1 cursor-excel">
      Exportar PDF
      <mat-icon svgIcon="pdf-table"></mat-icon>
    </a>
  </div>
</div>
