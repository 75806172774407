import {Injectable} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  private _platePattern: string = '^[a-zA-Z0-9]{6}$';
  private _alphanumericPattern: string = '[a-zA-Z0-9]+\s';
  private _alphabeticPattern: string = '[a-zA-Z]+';
  private _alphabeticWithAccentsPattern: string = '[A-Za-zÀ-ÿ ]+';
  private _alphabeticWithAccentsAndNumberPattern: string = '[A-Za-z0-9À-ÿ ]+';
  private _numberPattern: string = '[0-9]+';
  private _coordinatePattern: RegExp = /^-?\d*\.?\d+$/;
  private _positiveDecimalNumber: RegExp = /^\d*\.?\d+$/;
  private _decimalNumber: RegExp = /^-?\d+(\.\d{1,2})?$/;
  private _positiveTwoDecimalNumber: RegExp = /^\d+(\.\d{0,2})?$/;
  private _datePattern: RegExp = /^(\d{4})(\/|-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])$/;
  private _emailPattern: RegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private _RacionalNumber: RegExp = /^[+-]?\d+(\.?\d+)?$/;
  private _RacionalNumberSymbol: RegExp = /[\+\-\.]|[0-9]/;
  private _PositiveDecimalNumberSymbol: RegExp = /[\.]|[0-9]/;
  private _date: string = '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\d{4}';
  private _exceptionForId: number = -1;

  get exceptionForId() {
    return this._exceptionForId;
  }

  get expressionDecimalNumber() {
    return this._decimalNumber;
  }

  get date() {
    return this._date;
  }

  get positiveTwoDecimalNumber() {
    return this._positiveTwoDecimalNumber;
  }

  get _positiveDecimalNumberPattern() {
    return this._positiveDecimalNumber;
  }

  get coordinatePattern() {
    return this._coordinatePattern;
  }

  get plateValidator(): string {
    return this._platePattern;
  }

  get numberValidator(): string {
    return this._numberPattern;
  }

  get dateValidator(): RegExp {
    return this._datePattern;
  }

  get alphabeticValidator(): string {
    return this._alphabeticPattern;
  }

  get alphabeticWithAccentsValidator(): string {
    return this._alphabeticWithAccentsPattern;
  }

  get alphabeticWithAccentsAndNumberValidator(): string {
    return this._alphabeticWithAccentsAndNumberPattern;
  }

  get RacionalNumberValidator(): RegExp {
    return this._RacionalNumber;
  }

  /**
   * Verifies that the input only accept alphanumeric values
   * Not accept: !@#$%^&*()_+?"><:{}][ etc
   */
  alphanumericValidator(control: FormControl) {
    const alphanumericRegExp: RegExp = /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ0-9 ]*$/;
    const value: string = control.value;

    if (value && !alphanumericRegExp.test(value)) {
      return {alphanumeric: true};
    }
    return null;
  }

  validateCoordinate(event: any): boolean {
    const MIN_CHARCODE = 57;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const POINT = 190;
    const BAR = 189;
    const SPACE_NUMPAD = 32;
    const charCode = event.keyCode;
    return !(charCode > MIN_CHARCODE && charCode !== POINT && charCode !== BAR && (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD) || charCode === SPACE_NUMPAD);
  }

  validatePositiveNumber(event: any): boolean {
    const MIN_CHARCODE = 57;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const POINT = 190;
    const SPACE_NUMPAD = 32;
    const charCode = event.keyCode;
    return !(charCode > MIN_CHARCODE && charCode !== POINT && (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD) || charCode === SPACE_NUMPAD);
  }

  get emailValidator(): RegExp {
    return this._emailPattern;
  }

  /**
   * Verifies that the input only accept numbers
   */
  public numberOnly(event: any): boolean {
    const MIN_CHARCODE = 57;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const SPACE_NUMPAD = 32;
    const charCode = event.keyCode;
    return !(charCode > MIN_CHARCODE && (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD) || charCode === SPACE_NUMPAD);
  }

  public numberOnlyNo0(event: any): boolean {
    const MIN_CHARCODE = 57;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const SPACE_NUMPAD = 32;
    const charCode = event.keyCode;
    const key = event.key;
    if (key === '0') {
      return false;
    }
    return !(charCode > MIN_CHARCODE && (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD) || charCode === SPACE_NUMPAD);
  }
  /**
   * Verify that the input only accepts numbers and avoids displaying them in the input
   */
  public onlyNumber(event: any) {
    return (/^\d+$/.test(event.key)) || event.key == 'Backspace';
  }

  /**
   * Verifies that the input only accept alphanumeric
   */
  public alphanumericOnly(event: any): boolean {
    const ARROWS_MIN = 36;
    const ARROWS_MAX = 41;
    const MAYUS_MIN = 64;
    const MAYUS_MAX = 91;
    const NUMBER_MIN = 47;
    const NUMBER_MAX = 58;
    const NUMPAD_MIN = 95;
    const NUMPAD_MAX = 106;
    const LETTER_27_MAY = 209;
    const LETTER_27_MIN = 241;
    const DELETE = 8;
    const TAB = 9;
    const SUPR = 46;
    const SPACE = 32;
    const charCode = event.keyCode;
    return (charCode > MAYUS_MIN && charCode < MAYUS_MAX) ||
      (charCode > ARROWS_MIN && charCode < ARROWS_MAX) ||
      (charCode > NUMBER_MIN && charCode < NUMBER_MAX) ||
      (charCode > NUMPAD_MIN && charCode < NUMPAD_MAX) ||
      charCode === DELETE ||
      charCode === SPACE ||
      charCode === TAB ||
      charCode === SUPR ||
      charCode === LETTER_27_MIN ||
      charCode === LETTER_27_MAY;
  }

  public alphanumericOnlyStrict(event: any) {
    const key: string = event.key;
    const alphanumericRegex: RegExp = /^[a-zA-ZñÑ0-9]+$/;
    return alphanumericRegex.test(key);
  }

  /**
   * Verifies that the input only accepts text
   */
  public textOnly(event: any): boolean {
    const ARROWS_MIN = 36;
    const ARROWS_MAX = 41;
    const MAYUS_MIN = 64;
    const MAYUS_MAX = 91;
    const LETTER_27_MAY = 209;
    const LETTER_27_MIN = 241;
    const LETTER_27_SPEC = 192;
    const DELETE = 8;
    const TAB = 9;
    const SUPR = 46;
    const SPACE = 32;
    const charCode = event.keyCode;
    return (charCode > MAYUS_MIN && charCode < MAYUS_MAX) ||
      (charCode > ARROWS_MIN && charCode < ARROWS_MAX) ||
      charCode === DELETE ||
      charCode === TAB ||
      charCode === SUPR ||
      charCode === SPACE ||
      charCode === LETTER_27_SPEC ||
      charCode === LETTER_27_MIN ||
      charCode === LETTER_27_MAY;
  }

  /**
   * Verifies that the input only accept numbers or numbers with decimal
   */
  public numberWithDecimalOnly(event: any): boolean {
    const MIN_CHARCODE = 57;
    const POINT = 190;
    const MIN_NUMPAD = 96;
    const MAX_NUMPAD = 105;
    const SPACE_NUMPAD = 32;
    const charCode = event.keyCode;
    return !(charCode > MIN_CHARCODE && charCode !== POINT && (charCode < MIN_NUMPAD || charCode > MAX_NUMPAD) || charCode === SPACE_NUMPAD);
  }

  validateNumber(field: string, form: FormGroup) {
    const input = form.get(field);
    input?.setValue(input?.value.replace(/\D/g, ''));
  }


  validateNumberControl(form: FormControl) {
    form?.setValue(form?.value.toString().replace(/\D/g, ''));
  }

  clearInput(field: string, form: FormGroup) {
    const input = form.get(field);
    input?.setValue('');
  }

  cancelPasteInformation(event: ClipboardEvent) {
    event.preventDefault();
  }

  validateSymbol(field: string, form: FormGroup) {
    const input = form.get(field);
    input?.setValue(input?.value?.replace(/[¿'()´{}+¨*_:;?¡!"#$%&/=]$/g, ''));
  }

  validateText(field: string, form: FormGroup) {
    const input = form.get(field);
    input?.setValue(input?.value.replace(/[-¿'()´{}+0-9]$/g, ''));
  }

  validateAlphanumeric(field: string, form: FormGroup) {
    const input = form.get(field);
    input?.setValue(input?.value?.replace(/[-¿'()´{}+]$/g, ''));
  }

  validateRacionalNumberSymbol(field: string, form: FormGroup, event: KeyboardEvent) {
    const symbol = event.key
    const input = form.get(field);
    if (!this._RacionalNumberSymbol.test(symbol))
      event.preventDefault();
    input?.setValue(input.value, {emitEvent: false});
  }

  validatePositiveDecimalNumber(field: string, form: FormGroup, event: KeyboardEvent) {
    const symbol = event.key
    const input = form.get(field);
    if (!this._PositiveDecimalNumberSymbol.test(symbol)) {
      event.preventDefault();
      input?.setValue(input.value, {emitEvent: false});
    }
  }
}

export class CustomValidators {
  static minDate(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment.utc(control.value, 'DD-MM-YYYY');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment.utc(date, 'DD-MM-YYYY');
      return controlDate.isSameOrAfter(validationDate, 'day') ? null : {
        'date-minimum': {
          'date-minimum': validationDate.format('DD-MM-YYYY'),
          'actual': controlDate.format('DD-MM-YYYY')
        }
      };
    };
  }

  static maxDate(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      const controlDate = moment(control.value, 'DD-MM-YYYY');

      if (!controlDate.isValid()) {
        return null;
      }

      const validationDate = moment(date);

      return controlDate.isSameOrBefore(validationDate, 'day') ? null : {
        'date-maximum': {
          'date-maximum': validationDate.format('DD-MM-YYYY'),
          'actual': controlDate.format('DD-MM-YYYY')
        }
      };
    };
  }
}
