import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ProfessionalModel} from "../../models/professional.model";

@Component({
  selector: 'app-danger-level-info',
  templateUrl: './danger-level-info.component.html',
  styleUrls: ['./danger-level-info.component.scss']
})
export class DangerLevelInfoComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DangerLevelInfoComponent>
  ) { }

  ngOnInit(): void {
  }

  close(data?: ProfessionalModel) {
    this.dialogRef.close(data);
  }

}
