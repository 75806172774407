import { ClinicHistoryDB } from "../../../medical-history/types/medical-history.interface";
import { HospitalizationTrackingCreate } from "../../tracking-record/types/hospitalization-tracking.interface";
import { HospitalizationDischarge } from "../../check-out-record/types/hospitalization-check-out.interface";
import { EntityActor } from "../../../shared/entities/actor/actor.model.entity";

export interface HospitalizationIncome extends HospitalizationAdmissionCreate {
  id: number;
  createdAt: undefined | string,
  preliminaryClinicHistory: ClinicHistoryDB,
  hospitalizationMonitoring?: HospitalizationTrackingCreate[],
  hospitalizationDischarge?: HospitalizationDischarge;
  registeringActor?: EntityActor;
  chipNumber?: string;
  CNIF?: string;
  showButton: boolean;
}

export interface ValidationHospitalization {
  status: string;
  data: boolean;
}

export interface HospitalizationAdmissionCreate {
  date: string,
  time: string,
  weight: number,
  preliminaryClinicHistoryId: number,
  weightType: string,
  admissionType: IncomeType,
  dx: string,
  treatment: string
}

export enum IncomeType {
  INFECTIOUS = 'infectious',
  NOT_INFECTIOUS = 'no_infectious',
  POST_SURGICAL = 'post_surgical',
}

export const IncomeTypeHash: { [type: string]: string } = {
  'infectious': 'Infeccioso',
  'no_infectious': 'No infeccioso',
  'post_surgical': 'Post Quirúrgico'
};

export const taxonomicGroup: { [type: string]: string } = {
  bird: "Ave",
  mammal: "Mamífero",
  reptile: "reptil"
}
