export class AnimalFormModel {
  commonName: string;
  genusId: string;
  specieId: string;
  quantity: number;
  coordinatesX: string;
  coordinateY: string;
  medicalHistory: string;
  safeguardRecordNumber: string;
  seizureRecordNumber: string;

  constructor(
    commonName: string,
    genusId: string,
    specieId: string,
    quantity: number,
    coordinatesX: string,
    coordinateY: string,
    medicalHistory: string,
    safeguardRecordNumber: string,
    seizureRecordNumber: string
  ) {
    this.commonName = commonName;
    this.genusId = genusId;
    this.specieId = specieId;
    this.quantity = quantity;
    this.coordinatesX = coordinatesX;
    this.coordinateY = coordinateY;
    this.medicalHistory = medicalHistory;
    this.safeguardRecordNumber = safeguardRecordNumber;
    this.seizureRecordNumber = seizureRecordNumber;
  }
}
