import {Menu} from './menu.interface';

export const ERRORS: Menu[] = [
  {key: 'Name not provided', value: 'Debe proveer un nombre.'},
  {key: 'Invalid name', value: 'Nombre inválido.'},
  {key: 'This name already exists', value: 'El nombre ya existe.'},
  {key: 'Genus not found', value: 'Género no encontrado.'},
  {
    key: 'recordNumber not provided',
    value: 'Debe proveer un número de acta.',
  },
  {key: 'date not provided', value: 'Debe proveer una fecha.'},
  {key: 'file not provided', value: 'Debe proveer un archivo.'},
  {key: 'regional not provided', value: 'Debe proveer la regional.'},
  {key: 'specimens not provided', value: 'Debe proveer un especimen.'},
  {key: 'Invalid specimens data', value: 'Datos inválidos de especimen.'},
  {key: 'Invalid regional', value: 'Regional inválida.'},
  {key: 'Invalid quantity', value: 'Cantidad inválida.'},
  {key: 'Invalid date', value: 'Fecha inválida.'},
  {key: 'File must be valid', value: 'Extensión de archivo inválida.'},
  {
    key: 'This recordNumber already exists',
    value: 'El número de acta que se desea registrar ya existe.',
  },
  {key: 'Invalid id', value: 'Id invalido.'},
  {
    key: 'Safe Passage Record not found',
    value: 'No se encuentra el acta de salvoconducto.',
  },
  {
    key: 'Seizure Record not found',
    value: 'No se encuentra el ecta de incautación',
  },
  {key: 'Specie not found', value: 'Especie no encontrada.'},
  {key: 'commonName not provided', value: 'Debe proveer un Nombre Común.'},
  {key: 'Genus not found', value: 'Género no encontrado.'},
  {key: 'Specie not found', value: 'Especie no encontrada.'},
  {key: 'Invalid originId', value: 'Origen inválido.'},
  {key: 'Origin not found', value: 'Origen no encontrado.'},
  {key: 'Specimen not found', value: 'Sepcimen no encontrado.'},
  {key: 'Invalid weight', value: 'Peso inválido.'},
  {key: 'Invalid growth', value: 'Talla invalida.'},
  {key: 'Invalid status', value: 'Estatus inválido.'},
  {key: 'Invalid gender', value: 'Género inválido.'},
  {key: 'Invalid genusId', value: 'Identificador de género inválido.'},
  {key: 'Invalid specieId', value: 'Identificador de especie inválido'},
  {key: 'recordNumber', value: 'Número de acta'},
  {key: 'date', value: 'Fecha'},
  {key: 'dateOfDelivery', value: 'Fecha de Entrega'},
  {key: 'originEntity', value: 'Entidad de Procedencia'},
  {key: 'safeGuardRecordNumber', value: 'número de salvoconducto'},
  {key: 'genus', value: 'Género'},
  {key: 'specie', value: 'Especie'},
  {key: 'commonName', value: 'Nombre Común'},
  {key: 'scientificName', value: 'Nombre Científico'},
  {key: 'developmentStage', value: 'Etapa de Desarrollo'},
  {key: 'gender', value: 'Sexo'},
  {key: 'weight', value: 'Peso'},
  {key: 'typeWeight', value: 'Medida'},
  {key: 'statusAnimal', value: 'Estado del animal'},
  {key: 'origin', value: 'Procedencia'},
  {key: 'years', value: 'Años'},
  {key: 'months', value: 'Meses'},
  {key: 'days', value: 'Días'},
  {key: 'hours', value: 'Horas'},
  {key: 'numberPeople', value: 'Cantidad de personas en contacto'},
  {key: 'diet', value: 'Dieta'},
  {key: 'dietFrequency', value: 'frecuencia de la dieta'},
  {key: 'consumption', value: 'consumo'},
  {key: 'typeConfinement', value: 'tipo de encierro'},
  {key: 'contactOtherSpecies', value: 'contacto con otras especies'},
  {key: 'whichOtherSpecies', value: 'cuales otras especies'},
  {key: 'story', value: 'historia'},
  {key: 'initialBehavior', value: 'comportamiento inicial'},
  {key: 'whichBehavior', value: 'cuales comportamientos'},
  {key: 'levelAggressive', value: 'nivel de agresividad'},
  {key: 'levelDocility', value: 'nivel de mansedumbre'},
  {key: 'mutilation', value: 'presencia de mutilaciones'},
  {key: 'mutilationDescription', value: 'Descripción mutilación'},
  {
    key: 'mutilationRestriction',
    value: 'restricción (Manipulación del animal)',
  },
  {key: 'type', value: 'tipo'},
  {key: 'product', value: 'producto'},
  {key: 'dose', value: 'Dosis'},
  {key: 'administrationWay', value: 'Vías de administración'},
  {key: 'process', value: 'procedimiento'},
  {key: 'time', value: 'Tiempo'},
  {key: 'timeMin', value: 'Tiempo (Minutos)'},
  {key: 'problemsDuringRestraint', value: 'problemas durante la restricción'},
  {
    key: 'whichProblemsRestraint',
    value: 'cuales problemas durante la restricción',
  },
  {key: 'incomeClassification', value: 'clasificación de ingreso'},
  {key: 'authority', value: 'Autoridad Ambiental de la Jurisdicción'},
  {key: 'abnormalFindings', value: 'hallazgos anormales'},
  {key: 'destinyIndividual', value: 'destino del individuo'},
  {key: 'witchDestinyIndividual', value: 'cuales destinos'},
  {key: 'biologicalData', value: 'datos biológicos de la especie'},
  {key: 'habitsCustoms', value: 'Hábitos y costumbres'},
  {key: 'dangerLevel', value: 'nivel de peligrosidad'},
  {key: 'regional', value: 'regional'},
  {key: 'genusId', value: 'Género'},
  {key: 'specieId', value: 'especie '},
  {key: 'seizureRecordNumber', value: 'Número de acta de incautación'},
  {key: 'carRecordNumber', value: 'Número de radicación CAR'},
  {key: 'userName', value: 'Nombre'},
  {key: 'userLastName', value: 'Apellidos'},
  {key: 'position', value: 'Cargo'},
  {key: 'signature', value: 'Firma'},
  {key: 'entity', value: 'Entidad'},
  {key: 'documentType', value: 'Tipo de Documento'},
  {key: 'email', value: 'Correo Electrónico'},
  {key: 'document', value: 'Documento'},
  {key: 'phone', value: 'Teléfono'},
  {key: 'association', value: 'Vinculación'},
  {key: 'quantity', value: 'Cantidad'},
  {key: 'coordinatesX', value: 'Coordenada en X'},
  {key: 'coordinateY', value: 'Coordenada en Y'},
  {key: 'medicalHistory', value: 'No. Historia Clínica'},
  {key: 'safeguardRecordNumber', value: 'Número de salvoconducto'},
  {key: 'observation', value: 'Observación'},
  {key: 'observations', value: 'Observaciones'},

  //clinical- examination
  {key: 'CNIF', value: 'Código nacional de ingreso'},
  {key: 'numberChip', value: 'No. de Chip'},
  {key: 'clinicHistoryId', value: 'Historia clínica'},
  {key: 'amnesicIngressType', value: 'Tipo de ingreso'},
  {key: 'amnesicSource', value: 'Procedencia'},
  {key: 'amnesicExtractionSite', value: 'Lugar de Extracción'},
  {key: 'amnesicCaptivityTimeYears', value: 'años'},
  {key: 'amnesicCaptivityTimeMonths', value: 'meses'},
  {key: 'amnesicCaptivityTimeDays', value: 'días'},
  {key: 'amnesicCaptivityTimeHours', value: 'horas'},
  {
    key: 'amnesicSpeciesHaveHadContact',
    value: 'Especies con que ha estado en contacto',
  },
  {key: 'amnesicContactTime', value: 'Tiempo de contacto'},
  {
    key: 'amnesicIllnessesOfNearbyAnimals',
    value: 'Enfermedades de animales cercanos',
  },
  {
    key: 'amnesicCaptivityCharacteristics',
    value: 'Caracteristicas del cautiverio',
  },
  {key: 'amnesicDiet', value: 'Dieta suministrada'},
  {key: 'amnesicDietFrequency', value: 'Frecuencia de la dieta'},
  {key: 'amnesicConsumptionType', value: 'Consumo'},
  {key: 'amnesicAbnormalFindings', value: 'Hallazgos anormales'},
  {key: 'amnesicTreatments', value: 'Tratamientos instaurados y respuesta'},
  {key: 'amnesicVaccinesHistory', value: 'Historia de vacunaciones'},
  {key: 'amnesicRegionalManagement', value: 'Manejo en Dirección Regional'},
  {key: 'amnesicTransportType', value: 'Tipo de transporte'},
  {key: 'amnesicTransportTime', value: 'Tiempo de transporte'},
  {key: 'amnesicTransportDiet', value: 'Dieta'},
  {key: 'amnesicTransportTemperature', value: 'Temperatura'},
  {key: 'amnesicTransportHydration', value: 'Hidratación'},
  {key: 'amnesicTransportObservations', value: 'Observaciones'},
  {key: 'physicalTemperature', value: 'Temperatura'},
  {key: 'physicalCardiacFrequency', value: 'Frecuencia cardiaca'},
  {key: 'physicalBreathingFrequency', value: 'Frecuencia respiratoria'},
  {key: 'physicalMucousMembranes', value: 'Membranas Mucosas'},
  {key: 'physicalCapillaryFillTime', value: 'Tiempo de llenado capilar'},
  {key: 'physicalTemperament', value: 'Temperamento'},
  {key: 'physicalWeight', value: 'Peso'},
  {key: 'physicalWeightMeasurementType', value: 'Medida'},
  {key: 'physicalCondition', value: 'Condición corporal'},
  {key: 'physicalAttitude', value: 'Actitud'},
  {key: 'physicalO2Saturation', value: 'Saturación O²'},
  {key: 'physicalBloodPressure', value: 'Presión Arterial mmHg'},
  {key: 'physicalTRIAGEClassification', value: 'Clasificación Triage'},
  {key: 'physicalExamContention', value: 'Contención para examen'},
  {key: 'physicalProtocol', value: 'Protocolo'},
  {key: 'physicalGeneralState', value: 'Estado general'},
  {key: 'physicalHydration', value: 'Hidratación'},
  {key: 'physicalIntegument', value: 'Tegumento'},
  {key: 'physicalCardiovascularSystem', value: 'Sistema Cardiovascular'},
  {key: 'physicalRespiratorySystem', value: 'Sistema respiratorio'},
  {key: 'physicalDigestiveSystem', value: 'Sistema digestivo'},
  {key: 'physicalNervousSystem', value: 'Sistema nervioso'},
  {
    key: 'physicalMusculoskeletalSystem',
    value: 'Sistema musculo-esqueletico',
  },
  {key: 'physicalUrinarySystem', value: 'Sistema urinario'},
  {key: 'physicalReproductiveSystem', value: 'Sistema reproductivo'},
  {key: 'physicalLymphoidSystem', value: 'Sistema Linfoide'},
  {key: 'physicalSenseOrgans', value: 'Órganos de los sentidos'},
  {key: 'physicalMentalState', value: 'Comportamiento (estado mental)'},
  {key: 'physicalAbnormalFindings', value: 'Hallazgos anormales'},
  {key: 'physicalProblemsList', value: 'Lista de problemas'},
  {key: 'physicalDifferentialDiagnosis', value: 'Diagnósticos diferenciales'},
  {key: 'physicalImmediateDestination', value: 'Destino inmediato'},
  //therapy
  {key: 'denomination', value: 'Nombre Comercial - Presentación'},
  {key: 'frequencyUnit', value: 'Unidades (Frecuencia)'},
  {key: 'frequency', value: 'Frecuencia'},
  {key: 'timeUnit', value: 'Unidades (Tiempo de Tratamiento)'},
  {key: 'administrationRoute', value: 'Vía de Administración'},
  {key: 'quantity', value: 'cantidad'},
  {key: 'indications', value: 'indicaciones'},
  {key: 'indication', value: 'Indicaciones de Uso'},
  //diagnostic
  {key: 'testType', value: 'Tipo de prueba'},
  {key: 'testSampleTypeId', value: 'Tipo de prueba'},
  {key: 'testTypeDescription', value: 'Descripción'},
  {key: 'testTypeSpecifications', value: 'Especificaciones'},
  {key: 'testQuantity', value: 'Cantidad'},

  {key: 'drug', value: 'Fármaco'},
  {key: 'initialDose', value: 'Dosis inicial'},
  {key: 'finalDose', value: 'Dosis final'},
  {key: 'time', value: 'Tiempo'},
  {key: 'FRC', value: 'FR.C'},
  {key: 'FRR', value: 'FR.R'},
  {key: 'pulse', value: 'Pulso'},
  {key: 'oralMucosa', value: 'Mucosa oral'},
  {key: 'cornealReflex', value: 'Reflejo corneal'},
  {key: 'pupils', value: 'Pupilas'},
  {key: 'painReflex', value: 'Reflejo al dolor'},
  {key: 'growth', value: 'Etapa desarrollo'},
  {key: 'reason', value: 'Motivo procedimiento'},
  {key: 'preEuthanasiaUsed', value: 'Pre-eutanásico utilizado'},
  {key: 'hour', value: 'Hora'},
  {key: 'weightType', value: 'Unidad de medida'},
  {key: 'euthanasiaProcessOtherField', value: '¿Cuál?'},
  {key: 'preEuthanasiaMedicines', value: 'medicamento pre-eutanásico'},
  {key: 'euthanasiaMedicines', value: 'medicamento eutanásico'},
  {key: 'euthanasiaProcessUsed', value: 'Proceso de Eutanasia'},
  {key: 'euthanasiaProcessMethod', value: 'Método de Eutanasia'},
  {key: 'euthanasiaMethod', value: 'Método de Eutanasia'},
  {key: 'vitalSigns', value: 'Signos vitales'},
  {key: 'fileLoad', value: 'Evidencias'},
  {key: 'rigorMortis', value: 'Rigor mortis'},
  {key: 'usersForm', value: 'Funcionarios'},
  {
    key: 'duplicate key value violates',
    value:
      'Ya se ha registrado anteriormente un acta para los datos relacionados',
  },
  {key: 'preliminaryClinicHistory', value: 'Historia Clínica Preliminar'},
  {key: 'safePassageRecord', value: 'Acta de salvoconducto'},
  {key: 'observationsIndividual', value: 'Observaciones'},
  {key: 'specimenList', value: 'Agregar Individuos'},
  //  register-Biology
  {key: 'specieHabit', value: 'Especie de hábitos y/o costumbres'},
  {key: 'specieLevel', value: 'Nivel de Peligrosidad'},
  {key: 'suggestionAccommodation', value: 'Recomendaciones de Alojamiento'},
  {key: 'naturalDiet', value: 'Dieta Natural'},
  {key: 'biologicalManagement', value: 'Manejo Biológico (Problemas Biológicos)'},
  //  necropsy
  {key: 'macroscopicFindings', value: 'Hallazgos Macroscópicos'},
  {key: 'anamnesis', value: 'Anamnesis'},
  {key: 'dateDeath', value: 'Fecha de la Muerte'},
  {key: 'timeDeath', value: 'Hora de Muerte'},
  {key: 'pathology', value: 'Patología'},
  {key: 'collectionSamples', value: 'Muestras Recolectadas'},
  {key: 'digestiveSystem', value: 'Sistema Digestivo'},
  {key: 'urinarySystem', value: 'Sistema Urinario'},
  {key: 'reproductiveSystem', value: 'Sistema Reproductivo'},
  {key: 'cardiovascularSystem', value: 'Sistema Cardiovascular'},
  {key: 'respiratorySystem', value: 'Sistema Respiratorio'},
  {key: 'muscularSkeletalSystem', value: 'Sistema Muscular y Óseo'},
  {key: 'lymphoidOrgans', value: 'Órganos Linfoides'},
  {key: 'skinAppendages', value: 'Piel y Anexos'},
  {
    key: 'diagnosticClinicalRelevance',
    value: 'Diagnósticos Clínicos Relevantes',
  },
  {
    key: 'additionalData',
    value: 'Datos Adicionales',
  },
  {key: 'nervousSystem', value: 'Sistema Nervioso'},
  {
    key: 'pictures must contain at least 1',
    value: 'Debe al menos haber una imagen adjunta',
  },
  {
    key: '[fetch actor error] 400-Already exist an operator with this information',
    value: 'Este operador ya ha sido asignado',
  },
  {
    key: 'This specimen already have a necropsy',
    value: 'El número de acta de incautación que se quiere registrar ya existe',
  },
  {
    key: 'invalid input syntax for type double precision: ""',
    value: 'Peso Inválido',
  },
  {key: 'Invalid weightType', value: 'Debe seleccionar un tipo de peso'},
  // admissionForm
  {key: 'admissionDate', value: 'Fecha de admisión'},
  {key: 'taxonomicGroup', value: 'Grupo taxonómico'},
  {key: 'location', value: 'Ubicación'},
  {key: 'admissionType', value: 'Tipo de ingreso'},
  {key: 'markingDate', value: 'Fecha de marcaje'},
  {key: 'markingType', value: 'Tipo de marcación'},
  // departureForm
  {key: 'departureType', value: 'Tipo de salida'},
  {key: 'departureDate', value: 'Fecha de Salida'},
  {key: 'destiny', value: ' Destino'},
  {key: 'finalDestiny', value: 'Lugar de destino final'},
  {key: 'observationsDeparture', value: 'Observaciones'},
  {key: 'departmentId', value: 'Departamento'},
  {key: 'cityId', value: 'Municipio'},
  {key: 'transport', value: 'Medio de Transporte'},
  {key: 'description', value: 'Descripción'},
  {key: 'observationRelease', value: 'Observaciones'},
  // routine-Evaluation
  {key: 'dateRegister', value: 'Fecha de Registro'},
  {key: 'startTimeRE', value: 'Hora inicio de observación'},
  {key: 'endTime', value: 'Hora de finalización de observación'},
  {key: 'bodyCondition', value: 'Condición Corporal'},
  {key: 'fur', value: 'Pelaje'},
  {key: 'claws', value: 'Garras'},
  {key: 'clawsM', value: 'Garras, uñas, pezuñas'},
  {key: 'horns', value: 'Cuernos'},
  {key: 'hornsM', value: 'Cuernos y astas'},
  {key: 'teeth', value: 'Dientes'},
  {key: 'teethR', value: 'Ranfoteca y dientes'},
  {key: 'plumage', value: 'Plumaje'},
  {key: 'ranfoteca', value: 'Ranfoteca'},
  {key: 'podoteca', value: 'Podoteca'},
  {key: 'scales', value: 'Escamas y osteodermos'},
  {key: 'shell', value: 'Caparazón'},
  {
    key: 'recordNumberSafePassage',
    value: 'El número de acta que se quiere registrar ya existe',
  },
  // neonatal-monitoring
  {key: 'ingredients', value: 'Ingredientes'},
  {
    key: 'amount',
    value: 'Cantidad indicada por sesión de alimentación',
  },
  {key: 'frequency', value: 'Frecuencia de alimentación'},
  {key: 'temperature', value: 'Temperatura Ambiente °C'},
  {key: 'humidity', value: 'Humedad Ambiente %'},
  {key: 'lodgingType', value: 'Tipo de Alojamiento'},
  {key: 'enclosure', value: 'Recinto en Cuarentena No.'},
  {
    key: 'recommendation',
    value: 'Recomendaciones Especiales de Manejo',
  },
  {key: 'hour', value: 'Hora'},
  {key: 'weight', value: 'Hora'},
  {key: 'corporalCondition', value: 'Condición corporal'},
  {
    key: 'temperature',
    value: 'Temperatura',
  },
  {
    key: 'dehydrationGrade',
    value: 'Grado de deshidratación',
  },
  {
    key: 'respiration',
    value: 'Respiración / auscultación',
  },
  {
    key: 'consumption',
    value: 'Consumo alimento',
  },
  {
    key: 'stool',
    value: 'Heces',
  },
  {
    key: 'urine',
    value: 'Producción de orina',
  },
  {
    key: 'date',
    value: 'Fecha',
  },
  //  Dialog diagnostic-test
  {key: 'typeTest', value: 'Tipo de prueba'},
  {key: 'labSample', value: 'Tipo de muestra'},
  {key: 'amount', value: 'Cantidad'},
  {key: 'observationsDiagnostic', value: 'Datos clínicos / Observaciones'},
  // safeguard section
  {
    key: 'recordNumber_safeguard_register',
    value: 'Número de salvoconducto',
  },
  {
    key: 'regional_safeguard_register',
    value: 'Dirección Regional de Origen',
  },
  {
    key: 'quantity_safeguard_register',
    value: 'Cantidad especímenes',
  },

  // Deliver
  {
    key: 'usersDeliveryForm',
    value: 'Agregar funcionario que realiza la entrega',
  },
  {
    key: 'usersGetForm',
    value: 'Agregar funcionario que recibe los ejemplares',
  },
  {
    key: 'specimenList_deliver',
    value: 'Agregar Nuevo Registro de Ejemplares',
  },
  {key: 'entityId', value: 'Entidad de procedencia'},
  {key: 'ent', value: 'Entidad'},
  // Hospitalization Income
  {key: 'dateRegister_income', value: 'Fecha de Ingreso'},
  {key: 'startTime_income', value: 'Hora de Ingreso'},
  {key: 'incomeState', value: 'Infeccioso/No Infeccioso/Post Quirúrgico'},
  {key: 'presumptiveDX', value: 'DX Presuntivo'},
  {key: 'currentTreatments', value: 'Tratamientos Actuales'},
  // Hospitalization Check_Out
  {key: 'dateRegister_check_out', value: 'Fecha de Salida'},
  {key: 'startTime_check_out', value: 'Hora de Salida'},
  {key: 'hospitalizationTime', value: 'Tiempo de hospitalización (Días)'},
  {key: 'destination', value: 'Destino'},
  // Hospitalization Tacking
  {key: 'dateRegister_tracking', value: 'Fecha de seguimiento'},
  {key: 'time_tracking', value: 'Hora de seguimiento'},
  {key: 'corporalTemperature', value: 'Temperatura corporal (°C)'},
  {key: 'heartRate', value: 'Frecuencia cardiaca (lpm)'},
  {key: 'breathingFrequency', value: 'Frecuencia respiratoria (rpm)'},
  {key: 'pulseQuality', value: 'Calidad de pulso (b,r,m)'},
  {key: 'mucousColor', value: 'Color de mucosas'},
  {key: 'appetite', value: 'Apetito (b,r,m)'},
  {key: 'drinkWater', value: 'Consumo agua'},
  {key: 'attitude', value: 'Actitud'},
  {key: 'temperament', value: 'Temperamento'},
  {key: 'puke', value: 'Vómito'},
  {key: 'stoolConsistency', value: 'Consistencia de materia fecal'},
  {key: 'urineProduction', value: 'Producción de orina'},
  {key: 'dehydrationPercentage', value: 'Porcentaje de deshidratación (%)'},
  {key: 'fluidTherapy', value: 'Fluidoterapia'},
  {key: 'specialIndications', value: 'Indicaciones especiales'},
  {key: 'entityId', value: 'Entidad de procedencia'},
  // Genera - General Form
  {key: 'name_genera', value: 'Nombre Género'},
  // Regional - General Form
  {key: 'name_regional', value: 'Nombre Dirección Regional'},
  // Diagnostic test - General Form
  {key: 'name_diagnostic', value: 'Prueba diagnóstica'},
  {key: 'name_value', value: 'Valor'},
  //discharge-critical-care
  {key: 'criticalCareTime', value: 'Tiempo de cuidado crítico (Dias)'},
  // Enrichment material - General form
  {key: 'name_enrichment_material', value: 'Material Enriquecimiento'},
  {key: 'unit_value', value: 'Valor Unitario'},
  {key: 'total_value', value: 'Valor Total'},
  // Specie - General Form
  {key: 'name_specie', value: 'Especie'},
  {key: 'naturalDistribution', value: 'Distribución'},
  // Medical History
  {key: 'entry_date', value: 'Fecha de entrada'},
  {
    key: 'observationSeizure',
    value: 'Observación número Acta de incautación',
  },
  {key: 'observationSafeguard', value: 'Observaciones No. Salvoconducto'},
  // actor managment
  {key: 'association_actor', value: 'Vinculación'},
  {key: 'role', value: 'Rol'},
  //Ethograms
  {key: 'startDate', value: 'Fecha de Inicio'},
  {key: 'endDate', value: 'Fecha Fin'},
  {key: 'sampling', value: 'Método de Muestreo'},
  {key: 'individuals', value: 'Individuos'},
  {key: 'code', value: 'Código'},
  {key: 'behaviorPattern', value: 'Patrón Comportamental'},
  {key: 'behaviorUnit', value: 'Unidad Comportamental'},
  {key: 'notes', value: 'Notas'},
  {key: 'evaluationBehavior', value: 'Fecha Evaluación Comportamental'},
  {key: 'finalTime', value: 'Hora de Finalización'},
  {key: 'numberSample', value: 'Cantidad de Muestras'},
  {key: 'code1', value: 'Código 1'},
  {key: 'code2', value: 'Código 2'},
  {key: 'usersAdd', value: 'Agregar Observadores'},
  {key: 'codeAdd', value: 'Agregar Nuevo Código'},
  {
    key: 'behavioralSampling',
    value: 'Agregar Nuevo Muestreo de Comportamiento',
  },
  // Admission quarantine
  {key: 'partnerNxCx', value: 'Compañeros HxCx No.'},
  {key: 'typeQuarantine', value: 'Tipo de Cuarentena'},
  {key: 'quarantineTime', value: 'tiempo de cuarentena'},
  // Enrichment management
  {key: 'requestDate', value: 'Fecha de solicitud'},
  {key: 'objectiveEnrichment', value: 'Objetivo del enriquecimiento'},
  {key: 'photo', value: 'Agregar imagen'},
  {key: 'detail', value: 'Detalle'},
  {key: 'activity', value: 'Actividad'},
  {
    key: 'descriptionArticle',
    value: 'Descripción del artículo de enriquecimiento y/o actividad',
  },
  {key: 'categoriesDefinition', value: 'Definición de Categorías'},
  {key: 'clinicalConsiderations', value: 'Consideraciones clínicas'},
  {key: 'material', value: 'Material de enriquecimiento'},
  {key: 'otherCategory', value: 'Cuál'},
  {key: 'requiredQuantity', value: 'Cantidad requerida'},
  {key: 'quantityMaterial', value: 'Registrar materiales'},
  // settings Medicine
  {key: 'unit', value: 'Unidad'},
  {key: 'genericDenomination', value: 'Denominación Genérica - Presentación'},
  {key: 'valueMedicine', value: 'Valor'},
  {key: 'typeMedicine', value: 'Tipo Medicamento'},
  // settings Medicine
  {key: 'roleName', value: 'Nombre rol'},
  // Errors
  {
    key: 'can not delete this regional',
    value:
      'No se puede borrar la regional porque se encuentra asociada a un registro',
  },
  {
    key: 'can not delete this genus',
    value:
      'No se puede borrar el género porque se encuentra asociado a un registro',
  },
  {
    key: 'can not delete this specie',
    value:
      'No se puede borrar la especie porque se encuentra asociado a un registro',
  },
  {
    key: 'Preliminary Clinic History not found',
    value: 'No es posible encontrar una historia clinica asociada',
  },
  {
    key: 'This specimen already have a euthanasia',
    value:
      'El nombre del espécimen al que se quiere registrar la eutanasia ya existe',
  },
  // Therapeutic
  {
    key: 'request',
    value: 'Solicitud desde',
  },
  {
    key: 'registration',
    value: 'No. Registro',
  },
  {
    key: 'drugSupplyControl',
    value: 'Agregar Nuevo Registro de Terapéutica',
  },
  {
    key: 'date_drug_supply',
    value: 'Fecha de suministro',
  },
  {
    key: 'hour_drug_supply',
    value: 'Hora de suministro',
  },
  {
    key: 'dateDiet',
    value: 'Fecha Dieta Suministrada',
  },
  {
    key: 'dateResidue',
    value: 'Fecha Residuo Dieta',
  },
  {
    key: 'weightDiet',
    value: 'Peso (Gramos)',
  },
  {
    key: 'observationDiet',
    value: 'Observaciones',
  },
  // Enrichment Environment

  {
    key: 'registerDate',
    value: 'Fecha de Registro',
  },
  {
    key: 'genera',
    value: 'Género',
  },
  {
    key: 'taxonomic',
    value: 'Taxonomía',
  },
  {
    key: 'taxonomicDescription',
    value: 'Descripción Taxonómica',
  },
  {
    key: 'habitat',
    value: 'Hábitat y Distribución',
  },
  {
    key: 'food',
    value: 'Alimentación',
  },
  {
    key: 'reproduction',
    value: 'Reproducción',
  },
  {
    key: 'currentlyStatus',
    value: 'Estado Actual y Conservación',
  },
  {
    key: 'goalsControl',
    value: 'Agregar Meta',
  },
  {
    key: 'planning',
    value: 'Agregar Actividades de Enriquecimiento',
  },
  {
    key: 'attendees',
    value: 'Agregar Funcionarios',
  },
  {
    key: 'activityFile',
    value: 'Agregar Imagen',
  },
  {
    key: 'activityDocument',
    value: 'Agregar Documento',
  },
  {
    key: 'availableQuantity',
    value: 'Cantidad disponible',
  },
  {
    key: 'unitValue',
    value: 'Valor Unitario',
  },
  {
    key: 'totalValue',
    value: 'Valor total',
  },
  {
    key: 'requestFrom',
    value: 'Solicitud Desde',
  },
  {
    key: 'diagnosticTest',
    value: 'Agregar Pruebas Diagnósticas',
  },
  {
    key: 'latitude',
    value: 'Coordenadas eje X',
  },
  {
    key: 'longitude',
    value: 'Coordenadas eje Y',
  },
  // Critical Care
  {
    key: 'attentionCriticalCare',
    value: 'Especial atención',
  },
  {
    key: 'frequencyCriticalCare',
    value: 'Frecuencia de monitoreo',
  },
  {
    key: 'hosting',
    value: 'Requerimientos especiales de alojamiento',
  },
  {
    key: 'criticalCareAdmissionDate',
    value: 'Fecha de Ingreso',
  },
  {
    key: 'securityLevel',
    value: 'Nivel de Peligrosidad',
  },
  {
    key: 'securityLevelSpecial',
    value: 'Requerimientos especiales de seguridad',
  },
  {
    key: 'dietCriticalCare',
    value: 'Dieta (Tipo, cantidad y frecuencia)',
  },
  {
    key: 'quantitySpecimen',
    value: 'Cantidad de espécimenes',
  },
  // biological veterinary concept
  {
    key: 'kingdom',
    value: 'Reino',
  },
  {
    key: 'order',
    value: 'Orden',
  },
  {
    key: 'phylum',
    value: 'Phylum',
  },
  {
    key: 'family',
    value: 'Familia',
  },
  {
    key: 'classT',
    value: 'Clase',
  },
  {
    key: 'subSpecie',
    value: 'Subespecie',
  },
  {
    key: 'naturalHistory',
    value: 'Historia Natural',
  },
  {
    key: 'geographicDistribution',
    value: 'Distribución Geográfica',
  },
  {
    key: 'commonProblems',
    value: 'Problemas Frecuentes',
  },
  {
    key: 'bibliographicalReference',
    value: 'Referencia Bibliográfica',
  },
  {
    key: 'identification',
    value: 'Identificación',
  },
  {
    key: 'evolution',
    value: 'Evolución Comportamental',
  },
  {
    key: 'importance',
    value: 'Nivel de Impronta',
  },
  {
    key: 'requirements',
    value: 'Requerimientos de preadaptación al medio y la liberación',
  },
  {
    key: 'quarantinePeriod',
    value: 'Periodo de Cuarentena',
  },
  {
    key: 'currentLocation',
    value: 'Ubicación Actual',
  },
  {
    key: 'evaluationDate',
    value: 'Fecha de Evaluación',
  },
  {
    key: 'attendeesMaintenance',
    value: 'Evaluadores',
  },
  {
    key: 'maintenanceDetail',
    value: 'Detalle',
  },
  {
    key: 'category',
    value: 'Categoría',
  },
  {
    key: 'enrichmentDescription',
    value: 'Descripción del enriquecimiento y comportamientos esperados',
  },
  {
    key: 'maintenanceObservation',
    value: 'Observación',
  },
  {
    key: 'observationTime',
    value: 'Tiempo de Observación',
  },
  {
    key: 'maintenanceMaterial',
    value: 'Materiales Utilizados',
  },
  {
    key: 'enrichmentResponse',
    value: 'Respuesta al enriquecimiento',
  },
  {
    key: 'additionalComments',
    value: 'Comentarios adicionales',
  },
  {
    key: 'negativeReaction',
    value: 'Problemas de seguridad y reacciones negativas',
  },
  {
    key: 'maintenanceSuggestion',
    value: 'Comentarios y/o sugerencias',
  },
  {
    key: 'video',
    value: 'Foto y/o video',
  },
  {
    key: 'other',
    value: 'Cuál',
  },
  {
    key: 'status',
    value: 'Estado Actual',
  },
  {
    key: 'history',
    value: 'Historia',
  },
  {
    key: 'clinicalFollowup',
    value: 'Seguimiento Clínico',
  },
  {
    key: 'currentStatus',
    value: 'Estado Actual de Salud',
  },
  {
    key: 'recommendations',
    value: 'Recomendaciones Veterinarias',
  },
  {
    key: 'nutritionalHistory',
    value: 'Resumen Historia Nutricional y Zootécnica',
  },
  {
    key: 'conceptDate',
    value: 'Fecha de Concepto',
  },
  {
    key: 'nutritionalEvaluation',
    value: 'Evaluación Nutricional / Zootecnia Actual y Concepto Nutricional / Zootécnico de Egreso',
  },
  {
    key: 'responsible',
    value: 'Nombre del Responsable',
  },
  {
    key: 'site',
    value: 'Nombre del Sitio',
  },
  {
    key: 'siteCharacteristics',
    value: 'Características del Sitio',
  },
  {
    key: 'finalDisposition',
    value: 'Disposición Final Sugerida',
  },
  {
    key: 'whichFinalDisposition',
    value: 'Cuál',
  },
  {
    key: 'packaging',
    value: 'Embalaje',
  },
  {
    key: 'transport',
    value: 'Medio de Transporte',
  },
  {
    key: 'fasting',
    value: 'Ayuno',
  },
  {
    key: 'chemicalRestraint',
    value: 'Restricción Química',
  },
  {
    key: 'handlingDuring',
    value: 'Manejo Durante el Transporte',
  },
  {
    key: 'onArrivalHandling',
    value: 'Manejo Inmediato al Arribo',
  },
  // end of biological veterinary concept
  // Administration Management
  {
    key: 'name',
    value: 'Nombre',
  },
  {
    key: 'address',
    value: 'Dirección',
  },
  {
    key: 'entityCode',
    value: 'Código de la Entidad',
  },
  {
    key: 'entityNit',
    value: 'NIT',
  },
  {
    key: 'personalID',
    value: 'Número de Identificación',
  },
  {
    key: 'year',
    value: 'Año',
  },
  {
    key: 'taxonomyGroup',
    value: 'Grupo Taxonómico',
  },
  {
    key: 'value',
    value: 'Valor',
  },
  {
    key: 'effectiveDate',
    value: 'Fecha Inicio de Vigencia',
  },
  // critical care monitoring
  {
    key: 'cardiacFrequency',
    value: 'Frecuencia cardiaca (lpm)',
  },
  {
    key: 'respiratoryRate',
    value: 'Frecuencia respiratoria (rpm)',
  },
  {
    key: 'mucosalColor',
    value: 'Color de mucosas',
  },
  {
    key: 'water',
    value: 'Toma agua',
  },
  {
    key: 'vomit',
    value: 'Vómito',
  },
  {
    key: 'fecalMatter',
    value: 'Consistencia de materia fecal',
  },
  {
    key: 'specialInstructions',
    value: 'Indicaciones especiales',
  },
  {
    key: 'corporalTemperature',
    value: 'Temperatura corporal (°C)',
  },
  {
    key: 'clinicHistory',
    value: 'No. Historia Clínica',
  },
  {
    key: 'professionalCard',
    value: 'No. Tarjeta Profesional',
  },
  {
    key: 'dateStartContract',
    value: 'Fecha de Inicio del Contrato',
  },
  {
    key: 'dateEndContract',
    value: 'Fecha Fin del Contrato',
  },
  {
    key: 'physicalOther',
    value: 'Detalle',
  },
  {
    key: 'physicalOtherState',
    value: 'Estado del Examen Físico Especial',
  },
  {
    key: 'preclinicalHistory',
    value: 'No. Historia Clínica Preliminar',
  },
  {
    key: 'specifications',
    value: 'Especificaciones',
  },
  {
    key: 'amountDiagnosticTest',
    value: 'Cantidad',
  },
  {
    key: 'resultDate',
    value: 'Fecha del Resultado',
  },
  {
    key: 'sampleResult',
    value: 'Resultado de la Muestra',
  },
  {
    key: 'dateSample',
    value: 'Fecha de Toma de Muestra',
  },
  {
    key: 'hourSample',
    value: 'Hora de Toma de Muestra',
  },
  {
    key: 'placeSample',
    value: 'Sitio de Procesamiento de Muestra',
  },
  {
    key: 'dateAct',
    value: 'Fecha del Acta'
  },
  {
    key: 'numberClinicHistory',
    value: 'Historia Clínica'
  },
  {
    key: 'professionalCardNo',
    value: 'Tarjeta Profesional',
  },
  {
    key: 'markingNo',
    value: 'No. de Chip',
  },
  {
    key: 'originDepartmentId',
    value: 'Departamento de procedencia',
  },
  {
    key: 'originMunicipalityId',
    value: 'Municipio de procedencia',
  },
  {
    key: 'startTime',
    value: 'Hora de Inicio',
  },
  {
    key: 'individualsClinical',
    value: 'Individuos (Historia Clínica)',
  },
  {
    key: 'behaviour',
    value: 'Comportamientos',
  },
  {
    key: 'dateClinicalExam',
    value: 'Fecha del examen médico',
  },
  {
    key: 'ent',
    value: 'Entidad',
  }
];

export function getError(data: string, errors: Menu[] = ERRORS): any {
  for (const error of errors) {
    if (error.key === data) {
      return error.value;
    }
  }
}
