import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { ServerResponse } from "../../../shared/types/http.interfaces";
import {
  BasicDataInterface,
  ConfigurationYearInterface,
  LivingCostInterface, LogoInterface,
  NewYearInterface
} from "../types/basic-data.interface";

@Injectable({
  providedIn: 'root'
})
export class AdministrationManagementService {

  private _baseUrl = environment.api + '/general-services-ms';
  private _baseUrlLivingCost = environment.api + '/processesanddocumentsservice-ms';

  constructor(private http: HttpClient) {
  }

  /**
   * get all saved basic data
   */
  public async getAllBasicData(): Promise<BasicDataInterface[]> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.get<ServerResponse<BasicDataInterface[]>>(
      `${this._baseUrl}/basicsData`, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send new basic data
   */
  public async newBasicData(basicData: BasicDataInterface): Promise<BasicDataInterface> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.post<ServerResponse<BasicDataInterface>>(
      `${this._baseUrl}/basicData`, basicData, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send basic data to update
   */
  public async updateBasicData(basicData: BasicDataInterface, id: number): Promise<BasicDataInterface> {
    const res = await this.http.patch<ServerResponse<BasicDataInterface>>(
      `${this._baseUrl}/basicsData/${id}`, basicData).toPromise();
    return res.data;
  }

  /**
   * get all saved year
   */
  public async getAllConfigurationYear(): Promise<ConfigurationYearInterface[]> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.get<ServerResponse<ConfigurationYearInterface[]>>(
      `${this._baseUrl}/configurationYears`, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send new year
   */
  public async newConfigurationYear(year: NewYearInterface): Promise<ConfigurationYearInterface> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.post<ServerResponse<ConfigurationYearInterface>>(
      `${this._baseUrl}/configurationYears`, year, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send basic data to delete
   */
  public async deleteConfigurationYear(id: number): Promise<void> {
    let res;
    res = await this.http.delete<ServerResponse<ConfigurationYearInterface>>(
      `${this._baseUrl}/configurationYears/${id}`).toPromise();
  }

  /**
   * send year to update
   */
  public async updateConfigurationYear(newYear: NewYearInterface, id: number): Promise<ConfigurationYearInterface> {
    let res;
    res = await this.http.patch<ServerResponse<ConfigurationYearInterface>>(
      `${this._baseUrl}/configurationYears/${id}`, newYear).toPromise();
    return res.data;
  }

  /**
   * get all saved living cost
   */
  public async getAllLivingCost(): Promise<LivingCostInterface[]> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.get<ServerResponse<LivingCostInterface[]>>(
      `${this._baseUrlLivingCost}/livingCosts`, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send new living cost
   */
  public async newLivingCost(livingCost: LivingCostInterface[]): Promise<LivingCostInterface> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.post<ServerResponse<LivingCostInterface>>(
      `${this._baseUrlLivingCost}/livingCosts`, {livingCost}, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send living cost to delete
   */
  public async deleteLivingCost(id: number): Promise<void> {
    let res;
    res = await this.http.delete<ServerResponse<LivingCostInterface>>(
      `${this._baseUrlLivingCost}/livingCosts/${id}`).toPromise();
  }

  /**
   * get living cost by id
   */
  public async getLivingCostById(id: number, fetchHistory?: boolean): Promise<LivingCostInterface> {
    let res;
    let params = new HttpParams();
    if(fetchHistory) {
      params = params.append('fetchHistory', fetchHistory.toString());
    }

    res = await this.http.get<ServerResponse<LivingCostInterface>>(
      `${this._baseUrlLivingCost}/livingCosts/${id}`, {
        params
      }).toPromise()
    return res.data;
  }

  /**
   * send living cost to update
   */
  public async updateLivingCost(livingCost: LivingCostInterface, id: number): Promise<LivingCostInterface> {
    let res;
    res = await this.http.patch<ServerResponse<LivingCostInterface>>(
      `${this._baseUrlLivingCost}/livingCosts/${id}`, livingCost).toPromise();
    return res.data;
  }

  /**
   * get all saved logo
   */
  public async getAllLogo(): Promise<LogoInterface[]> {
    let res;
    const headers = new HttpHeaders();
    res = await this.http.get<ServerResponse<LogoInterface[]>>(
      `${this._baseUrl}/logos`, {
      headers,
    }).toPromise();
    return res.data;
  }

  /**
   * send new logo
   */
  public async newLogo(image: File): Promise<LogoInterface> {
    let res;
    const formData = this.getSaveUpdateBody(image);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    res = await this.http.post<ServerResponse<LogoInterface>>(
      `${this._baseUrl}/logos`, formData, {
      headers,
    })
      .toPromise();
    return res.data;
  }

  /**
   * get living cost by id
   */
  public async updateLogoById(id: number, image: File): Promise<LogoInterface> {
    let res;
    const formData = this.getSaveUpdateBody(image);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    res = await this.http.patch<ServerResponse<LogoInterface>>(
      `${this._baseUrl}/logos/${id}`, formData, { headers }).toPromise();
    return res.data;
  }


  /**
   * data to send for add image
   * @private
   * @param image
   */
  private getSaveUpdateBody(
    image: File
  ) {
    const formData = new FormData();
    formData.append(
      'logo',
      image
    );
    return formData;
  }
}
