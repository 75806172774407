// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {EnvironmentDefinition} from "./types";
import {environmentProd} from "./environment.prod";
import {environmentDev} from "./environment.dev";
import {environmentQa} from "./environment.qa";

const sitePath = window.location.href;

let exportVars: EnvironmentDefinition;

if (sitePath.includes("dev2.cav.smartsoftlabs.com")) {
  exportVars = environmentDev;
} else if (sitePath.includes("qa.cavfauna.com")) {
  exportVars = environmentQa;
} else if (sitePath.includes("cavfauna.com")) {
  exportVars = environmentProd;
} else {
  // if need to test other environment on localhost, change this var
  exportVars = environmentDev;
}

export const environment: EnvironmentDefinition = exportVars;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
