import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AnimalFormModel } from '../../models/animal-form.model';
import { DialogService } from '../../services/dialog.service';
import { GeneraService } from '../../services/genera.service';
import { SpeciesService } from '../../services/species.service';
import { AdministrationInterface } from '../../types/administration.interface';
import {
  AllValidationErrors,
  getFormValidationErrors,
  searchFormError,
} from '../../types/get-errors-form';

@Component({
  selector: 'app-register-animal-form',
  templateUrl: './register-animal-form.component.html',
  styleUrls: ['./register-animal-form.component.scss'],
})
export class RegisterAnimalFormComponent implements OnInit {
  @Output() animalForm: EventEmitter<AnimalFormModel> = new EventEmitter();
  public animalSaveForm: FormGroup;
  public quantityList: number[] = [];
  public quantityValue: number = 0;
  toppings = new FormControl();
  medicalHistoryList: string[] = ['2345689', '3567893', '3567891', '3567890'];

  /**
   * Get selectors options
   */
  public async getData() {
    this.generaList = await this.generaService.getGenera();
    this.speciesList = await this.specieService.getSpecies();
  }

  public generaList: AdministrationInterface[];
  public speciesList: AdministrationInterface[];

  constructor(
    private fb: FormBuilder,
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.buildAnimalSaveForm();
    this.getSelectorOptions();
    await this.getData();
  }

  /**
   * Set quanty and regionalList options
   * @private
   */
  private getSelectorOptions() {
    this.quantityList = [1, 2, 3, 4];
  }

  buildAnimalSaveForm() {
    this.animalSaveForm = this.fb.group({
      commonName: new FormControl('', Validators.required),
      genusId: new FormControl('', Validators.required),
      specieId: new FormControl('', Validators.required),
      quantity: new FormControl(''),
      coordinatesX: new FormControl(''),
      coordinatesY: new FormControl(''),
      medicalHistory: new FormControl('', Validators.required),
      safeguardRecordNumber: new FormControl(''),
      seizureRecordNumber: new FormControl(''),
    });
  }

  onSubmit() {
    if (this.animalSaveForm.valid) {
      const animalForm = new AnimalFormModel(
        this.animalSaveForm.get('commonName')?.value,
        this.animalSaveForm.get('genusId')?.value,
        this.animalSaveForm.get('specieId')?.value,
        this.animalSaveForm.get('quantity')?.value,
        this.animalSaveForm.get('coordinatesX')?.value,
        this.animalSaveForm.get('coordinateY')?.value,
        this.animalSaveForm.get('medicalHistory')?.value,
        this.animalSaveForm.get('safeguardRecordNumber')?.value,
        this.animalSaveForm.get('seizureRecordNumber')?.value
      );
      this.animalForm.emit(animalForm);
      this.animalSaveForm.reset();
      this.animalSaveForm.markAsUntouched();
    } else {
      const errorRegisterAnimal: AllValidationErrors = getFormValidationErrors(
        this.animalSaveForm.controls
      ).shift() as any;
      this.dialogService.errorModal(searchFormError(errorRegisterAnimal));
    }
  }
}
