import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ServerResponse } from "../../../shared/types/http.interfaces";
import { FormGroup } from "@angular/forms";
import {DiagnosticTestInterface} from "../types/diagnostic-test.interface";

@Injectable({
  providedIn: 'root',
})
export class DiagnosticTestService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(private http: HttpClient) {
  }


  /**
   * get all saved diagnostic tests
   */
  public async getDiagnosticTest(includeInactive:boolean=false): Promise<DiagnosticTestInterface[]> {
   let res;
    if(!includeInactive){
      let params = new HttpParams();
        params = params.set( 'status', true);
      res = await this.http.get<ServerResponse<DiagnosticTestInterface[]>>(
        `${this._baseUrl}/labSampleTypes`,{params}
      ).toPromise();
    }else{
      res = await this.http.get<ServerResponse<DiagnosticTestInterface[]>>(
        `${this._baseUrl}/labSampleTypes`
      ).toPromise();
    }
    return res.data;
  }

  /**
   * get a diagnostic test according to your id
   * @param id
   */
  public async getDiagnosticTestById(id: number): Promise<DiagnosticTestInterface> {
    const res = await this.http.get<ServerResponse<DiagnosticTestInterface>>(`${this._baseUrl}/labSampleTypes/${id}`).toPromise();
    return res.data;
  }

  /**
   * send new diagnostic test
   * @param data
   */
  public async postDiagnosticTest(data: FormGroup) {
    return await this.http.post(`${this._baseUrl}/labSampleTypes`, data).toPromise();
  }

  /**
   * service to get data agree the filter
   * @param params
   */
  public async getDiagnosticTestFilter(params?: HttpParams): Promise<DiagnosticTestInterface[]> {
    const res = await this.http.get<ServerResponse<DiagnosticTestInterface[]>>(
      `${this._baseUrl}/labSampleTypes`,
      { params }
    ).toPromise();
    return res.data;
  }

  /**
   * Method delete one row
   * @param id
   */
  public async deleteDiagnosticTest(id: number) {
    return this.http.delete(`${this._baseUrl}/labSampleTypes/${id}`).toPromise();
  }

  /**
   *
   * @param params
   */
  public async downloadDiagnosticTest(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http.get<any>(
        `${this._baseUrl}/labSampleTypes/download`,
        { params, responseType: 'blob' as 'json' }
      ).toPromise();
    }
    return result;
  }

  /**
   * update data by id
   * @param form
   * @param id
   */
  public async updateDiagnosticTest(form: DiagnosticTestInterface, id: number) {
    return await this.http.patch<DiagnosticTestInterface>(`${this._baseUrl}/labSampleTypes/${id}`, form).toPromise()
  }
  /**
   * Method to update only status
   * @param id
   * @param stateSearch
   */
  public async updateOnlyStatus(id: number, stateSearch: boolean) {
    return await this.http.patch<DiagnosticTestInterface>(`${this._baseUrl}/labSampleTypes/${id}`, {
      active: stateSearch
    }).toPromise()
  }
}
