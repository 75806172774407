export interface VitalSigns {
  time: number;
  FRC: boolean;
  FRR: boolean;
  pulse: boolean;
  oralMucosa: OralMucosa;
  cornealReflex: boolean;
  pupils: Pupils;
  painReflex: boolean;
  rigorMortis?: string;
}

export enum OralMucosa {
  NORMAL = "normal",
  PALLID = "pallid",
  CYANOTIC = "cyanotic"
}

export const ORAL_MUCOSA_TRANSLATE: Array<{ value: OralMucosa, label: string}> = [
  { value: OralMucosa.NORMAL, label: 'Normal' },
  { value: OralMucosa.PALLID, label: 'Pálidas' },
  { value: OralMucosa.CYANOTIC, label: 'Cianóticas' },
];

export const oralMucosaHash: { [k:string]: string } = {
  normal: 'Normal',
  pallid: 'Pálidas',
  cyanotic: 'Cianóticas',
};

export enum Pupils {
  NORMAL = "normal",
  DILATED = "dilated",
  FIXED = "fixed"
}

export const PUPILS_TRANSLATE: Array<{ value: Pupils, label: string}> = [
  { value: Pupils.NORMAL, label: 'Normal' },
  { value: Pupils.DILATED, label: 'Dilatadas' },
  { value: Pupils.FIXED, label: 'Contraidas' },
];

export const pupilsTranslateHash: { [k:string]: string } = {
  normal: 'Normal',
  dilated: 'Dilatadas',
  fixed: 'Contraidas',
};
