<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue"
          data-cy="btn-close-dialog" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-1">
    <div class="sub-title-blue-left title-triage">Detalle Categoría Triage</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <div class="table-title-triage">
    <div class="table-title-text">Categoría Triage</div>
    <div class="table-title-text">Pronóstico</div>
    <div class="table-title-text">Supervivencia</div>
    <div class="table-title-text">Descripción</div>
  </div>
  <div class="table-content">
    <div class="table-item-triage">
      <div class="table-item-text table-black-text">Negro</div>
      <div class="table-item-text">NA</div>
      <div class="table-item-text">Improbable</div>
      <div class="table-item-text">Individuos que han fallecido o que tienen pocas probabilidades de supervivencia</div>
    </div>
    <div class="table-item-triage">
      <div class="table-item-text table-red-text">Rojo</div>
      <div class="table-item-text">Malo</div>
      <div class="table-item-text">Poco probable</div>
      <div class="table-item-text">Depende de la intervención realizada. Alto riesgo de pérdida de miembros u órganos</div>
    </div>
    <div class="table-item-triage">
      <div class="table-item-text table-orange-text">Naranja</div>
      <div class="table-item-text">Reservado a malo</div>
      <div class="table-item-text">Probable</div>
      <div class="table-item-text">Depende de la intervención realizada. Riesgo de pérdida de miembros u órganos</div>
    </div>
    <div class="table-item-triage">
      <div class="table-item-text table-yellow-text">Amarillo</div>
      <div class="table-item-text">Bueno a reservado</div>
      <div class="table-item-text">Segura</div>
      <div class="table-item-text">Depende de los cuidados hospitalarios. Puede haber una evolución negativa</div>
    </div>
    <div class="table-item-triage">
      <div class="table-item-text table-green-text">Verde</div>
      <div class="table-item-text">Bueno a reservado</div>
      <div class="table-item-text">Segura</div>
      <div class="table-item-text">Depende de la atención hospitalaria, puede haber complicaciones.</div>
    </div>
    <div class="table-item-triage">
      <div class="table-item-text table-blue-text">Azul</div>
      <div class="table-item-text">Bueno</div>
      <div class="table-item-text">Muy segura</div>
      <div class="table-item-text">Recuperación sin complicaciones</div>
    </div>
  </div>
</mat-dialog-content>
