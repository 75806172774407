import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {environment} from "../../../../environments/environment";
import {DialogService} from "../dialog.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionValidatorService {

  constructor(
    private authService: AuthService,
    private dialogService: DialogService
  ) {
  }

  /**
   * will return true if the user has any of the input permissions
   * @param permissions
   * @param clientId
   * @param showError
   */
  hasPermission(permissions: string[], clientId: string = environment.keycloakClient, showError = true): boolean {
    const accessToken = this.authService.getDecodedToken('ACCESS');
    if (!accessToken) {
      return false;
    }
    const currentPermissions = (accessToken.resource_access[clientId] as { roles: string[] })?.roles;
    if (!currentPermissions?.length) {
      return false;
    }
    const hasAccess = permissions.every(value => currentPermissions.includes(value));
    if (showError && !hasAccess) {
      if (this.dialogService.isDialogOpen())
        this.dialogService.errorModal('No tiene acceso a esta funcionalidad');
    }
    return hasAccess;
  }

  /**
   * is the same as hasPermission but waits for access token
   * @param permissions
   * @param clientId
   * @param showError
   */
  async hasPermissionAsync(permissions: string[], clientId: string = environment.keycloakClient, showError = true) {
    if (!this.authService.checkToken("ACCESS")) {
      await this.authService.refreshToken();
    }
    return this.hasPermission(permissions, clientId, showError);
  }
}
