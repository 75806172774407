<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-0-5">
    <span class="material-icons-bold blue mr-1">add</span>
    <div class="sub-title-blue-left">Detalle de especímenes</div>
  </div>
  <div class="icon-container-center mb-0-5">
    <div class="sub-title-blue-left ">No. de {{subTitle}} {{ recordNumber }}</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <table mat-table [dataSource]="viewData" class="table width-100 mb-2 mt-1">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="text-m-14-blue">
        {{data?.specimens ? 'No. Historia Clínica Preliminar' : 'No. Historia Clínica'}}
      </th>
      <td mat-cell *matCellDef="let element">{{ data?.specimens ? element.referencedPreliminaryClinicHistory?.id : element.referencedPreliminaryClinicHistory?.clinicHistory
        ?.id  }}</td>
    </ng-container>

    <ng-container matColumnDef="commonName">
      <th mat-header-cell *matHeaderCellDef class="text-m-14-blue">
        Nombre Común
      </th>
      <td mat-cell *matCellDef="let element">{{ element.commonName ? element.commonName : element.referencedPreliminaryClinicHistory?.specimen?.commonName }}</td>
    </ng-container>

    <ng-container matColumnDef="genus">
      <th mat-header-cell *matHeaderCellDef class="text-m-14-blue">Género</th>
      <td mat-cell *matCellDef="let element">{{ element.genus ? element.genus.name : element.referencedPreliminaryClinicHistory?.specimen?.genus?.name}}</td>
    </ng-container>

    <ng-container matColumnDef="specie">
      <th mat-header-cell *matHeaderCellDef class="text-m-14-blue">
        Especie
      </th>
      <td mat-cell *matCellDef="let element">{{ element.specie ? element.specie.name : element.referencedPreliminaryClinicHistory?.specimen?.specie?.name}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="button-container flex-row-end">
    <button type="submit" mat-button class="btn-submit" data-cy="close-dialog" (click)="close()">
      CERRAR
    </button>
  </div>
</mat-dialog-content>

