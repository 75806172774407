import {Directive, OnInit, OnDestroy, ElementRef, Input} from "@angular/core";
import {NgControl} from '@angular/forms';

@Directive({
  selector: 'input[trim][formControlName], input[trim][formControl], textarea[trim][formControlName], textarea[trim][formControl]'
})
export class FormControlTrimDirective implements OnInit, OnDestroy {

  @Input() debounce: number = 300;

  isAlive = true;


  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  ngOnInit() {
    if (this.ngControl.control) {
      this.ngControl.control.valueChanges
        .subscribe((changes: string) => {
          if (changes == null) {
            return;
          }
          let trimmed = changes.trimLeft();
          trimmed = trimmed.replace(/\n?\n(?!\n?\n)/g, "\n");
          trimmed = trimmed.replace(/\ +|^\ +$/g, " ")
          if (trimmed !== changes) {
            this.ngControl.control!.setValue(trimmed);
          }
        });
    } else {
      console.error('FormControlTrimDirective: FormControl must exist!');
    }
  }

  ngOnDestroy() {
    this.isAlive = false;
  }

}

