import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuOptions } from '../../entities/menu-options/menu-options.model';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() menuOption: MenuOptions;
  @Input() bigMenu = false;
  public panelOpenState = false;
  customIcons = [
    'entry',
    'medical_history',
    'release_form',
    'medical_exam',
    'necropsy',
    'biological_exam',
    'bird2',
    'routine_evaluation',
    'biological',
    'quarantine',
    'exit-quarantine',
    'exit_register',
    'update_register',
    'history_white',
    'output_white',
    'critical-care',
    'waste',
    'grass-white',
    'temporary-exit',
  ];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  goRoute(route: string) {
    this.router.navigate([`/${route}`]);
  }

  public customIcon(name: string) {
    return this.customIcons.includes(name);
  }

  public customIconSubmenu(name: string) {
    return this.customIcons.includes(name);
  }
}
