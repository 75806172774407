import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  ClinicHistory,
} from '../../../medical-history/types/medical-history.interface';
import { DatePipe } from '@angular/common';
import { SpecimenService } from '../../services/specimens.service';

import {TableAction} from "../../types/table";
import {
  DEPARTUREHashSpanish,
} from "../../../admission-departure-management/types/departure-management.interface";
import {ProfessionalModel} from "../../models/professional.model";
import {RegionalDirectionService} from "../../services/regional-direction.service";

@Component({
  selector: 'app-exit-info-expandable',
  templateUrl: './exit-info-expandable.component.html',
  styleUrls: ['./exit-info-expandable.component.scss'],
})
export class ExitInfoExpandableComponent implements OnInit, OnChanges {
  @Input() panelOpenState: boolean = false;
  @Input() patientHistory: ClinicHistory;
  @Input() prof:  ProfessionalModel[] = [];
  @Input() displayedColumnsDeparture: any[] = [];
  @Input() public actions: TableAction[] = [];
  @Input() public editable: boolean = false;
  @Input() public isNewMarking: boolean = false;
  @Input() public isAdmissionDeparture: boolean = false;
  @Input() public showMarkings: boolean = true;
  @Output() changeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPanelOpen = new EventEmitter<boolean>();
  @Input() public fields: Array<| 'destiny'
    | 'finalDestiny'
    | 'observations'>;

  commonName: FormControl;
  public form: FormGroup;
  public dataSource: data[]=[];
  fieldsInfo: FieldInfo[] = [];
  tableLength: number;

  constructor(
    public datePipe: DatePipe,
    public specimenService: SpecimenService,
    public regDirserv : RegionalDirectionService,
  ) {
  }

  async ngOnInit() {
    this.setFields();
    await this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setFields();
  }

  /**
   * set information of fields to be showed
   * @private
   */
  private setFields() {
    this.fieldsInfo = [];
    this.fields.forEach((field) => {
      const addField = this.allFields.find((f) => f.name === field);
      if (addField) {
        this.fieldsInfo.push(addField);
      }
    });
  }

  checkStatusOpenPanel() {
    this.isPanelOpen.emit();
  }
  public async getData(){
    this.prof.forEach(data => {
      this.dataSource.push(
        {
          associationT: data?.association!,
          phoneT: data?.phone!,
          firstNameT: `${data?.firstName + ' ' + data?.lastName}`,
          entityT: data?.entity!,
          positionT: data?.position!,
          professionalCard: data?.professionalCard!,
          document: `${data?.documentType + ' ' + data?.document}`,
        }
      )
    })
    return this.dataSource;
  }

  /*ALL AVAILABLE FIELDS DEFINITION*/
  get allFields(): FieldInfo[] {
    const fields = [
      {
        name: 'destiny',
        label: 'Destino final',
        value: this.patientHistory?.departureRecord?.destiny ?
          DEPARTUREHashSpanish[this.patientHistory?.departureRecord?.destiny ?? '-'] : '-',
        ctrlName: '',
        ctrlValue: ''
      },
      {
        name: 'finalDestiny',
        label: 'Lugar de destino final',
        value: this.patientHistory?.departureRecord?.finalDestiny ?
          this.patientHistory?.departureRecord?.finalDestiny : '-',
        ctrlName: 'entityName',
        ctrlValue: this.patientHistory?.departureRecord?.finalDestiny ?? '',
      },
      {
        name: 'observations',
        label: 'Observaciones',
        value: this.patientHistory?.departureRecord?.observations ?? '',
        ctrlName: 'CNIF',
        ctrlValue: this.patientHistory?.departureRecord?.observations ?? '',
      },
    ];
    return fields;
  }

}
interface data{
  associationT:string,
  entityT:string,
  positionT:string,
  firstNameT:string,
  document:string,
  professionalCard:string,
  phoneT:string,
}
interface FieldInfo {
  name: string;
  label: string;
  value: any;
  ctrlName: string;
  ctrlValue?: any;
  options?: any[];
}
