<div class="button-close-dialog-container flex-row-end">
  <button tabindex="-1" mat-button class="button-close-dialog text-m-16-blue" (click)="close()">
    <i class="material-icons">close</i>
  </button>
</div>
<div class="main-dialog-container">
  <div class="icon-container-center mb-1">
    <span class="material-icons-bold blue">add</span>
    <div class="sub-title-blue-left">Registrar Medicamentos</div>
  </div>
  <mat-divider></mat-divider>
</div>
<mat-dialog-content>
  <form novalidate [formGroup]="therapyForm" class="input-form register-form mt-2" autocomplete="off">
    <div class="flex-column-center width-100">
      <mat-form-field class="input-control" appearance="outline" placeholder="Fármaco">
        <mat-label>Nombre Comercial - Presentación</mat-label>
        <mat-select formControlName="denomination" data-cy="denomination" class="form-control">
          <mat-option [value]="medicine.denomination"
                      *ngFor="let medicine of dataMedicine">{{medicine.denomination}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-control " appearance="outline">
        <mat-label>Dosis</mat-label>
        <input matInput formControlName="dose" data-cy="dose" class="form-control" type="text"
               (keydown)="validatorsService.validatePositiveNumber($event)"
               (paste)="validatorsService.validatePositiveNumber($event)"
               (keyup)="validatorsService.validatePositiveDecimalNumber('dose', therapyForm, $event)"/>
      </mat-form-field>
      <div class="two-fields-row">
        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Frecuencia</mat-label>
          <input matInput formControlName="frequency" data-cy="frequency" id="frequency" class="form-control"
                 type="text"
                 (keydown)="validatorsService.numberOnly($event)" (paste)="validatorsService.numberOnly($event)"
                 (keyup)="validatorsService.validateNumber('frequency', therapyForm)"/>
        </mat-form-field>
        <mat-form-field class="input-control" appearance="outline" placeholder="mg">
          <mat-label>Unidades</mat-label>
          <mat-select formControlName="frequencyUnit" data-cy="frequencyUnit" class="form-control">
            <mat-option [value]="frequencyUnit.value" *ngFor="let frequencyUnit of frequencyUnitsList">
              {{frequencyUnit.label}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="two-fields-row">
        <mat-form-field class="input-control" appearance="outline">
          <mat-label>Tiempo de Tratamiento</mat-label>
          <input matInput formControlName="time" data-cy="time" class="form-control" type="text"
                 (keydown)="validatorsService.numberOnly($event)" (paste)="validatorsService.numberOnly($event)"
                 (keyup)="validatorsService.validateNumber('time', therapyForm)"/>
        </mat-form-field>
        <mat-form-field class="input-control" appearance="outline" placeholder="mg">
          <mat-label>Unidades</mat-label>
          <mat-select formControlName="timeUnit" data-cy="timeUnit" class="form-control">
            <mat-option [value]="timeUnit.value" *ngFor="let timeUnit of timeUnitsList">{{timeUnit.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Vía de Administración</mat-label>
        <mat-select matInput formControlName="administrationRoute" data-cy="administrationRoute" class="form-control">
          <mat-option *ngFor="let routeOfAdministration of administrationRouteList"
                      [value]="routeOfAdministration.value">{{ routeOfAdministration.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Cantidad Total</mat-label>
        <input matInput type="number" formControlName="quantity" data-cy="quantity" class="form-control"/>
      </mat-form-field>
      <mat-form-field class="input-control" appearance="outline">
        <mat-label>Indicaciones de Uso</mat-label>
        <textarea formControlName="indication" data-cy="indication"
                  cdkTextareaAutosize cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4" autocomplete="off"
                  matInput></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="button-container flex-row-end mb-0-5">
    <button mat-button class="btn-cancel-stepper" data-cy="close-therapy-dialog" (click)="cancel()">CANCELAR</button>
    <button type="submit" mat-button class="btn-search" data-cy="save-therapy" (click)="onSubmit()">
      GUARDAR
    </button>
  </div>
</mat-dialog-content>
