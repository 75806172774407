import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {GeneraService} from '../../services/genera.service';
import {SpeciesService} from '../../services/species.service';
import {Specimen} from '../../types/specimen.interface';
import {AdministrationInterface} from '../../types/administration.interface';
import {ValidatorsService} from "../../services/validators.service";

@Component({
  selector: 'app-seizure-register-form',
  templateUrl: './seizure-register-form.component.html',
  styleUrls: ['./seizure-register-form.component.scss'],
})
export class SeizureRegisterFormComponent implements OnInit, OnChanges {
  @Output() speciesRegister: EventEmitter<any> = new EventEmitter();
  @Output() changeQuantity: EventEmitter<any> = new EventEmitter();
  @Input() quantity: number = 0;
  @Input() data: Specimen[] | undefined = undefined;
  public generaList: AdministrationInterface[];
  public speciesList: AdministrationInterface[];
  public registerSpecimensForm: FormGroup = this.fb.group({
    specimenForm: this.fb.array([]),
  });
  public validatedData = false;

  constructor(
    private generaService: GeneraService,
    private specieService: SpeciesService,
    private fb: FormBuilder,
    private validatorsService: ValidatorsService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onSelectedQuantity();
  }

  async ngOnInit() {
    await this.getData();
  }

  /**
   * Get selectors options
   */
  public async getData() {
    this.generaList = (await this.generaService.getGenera()).sort((a, b) => a.name.localeCompare(b.name));
    this.speciesList = (await this.specieService.getSpecies()).sort((a, b) => a.name.localeCompare(b.name));
  }

  get specimenForm(): any {
    return this.registerSpecimensForm.get('specimenForm') as FormArray;
  }

  /**
   * add a specimen to the form array
   */
  createSpecimenForm(index: number) {
    const data =
      this.data &&
      index < this.data?.length &&
      this.specimenForm.length < this.data?.length;
    const editable =
      this.data && data
        ? !this.data[index].referencedPreliminaryClinicHistory ||
        this.validatedData
        : true;
    const specimen = this.fb.group({
      genusId: [
        {
          value:
            this.data && data
              ? this.data[index].genus?.id
                ? this.data[index].genus.id
                : ''
              : '',
          disabled: !editable,
        },
        Validators.required,
      ],
      specieId: [
        {
          value: this.data && data ? this.data[index]?.specie?.id : '',
          disabled: !editable,
        },
        Validators.required,
      ],
      commonName: [
        {
          value: this.data && data ? this.data[index].commonName : '',
          disabled: !editable,
        },
        [Validators.required, Validators.pattern(this.validatorsService.alphabeticWithAccentsAndNumberValidator)
          , Validators.maxLength(30)]
      ],
      preliminaryClinicHistory: [
        {
          value: this.data && data ? this.data[index]?.referencedPreliminaryClinicHistory?.id : '',
          disabled: true,
        }
      ],
      editable: [
        this.data && data
          ? !this.data[index].referencedPreliminaryClinicHistory
          : true,
      ],
    });
    if (this.data && index === this.data.length - 1) {
      this.validatedData = true;
    }
    this.specimenForm.push(specimen);
  }

  /**
   * add or delete form groups to the form array according to quantity value
   */
  public onSelectedQuantity() {
    const addItems = this.quantity - this.specimenForm.length;
    for (let i = 0; i < Math.abs(addItems); i++) {
      if (addItems > 0) {
        this.createSpecimenForm(i);
      } else {
        this.deleteItem(this.specimenForm.length - 1 - i, false);
      }
    }
  }

  /**
   * clear form Array
   */
  public removeFormArray() {
    for (let i = this.specimenForm.length - 1; i >= 0; i--) {
      this.specimenForm.removeAt(i);
    }
  }

  /**
   * Delete specimen register from the form array
   * @param index
   * @param changeQuantity emitted when the action is performed from this component
   */
  deleteItem(index: number, changeQuantity = true) {
    this.specimenForm.removeAt(index);
    if (this.data && index < this.data?.length) {
      this.data?.splice(index, 1);
    }
    if (changeQuantity) {
      this.changeQuantity.emit(true);
    }
  }

  canBeDeleted(specimen: any) {
    return true;
  }
}
