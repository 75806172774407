import {Growth} from "../../medical-history/types/medical-history.interface";

export const GROWTH = [
  {value: Growth.ADULT, label: 'Adulto'},
  {value: Growth.GERIATRIC, label: 'Geronte'},
  {value: Growth.YOUTH, label: 'Juvenil'},
  {value: Growth.NEONATE, label: 'Neonato'},
];

export const GrowthHashSpanish: { [type: number]: string } = {
  0: 'Adulto',
  1: 'Sub Adulto',
  2: 'Juvenil',
  3: 'Infantil',
  4: 'Neonato',
  5: 'Geronte',
};


