<mat-accordion *ngIf="patientHistory" class="custom-expansion-panel">
    <mat-expansion-panel #expansion [expanded]="panelOpenState" (opened)="checkStatusOpenPanel()"
                         (closed)="checkStatusOpenPanel()">
        <!--HEADER-->
        <mat-expansion-panel-header (keydown)="validateKey($event)">
            <ng-container>
                <mat-panel-title class="panel-title" [ngClass]="{'open-panel':panelOpenState}">
                    <div class="custom-panel-info" *ngIf="editable && isMedicalExam">
                        <p class="center">Nombre Común:</p>
                        <mat-form-field class="mt-0-5" appearance="outline">
                            <input matInput [formControl]="commonName"/>
                        </mat-form-field>
                    </div>
                    <p class="panel-info" *ngIf="!(editable && isMedicalExam)">Nombre Común:
                        <strong class="ml-0-5">{{patientHistory.commonName}}</strong></p>
                    <p *ngIf="!showClinicHistoryTitle; else secondTitle" class="panel-info">Historia Clínica: <strong
                            class="ml-0-5">{{patientHistory?.clinicHistory?.id || '-'}}</strong>
                    </p>
                    <ng-template #secondTitle>
                        <p class="panel-info">No. Historia Clínica
                            Preliminar: <strong
                                    class="ml-0-5">{{patientHistory.id || '-'}}</strong>
                        </p>
                    </ng-template>
                </mat-panel-title>
            </ng-container>
        </mat-expansion-panel-header>


        <!--EDITABLE-->
        <ng-container *ngIf="editable">
            <form class="info-container" [formGroup]="form">
                <ng-container *ngFor="let field of fieldsInfo">

                    <p class="panel-info" *ngIf="!isEditable(field.name)">{{field.label}}<span
                            *ngIf="field.label !== ''">:</span>
                        <strong> {{field.value}}</strong></p>

                    <div class="custom-panel-info" *ngIf="isEditable(field.name) && field.name!='scientificName'">
                        <p class="mb-2">{{field.label}}:</p>
                        <mat-form-field appearance="outline">
                            <mat-select [formControlName]="field.ctrlName">
                                <mat-option *ngFor="let option of field.options"
                                            [value]="option.id">{{option.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="custom-panel-info" *ngIf="isEditable(field.name) && field.name=='scientificName'">
                        <p class="mb-2">Nombre Científico:</p>
                        <mat-form-field class="" appearance="outline">
                            <input matInput [value]="scientificName" disabled="true"/>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div class="custom-panel-info"></div>

                <mat-divider class="custom-divider" *ngIf="showMarkings"></mat-divider>
                <div class="width-50" *ngIf="showMarkings">
                    <div class="custom-panel-info-marking" *ngIf="editableMarking">
                        <p class="mb-2">Tipo de Marcaje:</p>
                        <mat-form-field class="" appearance="outline">
                            <mat-select formControlName="type">
                                <mat-option *ngFor="let marking of markingType"
                                            [value]="marking.value"> {{marking.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="width-100" *ngIf="!editableMarking">
                        <p class="panel-info" *ngIf="editable && showMarkings">Tipo de marcaje: <strong
                                class="ml-0-5">{{markings[0]?.type ? markingTypesHash[markings[0].type] : ''}}</strong>
                        </p>
                    </div>
                </div>

                <div class="width-50" *ngIf=" showMarkings">
                    <div class="custom-panel-info-marking" *ngIf="editableMarking">
                        <p>Fecha:</p>
                        <mat-form-field class="" appearance="outline">
                            <input matInput [matDatepicker]="picker" onkeydown="return false"
                                   [matDatepickerFilter]="dateFilter"
                                   formControlName="markingDate" readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="width-100" *ngIf="!editableMarking">
                        <p class="panel-info" *ngIf="editable && showMarkings">Fecha: <strong
                                class="ml-0-5">{{datePipe.transform(markings[0]?.markingDate, 'dd/MM/yyyy') || ''}}</strong>
                        </p>
                    </div>
                </div>

                <div class="width-50" *ngIf=" showMarkings">
                    <div class="custom-panel-info-marking" *ngIf="editableMarking">
                        <p class="mb-2">No. de Chip:</p>
                        <mat-form-field class="" appearance="outline">
                            <input matInput formControlName="markingNo"
                                   (keypress)="validatorService.alphanumericOnlyStrict($event)" (paste)="false"/>
                        </mat-form-field>
                    </div>
                    <div class="width-100" *ngIf="!editableMarking">
                        <p class="panel-info" *ngIf="editable && showMarkings">No. de Chip: <strong
                                class="ml-0-5">{{markings[0].markingNo}}</strong>
                        </p>
                    </div>
                </div>

                <div class="width-50" *ngIf="(isMedicalExam || isNewMarking) && !isAdmissionDeparture">
                    <div class="width-100 mb-1" *ngIf="editableMarking">
                        <button mat-button (click)="addChip()" class="btn-cancel-stepper float-right">AGREGAR
                            REGISTRO
                        </button>
                    </div>
                </div>

            </form>
        </ng-container>

        <!--TABLE-->
        <div class="text-m-14-light-blue title-history mb-0-5">
            <span *ngIf="editable && showMarkings"><strong>Historial</strong></span>
        </div>
        <div class="table-c">
            <app-table *ngIf="editable && showMarkings" [sortBy]="'createdAt'" [data]="dataSourceMarkings"
                       [displayedColumns]="displayedColumns"
                       [tableLength]="tableLength" [showDownloadExcel]="false" [hidePaginator]="true">
            </app-table>
        </div>
        <!--FORM OPTIONS-->
        <div class="button-container display-right-button mb-2" *ngIf="editable && saveLocally">
            <button mat-button class="btn-cancel-stepper" (click)="clear(true)">CANCELAR</button>
            <button type="submit" mat-button class="btn-search" (click)="sendInformation()">
                GUARDAR
            </button>
        </div>

        <!--FORM OPTIONS-->
        <div class="button-container display-right-button mb-2" *ngIf="editable && !saveLocally">
            <button mat-button class="btn-cancel-stepper" (click)="clear(true)">CANCELAR</button>
            <button type="submit" mat-button class="btn-search" (click)="sendInformationNoLocally()">
                GUARDAR
            </button>
        </div>

        <!--NOT EDITABLE-->
        <ng-container *ngIf="!editable">
            <div class="info-container">
                <p class="panel-info" *ngFor="let field of fieldsInfo">{{field.label}}<span
                        *ngIf="field.label !== ''">:</span>
                    <strong> {{field.value}}</strong></p>
            </div>
            <div class="width-100">
                <p class="panel-info" *ngFor="let field of otherInfo">{{field.label}}<span
                        *ngIf="field.label !== ''">:</span>
                    <strong> {{field.value}}</strong></p>
            </div>
        </ng-container>
        <!--MARKING SECTION-->
        <mat-divider class="custom-divider" *ngIf="!editable && showMarkings"></mat-divider>
        <p class="panel-info" *ngIf="!editable && showMarkings">Tipo de Marcaje: <strong
                class="ml-0-5">{{markings[0]?.type ? markingTypesHash[markings[0].type] : ''}}</strong>
        </p>
        <p class="panel-info" *ngIf="!editable && showMarkings">Fecha: <strong
                class="ml-0-5">{{datePipe.transform(markings[0]?.markingDate, 'dd/MM/yyyy') || ''}}</strong></p>
        <p class="panel-info" *ngIf="!editable && showMarkings">No. de Chip: <strong
                class="ml-0-5">{{markings[0]?.markingNo || ''}}</strong>
        </p>
        <div class="custom-panel-info" *ngIf="!editable && showMarkings"></div>

    </mat-expansion-panel>
</mat-accordion>
