import {Specimen} from 'src/app/shared/types/specimen.interface';
import {AdministrationInterface} from '../../shared/types/administration.interface';

export interface SeizureRecord {
  id: number;
  recordNumber: string;
  date: string;
  regional: AdministrationInterface;
  files: { id: number, bucket: string, objectName: string }[];
  filesURL?: Array<string>;
  quantity: number;
  status: SpecimenStatus;

  originDepartmentId: number | null;
  originMunicipalityId: number | null;

  specimens: Specimen[];
  createdAt: Date | string;
  updatedAt: Date | string;
  deletedAt: Date | string;
  statusT?: string;
  incomeClassification: IncomeClassification;
  fileUrl: string;
}

export enum SpecimenStatus {
  REGISTERED,
  IN_ASSOCIATION,
  ASSOCIATED,
}

export enum IncomeClassification {
  VOLUNTEER = 'volunteer',
  SEIZURE = 'seizure',
  RESTITUTION = 'restitution',
  PREVENTIVE_APPREHENSION = 'preventive-apprehension',
  PREVENTIVE_SEIZURE = 'preventive_seizure',
  OTHER = 'other',
}

export const SeizureIncomeHashSpanish: { [type: string]: string } = {
  volunteer: 'Entrega voluntaria',
  seizure: 'Incautación',
  restitution: 'Restitución',
  'preventive-apprehension': 'Aprehensión preventiva',
  preventive_seizure: 'Incautación preventiva',
  other: 'Otro',
};

export const incomeClassificationList: Array<{ value: string, description: string }> = [
  {value: IncomeClassification.VOLUNTEER, description: 'Entrega voluntaria'},
  {value: IncomeClassification.SEIZURE, description: 'Incautación'},
  {value: IncomeClassification.RESTITUTION, description: 'Restitución'},
  {value: IncomeClassification.PREVENTIVE_APPREHENSION, description: 'Aprehensión preventiva'},
  {value: IncomeClassification.PREVENTIVE_SEIZURE, description: 'Incautación preventiva',},
  {value: IncomeClassification.OTHER, description: 'Otro'},
];
