import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {
  Safeguard,
  SafeguardSeizureStatusHashSpanish,
} from '../types/safeguard.interface';
import {SeizureRecord} from '../../seizure-record/types/seizure-record.interface';
import {ServerResponse} from '../../shared/types/http.interfaces';
import {FormGroup} from '@angular/forms';
import {CalendarManagerService} from '../../shared/services/calendar-manager.service';
import {DatePipe} from '@angular/common';
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root',
})
export class SafeguardService {
  private _baseUrl = environment.api + '/processesanddocumentsservice-ms';

  constructor(
    private http: HttpClient,
    private readonly sanitizer: DomSanitizer,
    private calendarManager: CalendarManagerService,
    private datePipe: DatePipe
  ) {
  }

  getSafeguardUrl() {
    return `${this._baseUrl}/safepassagerecords`;
  }

  public async getSafeguards(params?: HttpParams): Promise<Safeguard[]> {
    const res = await this.http
      .get<ServerResponse<Safeguard[]>>(`${this._baseUrl}/safepassagerecords`, {
        params,
      })
      .toPromise();
    return res.data.map((data) => {
      const statusT = SafeguardSeizureStatusHashSpanish[data.status.toString()];
      const regionT = data.regional?.name || '';
      return {
        dateT: this.datePipe.transform(data.date, 'dd/MM/yyyy'),
        regionT,
        statusT,
        ...data,
      };
    });
  }

  public async saveSafeguardRecords(
    form: FormGroup,
    file: File,
    specimens: any
  ) {
    const formData = this.getSaveUpdateBody(form, file, specimens);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const res = await this.http
      .post<ServerResponse<Safeguard>>(
        `${this._baseUrl}/safepassagerecords`,
        formData,
        {headers}
      )
      .toPromise();
  }

  public async downloadSafeguard(params: HttpParams) {
    let result: any;
    if (params.get('status') === 'undefined') {
    } else {
      result = await this.http
        .get<any>(`${this._baseUrl}/safepassagerecords/download`, {
          params,
          responseType: 'blob' as 'json',
        })
        .toPromise();
    }
    return result;
  }

  async getSafeguardById(id: number): Promise<Safeguard> {
    const res = await this.http
      .get<ServerResponse<Safeguard>>(
        `${this._baseUrl}/safepassagerecords/${id}`
      )
      .toPromise();
    return res.data;
  }

  async updateSafeguardRecords(
    form: FormGroup,
    file: File,
    specimens: string,
    id: number
  ) {
    const formData = this.getSaveUpdateBody(form, file, specimens, true);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return await this.http
      .put<SeizureRecord>(
        `${this._baseUrl}/safepassagerecords/${id}`,
        formData,
        {headers}
      )
      .toPromise();
  }

  private getSaveUpdateBody(
    form: FormGroup,
    file: File,
    specimens: string,
    update = false
  ) {
    const formData = new FormData();
    if (!update) {
      formData.append(
        'recordNumber',
        form.get('recordNumber_safeguard_register')?.value
      );
      formData.append(
        'date',
        this.calendarManager.changeFormatDate(form.get('date')?.value)
      );
      formData.append(
        'regionalId',
        form.get('regional_safeguard_register')?.value
      );
    }
    formData.append('quantity', form.get('quantity_safeguard_register')?.value);
    if (file) {
      formData.append('files', file);
    }
    formData.append('specimens', specimens);
    return formData;
  }

  public deleteRegisterSafeguard(id: number): Promise<any> {
    return this.http
      .delete(`${this._baseUrl}/safepassagerecords/${id}`)
      .toPromise();
  }

  async downloadSafeguardFile(response: string, filename: string): Promise<void> {
    fetch(
      response
    ).then((data: Response) => {
      data.blob().then((data: Blob) => {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(data);
        link.download = `Acta de salvoconducto ${filename}.pdf`;
        link.click();
      });
    });
  }

  /**
   * delete document of safeguard record by id
   * @param id
   * @param filesToDelete
   */
  public async deleteDocumentSafepassage(id: number, filesToDelete: number[]) {
    let formData = new FormData();
    formData.append('filesToDelete', JSON.stringify(filesToDelete));
    return this.http
      .put(`${this._baseUrl}/safepassagerecords/${id}`, formData)
      .toPromise();
  }
}
