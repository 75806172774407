<mat-accordion *ngIf="patientHistory" class="custom-expansion-panel">
  <mat-expansion-panel [expanded]="panelOpenState" (opened)="checkStatusOpenPanel()" (closed)="checkStatusOpenPanel()">
    <!--HEADER-->
    <mat-expansion-panel-header>
      <ng-container>
        <mat-panel-title class="panel-title" [ngClass]="{'open-panel':panelOpenState}">
          <p class="panel-info" *ngIf="!(editable)">Tipo de ingreso:
            <strong class="ml-0-5">{{patientHistory?.entryRecord?.admissionType==='entry'?'Ingreso':'Reingreso'}}</strong></p>
          <p class="panel-info">Fecha: <strong
              class="ml-0-5">{{patientHistory?.entryRecord?.admissionDate| date: 'dd/MM/yyyy'}}</strong>
          </p>
        </mat-panel-title>
      </ng-container>
    </mat-expansion-panel-header>

    <!--NOT EDITABLE-->
    <ng-container>
      <div class="info-container">
        <p class="panel-info" *ngFor="let field of fieldsInfo">{{field.label}}: <strong> {{field.value}}</strong></p>
      </div>
    </ng-container>
    <div class="table-width">
      <div class="flex-row-space-between">
      <div class="icon-container-center mb-1 mt-1">
        <span class="material-icons-two-tone mr-1"> person </span>
        <span class="text-m-14-light-blue"><strong>Funcionario que realiza la entrega</strong></span>
      </div>
      </div>
    <mat-divider class="mb-2"></mat-divider>
    <ng-template matStepLabel><span class="text-m-14-blue">Funcionario</span></ng-template>
    <div class="width-100 mt-1 mb-1" *ngIf="data.length > 0">
      <app-table [data]="dataSourceT" [hidePaginator]="true" [actions]="actions"
                 [useTable]="'makeDelivery'" [displayedColumns]="displayedColumnsPro"
                 [showDownloadExcel]="false">
      </app-table>
    </div>
      <div class="flex-row-space-between">
        <div class="icon-container-center mb-1 mt-1">
          <span class="material-icons-two-tone mr-1"> person </span>
          <span class="text-m-14-light-blue"><strong>Funcionario que recibe los ejemplares</strong></span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="width-100 mt-1 mb-1">
        <app-table [hidePaginator]="true" [data]="dataSourceE" [actions]="actions"
                   [useTable]="'receiveCopies'" [displayedColumns]="displayedColumnsPro"
                   [showDownloadExcel]="false">
        </app-table>
      </div>
      <div class="flex-row-space-between">
        <div class="icon-container-center mb-1 mt-1">
          <span class="material-icons-two-tone mr-1"> person </span>
          <span class="text-m-14-light-blue"><strong>Funcionario que registra el ingreso</strong></span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="width-100 mt-1 mb-1">
        <app-table [hidePaginator]="true" [data]="dataSourceR" [actions]="actions"
                   [useTable]="'receiveCopies'" [displayedColumns]="displayedColumnsPro"
                   [showDownloadExcel]="false">
        </app-table>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
